import * as PIXI from 'pixi.js-legacy';
import { EventSystem } from '@pixi/events';

import WideoObject from "./WideoObject";
import EngineUtils from "./EngineUtils";

import Wideo from "./Wideo";
import Logger from "../log/Logger";

export default class Engine {
  public renderer: PIXI.AbstractRenderer;

  public stage: PIXI.Container;
  private zoomAndPan: PIXI.Container;
  private wideo: Wideo;

  /**
   * Initializes the Engine. This method should be called ONCE and only ONCE. It
   * creates the PIXI renderer and appends it to the wrapping DOM element.
   *
   */
  public init(view: HTMLCanvasElement, forceCanvas: boolean, antialias: boolean, width: number, height: number): void {

    // Set all PIXI settings before creating the renderer to avoid problems
    PIXI.settings.MIPMAP_TEXTURES = PIXI.MIPMAP_MODES.ON;
    PIXI.settings.SCALE_MODE = PIXI.SCALE_MODES.LINEAR;
    PIXI.settings.RESOLUTION = 1;

    // Create the Renderer using PIXI Autodetect (WebGL o Canvas)
    const options: Object = {
      view: view,
      resolution: PIXI.settings.RESOLUTION,
      backgroundColor: 0x7f7f7f,
      antialias: antialias && (this.isNotChromeOnMacWithNvidiaGeforceGT650M()),
      preserveDrawingBuffer: false,
      forceCanvas: forceCanvas,
      width: width,
      height: height
    };

    this.renderer = PIXI.autoDetectRenderer(options);

    /* BEGIN PIXI Upgrade to 6.5.1 change InteractionManage for EventSystem */
    // Uninstall interaction
    PIXI.extensions.remove(PIXI.InteractionManager);

    // Install events
    this.renderer.addSystem(EventSystem, 'events');
    /* END PIXI Upgrade to 6.5.1 change InteractionManage for EventSystem */
    
    // Create an empty stage
    this.stage = new PIXI.Container();
    this.stage.name = "Stage";

    // Create a container to manage Zooming and Panning
    this.zoomAndPan = new PIXI.Container();
    this.zoomAndPan.name = "ZoomAndPan";
    this.stage.addChild(this.zoomAndPan);

  }

  
  private isNotChromeOnMacWithNvidiaGeforceGT650M() {
    const canvas = document.createElement('canvas');
    const gl = canvas.getContext('webgl');
    if (gl) {
      const platformIsMacIntel = window.navigator.platform === 'MacIntel';
      const debugInfo = gl.getExtension('WEBGL_debug_renderer_info');
      if (debugInfo) {
        const isNvidiaGeforceGT650M = gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL) === 'NVIDIA GeForce GT 650M OpenGL Engine';
        if (this.isChrome() && platformIsMacIntel && isNvidiaGeforceGT650M) {
          Logger.warn('Detected Nvidia Geforce GT 650, Chrome and MacOS, falling back to no antialias.')
          return false;
        }
        else {
          return true;
        }
      } 
      else {
        return true;
      }
    } else {
      return true;
    }
  }

  private isChrome() {
    // please note, 
    // that IE11 now returns undefined again for window.chrome
    // and new Opera 30 outputs true for window.chrome
    // but needs to check if window.opr is not undefined
    // and new IE Edge outputs to true now for window.chrome
    // and if not iOS Chrome check
    // so use the below updated condition
    const isChromium = window['chrome'];
    const winNav = window.navigator;
    const vendorName = winNav.vendor;
    const isOpera = typeof window['opr'] !== "undefined";
    const isIEedge = winNav.userAgent.indexOf("Edge") > -1;
    const isIOSChrome = winNav.userAgent.match("CriOS");

    if (isIOSChrome) {
      return true;
    } else if (
      isChromium !== null &&
      typeof isChromium !== "undefined" &&
      vendorName === "Google Inc." &&
      isOpera === false &&
      isIEedge === false
    ) {
      return true;
    } else {
      return false;
    }
  }

  public getRendererGLParameter() {
    const debugInfo = this.renderer['gl'].getExtension('WEBGL_debug_renderer_info');
    return this.renderer['gl'].getParameter(debugInfo.UNMASKED_RENDERER_WEBGL);
  }

  public getVendorGLParameter() {
    const debugInfo = this.renderer['gl'].getExtension('WEBGL_debug_renderer_info');
    return this.renderer['gl'].getParameter(debugInfo.UNMASKED_VENDOR_WEBGL);
  }

  public removeAndAddWideo(wideo: Wideo) { 
    if (this.zoomAndPan.children.length > 0) {
      this.zoomAndPan.removeChild(wideo);
    }
    this.addWideo(wideo);
  }

  public addWideo(wideo: Wideo) {
    this.wideo = wideo;
    this.zoomAndPan.addChild(wideo);
  }

  public getStage() {
    return this.stage;
  }

  public resizeRenderer(width: number, height: number) {
    if (this.renderer) {
      this.renderer.resize(width, height);
      this.renderer.view.style.width = (parseFloat(this.renderer.view.getAttribute("width")) / window.devicePixelRatio) + "px";
      this.renderer.view.style.height = (parseFloat(this.renderer.view.getAttribute("height")) / window.devicePixelRatio) + "px";
    }
  }

  public getAvailableWidth(): number {
    if (this.renderer && this.renderer.view.parentElement) {
      return this.renderer.view.parentElement.clientWidth * window.devicePixelRatio;
    }
    return 1920;
  }

  public getAvailableHeight(): number {
    if (this.renderer && this.renderer.view.parentElement) {
      return this.renderer.view.parentElement.clientHeight * window.devicePixelRatio;
    }
    return 1080;
  }

  public scale(scale: number) {
    if (this.zoomAndPan) { 
      this.zoomAndPan.scale.x = scale;
      this.zoomAndPan.scale.y = scale;
    }
  }

  public getScale(): number {
    if (this.zoomAndPan) { 
      return this.zoomAndPan.scale.x;
    }
    return null;
  }

  public scaleToFit(): number {
    if (this.renderer && this.renderer.view.parentElement && this.wideo) {
      const scaleX: number =
        this.getAvailableWidth() / this.wideo.getWidth();
      const scaleY: number =
        this.getAvailableHeight() / this.wideo.getHeight();
      const scale: number = Math.min(scaleX, scaleY);
      if (scale <= 1) {
        return scale;
      } else {
        return 1;
      }
    }
    return 1;
  }

  public centerDisplay() {
    if (this.renderer && this.renderer.view.parentElement && this.wideo) {
      const width = this.wideo.getWidth();
      const height = this.wideo.getHeight();
      const x = (this.getAvailableWidth() -
        width * this.zoomAndPan.scale.x) / 2
      const y = (this.getAvailableHeight() -
        height * this.zoomAndPan.scale.y) / 2;
      this.translate(x, y);
    }
  }

  public translate(newX: number, newY: number) {
    this.zoomAndPan.x = newX;
    this.zoomAndPan.y = newY;
  }

  public pan(dx: number, dy: number) {
    this.zoomAndPan.x += dx;
    this.zoomAndPan.y += dy;
  }

  public render(): void {
    try {
      if (this.renderer) {
        this.renderer.render(this.stage as any);
      }
    } catch (error) {
      // Silently ignore all render errors since it totally spams logdna 
      // (in the future we can have some kind of counters here instead)
      // Logger.error('Unexpected error in Engine.render(): ' + Logger.errorToString(error));
      // throw error;
    }
  }

  public getObjectSnapshot(object: WideoObject, boundsWidthHeight: number): HTMLImageElement {
    const objectBounds: PIXI.Rectangle = object.getLocalBounds();
    const objectMaxSize: number = Math.max(
      objectBounds.width,
      objectBounds.height
    );
    const scale: number = boundsWidthHeight / objectMaxSize;

    return EngineUtils.createImageFromObject(object, scale, this.renderer);
  }

  public destroy = () => {
    if (this.renderer) {
      this.renderer.destroy();
      this.renderer = null;
    }

    if (this.stage) {
      this.stage.destroy();
      this.stage = null;
    }

    if (this.zoomAndPan) {
      this.zoomAndPan.destroy();
      this.zoomAndPan = null;
    }

    if (this.wideo) {
      this.wideo.destroy();
      this.wideo = null;
    }
  };
}
