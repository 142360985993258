import {WideoDefVersion} from "../../core/model/WideoDef";
import ITransformer from "../ITransformer";

export default class TransformerToV46 implements ITransformer {
    readonly toVersion: WideoDefVersion = WideoDefVersion.V46;

    private templateWideoIds: string[] = [
      "21533741217801",
      "756721533305439900",
      "756721533301784838",
      "756721533144568300",
      "756721533156204898",
      "21533315812165",
      "21533667339952",
      "21533669159661",
      "21533743703451",
      "21533748774231",
      "21533748980714",
      "21533751972720",
      "21533751732268",
      "21533755150661",
      "21533840262624",
      "21533841238137",
      "21534360542240",
      "21534362109487",
      "21534361999133",
      "21534429286876",
      "21534433077846",
      "21534437832043",
      "21534441029563",
      "21534442000697",
      "21534530574252",
      "21534530453804",
      "21534866388516",
      "21534866952834",
      "21534951208535",
      "21534968608825",
      "21535039216140",
      "21535041947585",
      "21535054623136",
      "21535378336624",
      "21537208496758",
      "21535381974138",
      "21535382848148",
      "21535471231217",
      "21535567313743",
      "2153557311204",
      "21537208685418",
      "21537537819204",
      "756721417621629972",
      "756721432671514921",
      "756721432671505668",
      "21539106604250",
      "756721440686056704",
      "21539182380214",
      "21539182805294",
      "21539199302911",
      "21539283719259",
      "21539288548446",
      "21539802356135",
      "21540393726776",
      "21540394110437",
      "21540394643996",
      "21540401108452",
      "21540401139467",
      "21540402925083",
      "21540404984843",
      "21540406979412"
    ];

    // tslint:disable-next-line:no-any
    public transformWideo( wideoDef: any ) {

      //Update version and timestamp
      wideoDef.version = this.toVersion;
      wideoDef.timeStamp = Date.now();

      // Set AudioComponents repeat property default to false
      // tslint:disable-next-line:no-any
      wideoDef.audioComponents.map( (audioComponent: any) => {
        if ( this.templateWideoIds.indexOf(wideoDef.id) === -1) {
          // This is not a Wideo Template (set repeat to false)
          audioComponent.repeat = false;
        } else {
          // This is a Wideo Template (set repeat to true since all our templates have music that should repeat)
          audioComponent.repeat = true;
        }
      });

      for (const sceneDef of wideoDef.scenes) {
        this.transformScene(sceneDef);
      }

    }   

    // tslint:disable-next-line:no-any
    transformScene(sceneDef: any): void {
      sceneDef.version = this.toVersion;
      sceneDef.brightness = 0.5;
      for (const wideoObjectDef of sceneDef.objects) {
        this.transformWideoObject(wideoObjectDef);
      }
    }

    // tslint:disable-next-line:no-any
    transformWideoObject(wideoObjectDef: any): void {

      for (const innerObjectDef of wideoObjectDef.objects) {
          this.transformWideoObject(innerObjectDef);
      }

      for (const componentDef of wideoObjectDef.components) {
        this.transformComponent(wideoObjectDef, componentDef);
      }

      // Make sure that the wideo object has a brightness after parsing all the components
      if (wideoObjectDef.brightness !== 0 && !wideoObjectDef.brightness) {
        wideoObjectDef.brightness = 0.5;
      }
  
    }

    // tslint:disable-next-line:no-any
    transformComponent(wideoObjectDef: any, componentDef: any): void {   
      // Move brightness from the component to the WideoObject   
      if (componentDef.brightness === 0 || componentDef.brightness) {
        wideoObjectDef.brightness = componentDef.brightness;
      } else {
        wideoObjectDef.brightness = 0.5;
      }
      delete componentDef.brightness;
    }


}