import queryString from 'query-string';
import Logger from '../common/log/Logger';

export default class ApiUtils {
    /** Perform HTTP GET request and deserialize/parse the expected JSON string response to a
     *  Javascript object.
     *
     *  On anything other than a 2xx response it throws an (async)exception/rejects the returned promise
     */
    public static httpGetJson = async <R extends {}>(baseUrl: string, path: string, queryParams: { [s: string]: string; } = {}, headers: { [s: string]: string; } = {}): Promise<R> => {
        const url = ApiUtils.buildUrl(baseUrl, path, queryParams);
        const init = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                ...headers
            },
            mode: 'cors' as RequestMode
        };

        try {
            const response = await fetch(url, init);
            return ApiUtils.parseJsonResponse<R>(response);
        } catch (error) {
            Logger.warn('Failed GET request to ' + url + '. Reason: ' + Logger.errorToString(error));
            throw error;
        }

    }
    /**
     * 
     */
    public static httpPost = async <R>(baseUrl: string, path: string, queryParams: { [s: string]: string; } = {}): Promise<R> => {

        const url = ApiUtils.buildUrl(baseUrl, path, queryParams);
        const init = {
            method: 'POST',
            headers: {
                'Accept': 'application/json'
            },
            mode: 'cors' as RequestMode
        };
        try {
            const response = await fetch(url, init);
            return ApiUtils.parseJsonResponse<R>(response);
        } catch (error) {
            Logger.warn('Failed POST request to ' + url + '. Reason: ' + Logger.errorToString(error));
            throw error;
        }

    }

    /** Perform HTTP POST request by serializing the supplied body to a JSON string. Deserializes/parse the
     *  expected JSON string response to a Javascript object.
     *
     *  On anything other than a 2xx response it throws an (async)exception/rejects the returned promise
     */
    public static httpPostJson = async <R, B>(baseUrl: string, path: string, queryParams: { [s: string]: string; } = {}, body: B): Promise<R> => {

        const url = ApiUtils.buildUrl(baseUrl, path, queryParams);
        const init = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            mode: 'cors' as RequestMode,
            body: JSON.stringify(body)
        };

        try {
            const response = await fetch(url, init);
            return ApiUtils.parseJsonResponse<R>(response);
        } catch (error) {
            Logger.warn('Failed POST request with JSON data to ' + url + '. Reason: ' + Logger.errorToString(error));
            throw error;
        }

    }

    /** Perform HTTP POST request by serializing the supplied body to a JSON string. Deserializes/parse the
     *  expected JSON string response to a Javascript object.
     *
     *  On anything other than a 2xx response it throws an (async)exception/rejects the returned promise
     */
    public static httpPostFormData = async <R>(baseUrl: string, path: string, formData: FormData, queryParams: { [s: string]: string; } = {}): Promise<R> => {

        const url = ApiUtils.buildUrl(baseUrl, path, queryParams);
        const init = {
            method: 'POST',
            headers: {
                'Accept': 'application/json'
            },
            mode: 'cors' as RequestMode,
            body: formData
        };

        try {
            const response = await fetch(url, init);
            return ApiUtils.parseJsonResponse<R>(response);
        } catch (error) {
            Logger.warn('Failed POST request with form data to ' + url + '. Reason: ' + Logger.errorToString(error))
            throw error;
        }

    }

    public static async parseJsonResponse<R>(response: Response): Promise<R> {
        // Make sure it is a 2xx response and await parsing of response json to object
        if (response.ok) {
            try {
                const responseText: string = await response.text();
                if (responseText && responseText.length > 0) {
                    return JSON.parse(responseText);
                } else {
                    return null;
                }
            } catch (error) {
                Logger.warn("Failed parsing json response. url: " + response.url)
                throw error;
            }
        } else {
            //otherwise throw error/reject Promise
            const body = await response.text();
            throw new Error('Failed REST request because ' +
                response.status + ': ' +
                response.statusText + ", url: " +
                response.url + ", headers: " +
                ApiUtils.getAllHeaders(response) +
                ', body: ' + body);
        }
    }

    public static getAllHeaders(response: Response): string {
        let headerStr = 'HEADERS: ';
        response.headers.forEach((value, key) => {
            headerStr += value + '=' + key;
        })
        return headerStr;
    }

    public static buildUrl(baseUrl: string, path: string, queryParams: { [key: string]: string; }): string {
        const _queryString = queryString.stringify(queryParams);
        let finalUrl: string = baseUrl + path;
        
        if (_queryString && _queryString.length) {
            finalUrl += '?' + _queryString;
        }

        if (this.isURL(finalUrl)) {
            return finalUrl;
        } else {
            throw new Error('Please make sure you are building a valid url: ' + finalUrl);
        }
    }s

    private static isURL(str: string): boolean {
        const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
        return pattern.test(str);
    }

}
