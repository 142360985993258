import * as PIXI from 'pixi.js-legacy';
import AssetsLoader from './AssetsLoader';
import MaskObject from './MaskObject';
import Logger from '../log/Logger';

export default class WideoContext {
  private assetsLoader: AssetsLoader;
  private masksQueue: MaskObject[] = [];
  private renderer: PIXI.AbstractRenderer;
  private watermarkSrc: string;

  public setAssetsLoader(loader: AssetsLoader) {
    this.assetsLoader = loader;
  }

  public getAssetsLoader() {
    return this.assetsLoader;
  }

  public pushMask(maskObject: MaskObject) {
    this.masksQueue.push(maskObject);
  }

  public applyMasks() {
    for (const maskObject of this.masksQueue) {
      maskObject.apply();
    }
    this.masksQueue = [];
  }

  public async applyAndLoad(reason: string) {
    Logger.leaveBreadcrumb("ApplyAndLoad: " + reason);
    // Apply the masks from the queue
    this.applyMasks();

    // Start loading all the assets
    await this.getAssetsLoader().forceLoad(reason);
    this.getAssetsLoader().reset();
  }

  public setWatermarkSrc(watermark: string) {
    this.watermarkSrc = watermark;
  }

  public getWatermarkSrc(): string {
    return this.watermarkSrc;
  }

  public setRenderer(renderer: PIXI.AbstractRenderer): void {
    this.renderer = renderer;
  }

  public getRenderer(): PIXI.AbstractRenderer {
    return this.renderer;
  }

  public destroy() {
    if (this.assetsLoader) {
      this.assetsLoader.destroy();
      this.assetsLoader = null;
    }

    if (this.masksQueue) {
      // NOTE: The masks contained in the masksQueue are not owned by the context
      this.masksQueue = null;
    }

    // NOTE: this.editor and this.renderer are not owned by the WideoContext (they are just kept for reference)
    // private editor: Editor;
    // private renderer: PIXI.AbstractRenderer;
  }

  public debug(): Object {
    return {
      assetsLoader: this.assetsLoader.debug(),
      masksQueue: this.masksQueue.map((maskObject: MaskObject) => {
        return maskObject.getId() + ' - ' + maskObject.getTargetId();
      })
    };
  }

}
