import {WideoDefVersion, Class} from "../../core/model/WideoDef";
import ITransformer from "../ITransformer";

export default class TransformerToV49 implements ITransformer {
    readonly toVersion: WideoDefVersion = WideoDefVersion.V49;

    // tslint:disable-next-line:no-any
    public transformWideo( wideoDef: any ) {

      //Update version and timestamp
      wideoDef.version = this.toVersion;
      wideoDef.timeStamp = Date.now();

      for (const sceneDef of wideoDef.scenes) {
        this.transformScene(sceneDef);
      }

    }   

    // tslint:disable-next-line:no-any
    transformScene(sceneDef: any): void {

      //Update version 
      sceneDef.version = this.toVersion;

      // Remove all MaskComponents from all Scenes
      // tslint:disable-next-line:no-any
      sceneDef.components = sceneDef.components.filter( function(component: any){
        return component.class !== Class.MaskComponent;    
      });
    }

}