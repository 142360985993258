import { AttributesDef } from './model/WideoDef';

export default class Attributes {
    x: number;         // The position (x, y) attributes are additive
    y: number;
    rotation: number;  // In radians, rotation is additive
    scaleX: number;    // The scale (x, y) is multiplicative
    scaleY: number;
    alpha: number;     // As a decimal [0..1] corresponding to [0%..100%] opacity, alpha i multiplicative

    constructor( attributesDef: AttributesDef ) {
      this.x = attributesDef.x;
      this.y = attributesDef.y;
      this.rotation = attributesDef.rotation;
      this.scaleX = attributesDef.scaleX;
      this.scaleY = attributesDef.scaleY;
      this.alpha = attributesDef.alpha;
    }

    public serialize = (): AttributesDef => {
      return {
        x: this.x,
        y: this.y,
        scaleX: this.scaleX,
        scaleY: this.scaleY,
        rotation: this.rotation,
        alpha: this.alpha
      }
    }

}
