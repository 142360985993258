import * as React from 'react'
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { SvgIconProps } from '@mui/material/SvgIcon';

import { WithTranslation, withTranslation } from 'react-i18next';

interface Props {
  icon: SvgIconProps,
  text: string,
}

interface State {
}

type PropsWithStyles = Props & WithTranslation;

//type PropsWithStyles = Props & WithTranslation & WithStyles<''>

class Placeholder extends React.Component<PropsWithStyles, State>{

  constructor(props: PropsWithStyles) {
    super(props);
  }

  render() {
    const { 
      icon, 
      text } = this.props;
      return (
        <Box width="100%" py={8}>
          <Grid
            container
            direction="column"
            alignContent="center"
            alignItems="center"
          >
            <Grid item>
              <Box fontSize={100}>
                {icon as any}
              </Box>
            </Grid>
            <Grid item>
              <Box maxWidth={200} textAlign="center" color="#949494">
                <Typography variant="body1" color="inherit">
                  {text}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      );
  };

}

export default withTranslation("leftbar")(Placeholder);

