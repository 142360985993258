import * as React from 'react'
import { Container, Grid, Box, Fab, Typography, Link } from '@mui/material';

import { withTranslation, WithTranslation } from 'react-i18next';

interface Props {
    typeError: string;
    image: string;
    title: string;
    content: string;
    buttonText: string;
    handleOnClickError: () => void,
    handleGoToHelpCenter: () => void,
}

interface State {
}

type PropsWithStyles = Props & WithTranslation;

class EditorErrorPage extends React.Component<PropsWithStyles, State> {

    constructor(props: PropsWithStyles) {
        super(props);
    }

    handleOnClickError = () => {
        this.props.handleOnClickError();
    }

    render() {
        const { t } = this.props;
        return (
            <Container maxWidth="xs" cyid="editorErrorPage" >
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <img
                            alt="wideo error message"
                            src={this.props.image}
                            height="412px"
                        />
                        <Box textAlign="center">
                            <Typography variant="h3" component="h1" color="primary" gutterBottom>
                                <Box component="span" display="block" fontWeight="fontWeightLight">{this.props.title}</Box>
                            </Typography>
                            <Typography variant="body1" color="textSecondary" gutterBottom>
                                <Box component="span" display="block" fontWeight="fontWeightLight" mb={3}>{this.props.content}</Box>
                            </Typography>

                            <Fab variant="extended" color="primary" aria-label="Retry" onClick={this.props.handleOnClickError}>
                                <Box component="span" display="block" px={4}>{this.props.buttonText}</Box>
                            </Fab>
                            {this.props.typeError === 'loadError' && 
                                <Box display="block" fontWeight="fontWeightLight" mt={4}>
                                    <Typography variant="body2" color="textSecondary" gutterBottom>
                                        { t("error.secondaryText-1") }&nbsp;
                                        <Link
                                            onClick={this.props.handleGoToHelpCenter}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            { t("error.secondaryText-2") }
                                        </Link>.
                                    </Typography>
                                </Box>
                            }
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        );
    }
}

export default withTranslation("common")(EditorErrorPage);

