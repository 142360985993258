
import { environments } from '../common/Env';
import WebSocketConnection from './websocket/WebsocketConnection';
import AwsApi from './AwsApi';

import { v4 as uuid } from 'uuid';
import WideoFile from '../common/core/model/WideoFile';
import {videoMaxWidthHeight, videoMaxDuration} from '../editor/core/EditorConstants';
import Logger from '../common/log/Logger';

export interface OptimizedAsset {
  url: string;
  mimeType: string;
}

export default class OptimizeAssetApi {

  private environment: string;

  constructor(environment: string) {
    this.environment = environment;
  }

  public async uploadAssetForOptimization(inputFile: File | WideoFile): Promise<string> {
    // Upload original asset file directly to S3 using Coqnito Identity Pool
    const bucket = environments[this.environment].assetUploadsBucket;
    const originalKey = uuid();
    const originalS3Url = "https://s3.amazonaws.com/" + bucket + "/" + originalKey ; 
    const awsApi = new AwsApi(environments[this.environment].identityPoolId, environments[this.environment].region);
    await awsApi.uploadToS3(bucket, originalKey, inputFile);
    return originalS3Url;
  }

  public async optimizeAsset(originalUrl: string): Promise<OptimizedAsset> { 
    
    // Connect Websocket 
    const wsConnection = new WebSocketConnection();
    try {
      await wsConnection.connect(environments[this.environment].optimizeAssetApiUrl);
    
      // Pass action "optimize" and url to file to be optimized  
      const bucket = environments[this.environment].assetUploadsBucket;
      const optimizedKey = uuid() + ".mp4"; // TODO: Assumes video asset
      const optimizedUrl = "https://s3.amazonaws.com/" + bucket + "/" + optimizedKey; 
      const optimizedMimeType = "video/mp4"; //TODO: Assumes video asset
      const optimizeMsg = {
        action: "optimize",
        url: originalUrl,   // source url
        maxVideoWidthHeight: videoMaxWidthHeight,
        maxVideoDuration: videoMaxDuration,      
        bucket: bucket,       // target bucket
        key: optimizedKey,    // target key
        mimeType: optimizedMimeType // target mime type, 
      }    
      wsConnection.send(JSON.stringify(optimizeMsg));  

      // Wait 2 minutes seconds for response message 
      const optimizeResponse = JSON.parse((await wsConnection.recieve(120000)));

      if (optimizeResponse.originalUrl !== originalUrl) {
        throw new Error("Error trying to optimize asset " +  originalUrl + ", response: " + JSON.stringify(optimizeResponse));
      }

      return {
        url: optimizedUrl,
        mimeType: optimizedMimeType
      }
    } catch (error) {
      Logger.error("OptimizeAsset failed, %o", error)
      throw error;
    } finally {
      wsConnection.disconnect();
    }

  }
}