import { createTheme } from '@mui/material/styles';

import overrides from './overrides';
import palette from './palette';
import mixins from './mixins';

const theme = createTheme({
  overrides,
  palette,
  mixins
});

// TODO: Remove usage of the theme directly imported from here, we need to override the
//       palette dynamically based on received primary and secondary color. See ThemeProvider usage.
export default theme;
