import * as PIXI from 'pixi.js-legacy';
import Logger from '../log/Logger';

export interface PendingAssetResource {
  id: string,
  src: string,
  content?: PIXI.ILoaderResource
  /* tslint:disable-next-line:no-any */
  contentGif?: any
}
export interface ExtendedPixiMetadata extends PIXI.IResourceMetadata {
  /* tslint:disable-next-line:no-any */
  [key: string]: any;
}
export interface ExtendedPixiILoaderOptions extends PIXI.IAddOptions {
  metadata?: ExtendedPixiMetadata;
}
export interface PendingAssetItem {
  id: string,
  onCompleteList: (() => void)[],
  usesPIXILoader: boolean,
  asset: PendingAssetResource,
  request: XMLHttpRequest
}

export default class AssetsLoader {

  protected _reason: string;

  protected lazyLoading: boolean = true;

  protected _onLoadedListeners: { (): void; }[] = [];

  protected _onErrorListeners: { (): void; }[] = [];

  protected _pending: PendingAssetItem[] = [];

  protected _done: { [index: string]: PendingAssetResource } = {};

  protected _pixiLoader: PIXI.Loader;
  private _loadFullVideos: boolean;

  constructor() {
    this._pixiLoader = new PIXI.Loader();
  }

  public debug(): object {
    return {
      reason: this._reason,
      pending: this._pending.map((pendingAssetItem: PendingAssetItem) => {
        return pendingAssetItem.id;
      }),
      // done: Object.keys(this._done).map(key => {
      //   //let value = this._done[key];
      //   return 'id: ' + key;
      // }),
      pixiLoader: {
        pendingResources: Object.keys(this._pixiLoader.resources).map(key => {
          const value = this._pixiLoader.resources[key];
          return (!value.isComplete ? key : null);
        })
      }
    }
  }

  public getLoadFullVideos() {
    return this._loadFullVideos;
  }

  public setLoadFullVideos(loadFullVideos: boolean) {
    this._loadFullVideos = loadFullVideos;
  }

  public addPreMiddleWare(fn: (resource: PIXI.ILoaderResource, next: () => void) => void): void {
    this._pixiLoader.pre(fn);
  }

  public addPostMiddleWare(fn: (resource: PIXI.ILoaderResource, next: () => void) => void): void {
    this._pixiLoader.use(fn);
  }

  public hasPending(): boolean {
    return this._pending.length > 0;
  }

  public addOnLoadedListener(callback: () => void): void {
    this._onLoadedListeners.push(callback);
  }

  public addOnErrorListener(callback: () => void): void {
    this._onErrorListeners.push(callback);
  }

  public getAsset(id: string): PendingAssetResource {
    return this._done[id];
  }

  public push(asset: PendingAssetResource, onCompleteCallback: () => void, options?: ExtendedPixiILoaderOptions): void {
    try {
      if (this._done[asset.id]) {
        setTimeout(() => {
          onCompleteCallback();
        }, 0);
        return;
      }

      // I could use a dictionary / map / associative array / whatever and then delete this._pending[key];
      // but I'll need to iterate the list later, and don't want to get keys with an undefined value.
      let isPending: boolean = false;
      for (const pendingItem of this._pending) {
        if (pendingItem.id === asset.id) {
          pendingItem.onCompleteList.push(onCompleteCallback);
          isPending = true;
          break;
        }
      }

      if (isPending) {
        return;
      }

      const pendingAssetItem: PendingAssetItem = {
        id: asset.id,
        onCompleteList: [onCompleteCallback],
        usesPIXILoader: true,
        asset: {
          id: asset.id,
          src: asset.src,
          content: {} as PIXI.ILoaderResource,
          contentGif: null,
        },
        request: null
      };

      this._pending.push(pendingAssetItem);
      let extension: string;
      if (asset.src) {
        const extensionIndex: number = asset.src.lastIndexOf(".");
        extension = asset.src.substr(extensionIndex + 1).toLowerCase();
      }

      //Added error support
      // this._pixiLoader.onError.add(() => {
      //   this.onAssetError(pendingAssetItem);
      // });

      if (extension === "gif") {
        pendingAssetItem.usesPIXILoader = false;

        const oReq = new XMLHttpRequest();
        //x.xhrx=true added to avoid issue issue with Chrome/S3/CloudFront, described here: https://serverfault.com/questions/856904/chrome-s3-cloudfront-no-access-control-allow-origin-header-on-initial-xhr-req
        const url = pendingAssetItem.asset.src && pendingAssetItem.asset.src.indexOf("?") === 0 ? pendingAssetItem.asset.src : pendingAssetItem.asset.src + '?x-xhrx=true';
        oReq.open("GET", url, true);
        oReq.responseType = "arraybuffer";
        oReq.onload = (oEvent) => {
          // BUGFIX: This function survives destroy of the AssetsLoader and the PixiLoader,
          //         it can be called when a Asset finishes loading long after the loader
          //         is destroyed. Silently ignore callback if _done is null
          if (this._done) {
            pendingAssetItem.asset.contentGif = oReq.response;
            this.onAssetComplete(pendingAssetItem);
          }
        };
        pendingAssetItem.request = oReq;
        // oReq.send(null);

      } else {
        if (!this._pixiLoader.resources[pendingAssetItem.id]) {
          this.addToPixiLoader(pendingAssetItem, options);
        }
        else {
          this.onAssetComplete(pendingAssetItem);
        }
      }

      if (!this.lazyLoading) {
        if (!pendingAssetItem.usesPIXILoader) {
          pendingAssetItem.request.send(null);
        } else {
          this._pixiLoader.load();
        }
      }
    } catch (error) {
      if (error.message.startsWith('Cannot add resources while the loader is running')) {
        Logger.error('Failed pushing new Asset for load, asset: ' + JSON.stringify(asset) + ', options: ' + JSON.stringify(options) + ', AssetsLoader.debug(): ' + JSON.stringify(this.debug()));
      }
      // this.onAssetError(null);

      throw error;
    }
  }

  private addToPixiLoader(pendingAssetItem: PendingAssetItem, options: ExtendedPixiILoaderOptions) {
    //x.xhrx=true added to avoid issue issue with Chrome/S3/CloudFront, described here: https://serverfault.com/questions/856904/chrome-s3-cloudfront-no-access-control-allow-origin-header-on-initial-xhr-req
    var url: string = ''
    if (pendingAssetItem.asset.src){
      url = `${pendingAssetItem.asset.src}${(pendingAssetItem.asset.src.includes('?')?'&':'?')}x-xhrx=true`
    }
    if (this._pixiLoader.loading) {
      setTimeout(() => { this.addToPixiLoader(pendingAssetItem, options) }, 500);
    } else {
      this._pixiLoader.add(pendingAssetItem.id, url, options, () => {
        // BUGFIX: This function survives destroy of the AssetsLoader and the PixiLoader,
        //         it can be called when a Asset finishes loading long after the loader
        //         is destroyed. Silently ignore callback if there is no PixiLoader
        if (this._pixiLoader) {
          const resource: PIXI.ILoaderResource = this._pixiLoader.resources[pendingAssetItem.id] as PIXI.ILoaderResource;
          pendingAssetItem.asset.content = resource;
          this.onAssetComplete(pendingAssetItem);
        }
      });
    }
  }

  private onAssetComplete(pendingAssetItem: PendingAssetItem): void {
    this._done[pendingAssetItem.id] = pendingAssetItem.asset;

    const itemIndex: number = this._pending.indexOf(pendingAssetItem);
    this._pending.splice(itemIndex, 1);

    const onCompleteList = pendingAssetItem.onCompleteList;
    for (const callback of onCompleteList) {
      callback();
    }

    if (this._pending.length <= 0) {
      // this.dispatchOnCompleteEvent();
      setTimeout(() => { this.dispatchOnCompleteEvent() }, 0);
    }

  }

  // private onAssetError(pendingAssetItem: PendingAssetItem): void {
  //   if (pendingAssetItem) {
  //     const itemIndex: number = this._pending.indexOf(pendingAssetItem);
  //     this._pending.splice(itemIndex, 1);
  //   }
  //   setTimeout(() => { this.dispatchOnErrorEvent() }, 0);
  // }

  private dispatchOnCompleteEvent(): void {
    for (const callback of this._onLoadedListeners) {
      callback();
    }
  }

  // private dispatchOnErrorEvent(): void {
  //   for (const callback of this._onErrorListeners) {
  //     callback();
  //   }
  // }

  public reset(): void {
    this._pixiLoader.reset();
    this.removeAll();
  }

  /**
 * FORCE LOAD IF USING LAZY LOADING ONLY, UNLESS YOU KNOW WHAT YOU ARE DOING
   */
  public async forceLoad(reason: string): Promise<void> {
    this._reason = reason;
    return new Promise<void>((resolve, reject) => {
      this.addOnLoadedListener(() => {
        resolve();
      });
      // this.addOnErrorListener(() => {
      //   reject(new Error("Rejecting AssetsLoader.forceLoad for reason: " + reason));
      // })
      if (this.hasPending()) {

        this._pixiLoader.load();

        for (const item of this._pending) {
          if (!item.usesPIXILoader) {
            item.request.send(null);
          }
        }
      } else {
        setTimeout(() => this.dispatchOnCompleteEvent(), 0);
      }

    });
  }

  public removeAll(): void {
    this._onLoadedListeners = [];
    this._onErrorListeners = [];
    this._pending = [];
    this._done = {};
  }

  destroy() {
    this.reset();
    this._done = null;
    this._onLoadedListeners = null;
    this._onErrorListeners = null;
    this._pending = null;
    this._pixiLoader.destroy();
    this._pixiLoader = null;
  }

}
