
export enum TemplateSceneCategory {
    IMG = 'IMG',
    TXT = 'TXT'
}

export default abstract class AbstractTemplate {

    id: string;
    wideoId: string;
    chargeTypeId: number;
    lang: string;
    category: string;
    basePreviewUrl: string;
    snapshot: HTMLImageElement; //not persisted

    setSnapshot(snapshot: HTMLImageElement) {
        this.snapshot = snapshot;
    }
}