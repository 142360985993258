import common_es from "../../translations/es/common.json";
import common_en from "../../translations/en/common.json";
import utils_en from "../../translations/en/utils.json";
import utils_es from "../../translations/es/utils.json";
import player_en from "../../translations/en/player.json";
import player_es from "../../translations/es/player.json";
import topbar_es from "../../translations/es/topbar.json";
import topbar_en from "../../translations/en/topbar.json";
import leftbar_en from "../../translations/en/leftbar.json";
import leftbar_es from "../../translations/es/leftbar.json";
import bottombar_en from "../../translations/en/bottombar.json";
import bottombar_es from "../../translations/es/bottombar.json";
import rightbar_en from "../../translations/en/rightbar.json";
import rightbar_es from "../../translations/es/rightbar.json";
import rightbarObj_en from "../../translations/en/rightbarObjects.json";
import rightbarObj_es from "../../translations/es/rightbarObjects.json";
import dialogs_en from "../../translations/en/dialogs.json";
import dialogs_es from "../../translations/es/dialogs.json";
import toolbar_en from "../../translations/en/toolbar.json";
import toolbar_es from "../../translations/es/toolbar.json";
import tui_en from "../../translations/en/tui.json";
import tui_es from "../../translations/es/tui.json";
import i18next from "i18next";
import { getCookie } from "../../editor/core/EditorConstants";

export default function translationsInit() {

    const langID = getCookie("langID");

    // tslint:disable-next-line: no-floating-promises
    i18next.init({
        interpolation: { escapeValue: false },  // React already does escaping
        lng: langID && langID === "es" || langID === "en" ? langID : "en",           // language to use
        resources: {
            en: {
                common: common_en,
                utils: utils_en,
                player: player_en,
                topbar: topbar_en,               
                leftbar: leftbar_en,
                bottombar: bottombar_en,
                rightbar: rightbar_en,
                rightbarObj: rightbarObj_en,
                dialogs: dialogs_en,
                toolbar: toolbar_en,
                tui: tui_en
            },
            es: {
                common: common_es,
                utils: utils_es,
                player: player_es,
                topbar: topbar_es,
                leftbar: leftbar_es,
                bottombar: bottombar_es,
                rightbar: rightbar_es,
                rightbarObj: rightbarObj_es,
                dialogs: dialogs_es,
                toolbar: toolbar_es,
                tui: tui_es
            },
        },
    });
}
