import Api from './Api';
import WideoInfoLight from './model/WideoInfoLight';
import { dataURLToWideoFile } from '../editor/core/EditorConstants';
import Logger from '../common/log/Logger';
import WideoFile from '../common/core/model/WideoFile';
import UpdateWideoData from './model/UpdateWideoData';
import WideoIsEditableInfo from './model/WideoIsEditableInfo';

export default class WideoApi extends Api {

  public getJson = async (wideoId: string): Promise<Object> => {
    const path: string = '/wideos/' + wideoId;
    const params: { [s: string]: string; } = { accessToken: this._accessToken, html5: true.toString() }; //Force HTML version
    return this.httpGetJson<Object>(path, params);
  }

  public getJsonFlash = async (wideoId: string): Promise<Object> => {
    const path: string = '/wideos/' + wideoId;
    const params: { [s: string]: string; } = { accessToken: this._accessToken, html5: false.toString() }; //Force Flash version
    return this.httpGetJson<Object>(path, params);
  }

  public saveJson = async (wideoId: string, wideoDef: Object): Promise<void> => {
    const path: string = '/wideos/' + wideoId + '/save';
    const params: { [s: string]: string; } = { accessToken: this._accessToken, html5: true.toString() };
    await this.httpPostJson<void, Object>(path, params, wideoDef);
  }

  public savePreview = async (wideoId: string, preview: HTMLImageElement): Promise<void> => {
    const path: string = '/wideos/' + wideoId + '/previews/1';
    const params: { [s: string]: string; } = {
      accessToken: this._accessToken
    };
    const body: FormData = new FormData();
    try {
      const file: WideoFile = dataURLToWideoFile(preview.src, "preview.png");
      body.append('image', file, "preview.png");

      // tslint:disable-next-line:no-any (when a new version of Wideo API is created we can change any to that type)
      await this.httpPostFormData<any>(path, body, params);
    } catch (error) {
      Logger.error('Failed saving wideo preview in WideoApi, reason: ' + Logger.errorToString(error));
    }
  }

  public rename = async (wideoId: string, title: string) => {
    const path: string = '/wideos/' + wideoId + '/update';
    const params: { [s: string]: string; } = { accessToken: this._accessToken };

    await this.httpPostJson<void, UpdateWideoData>(path, params,
      { title: title, description: '', tags: [], category: 'NONE' });
  }

  public getInformation = async (wideoId: string, jsonUrl: string, skipPlayerApiRequest: boolean): Promise<WideoInfoLight> => {
    // Hack to avoid problems with Automation wideos that do not exist and to avoid
    // loading the Wideo API unneccessary during automation.
    if ((wideoId.startsWith("AUT") || jsonUrl) && skipPlayerApiRequest) {
      return {
        watermark: false,
        watermarkType: "default",
        chargeTypeId: 0,
        ownerUserId: 0
      }
    }
    const path: string = '/wideos/' + wideoId + '/information';
    const params: { [s: string]: string; } = { accessToken: this._accessToken };
    return this.httpGetJson<WideoInfoLight>(path, params);
  }

  public isEditableWideo = async (wideoId: string): Promise<WideoIsEditableInfo> => {
    const path: string = '/wideos/' + wideoId + '/isEditable';
    const params: { [s: string]: string; } = { accessToken: this._accessToken };
    return this.httpGetJson<WideoIsEditableInfo>(path, params);
  }

}
