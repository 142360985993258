import UserAsset from "./UserAsset";
import { resourcesBaseUrl } from "../../editor/core/EditorConstants";
import { ACLResourceType } from "./ACLResourceType";

export default class UserAssetAudio extends UserAsset {

  constructor(
    id: string,
    name: string,
    url: string,
    userId: string,
    preview: string,
    duration: number) {
    super(id, name, url, userId, resourcesBaseUrl + "/img/editor/icons/default-audio.png", duration, false, null, null);
  }

  public getAssetType = () => {
    return "userAudios";
  }

  public getACLResourceType = () => {
    return ACLResourceType.ASSET_AUDIO;
  }

}
