import { darken } from '@mui/system';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { BrandContextProvider } from './BrandContextProvider';

import palette from 'src/common/ui/theme/palette';
import overrides from 'src/common/ui/theme/overrides';
import mixins from 'src/common/ui/theme/mixins';

import { environments } from '../Env';

import { ZENDESK_KEY } from 'src/editor/core/EditorConstants';

const BrandThemeProvider = ({ queryParams, children }) => {
  const environment = environments[queryParams.environment];
  const brandConfig = environment.brandConfig;

  const secondaryColor = brandConfig ? brandConfig.secondaryColor : palette.secondary.main;
  const darkenedSecondaryColor = darken(secondaryColor, 0.2);

  const brandedPalette = {
    ...palette,
    primary: {
      ...palette.primary,
      main: brandConfig ? brandConfig.primaryColor : palette.primary.main
    },
    secondary: {
      ...palette.secondary,
      main: secondaryColor,
      dark: darkenedSecondaryColor
    }
  };

  const theme = createTheme({
    overrides,
    palette: brandedPalette,
    mixins
  });

  return (
    <BrandContextProvider brandConfig={brandConfig}>
      <ThemeProvider theme={theme}>
        {children}
      </ThemeProvider>
    </BrandContextProvider>
  );
};

BrandThemeProvider.getBrandInfo = (queryParams) => {
  const environment = environments[queryParams.environment];
  const brandConfig = environment.brandConfig;
  const brandName = brandConfig ? brandConfig.brandName : 'Wideo';
  const icon = brandConfig ? brandConfig.icon : '/icons/wideo-w-orange.svg';
  const zendeskKey = brandConfig ? brandConfig.zendeskKey : ZENDESK_KEY;

  return { brandName, icon, brandConfig, zendeskKey };
};

export default BrandThemeProvider;
