import * as PIXI from 'pixi.js-legacy';

import WideoObject from "./WideoObject";
import MaskObject from './MaskObject';
import { Class } from "./model/WideoDef";

/**
 * Un MaskedObject is an “object group” with one MaskObject and one other object that is being masked
 */
export default class MaskedObject extends WideoObject {

  public getLocalBounds(rect?: PIXI.Rectangle): PIXI.Rectangle {
    // TODO: Unapply the mask and remove the other object temporarily in this masked object to get
    // the actual bounds of the mask and not the intersection of the two areas. Performance? There is an
    // alternative solution here: https://github.com/pixijs/pixi.js/issues/2032
    const maskObject = this.getMaskObject();
    const otherObject = this.getOtherObject();

    if (maskObject && otherObject) {
      this.getMaskObject().unapply();
      this.removeWideoObject(otherObject);
    }

    const bounds = super.getLocalBounds(rect);
    
    if (maskObject && otherObject) {
      this.addWideoObject(otherObject);
      this.getMaskObject().apply();
    }
    
    return bounds;
  }

  public getMaskObject = (): MaskObject => {
    for ( const child of this.getWideoObjects() ) {
      if ( MaskObject.isMaskObject(child) ) {
        return child;
      }
    }
    return null;
  }

  public getOtherObject = (): WideoObject => {
    for ( const child of this.getWideoObjects() ) {
      if (!MaskObject.isMaskObject(child)) {
        return child;
      }
    }
    return null;
  }

  public setStartTime(value: number): void {
    super.setStartTime(value);

    // The lifetime of the mask and the other object are linked to this objects lifetime
    this.getOtherObject().setStartTime(0);
    this.getMaskObject().setStartTime(0);
    this.getOtherObject().setEndTime(this.getLifetime());
    this.getMaskObject().setEndTime(this.getLifetime());
  }

  public setEndTime(time: number): void {
    super.setEndTime(time);

    // The lifetime of the mask and the other object are linked to this objects lifetime
    this.getOtherObject().setStartTime(0);
    this.getMaskObject().setStartTime(0);
    this.getOtherObject().setEndTime(this.getLifetime());
    this.getMaskObject().setEndTime(this.getLifetime());
  }

  public static isMaskedObject = (object: WideoObject): object is MaskedObject => {
    return (object && object.getClass() === Class.MaskedObject);
  }
  
}
