import Logger from '../../common/log/Logger';
import WideoDefFlash, { FlashScene, FlashObject, FlashIntro, FlashOutro, FlashBackground, FlashKeyFrame, FlashMusic, FlashTextAsset } from './model/WideoDefFlash';
import WideoDef, { Class, CURRENT_WIDEO_DEF_VERSION, TextObjectDef, SceneDef, WideoObjectDef, AttributesDef, TextComponentDef, MaskComponentDef, TransitionType, ShapeType, AnimationComponentDef, TextStyleDef, ComponentDef } from '../../common/core/model/WideoDef';
import WideoDefFactory from '../../common/core/model/WideoDefFactory';
import EditorDefFactory from '../../editor/core/model/EditorDefFactory';
import { v4 as uuid } from 'uuid';
import ConverterUtils, { flashMovieWidth, flashMovieHeight } from './ConverterUtils';
import { animatedAssetIds, AnimatedAssetId } from '../../editor/core/EditorConstants';
import { MIN_FONT_SIZE } from "../../common/core/TextObject"; //TODO: Dependency
import IdManager from '../../common/core/ids/IdManager';
const defaultFlashShapeWidth: number = 100;
const defaultFlashShapeHeight: number = 100;

export default class Converter {

  private environment: string;
  private accessToken: string;

  constructor(environment: string, accessToken: string) {
    this.environment = environment;
    this.accessToken = accessToken;
  }

  /**
  ** parser flash html text. Ex:
  "<TEXTFORMAT LEADING='7'>
    <P ALIGN='LEFT'><FONT FACE='OpenSans_v2' SIZE='30' COLOR='#2381BB' LETTERSPACING='0' KERNING='0'><B>Type text here</B></FONT>
    </P></TEXTFORMAT>

  **/
  private extractHTMLTextObject = (flashObject: FlashObject, parentObjectDef: WideoObjectDef): WideoObjectDef => {
    //TODO: For now we only use a textComponent breaking text with /n, and we merge styles of all <p> in one textComponent
    //We will need to create and align a textComponent for each <p> element, and then, get styles for each <p> separately


    let text: string;
    const style: TextStyleDef = {};

    if (flashObject.asset.type === "HTMLTextAsset") {

      const parser = new DOMParser();
      const parsedHtml = parser.parseFromString(flashObject.asset.text, 'text/html');

      const textFormat = parsedHtml.getElementsByTagName("textFormat");
      //get flash leading.
      let leading: number = 0;

      if (textFormat && textFormat.item(0)) {
        leading = parseInt(textFormat.item(0).attributes.getNamedItem('leading').value, 10);
      }
      //parsing html text
      const paragraphs: HTMLCollectionOf<HTMLParagraphElement> = parsedHtml.getElementsByTagName("p");
      let textStr: string = "";
      for (let i: number = 0; i < paragraphs.length; i++) {
        //get paragraph <p>
        const p: HTMLParagraphElement = paragraphs.item(i);

        //calculating text align. align comes in p element (OMG)
        if (p.attributes.getNamedItem("align")) {
          style['align'] = p.attributes.getNamedItem("align").value.toLowerCase() as ('left' | 'right' | 'center');
        }

        //get font element in <p><font></font></p>
        const font = p.getElementsByTagName("font")[0];

        //for each text style attribute on element <font>
        for (let attrIndex: number = 0; attrIndex < font.attributes.length; attrIndex++) {
          const attr: Attr = font.attributes.item(attrIndex);

          //calculating other text styles
          switch (attr.name) {
            case "face": {
              style.fontFamily = ConverterUtils.flashFontFamilyToHtml5FontFamily(attr.value);
              break;
            }
            case "size": {
              const fontSize = parseFloat(attr.value);
              style.fontSize = (fontSize && !isNaN(fontSize) ? fontSize : MIN_FONT_SIZE);
              style.lineHeightFactor = ConverterUtils.flashFontSizeAndLeadingToHTML5LineHeightFactor(fontSize, leading);

              break;
            }
            case "color": {
              style.fill = attr.value;
              break;
            }
            default:
              break;
          }

          // Get bold tags
          const bold = p.getElementsByTagName("b")[0];
          if (bold) {
            style.fontWeight = 'bold';
          }
          const italic = p.getElementsByTagName("i")[0];
          if (italic) {
            style.fontStyle = 'italic';
          }

        }
        textStr += font.textContent
        if (i < paragraphs.length - 1) {
          textStr += "\n";
        }
      }
      text = textStr;

    } else {
      // Flash "old" TextAsset
      const textAsset = flashObject.asset as FlashTextAsset;
      style['align'] = textAsset.alignment as ('left' | 'right' | 'center');
      style.fontFamily = ConverterUtils.flashFontFamilyToHtml5FontFamily(textAsset.font);
      style.fontSize = (textAsset.textSize ? textAsset.textSize : 18); // Defaults to 18 in Flash
      style.fill = "#" + ConverterUtils.flashFontTextColorToHexString(textAsset.textColor);
      style.fontWeight = (textAsset.bold ? 'bold' : 'normal');
      style.fontStyle = (textAsset.italic ? 'italic' : 'normal');
      text = textAsset.text;
    }

    return WideoDefFactory.createTextWideoObjectDef(
      ConverterUtils.calculateX(flashObject.transform.x, parentObjectDef),
      ConverterUtils.calculateY(flashObject.transform.y, parentObjectDef),
      ConverterUtils.flashTimeToHtmlTime(parentObjectDef, flashObject.endTime),
      style,
      flashObject.asset.assetWidth,
      flashObject.asset.assetHeight,
      text,
      flashObject.transform.scaleX,
      flashObject.transform.scaleY,
      flashObject.transform.angle,
      flashObject.asset.opacity,
      ConverterUtils.flashTimeToHtmlTime(parentObjectDef, flashObject.startTime),
      flashObject.hidden,
      flashObject.objectId,
      flashObject.maskID
    );

  }

  private extractObject = async (flashObject: FlashObject, parentObjectDef: WideoObjectDef): Promise<WideoObjectDef> => {
    //Hack for Placeholder
    if (flashObject.croppingMask) {
      flashObject.croppingMask['isMask'] = true; // we set this to mark a flash object as beeing used as a mask
      flashObject.croppingMask['targetId'] = flashObject.objectId;
      const maskObjectArray: WideoObjectDef[] = await this.extractObjects([flashObject.croppingMask], parentObjectDef);
      const maskObject = maskObjectArray[0];
      parentObjectDef.objects.push(maskObject); //In flash the mask for placeholders is a special child stored under .croppingMask
      //In HTML5 we store the mask as a sibling to the masked object
    }

    if (flashObject.asset.type === "HTMLTextAsset" || flashObject.asset.type === "TextAsset") {
      return this.extractHTMLTextObject(flashObject, parentObjectDef);
    }
    else if (flashObject.asset.type === "Asset" || flashObject.asset.type === "AnimatedGifAsset") {
      const animated: AnimatedAssetId[] = animatedAssetIds.filter((id: AnimatedAssetId) => { return flashObject.asset.assetId === id.id });

      //Before we check if it's an animated asset
      if (animated[0]) {
        return EditorDefFactory.CreateAnimatedImageWideoObjectDef(ConverterUtils.calculateX(flashObject.transform.x, parentObjectDef),
          ConverterUtils.calculateY(flashObject.transform.y, parentObjectDef),
          ConverterUtils.flashTimeToHtmlTime(parentObjectDef, flashObject.endTime),
          flashObject.asset.assetId,
          flashObject.asset.assetId,
          ConverterUtils.calculateScaleXOfAssetImage(flashObject.asset.assetWidth, flashObject.transform.scaleX, animated[0].width),
          ConverterUtils.calculateScaleYOfAssetImage(flashObject.asset.assetHeight, flashObject.transform.scaleY, animated[0].height),
          flashObject.transform.angle,
          flashObject.asset.opacity,
          ConverterUtils.flashTimeToHtmlTime(parentObjectDef, flashObject.startTime),
          flashObject.hidden,
          flashObject.objectId);
      }
      else {
        const assetUrl: string = await ConverterUtils.getAssetImageUrl(this.environment, this.accessToken, flashObject.asset.assetId);
        //TODO: Esto es una mierda pero no veo otra opcion para saber el width y height original de nuestros assets
        let img: HTMLImageElement;
        try {
          img = await ConverterUtils.getImage(assetUrl);
        } catch (error) {
          Logger.warn('Could not download and decide dimensions of asset: ' + flashObject.asset.assetId + ' from url: ' + assetUrl +
            '. Defaulting to an empty image of the same dimensions as the original flash object.');
          img = new Image(flashObject.asset.assetWidth, flashObject.asset.assetHeight);
        }

        return WideoDefFactory.CreateImageWideoObjectDef(ConverterUtils.calculateX(flashObject.transform.x, parentObjectDef),
          ConverterUtils.calculateY(flashObject.transform.y, parentObjectDef),
          ConverterUtils.flashTimeToHtmlTime(parentObjectDef, flashObject.endTime),
          flashObject.asset.assetId,
          assetUrl,
          ConverterUtils.calculateScaleXOfAssetImage(flashObject.asset.assetWidth, flashObject.transform.scaleX, img.naturalWidth),
          ConverterUtils.calculateScaleYOfAssetImage(flashObject.asset.assetHeight, flashObject.transform.scaleY, img.naturalHeight),
          flashObject.transform.angle,
          flashObject.asset.opacity,
          ConverterUtils.flashTimeToHtmlTime(parentObjectDef, flashObject.startTime),
          flashObject.hidden,
          flashObject.objectId,
          flashObject.maskID
        );
      }
    } else if (flashObject.asset.type === "PolygonAsset") { //shapes and masks
      //esto es una mierda hecha en flash
      const shapeType: ShapeType = ConverterUtils.getShapeType(flashObject.asset.shape);
      let height: number;
      if (shapeType === ShapeType.Rectangle ||
        shapeType === ShapeType.RoundedRect) {
        height = defaultFlashShapeHeight / 2;
      } else if (shapeType === ShapeType.Line ||
        shapeType === ShapeType.DottedLine) {
        height = defaultFlashShapeHeight / 10;
      }

      if (flashObject['isMask']) {
        const mask = WideoDefFactory.CreateMaskWideoObjectDef(
          ConverterUtils.calculateX(flashObject.transform.x, parentObjectDef),
          ConverterUtils.calculateY(flashObject.transform.y, parentObjectDef),
          defaultFlashShapeWidth,
          height ? height : defaultFlashShapeHeight,
          ConverterUtils.getMaskType(flashObject.asset.shape),
          ConverterUtils.flashTimeToHtmlTime(parentObjectDef, flashObject.startTime),
          ConverterUtils.flashTimeToHtmlTime(parentObjectDef, flashObject.endTime),
          flashObject.transform.scaleX,
          flashObject.transform.scaleY,
          flashObject.objectId,
          flashObject['targetId']);
        return mask;
      } else {
        const shape = EditorDefFactory.CreateShapeObjectDef(
          ConverterUtils.calculateX(flashObject.transform.x, parentObjectDef),
          ConverterUtils.calculateY(flashObject.transform.y, parentObjectDef),
          ConverterUtils.flashTimeToHtmlTime(parentObjectDef, flashObject.endTime),
          shapeType,
          Math.abs(defaultFlashShapeWidth * flashObject.transform.scaleX),
          Math.abs(height ? height * flashObject.transform.scaleY : defaultFlashShapeHeight * flashObject.transform.scaleY),
          ConverterUtils.getColorRGB(flashObject.asset.borderColor),
          flashObject.asset.borderOpacity,
          ConverterUtils.getColorRGB(flashObject.asset.fillColor),
          flashObject.asset.opacity,
          flashObject.asset.borderThickness,
          Math.sign(flashObject.transform.scaleX),
          Math.sign(flashObject.transform.scaleY),
          flashObject.transform.angle,
          ConverterUtils.flashTimeToHtmlTime(parentObjectDef, flashObject.startTime),
          flashObject.hidden,
          flashObject.objectId,
          flashObject.maskID);
        return shape;
      }

    } else {
      Logger.warn("Unknown asset type: %s in object: %s, different to TextAsset or Asset. Not implemented. %s", flashObject.asset.type, flashObject.objectId, JSON.stringify(flashObject));
    }

    return null;
  }

  //intro outro scene
  private extractTransition = (flashIntro: FlashIntro, flashOutro: FlashOutro, wideoObjectDef: WideoObjectDef) => {
    if (flashIntro) {
      wideoObjectDef.components.push(WideoDefFactory.CreateTransitionDef(TransitionType.Intro, ConverterUtils.getTweenIntro(flashIntro), flashIntro.length * 1000));
    }

    if (flashOutro) {
      wideoObjectDef.components.push(WideoDefFactory.CreateTransitionDef(TransitionType.Outro, ConverterUtils.getTweenOutro(flashOutro), flashOutro.length * 1000));
    }
  }

  private extractBackground = async (background: FlashBackground, flashColor: number, wideoObjectDef: WideoObjectDef) => {
    if (background) {
      const url: string = await ConverterUtils.getBackgroundImageUrl(this.environment, this.accessToken, background.assetId);
      const backgroundComponent = WideoDefFactory.CreateBackgroundImageComponentDef(background.assetId, url);
      wideoObjectDef.components.push(backgroundComponent);
    } else if (flashColor) {
      wideoObjectDef.components.push(WideoDefFactory.CreateBackgroundColorComponentDef(uuid(), ConverterUtils.getColorRGB(flashColor)));
    }
  }

  private extractKeyFrames = (flashObject: FlashObject, ownerObjectDef: WideoObjectDef, parentObjectDef: WideoObjectDef): AnimationComponentDef => {
    let animationDef: AnimationComponentDef;
    if (flashObject.animation) {
      animationDef = WideoDefFactory.CreateAnimationComponentDef();
      flashObject.animation.keyFrames.map((keyFrame: FlashKeyFrame, index: number) => {

        const flashKeyFrameAttributes = keyFrame.attributes[0];
        const newAttributesDef: AttributesDef = {
          x: flashKeyFrameAttributes.x - flashObject.transform.x, //keyframes for html5 always are relative to the base attributes
          y: flashKeyFrameAttributes.y - flashObject.transform.y,
          rotation: flashKeyFrameAttributes.angle - flashObject.transform.angle,
          scaleX: flashKeyFrameAttributes.scaleX / flashObject.transform.scaleX,
          scaleY: flashKeyFrameAttributes.scaleY / flashObject.transform.scaleY,
          alpha: 1 //does not exist as animatable in Flash
        };

        //SPECIAL CASE: Do not scale Flash shape lines y-wise
        if (flashObject.asset && flashObject.asset.type === 'PolygonAsset' && flashObject.asset.shape === 'LINE') {
          newAttributesDef.scaleY = 1;
        }

        animationDef.animation.keyFrames.push(
          WideoDefFactory.CreateKeyFrameDef(
            // TODO: Fix time in a common place...
            (parentObjectDef.class === Class.Scene ? keyFrame.time * 1000 : keyFrame.time * 1000 - flashObject.startTime * 1000),
            newAttributesDef));
      });
      ownerObjectDef.components.push(animationDef);
    }
    return animationDef;

  }

  private extractMusic = async (flashMusic: FlashMusic): Promise<WideoObjectDef> => {
    const audioUrl = await ConverterUtils.getAudioUrl(this.environment, this.accessToken, flashMusic.soundId);
    if (audioUrl) { 
      return WideoDefFactory.CreateAudioWideoObjectDef(audioUrl, null, flashMusic.volume, true);
    }
    return null;
  }

  private extractGroup = async (flashObject: FlashObject, parentObjectDef: WideoObjectDef): Promise<WideoObjectDef> => {

    const objectGroupDef: WideoObjectDef = ConverterUtils.createObjectGroupDef(flashObject, parentObjectDef);
    //for every object in group, recursively call to extract objects
    objectGroupDef.objects = await this.extractObjects(flashObject.objects, objectGroupDef);
    return objectGroupDef;
  }

  /** SceneObjectCropped, will be converted either into MaskedObject or Placeholder */
  private extractSceneObjectCropped = async (flashObject: FlashObject, parentObjectDef: WideoObjectDef): Promise<WideoObjectDef> => {
    let wideoObjectDef: WideoObjectDef;

    // Sneak peek into the child objects of the SceneObjectCropped to see if it should be 
    // converted into a masked object or a placeholder
    if (flashObject.objects[0].placeholder) {
      wideoObjectDef = ConverterUtils.createPlaceholderDef(flashObject, parentObjectDef);
    } else {
      wideoObjectDef = ConverterUtils.createMaskedObjectDef(flashObject, parentObjectDef);
    }

    //for every object in the SceneObjectCropped (assume only 1), recursively call to extract objects
    const children: WideoObjectDef[] = await this.extractObjects(flashObject.objects, wideoObjectDef);
    wideoObjectDef.objects.push(children[0]); //Assuming only 1 child
    return wideoObjectDef;
  }

  private markMaskObjects = (flashObjects: FlashObject[]): void => {

    flashObjects.map((flashObject: FlashObject, index: number) => {
      //if this object has maskId, means that some other object is masking it
      //so we need to mark that object as a Mask so it can be created correctly later
      if (flashObject.maskID) {
        //so we need to search the object mask first
        const maskObject: FlashObject = flashObjects.filter((flashObjectFilter: FlashObject) => {
          return flashObjectFilter.objectId === flashObject.maskID;
        })[0];

        if (maskObject) {
          maskObject['isMask'] = true; // we set this to mark a flash object as beeing used as a mask
          maskObject['targetId'] = flashObject.objectId;
        } else {
          if (!flashObject.croppingMask) {
            Logger.warn("Could not find object with ID: %s referenced from: %s. Removing the reference to it before converting", flashObject.maskID, JSON.stringify(flashObject));
            flashObject.maskID = undefined;
          }
        }
      }

    });
  }

  private extractObjects = async (flashObjects: FlashObject[], parentObjectDef: WideoObjectDef): Promise<WideoObjectDef[]> => {
    this.markMaskObjects(flashObjects);

    //iterate over objects. Object is never rejected
    return Promise.all(flashObjects.map(async (flashObject: FlashObject) => {
      try {
        let wideoObjectDef: WideoObjectDef;

        if (flashObject.type === "SceneObjectGroup") {
          //extract group
          wideoObjectDef = await this.extractGroup(flashObject, parentObjectDef);
        }
        else if (flashObject.type === 'SceneObjectCropped') {
          wideoObjectDef = await this.extractSceneObjectCropped(flashObject, parentObjectDef);
        }
        else if (flashObject.type === 'SceneObject') {
          if (flashObject.asset) {
            wideoObjectDef = await this.extractObject(flashObject, parentObjectDef);
          }
          else {
            Logger.warn("Unrecoqnised SceneObject: %s, %s", flashObject.objectId, JSON.stringify(flashObject));
          }
        }
        else {
          Logger.warn("Unknown flash object type: %s, %s, %s", flashObject.type, flashObject.objectId, JSON.stringify(flashObject));
        }


        //TODO: can be null if is a asset.type = buttonAsset for now
        if (wideoObjectDef) {

          //flash object intro/Outro
          this.extractTransition(flashObject.intro, flashObject.outro, wideoObjectDef);
          this.extractKeyFrames(flashObject, wideoObjectDef, parentObjectDef);
          this.postProcessTextObjectDef(wideoObjectDef);

          // Warn in console if object has brightness set
          if (flashObject.asset) {
            if (flashObject.asset.type === "PolygonAsset") {
              if ((flashObject.asset.brightness && flashObject.asset.brightness !== 0) ||
                (flashObject.asset.borderBrightness && flashObject.asset.borderBrightness !== 0)) {
                Logger.warn("Flash Shape Object " + flashObject.objectId + " used had brightness set to " + flashObject.asset.brightness + ". Brightness was not converted to HTML5 Object " + wideoObjectDef.id + ". Shape: " + flashObject.asset.shape);
              }
            } else {
              if (flashObject.asset.brightness && flashObject.asset.brightness !== 0.5) {
                Logger.warn("Flash Other Object " + flashObject.objectId + " used had brightness set to " + flashObject.asset.brightness + ". Brightness was not converted to HTML5 Object " + wideoObjectDef.id + ". Asset id: " + (flashObject.asset.assetId ? flashObject.asset.assetId : "(none)"));
              }
            }
          }


          return wideoObjectDef;
        }
        else {
          Logger.warn("No object created from flash object %s, %s", flashObject.objectId, JSON.stringify(flashObject));
        }
      }
      catch (e) {
        Logger.error("Object cannot be converted %s", flashObject.objectId);
      }
      return null;
    }));
  }

  private postProcessTextObjectDef(wideoObjectDef: WideoObjectDef) {

    // Special case for Text Objects with animation
    if (wideoObjectDef.class === Class.TextObject) {
      const textObjectDef: TextObjectDef = wideoObjectDef as TextObjectDef;

      const animationComponentDefs: AnimationComponentDef[] = wideoObjectDef.components.filter((component: ComponentDef) => {
        return component.class === Class.AnimationComponent
      }) as AnimationComponentDef[];

      let scaleMax = 1;

      // Go through all keyframes and make sure that no keyframe has a scale > 100%
      if (animationComponentDefs.length > 0) {
        for (const keyFrameDef of animationComponentDefs[0].animation.keyFrames) {
          // 1. Find keyframe with largest scale
          scaleMax = Math.max(keyFrameDef.attributes.scaleX,
            keyFrameDef.attributes.scaleY,
            scaleMax);
        }
      }

      // Rescale base font size and scaleX/scaleY
      const textComponents: TextComponentDef[] = textObjectDef.components.filter((component: ComponentDef) => {
        return component.class === Class.TextComponent
      }) as TextComponentDef[];
      textComponents[0].style.fontSize *= textObjectDef.attributes.scaleX * scaleMax; // There is only one textComponents in a TextObject
      textComponents[0].width *= textObjectDef.attributes.scaleX * scaleMax;
      textComponents[0].height *= textObjectDef.attributes.scaleX * scaleMax;

      const maskComponents: MaskComponentDef[] = textObjectDef.components.filter((component: ComponentDef) => {
        return component.class === Class.MaskComponent
      }) as MaskComponentDef[];
      maskComponents[0].width *= textObjectDef.attributes.scaleX * scaleMax;
      maskComponents[0].height *= textObjectDef.attributes.scaleX * scaleMax;

      textObjectDef.attributes.scaleX = 1;
      textObjectDef.attributes.scaleY = 1;

      if (animationComponentDefs.length > 0) {
        // Rescale all keyframes scaleX/scaleY
        animationComponentDefs[0].animation.keyFrames.forEach((element) => {
          element.attributes.scaleX /= scaleMax;
          element.attributes.scaleY /= scaleMax;
        });
      }

    }
  }

  private extractScene = async (wideoLength: number, flashScene: FlashScene): Promise<SceneDef> => {

    //scene created with a mask size, representing flash's workarea.
    const sceneDef: SceneDef = WideoDefFactory.CreateEmptySceneDef(
      ConverterUtils.flashTimeToHtmlTime(null, flashScene.startTime),
      wideoLength,
      flashMovieWidth,
      flashMovieHeight,
      flashScene.sceneID);

    this.extractTransition(flashScene.intro, flashScene.outro, sceneDef);
    await this.extractBackground(flashScene.background, flashScene.color, sceneDef);

    const objects: WideoObjectDef[] = await this.extractObjects(flashScene.objects, sceneDef);

    //TODO: remove this when all assetType are implemented
    objects.map((object: WideoObjectDef) => {
      if (object) {
        sceneDef.objects.push(object);
      }
    })

    return sceneDef;
  }


  convert = async (flashJson: Object): Promise<WideoDef> => {

    const wideoDefFlash: WideoDefFlash = flashJson as WideoDefFlash;
    Logger.info('#################################### Conversion of Wideo (' + wideoDefFlash.id + ') from Flash JSON v.' + wideoDefFlash.version + ' to HTML5 JSON v.' + CURRENT_WIDEO_DEF_VERSION + ' started... ####################################')

    if (wideoDefFlash.version !== "2.6") {
      throw new Error("Failed converting Flash JSON. Version of Flash JSON must be 2.6. Actual version: " + wideoDefFlash.version)
    }

    let wideoLength: number = 0;
    const wideoDef: WideoDef = WideoDefFactory.CreateEmptyWideoDef(wideoDefFlash.id, flashMovieWidth, flashMovieHeight);

    //iterate over scenes
    wideoDef.scenes = await Promise.all(wideoDefFlash.scenes.map(async (flashScene: FlashScene) => {
      wideoLength += flashScene.length * 1000;
      return  this.extractScene(wideoLength, flashScene);
    }));

    // Replace all ids in all scenes, objects and compónents since HTML5 architecture is very picky on unique ids
    wideoDef.scenes.forEach( (sceneDef) => {
      new IdManager().replaceIds(sceneDef);
    });

    //set audios
    if (wideoDefFlash.music) {
      wideoDef.audios = await Promise.all(wideoDefFlash.music.filter((music: FlashMusic) => {
        //FLASH SHIT. When there isn't an audio for voice over, keeps a nulled flashMusic object
        return music.soundId ? true : false;
      }).map(async (music: FlashMusic) => {
        return this.extractMusic(music);
      }));
    }
    Logger.info('#################################### Conversion of Wideo from Flash JSON to HTML5 JSON done ####################################')

    return wideoDef;
  }

}
