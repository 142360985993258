import ApiUtils from '../../api/ApiUtils';
import Part from './model/Part';

export default class EncoderApi {

    private _baseUrl: string;

    constructor( baseUrl: string ) {
        this._baseUrl = baseUrl;
    }

    public requestJob = async ( encoderId: string, status: string): Promise<Part> => {
        const path: string = '/encoder/ping';
        const params = {
            encoderID: encoderId,
            status: status
        };
        const response: any =  await ApiUtils.httpGetJson<Object>(this._baseUrl, path, params); // tslint:disable-line:no-any
        if (response.response && response.response.toLowerCase && response.response.toLowerCase() === 'ok') {
            return null;
        } else {
            return response.response as Part;
        }
    }

    public uploadFrame = async (file: Blob, imageFormat: string, frame: number, part: Part, encoderId: string): Promise<void> => {
        const path: string = '/encoder/upload';

        const formData: FormData = new FormData();
        formData.append('encoderID', encoderId);
        formData.append('range', frame + '-' + frame);
        formData.append('processId', part.processId);
        formData.append('partId', '' + part.partId);
        formData.append('width', '' + part.width);
        formData.append('height', '' + part.height);
        formData.append('wideoId', part.wideoId);
        formData.append('jobType', part.jobType);
        formData.append('filedata', new File([file], frame + '.jpg', {type: "image/jpeg"}));

        await ApiUtils.httpPostFormData(this._baseUrl, path, formData);

    }

}
