import * as React from 'react';

import IEncoderQueryParams from './IEncoderQueryParams';

import ManualEncoder from './ManualEncoder';
import AutomaticEncoder from './AutomaticEncoder';

import * as Sentry from '@sentry/browser';
import { Helmet } from 'react-helmet';

import { environments } from '../../common/Env';

interface State {
}

interface Props {
    queryParams: IEncoderQueryParams;
}

/* EncoderApp is the root component in the Encoder. */
export default class EncoderApp extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    
    Sentry.configureScope((scope: Sentry.Scope) => {
      scope.setExtra("wideoId", this.props.queryParams.wideoId);
    });
  }

  render() {
    const brandName = environments[this.props.queryParams.environment].brandConfig ? environments[this.props.queryParams.environment].brandConfig.brandName : 'Wideo';
    const icon = environments[this.props.queryParams.environment].brandConfig ? environments[this.props.queryParams.environment].brandConfig.icon : 'https://resources.wideo.co/img/ng/wideo-w-white.png';

    return (
      <>
        <Helmet>
          <title>{brandName} - Encoder</title>
          <link rel="icon" href={icon} />
        </Helmet>
        <div>
          { this.props.queryParams.serverName &&
            <AutomaticEncoder {...this.props} />
          }
          { !this.props.queryParams.serverName &&
            <ManualEncoder {...this.props} />
          }
        </div>
      </>
    );
  }
}
