import { WideoDefVersion, Class } from "../../core/model/WideoDef";
import ITransformer from "../ITransformer";
import { v4 as uuid } from 'uuid';

export default class TransformerToV48 implements ITransformer {
  readonly toVersion: WideoDefVersion = WideoDefVersion.V48;

  // tslint:disable-next-line:no-any
  public transformWideo(wideoDef: any) {

    //Update version and timestamp
    wideoDef.version = this.toVersion;
    wideoDef.timeStamp = Date.now();

    for (const sceneDef of wideoDef.scenes) {
      this.transformScene(sceneDef);
    }

  }

  // tslint:disable-next-line:no-any
  transformScene(sceneDef: any): void {
    //Update version 
    sceneDef.version = this.toVersion;
      
    for (const wideoObjectDef of sceneDef.objects) {
      this.transformWideoObject(wideoObjectDef);
    }
  }

  // tslint:disable-next-line:no-any
  transformWideoObject(wideoObjectDef: any): void {
    for (const componentDef of wideoObjectDef.components) {
      if (componentDef.class === Class.AnimationComponent) {
        this.transformComponent(componentDef);
      }
    }
  }

  // tslint:disable-next-line:no-any
  transformComponent(componentDef: any): void {
    //get each keyframe and add id
    for (const keyframeDef of componentDef.animation.keyFrames) {
      keyframeDef.id = uuid();
    }
  }
}
