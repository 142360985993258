import * as PIXI from 'pixi.js-legacy';

import { SceneDef } from './model/WideoDef';
import WideoContext from './WideoContext';
import WideoObject from './WideoObject';

/**
 *
 * @export
 * @class Scene
 * @extends {WideoObject}
 */
export default class Scene extends WideoObject {

  protected _version: number;
  protected _width: number;
  protected _height: number;

  protected _foregroundContainer: PIXI.Container;

  protected _sceneMask: PIXI.Graphics;

  protected _thumbnail: number; 

  constructor(context: WideoContext, parent: WideoObject, sceneDef: SceneDef) {
    super(context, parent, sceneDef);

    this._version = sceneDef.version;
    this._width = sceneDef.width;
    this._height = sceneDef.height;

    this._sceneMask = new PIXI.Graphics();
    this._sceneMask.name = "Mask-" + this.name;
    this._thumbnail = sceneDef.thumbnail;

    this.drawMask();
    this.enableMask();
  }

  public serialize(): SceneDef {
    const sceneDef: SceneDef = super.serialize() as SceneDef;
    sceneDef.version = this._version;
    sceneDef.width = this._width;
    sceneDef.height = this._height;
    sceneDef.thumbnail = ( this._thumbnail === undefined ? undefined : this._thumbnail);
    return sceneDef;
  }

  public getLocalBounds(rect?: PIXI.Rectangle): PIXI.Rectangle {
    // Limit the localbounds of the Scene to the local bounds of the scene mask
    // Otherwise calculations in the TransitionComponent will fail.
    return this._sceneMask.getLocalBounds(rect);
  }

  /** Return the scene ancestor of this WideoObject  */
  public getScene(): Scene {
    return this;
  }

  /**
   * Adds a DisplayObject to the foreground of the Scene. An object added here
   * "floats" on-top of the WideoObjects in the Scene.
   */
  public addToForeground(object: PIXI.DisplayObject) {
    if (!this._foregroundContainer) {
      this._foregroundContainer = new PIXI.Container();      
      this._foregroundContainer.name = 'ForeGroundContainer';
      this.addChildAt(this._foregroundContainer, this.children.length);
    }
    this._foregroundContainer.addChild(object);
  }

  /**
   * Removes a DisplayObject from the foreground of the Scene.
   */
  public removeFromForeground(object: PIXI.DisplayObject) {
    if (this._foregroundContainer) {
      this._foregroundContainer.removeChild(object);
      if (this._foregroundContainer.children.length === 0) {
        this._foregroundContainer.destroy();
        this._foregroundContainer = null;
      }
    }
  }

  private drawMask() {
    this._sceneMask.beginFill(0xffffff, 1);
    this._sceneMask.drawRect(-this._width/2, -this._height/2, this._width, this._height);
    this._sceneMask.endFill();
  }

  /**
   * Disable the rectangular graphics object that masks the Scene.
   */
  public disableMask() {
    if (this.mask) {
      this.removeChild(this._sceneMask);
      this.mask = null;
    }    
  }

  /**
   * Enable the rectangular graphics object that masks the Scene.
   */
  public enableMask() {

    if (!this.mask) {
      this.addChild(this._sceneMask);
      this.mask = this._sceneMask as any;
    }
  }

  public destroy() {
    if (this._foregroundContainer) {
      this._foregroundContainer.destroy();
      this._foregroundContainer = null;
    }

    if (this._sceneMask) {
      this._sceneMask.destroy();
      this._sceneMask = null;
      this.mask = null;
    }
    super.destroy();
  }
  
  public getWidth(): number {
    return this._width;
  }

  public getHeight(): number {
    return this._height;
  }

  public setWidth(width: number) {
    this._width = width;
    this.drawMask();
  }

  public setHeight(height: number) {
    this._height = height;
    this.drawMask();
  }

  /**
   * 
   * @param indexScene 
   * @returns the moment in time (ms) for the thumbnail of the scene (scene local time)
   */
  public getThumbnail(): number {
    return this._thumbnail;
  }

  /**
   * 
   * @param time the moment in time (ms) for the thumbnail of the scene (scene local time)
   */
  public setThumbnail(time: number) {
    this._thumbnail = time;
  }

  /**
   * Clears the thumbnail of the scene
   */
  public clearThumbnail() {
    this._thumbnail = undefined;
  }

}
