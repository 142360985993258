/**
 * Created by dclerici.
 * @deprecated - we don't use this for now, spine sucks
 */
import * as PIXI from 'pixi.js-legacy';
import WideoObject from "../WideoObject";
// import { PendingAssetResource } from "../AssetsLoader";
import { ImageComponentDef, AnimatedImageComponentDef } from '../model/WideoDef';
import AbstractComponent from "./AbstractComponent";
import WideoContext from '../WideoContext';
import Attributes from "../Attributes";
import { resourcesBaseUrl } from '../../../editor/core/EditorConstants';

export default class AnimatedImageComponent extends AbstractComponent {
  protected _src: string;
  protected _lastLocalElapsedTime: number = 0;

  constructor(context: WideoContext, owner: WideoObject, def: ImageComponentDef) {
    super(true, 'AnimatedImageComponent-' + def.id);

    this._context = context;
    this._owner = owner;
    this._class = def.class;
    this._id = def.id;
    this._src = def.src;

    this._context.getAssetsLoader().push(
      {
        id: this._id,
        src: resourcesBaseUrl + '/img/editor/animated/' + this._src + ' / ' + this._src + '.json',
        content: null,
        contentGif: null
      },
      () => {
        this.addContent(this._id);
      },
      {
        loadType: PIXI.LoaderResource.LOAD_TYPE.XHR,
        xhrType: PIXI.LoaderResource.XHR_RESPONSE_TYPE.JSON
      }
    );
  }

  public addContent = (key: string) => {
    // const asset: PendingAssetResource = this._context.getAssetsLoader().getAsset(key);
    // const content: PIXI.ILoaderResource = asset.content;

    // if (content.spineData) {
    //   // this._animation = new PIXI.spine.Spine(content.spineData);

    //   // this._animation.position.x = 0
    //   // this._animation.position.y = 0

    //   // this._animation.scale.set(0.5);

    //   // // play animation
    //   // this._animation.state.setAnimation(0, 'animation', true);
    //   // this._animation.state.timeScale = 0.0005;
    //   // this._animation.autoUpdate = false;
    //   // this._displayObject.addChild(this._animation);
    // } 
  }

  public serialize(): AnimatedImageComponentDef {
    const component = {
      id: this._id,
      class: this._class,
      src: this._src
    };

    return component;

  }

  public getSrc = (): string => {
    return this._src;
  }

  public update(elapsedTime: number): Attributes {
    // Calculate object local time (the time relative to the start time of the owning WideoObject)
    const localElapsedTime = elapsedTime - this._owner.getStartTime();

    // if there is a problem loading the animation, check for null safe
    // if (this._animation) {
    //   // PIXI.Spine works with an internal state that keeps track of the elapsedTime, we need to pass the delta time only
    //   this._animation.update(localElapsedTime - this._lastLocalElapsedTime);
    // }
    this._lastLocalElapsedTime = localElapsedTime;
    return null;
  }

  public destroy(): void {
    super.destroy();
  }

}