import { colors } from '@mui/material';

export default {
  primary: {
    main: '#4285F4'
  },
  secondary: {
    main: '#4caf50',
    dark: '#388e3c',
    contrastText: '#fff'
  },
  text: {
    primary: colors.grey[800],
    secondary: colors.grey[600]
  },
  input: {
    bottomLine: colors.grey[200],
    inputText: colors.grey[600]
  }
};
