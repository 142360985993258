import {WideoDefVersion} from "../../core/model/WideoDef";
import ITransformer from "../ITransformer";
import Logger from "../../log/Logger";

export default class TransformerToV50 implements ITransformer {
    readonly toVersion: WideoDefVersion = WideoDefVersion.V50;

    // tslint:disable-next-line:no-any
    public transformWideo( wideoDef: any ) {

      //Update version and timestamp
      wideoDef.version = this.toVersion;
      wideoDef.timeStamp = Date.now();

      for (const sceneDef of wideoDef.scenes) {
        this.transformScene(sceneDef);
      }

    }   

    // tslint:disable-next-line:no-any
    transformScene(sceneDef: any): void {
      
      //Update version and timestamp
      sceneDef.version = this.toVersion;

      for (const wideoObjectDef of sceneDef.objects) {
        this.transformWideoObject(wideoObjectDef);
      }
    }

    // tslint:disable-next-line:no-any
    transformWideoObject(wideoObjectDef: any): void {

      if (wideoObjectDef.brightness) {
        if (wideoObjectDef.brightness !== 0.5) {
          Logger.warn("The wideo object with id " + wideoObjectDef.id + " was using Brightness. It is no longer suported.");
        }
        delete wideoObjectDef.brightness  
      }

      for (const innerObjectDef of wideoObjectDef.objects) {
          this.transformWideoObject(innerObjectDef);
      }

      for (const componentDef of wideoObjectDef.components) {
        this.transformComponent(componentDef);
      }

    }

    // tslint:disable-next-line:no-any
    transformComponent(componentDef: any): void {
      if (componentDef.brightness) {
        delete componentDef.brightness
      }
    }
}