import * as React from 'react';

import IEncoderQueryParams from './IEncoderQueryParams';
import { ImageFormat } from '../core/Encoder';
import EncoderClient from '../core/EncoderClient';
import EncoderClientListener from '../core/EncoderClientListener';
import { EncoderClientState } from '../core/EncoderClientListener';
import { version } from '../core/EncoderConstants';
import Part from '../api/model/Part';

interface State {
  version: string;
  state: string;
  error: Object;
  baseUrl: string;
  encoderId: string;
  pingPort: number;
  frames: string[];
  framesGenerated: number;
  framesUploaded: number;
  part: Part;
}


interface Props {
  queryParams: IEncoderQueryParams;
}

/* EncoderApp is the root component in the Encoder. */
export default class AutomaticEncoder extends React.Component<Props, State> implements EncoderClientListener {

  private encoderClient: EncoderClient;

  constructor(props: Props) {
    super(props);
    this.state = {
      version: version,
      state: "READY",
      error: null,
      baseUrl: props.queryParams.serverName,
      encoderId: props.queryParams.encoderID,
      pingPort: props.queryParams.pingPort,
      frames: [],
      framesGenerated: 0,
      framesUploaded: 0,
      part: null
    };

    if (this.state.encoderId) {
      this.encoderClient = new EncoderClient();
      this.encoderClient.addEncoderClientListener(this);
    }

  }

  componentDidMount(): void {
    if (this.state.baseUrl) {
      this.startEncoderClient();
    }
  }

  startEncoderClient = () => {
    const baseUrl: string = this.state.baseUrl;
    const encoderId: string = this.state.encoderId;
    const environment: string = this.props.queryParams.environment;
    const accessToken: string = this.props.queryParams.accessToken;
    const pingPort: number = this.props.queryParams.pingPort;
    const imageFormat: ImageFormat = ImageFormat[this.props.queryParams.imageType] as ImageFormat;
    const forceCanvas: boolean = this.props.queryParams.forceCanvas;
    const antialias: boolean = this.props.queryParams.antialias;
    const jsonUrl: string = this.props.queryParams.jsonUrl;

    this.encoderClient.init(
      encoderId,
      baseUrl,
      environment,
      accessToken,
      pingPort,
      imageFormat,
      forceCanvas,
      antialias,
      jsonUrl);

    this.encoderClient.run().catch((e) => {
      throw e;
    });

    this.setState(() => {
      return {
        state: "RUNNING"
      };
    });

  }

  stateChanged(state: EncoderClientState, error: Object): void {
    this.setState(() => {
      return {
        state: state,
        error: error
      };
    });
  }

  newPartReceived(part: Part): void {
    this.setState(() => {
      return {
        part: part
      };
    });
  }

  frameGenerated(frameNo: number): void {
    this.setState(() => {
      return {
        framesGenerated: frameNo + 1
      };
    });
  }

  frameUploaded(frameNo: number, frame: ArrayBuffer): void {

    this.state.frames.push(URL.createObjectURL(new Blob([frame])));

    this.setState(() => {
      return {
        framesUploaded: frameNo + 1
      };
    });
  }

  partDone(part: Part): void {
    this.setState(() => {
      return {
        part: null,
        framesUploaded: 0,
        framesGenerated: 0
      };
    });
  }

  render() {

    return (
      <div>
        <div>
          Encoder Version: {this.state.version} <br />
          EncoderId: {this.state.encoderId} <br />
          Query Parameters: <pre>{JSON.stringify(this.props.queryParams, null, '  ')}</pre> <br />
          State: {this.state.state} <br />
          {this.state.error &&
            <div>
              Error: {JSON.stringify(this.state.error)}
              <br />
            </div>}
          {this.state.part &&
            <div>
              Part: <pre>{JSON.stringify(this.state.part, null, '  ')}</pre><br />
              <div>
                Generated frames: {this.state.framesGenerated} / {this.state.part.noOfFrames} ({((this.state.framesGenerated) / (this.state.part.noOfFrames) * 100).toFixed(0)}%) <br />
                Uploaded frames: {this.state.framesGenerated} / {this.state.part.noOfFrames} ({((this.state.framesGenerated) / (this.state.part.noOfFrames) * 100).toFixed(0)}%) <br />
              </div>
            </div>
          }
        </div>
        <div>
          {this.state.frames.map((frameUrl: string, i: number) => {
            return (
              <span key={i}>
                <a href={frameUrl} download={i + '.data'}>{i + '.data'}</a><br />
              </span>
            );
          })
          }
        </div>
      </div>
    );
  }
}
