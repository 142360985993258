import * as React from 'react';

import { Theme } from '@mui/material/styles';

import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ListSubheader from '@mui/material/ListSubheader';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardMedia from '@mui/material/CardMedia';
import CircularProgress from '@mui/material/CircularProgress';
import Editor from '../../../../core/Editor';
import SearchTermResultItem from '../../../../../api/model/SearchTermResultItem';
import { imagesByPageInSearchDialogWhenFetchMore, AssetSearchSource, resourcesBaseUrl } from '../../../../../editor/core/EditorConstants';
import SearchResultWrapper from '../../../../../api/model/SearchResultWrapper';

import SearchDialogVideoCardMedia from './SearchDialogVideoCardMedia';
import { WithTranslation, withTranslation } from 'react-i18next';
import { getWhiteColor } from '../FontDialog';
import theme from 'src/common/ui/theme';

const cellHeightItem: number = 147
const cellHeightVideo: number = 120
const colsGridList: number = 3
const colsGridListMedia: number = 2

export const getBackgroundColor200 = (theme: Theme) => ({
  backgroundColor: theme.palette.grey['200'],
});

export const getBackgroundColor400 = (theme: Theme) => ({
  backgroundColor: theme.palette.grey['400'],
});

export const getBackgroundColor500 = (theme: Theme) => ({
  backgroundColor: theme.palette.grey['500'],
});

export const getBackgroundColorBlack = (theme: Theme) => ({
  backgroundColor: `${theme.palette.common.black}!important`,
});

const styles = {
  img: {
    height: cellHeightItem,
    padding: '0',
    width: '100%',
    backgroundSize: '100%'
  },
  buttonMoreContainer: {
    textAlign: 'center' as 'center',
  },
  buttonLessContainer: {
    textAlign: 'right' as 'right',
  },
  button: {
    ...getWhiteColor(theme),
    padding: '8px 20px',
    marginTop: 10,
    borderRadius: '25px'
  },
  buttonDefault: {
    margin: '0 0 20px',
    ...getBackgroundColor400(theme),
    '&:hover': {
      ...getBackgroundColor500(theme)
    }
  },
  card: {
    margin: 0,
    position: 'relative' as 'relative',
    boxShadow: 'none',
    ...getBackgroundColor200(theme),
    '&:hover': {
      '& $gridListTileBar': {
        opacity: 1,
      }
    },
    '&$cardVideo': {
      '& $gridListTileBar': {
        background: 'rgb(000 000 000 / 60%)'
      },
      '& $ghostAuthourName': {
        color: '#f5f5f5'
      },
    }
  },
  cardVideo: {
    ...getBackgroundColorBlack(theme),
    display: 'block',
    width: '100%',
    padding: 0,
    overflow: 'hidden' as React.CSSProperties['overflow']
  },
  cardActionArea: {
    paddingTop: '56.25%',
  },
  sourceIcon: {
    width: '30%',
    height: 'auto',
    verticalAlign: 'middle'
  },
  gridListTileBar: {
    opacity: 0,
    height: 'auto',
    padding: '6px 0',
    background: 'rgb(238 238 238 / 80%)'
  },
  ghostAuthourName: {
    color: '#3c3b3b',
    fontSize: '10px',
    textDecoration: 'none',
    textAlign: 'right' as React.CSSProperties['textAlign'],
    display: 'block'
  },
  divider: {
    margin: '20px 0',
    height: '1px'
  },
};

interface Props {
  editor: Editor,
  handleSearchSelection: (searchItem: SearchTermResultItem, source: AssetSearchSource) => void;
  assetSearchSource: AssetSearchSource;
  query: string;
}

interface State {
  items: SearchResultWrapper;
  noMoreResults: boolean;
  loading: boolean;
  page: number;
}

type Styled = any

type PropsWithStyles = Props & WithTranslation & Styled;


//type PropsWithStyles = Props & WithTranslation & WithStyles<'card' | 'cardVideo' | 'cardActionArea' | 'img' | 'buttonMoreContainer' | 'buttonLessContainer' | 'button' | 'buttonDefault' | 'sourceIcon' | 'gridListTileBar' | 'ghostAuthourName' |
//  'divider'>;

class SearchProvider extends React.Component<PropsWithStyles, State> {

  constructor(props: PropsWithStyles) {
    super(props);
    this.state = {
      items: null,
      noMoreResults: false,
      loading: true,
      page: 0
    }
  }

  /**
   * Fetch results from backend.
   * @param query:string => the query we will search
   * @param page: number => the page we need
   * @param source: AssetSearchSource
   */
  fetchResults = async (query: string, page: number, source: AssetSearchSource, qtyByPage?: number): Promise<SearchResultWrapper> => {
    if (query) {
      this.setState({ noMoreResults: false, loading: true });

      const result: SearchResultWrapper = await this.props.editor.searchForAssets(query, page, source, qtyByPage);
      let noMoreResults: boolean = false;

      if (!result.searchResult || result.searchResult.items === null || result.searchResult.items.length < qtyByPage) {
        noMoreResults = true;
      }

      this.setState({ noMoreResults: noMoreResults, loading: false });
      return result;
    }
    return null;
  }

  /**
   * here we "merge" the results of the new search for the next page
   * and the old results we have in state
   */
  private mergeNewItems = (newItems: SearchResultWrapper) => {

    if (newItems) {

      if (this.state.items.searchResult && newItems.searchResult) {

        //update items and page
        this.state.items.searchResult.items = (this.state.items.searchResult.items.concat(newItems.searchResult.items));
        this.state.items.page += 1;

      }
    }
    return this.state.items;
  }

  /**
 * Async method, called when user clicks in "show more button"
 * This execute a new fetchResults, with the next page of the source.
 * @param source: AssetSearchSource => the source where we search a new page 
 */
  fetchMore = async (source: AssetSearchSource) => {

    //get last page used for this source
    const nextPage: number = this.state.page + 1;

    // HACK Clear items
    if (this.state.page === 0 && this.state.items) {
      this.state.items.searchResult.items = [];
    }

    //perform a new fetch results
    const moreItems: SearchResultWrapper = await this.fetchResults(this.props.query, nextPage, source, imagesByPageInSearchDialogWhenFetchMore);

    this.setState({ items: this.mergeNewItems(moreItems), page: nextPage });
    // const elem = document.getElementById("sourceResults-" + source);
    // elem.scrollIntoView({ block: 'end', behavior: 'smooth' })
  }

  showLess = async (source: AssetSearchSource) => {
    const qtyByPage = 3;
    const newItems = await this.fetchResults(this.props.query, 1, source, qtyByPage);

    this.setState({ items: newItems, page: 0 });

  }

  /**
   * perform a search
   * sets state as loading, save used query and fetch results
   * 
   */
  search = async () => {
    if (this.props.query) {

      const qtyByPage = 3;
      const items = await this.fetchResults(this.props.query, 1, this.props.assetSearchSource, qtyByPage);

      this.setState({ items: items }, () => {
        if (this.isVideoSearchDialog()) {
          this.props.editor.logAmplitudeEvent("VIDEO_SEARCH_DONE_HTML5", { query: this.props.query })
          this.props.editor.logGAEvent("VIDEO_SEARCH_DONE_HTML5", null, false);
        } else {
          this.props.editor.logAmplitudeEvent("IMAGE_SEARCH_DONE_HTML5", { query: this.props.query })
          this.props.editor.logGAEvent("IMAGE_SEARCH_DONE_HTML5", null, false);
        }
      });
    }
  }

  private isVideoSearchDialog = () => {
    return (this.props.assetSearchSource === AssetSearchSource.PIXABAY_VIDEO ||
      this.props.assetSearchSource === AssetSearchSource.PEXELS_VIDEO);
  }

  async componentDidUpdate(prevProps: Props) {
    if (this.props.query !== prevProps.query) {
      await this.search();
    }
  }

  async componentDidMount() {
    // trigger first default search
    await this.search();
  }

  /**
   * return the dialog content part, iterating over search's results
   */
  renderDialogContent = () => {
    const { t } = this.props;

    //if there are results to show
    if (this.state.items) {
      let itemJsx: JSX.Element[]
      if (this.state.items.searchResult) {
        //this resprents an image result
        itemJsx = this.state.items.searchResult.items.map((item: SearchTermResultItem, index: number) => {
          return (
            <ImageListItem key={index}>
              <Card 
                  style={{
                    ...styles.card,
                    ...(this.state.items.searchResult.category === 'VIDEO' ? styles.cardVideo : {}),
                  }}
              >
                <CardActionArea
                  onClick={() => this.props.handleSearchSelection(item, this.props.assetSearchSource)}
                  cyid={this.state.items.source + '-searchResultItem-' + index}
                  style={{ ...this.state.items.searchResult.category === 'VIDEO' ? styles.cardActionArea : '' }}
                >
                  {this.state.items.searchResult.category === 'IMAGE' &&
                    <CardMedia
                      style={styles.img}
                      image={item.thumbnailLink}
                      title={item.authorName ? item.authorName : ""}
                    />
                  }
                  {this.state.items.searchResult.category === 'VIDEO' &&
                    <SearchDialogVideoCardMedia
                      src={item.previewUrl}
                      poster={item.thumbnailLink}
                    />
                  }
                </CardActionArea>
                {item.authorName &&
                  <ImageListItemBar
                    style={{ ...styles.gridListTileBar }}
                    subtitle={
                      <a target="_blank" href={item.authorProfileUrl} style={{...styles.ghostAuthourName}} rel="noreferrer">{item.authorName}</a>
                    }
                  />
                }
              </Card>
            </ImageListItem>
          )
        });

      };

      return (
        <div id={"sourceResults-" + this.state.items.source}
          key={"sourceResults-" + this.state.items.source}>
          <ImageList
            cols={this.state.items.searchResult.category === 'VIDEO' ? colsGridListMedia : colsGridList}
            cellHeight={this.state.items.searchResult.category === 'VIDEO' ? cellHeightVideo : cellHeightItem}
            spacing={3}
          >
            <ImageListItem key="Subheader" cols={3} style={{ height: '55px' }}>
              <ListSubheader component="div">
                <Grid container spacing={0}>
                  <Grid item xs={8}>
                    <img style={styles.sourceIcon} title={this.state.items.source} src={resourcesBaseUrl + "/img/editor/icons/" + this.state.items.source + ".png"} />
                  </Grid>
                  <Grid item xs={4}>
                    <div style={styles.buttonLessContainer}>
                      {(this.state.items.page > 1) &&
                        <Button
                          variant="contained"
                          size="large"
                          style={{...styles.buttonDefault, ...styles.button }}
                          onClick={async () => { await this.showLess(this.state.items.source) }}
                        >
                          {t("leftbar.commons.search.show-less")}
                        </Button>
                      }
                    </div>
                  </Grid>
                </Grid>
              </ListSubheader>
            </ImageListItem>

            {itemJsx}

            {this.state.noMoreResults &&
              <ImageListItem cols={3} style={{ height: 'auto' }}>
                <p>
                  {t("leftbar.commons.search.no-results")}
                </p>
              </ImageListItem>
            }

            {this.state.loading &&
              <ImageListItem key="Loading" cols={3} style={{ height: 'auto' }}>
                <CircularProgress
                  variant="indeterminate"
                  disableShrink
                  size={24}
                  thickness={4}
                  cyid="searchDialogProgress"
                />
              </ImageListItem>
            }

          </ImageList>
          <div style={styles.buttonMoreContainer}>
            {(!this.state.noMoreResults) &&
              <Button
                variant="contained"
                size="large"
                color="primary"
                style={{ ...styles.button }}
                onClick={async () => { await this.fetchMore(this.state.items.source) }}
              >
                {t("leftbar.commons.search.show-more")}
              </Button>
            }
          </div>
          <Divider 
           style={{ ...styles.divider }} 
          />
        </div>
      )
    }
    else { return (<span />) }
  }

  render() {
    return (
      <this.renderDialogContent />
    );
  }
}

export default withTranslation("leftbar")(SearchProvider);

