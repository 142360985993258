import * as PIXI from 'pixi.js-legacy';

import { Class } from '../model/WideoDef';
import WideoContext from '../WideoContext';
import WideoObject from '../WideoObject';
import Attributes from "../Attributes";
import IComponent from "./IComponent";
import { v4 as uuid } from 'uuid';

export default class AbstractComponent implements IComponent {

  protected _displayObject: PIXI.Container;

  // do not serialize
  public priority: number;
  protected _owner: WideoObject;
  protected _id: string;
  protected _class: Class;
  protected _context: WideoContext;
  protected _isEnabled: boolean = true;

  constructor(displayable: boolean, name?: string) {
    if (displayable) {
      this._displayObject = new PIXI.Container();
      this._displayObject.name = name;
    }
  }

  public getDisplayObject(): PIXI.DisplayObject {
    return this._displayObject;
  }

  public isEnabled(): boolean {
    return this._isEnabled;
  }

  public enable(): void {
    this._isEnabled = true;
  }

  public disable(): void {
    this._isEnabled = false;
  }

  public getClass(): Class {
    return this._class;
  }

  public getId(): string {
    return this._id;
  }

  public beforeUpdate(): void {
    // Intentionally blank
  }
  
  public async seek(elapsedTime: number): Promise<void> {
    return Promise.resolve();
  }
  public play(): void {
    // Intentionally blank   
  }
  public pause(): void {
    // Intentionally blank
  }
  public update(deltaTime: number, playing?: boolean): Attributes {
    throw new Error("Method not implemented.");
  }
  public onAdded(): void {
    //Intentionally blank
  }
  public onRemoved(): void {
    //Intentionally blank
  }
  public destroy(): void {
    if (this._displayObject) {
      this._displayObject.destroy();
      this._displayObject = null;
    }
  }
  public serialize() {
    throw new Error("Method not implemented.");
  }

  public reset(): void {
    this._id = uuid();
  }
}
