import * as PIXI from 'pixi.js-legacy';
import Logger from '../log/Logger';
import { ShapeType, MaskType } from "./model/WideoDef";

export function maskTypeToShapeType(maskType: MaskType): ShapeType {
    if (maskType === MaskType.Rectangle) {            
        return ShapeType.Rectangle;
    } else if (maskType === MaskType.Circle) {
        return ShapeType.Ellipse;
    } else if (maskType === MaskType.RoundedRect) {
        return ShapeType.RoundedRect;
    } else if (maskType === MaskType.Ellipse) {
        return ShapeType.Ellipse;
    } else if (maskType === MaskType.Diamond) {
        return ShapeType.Diamond;
    }
    else {
        Logger.warn('Unknown mask type: ' + maskType);
        return ShapeType.Rectangle;
    }       
}

export function drawShape(
  graphics: PIXI.Graphics,
  shapeType: ShapeType,
  width: number,
  height: number,
  cornerRadius?: number
) {

  switch (shapeType) {
    case ShapeType.Rectangle:
    case ShapeType.Square: {      
      graphics.drawRect(0, 0, width, height);
      break;
    }
    case ShapeType.Ellipse: {
      graphics.drawEllipse(width / 2, height / 2, width / 2, height / 2);
      break;
    }
    case ShapeType.Diamond: {
      // Draw a diamond as a closed path, [x1, y1, x2, y2, ..., xn, yn]
      graphics.drawPolygon([
        width / 2,
        0,
        width,
        height / 2,
        width / 2,
        height,
        0,
        height / 2,
        width / 2,
        0
      ]);
      break;
    }
    case ShapeType.RoundedRect:
    case ShapeType.RoundedSquare: {
      if (cornerRadius) {
        graphics.drawRoundedRect(0, 0, width, height, cornerRadius);
      } else {        
        const defaultRadius = 0.15 * Math.min(height, width); // 15% corner rounding radius relative the shortest side
        graphics.drawRoundedRect(0, 0, width, height, defaultRadius);
      }
      break;
    }
    case ShapeType.Line:
    case ShapeType.DottedLine: {
      graphics.moveTo(0, 0);
      graphics.lineTo(width, 0);
      break;
    }
    case ShapeType.Triangle: {
      // Draw a triangle as a closed path, [x1, y1, x2, y2, ..., xn, yn]
      graphics.drawPolygon([
        width / 2,
        0,
        width,
        height,
        0,
        height,
        width / 2,
        0
      ]);
      break;
    }
    case ShapeType.TriangleRectangle: {
      // Draw a triangle as a closed path, [x1, y1, x2, y2, ..., xn, yn]
      graphics.drawPolygon([0, 0, width, height, 0, height, 0, 0]);
      break;
    }
    case ShapeType.Arrow: {
      graphics.moveTo(1.0 * width, 0.501 * height);
      graphics.lineTo(1.0 * width, 0.499 * height);
      graphics.lineTo(0.4493 * width, 0.0 * height);
      graphics.lineTo(0.4493 * width, 0.2742 * height);
      graphics.lineTo(0.0 * width, 0.2742 * height);
      graphics.lineTo(0.0 * width, 0.7258 * height);
      graphics.lineTo(0.4493 * width, 0.7258 * height);
      graphics.lineTo(0.4493 * width, 1.0 * height);
      graphics.lineTo(1.0 * width, 0.501 * height);
      graphics.closePath();
      break;
    }
    case ShapeType.MultipleStar: {
      graphics.moveTo(0.7637 * width, 0.1975 * height);
      graphics.lineTo(0.7936 * width, 0.0935 * height);
      graphics.lineTo(0.7055 * width, 0.1552 * height);
      graphics.lineTo(0.7178 * width, 0.0476 * height);
      graphics.lineTo(0.6402 * width, 0.1235 * height);
      graphics.lineTo(0.6332 * width, 0.0159 * height);
      graphics.lineTo(0.5714 * width, 0.1041 * height);
      graphics.lineTo(0.545 * width, 0.0 * height);
      graphics.lineTo(0.4991 * width, 0.0988 * height);
      graphics.lineTo(0.455 * width, 0.0 * height);
      graphics.lineTo(0.4286 * width, 0.1041 * height);
      graphics.lineTo(0.3668 * width, 0.0159 * height);
      graphics.lineTo(0.3598 * width, 0.1235 * height);
      graphics.lineTo(0.2822 * width, 0.0476 * height);
      graphics.lineTo(0.2945 * width, 0.1552 * height);
      graphics.lineTo(0.2063 * width, 0.0935 * height);
      graphics.lineTo(0.2363 * width, 0.1975 * height);
      graphics.lineTo(0.1376 * width, 0.1534 * height);
      graphics.lineTo(0.1869 * width, 0.2487 * height);
      graphics.lineTo(0.0829 * width, 0.2222 * height);
      graphics.lineTo(0.1481 * width, 0.3086 * height);
      graphics.lineTo(0.0406 * width, 0.3016 * height);
      graphics.lineTo(0.1199 * width, 0.3757 * height);
      graphics.lineTo(0.0123 * width, 0.388 * height);
      graphics.lineTo(0.1041 * width, 0.4444 * height);
      graphics.lineTo(0.0 * width, 0.4762 * height);
      graphics.lineTo(0.1005 * width, 0.5168 * height);
      graphics.lineTo(0.0035 * width, 0.5661 * height);
      graphics.lineTo(0.1093 * width, 0.5873 * height);
      graphics.lineTo(0.0247 * width, 0.6543 * height);
      graphics.lineTo(0.1323 * width, 0.6561 * height);
      graphics.lineTo(0.06 * width, 0.7355 * height);
      graphics.lineTo(0.1658 * width, 0.7196 * height);
      graphics.lineTo(0.1093 * width, 0.8113 * height);
      graphics.lineTo(0.2099 * width, 0.776 * height);
      graphics.lineTo(0.1711 * width, 0.8765 * height);
      graphics.lineTo(0.2645 * width, 0.8236 * height);
      graphics.lineTo(0.2434 * width, 0.9295 * height);
      graphics.lineTo(0.3263 * width, 0.8607 * height);
      graphics.lineTo(0.3245 * width, 0.9683 * height);
      graphics.lineTo(0.3933 * width, 0.8854 * height);
      graphics.lineTo(0.4109 * width, 0.9912 * height);
      graphics.lineTo(0.4638 * width, 0.8977 * height);
      graphics.lineTo(0.4991 * width, 1.0 * height);
      graphics.lineTo(0.5362 * width, 0.8977 * height);
      graphics.lineTo(0.5891 * width, 0.9912 * height);
      graphics.lineTo(0.6067 * width, 0.8854 * height);
      graphics.lineTo(0.6755 * width, 0.9683 * height);
      graphics.lineTo(0.6737 * width, 0.8607 * height);
      graphics.lineTo(0.7566 * width, 0.9295 * height);
      graphics.lineTo(0.7355 * width, 0.8236 * height);
      graphics.lineTo(0.8289 * width, 0.8765 * height);
      graphics.lineTo(0.7901 * width, 0.776 * height);
      graphics.lineTo(0.8906 * width, 0.8113 * height);
      graphics.lineTo(0.8342 * width, 0.7196 * height);
      graphics.lineTo(0.94 * width, 0.7355 * height);
      graphics.lineTo(0.8677 * width, 0.6561 * height);
      graphics.lineTo(0.9753 * width, 0.6543 * height);
      graphics.lineTo(0.8906 * width, 0.5873 * height);
      graphics.lineTo(0.9965 * width, 0.5661 * height);
      graphics.lineTo(0.8995 * width, 0.5168 * height);
      graphics.lineTo(1.0 * width, 0.4762 * height);
      graphics.lineTo(0.8959 * width, 0.4444 * height);
      graphics.lineTo(0.9877 * width, 0.388 * height);
      graphics.lineTo(0.8801 * width, 0.3757 * height);
      graphics.lineTo(0.9594 * width, 0.3016 * height);
      graphics.lineTo(0.8519 * width, 0.3086 * height);
      graphics.lineTo(0.9171 * width, 0.2222 * height);
      graphics.lineTo(0.813 * width, 0.2487 * height);
      graphics.lineTo(0.8624 * width, 0.1534 * height);
      graphics.lineTo(0.7637 * width, 0.1975 * height);
      graphics.closePath();
      break;
    }
    case ShapeType.Hexagon: {
      graphics.moveTo(1.0 * width, 0.5 * height);
      graphics.lineTo(0.7506 * width, 1.0 * height);
      graphics.lineTo(0.2494 * width, 1.0 * height);
      graphics.lineTo(0.0 * width, 0.5 * height);
      graphics.lineTo(0.2494 * width, 0.0 * height);
      graphics.lineTo(0.7506 * width, 0.0 * height);
      graphics.lineTo(1.0 * width, 0.5 * height);
      graphics.closePath();
      break;
    }
    case ShapeType.Star: {
      graphics.moveTo(0.6558 * width, 0.3281 * height);
      graphics.lineTo(0.5011 * width, 0.0 * height);
      graphics.lineTo(0.3464 * width, 0.3281 * height);
      graphics.lineTo(0.0 * width, 0.3824 * height);
      graphics.lineTo(0.2505 * width, 0.638 * height);
      graphics.lineTo(0.1917 * width, 1.0 * height);
      graphics.lineTo(0.5011 * width, 0.8281 * height);
      graphics.lineTo(0.8105 * width, 1.0 * height);
      graphics.lineTo(0.7495 * width, 0.638 * height);
      graphics.lineTo(1.0 * width, 0.3824 * height);
      graphics.lineTo(0.6558 * width, 0.3281 * height);
      graphics.closePath();
      break;
    }
    default: {
      Logger.warn("Non supported shape: " + shapeType);
      graphics.drawRect(0, 0, width, height);
      break;
    }
  }
}
