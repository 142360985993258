import ApiUtils from './ApiUtils';
import Logger from '../common/log/Logger';

export default class GenericApi {

    /** Perform HTTP GET request and deserialize/parse the expected JSON string response to a
     *  Javascript object.
     *
     *  On anything other than a 2xx response it throws an (async)exception/rejects the returned promise
     */
    public static httpGetJson = async <R extends {}>(url: string): Promise<R> => {
      
      const init = {
          method: 'GET',
          headers: {
              'Accept': 'application/json'
          },
          mode: 'cors' as RequestMode
      };

      try {
          const response = await fetch(url, init);
          return ApiUtils.parseJsonResponse<R>(response);
      } catch (error) {
          Logger.warn('Failed GET request to ' + url + '. Reason: ' + Logger.errorToString(error));
          throw error;
      }

  }

}
