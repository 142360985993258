import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';


type Mp4DownloadModalProps = {
  open: boolean;
  onClose: () => void;
  error: string;
  data: {
    userEmail?: string;
    batchId?: string;
  };
};

export default function Mp4DownloadModal({ open, onClose, error, data }: Mp4DownloadModalProps) {
  return (
    <Dialog
      open={open}
      keepMounted
      onClose={onClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{"Automation response"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {error ?? `The email will be sent to ${data.userEmail} with an mp4 download link.`}
        </DialogContentText>
        <DialogContentText id="alert-dialog-slide-description">
          {error ?? `BATCH ID: ${data.batchId}`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
