import ApiUtils from "./ApiUtils";
import { environments } from "../common/Env";

export interface GetReplaceInfoResponse {
    statusCode: number,
    body: { [key: string]: string }
}

export interface SaveReplaceInfoResponse {
    statusCode: number,
    body: { id: string }
}

export default class AutomationReplaceApi {

    private baseApiUrl: string;

    constructor(environment: string) {
        this.baseApiUrl = environments[environment].automationApiUrl;
    }

    private sleep = async (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
      }

    public getReplaceInfo = async (replaceId: string): Promise<GetReplaceInfoResponse> => {
        const path: string = '/automation/replace';
        let replaceInfoResponse = await ApiUtils.httpGetJson<GetReplaceInfoResponse>(this.baseApiUrl, path, { replaceId: replaceId });
        let attempts = 0;

        /* Dirty polling for replaceId to eventually exist or timeout after 60 seconds */
        while (((!replaceInfoResponse.statusCode) || replaceInfoResponse.statusCode === 403 || replaceInfoResponse.statusCode === 404)  && attempts <= 60) {
            replaceInfoResponse = await ApiUtils.httpGetJson<GetReplaceInfoResponse>(this.baseApiUrl, path, { replaceId: replaceId });
            attempts++;
            await this.sleep(1000);
        }
        if (!replaceInfoResponse.statusCode || replaceInfoResponse.statusCode !== 200) {
            throw new Error("Unexpected response statusCode from getReplaceInfo(): " + (replaceInfoResponse.statusCode ? replaceInfoResponse.statusCode : "undefined"));
        }
        return replaceInfoResponse;
    }

    public saveReplaceInfo = async (data: Object): Promise<SaveReplaceInfoResponse> => {
        const path: string = '/automation/replace';
        return ApiUtils.httpPostJson<SaveReplaceInfoResponse, Object>(this.baseApiUrl, path, null, data);
    }
}