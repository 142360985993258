/**
 * responsible to initialize Router where we will render our routes.
 */

import * as PIXI from 'pixi.js-legacy';
import * as React from 'react';
import { Routes, Route, useSearchParams, Navigate, useLocation } from 'react-router-dom';
import IPlayerQueryParams from '../../player/ui/IPlayerQueryParams';
import IEditorQueryParams from '../../editor/ui/IEditorQueryParams';
import IMaticQueryParams from '../../matic/ui/IMaticQueryParams';
import IEncoderQueryParams from '../../encoder/ui/IEncoderQueryParams';
import Logger from '../../common/log/Logger';
import { defaultAccessToken } from "../../editor/core/EditorConstants";
import { Suspense } from 'react';
import PlayerApp from 'src/player/ui/PlayerApp';
import EncoderApp from 'src/encoder/ui/EncoderApp';
import MaticApp from '../../matic/ui/MaticApp';
import { environments } from '../Env';

PIXI.utils.skipHello();

const PLAYER_DEFAULTS_QUERY_PARAMS: IPlayerQueryParams = {
  debug: false,
  autoplay: false,
  repeat: false,
  startTime: 0,
  wideoId: null,
  accessToken: defaultAccessToken,
  environment: 'develepe',
  config: null,
  hideControls: false,
  convert: false,
  forceCanvas: true,
  antialias: true,
  replace: null,
  replaceId: null,
  jsonUrl: null,
  superUser: false
};

const EDITOR_DEFAULTS_QUERY_PARAMS: IEditorQueryParams = {
  environment: 'develepe',
  config: null,
  wideoId: null,
  accessToken: defaultAccessToken,
  debug: false,
  autoSave: true,
  new: false,
  superUser: false,
  convert: false,
  width: undefined,
  height: undefined,
  forceCanvas: true,
  antialias: true,
  replace: null,
  replaceId: null
};

const MATIC_DEFAULTS_QUERY_PARAMS: IMaticQueryParams = {
  debug: false,
  autoplay: false,
  repeat: false,
  startTime: 0,
  wideoId: null,
  accessToken: defaultAccessToken,
  environment: 'develepe',
  config: null,
  hideControls: false,
  convert: false,
  forceCanvas: true,
  antialias: true,
  replace: null,
  replaceId: null,
  jsonUrl: null,
  superUser: false,
  redirect: true,
  showCTA: true,
  excludeColors: '',
  export: false
};

const ENCODER_DEFAULTS_QUERY_PARAMS: IEncoderQueryParams = {
  environment: 'develepe',
  config: null,
  wideoId: null,
  accessToken: null,
  encoderID: 'HTML5-Encoder',
  serverName: null,
  apiURL: null,
  pingPort: null,
  imageType: 'jpg',
  fps: 30,
  forceCanvas: true,
  antialias: true,
  frameExtract: 'pixiExtractPixels',
  replace: null,
  replaceId: null,
  jsonUrl: null,
  start: 0,
  end: 0,
  processId: 0,
  s3Key: '',
  s3AccessKey: null,
  s3Bucket: null
};

function initEnv() {
  // Handle special case where we pass environment configuration as a 
  // query parameter (whitelabel)
  const url = new URL(window.location.href);
  const environmentConfig = url.searchParams.get('config');
  if (environmentConfig) {
    console.debug("We are running Editor as White Label, using host: " + url.host);
    environments.whitelabel = JSON.parse(decodeURIComponent(atob(environmentConfig)));
    console.debug("InitEnv: environmentConfig: %o", environments.whitelabel);
    ENCODER_DEFAULTS_QUERY_PARAMS.environment = 'whitelabel';
    EDITOR_DEFAULTS_QUERY_PARAMS.environment = 'whitelabel';
    PLAYER_DEFAULTS_QUERY_PARAMS.environment = 'whitelabel';
    MATIC_DEFAULTS_QUERY_PARAMS.environment = 'whitelabel';
  }
  else if (window.location.hostname.indexOf('editordev.wideo.co') >= 0) {
    ENCODER_DEFAULTS_QUERY_PARAMS.environment = 'develepe';
    EDITOR_DEFAULTS_QUERY_PARAMS.environment = 'develepe';
    PLAYER_DEFAULTS_QUERY_PARAMS.environment = 'develepe';
    MATIC_DEFAULTS_QUERY_PARAMS.environment = 'develepe';
  }
  else if (window.location.hostname.indexOf('editor.wideo.co') >= 0) {
    ENCODER_DEFAULTS_QUERY_PARAMS.environment = 'prod';
    EDITOR_DEFAULTS_QUERY_PARAMS.environment = 'prod';
    PLAYER_DEFAULTS_QUERY_PARAMS.environment = 'prod';
    MATIC_DEFAULTS_QUERY_PARAMS.environment = 'prod';
  }
}


function getQueryParams<T>(searchString: string, defaults: T): T {
  const queryParams: T = {} as T;
  const nameValuePairs: string[] = searchString.substr(1).split('&');
  if (searchString !== 'undefined' && searchString !== '') {
    for (const nameValue of nameValuePairs) {
      const [name, value] = nameValue.split('=');

      if (defaults.hasOwnProperty(name)) {
        if (value === 'false') {
          queryParams[name] = false;
        } else if (value === 'true') {
          queryParams[name] = true;
        } else {
          queryParams[name] = value;
        }
      } else {
        Logger.warn('Non existent query param: ' + name + ' = ' + value);
      }
    }

    for (const param in defaults) {
      if (typeof queryParams[param] !== 'undefined') {
        defaults[param] = queryParams[param];
      }
    }

  }

  return defaults;
}

const EditorLoader = () => {
  const [searchParams] = useSearchParams();
  const location = useLocation();

  const queryParams = getQueryParams(location.search, EDITOR_DEFAULTS_QUERY_PARAMS);

  if (searchParams.has('wideoId')) {
    const EditorApp = React.lazy(() => import('../../editor/ui/EditorApp'));

    return (
      <Suspense fallback={<></>}>
        <EditorApp queryParams={queryParams} />
      </Suspense>
    );
  } else {
    const url = environments[queryParams.environment].webUrl.base + 
    environments[queryParams.environment].webUrl.myProfile
    window.location.href = url;
    return null;
  }
};

function PlayerRoute() {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const queryParams = getQueryParams(location.search, PLAYER_DEFAULTS_QUERY_PARAMS);

  if (searchParams.has('wideoId')) {
    return (
      <Suspense fallback={<></>}>
        <PlayerApp queryParams={queryParams} />
      </Suspense>
    );
  } else {
    const url = environments[queryParams.environment].webUrl.base + 
    environments[queryParams.environment].webUrl.myProfile
    window.location.href = url;
    return null;
  }
}

const Main = () => {
  const location = useLocation();
  
  initEnv();

  return (
    <main>
      <Routes>
        <Route path="/" element={<Navigate to="/player" replace />} />
        <Route
          path="/editor"
          element={<EditorLoader />}
        />
        <Route
          path="/player"
          element={
            <PlayerRoute />
          }
        />        
        <Route
          path="/encoder"
          element={
            <Suspense fallback={<></>}>
              <EncoderApp queryParams={getQueryParams(location.search, ENCODER_DEFAULTS_QUERY_PARAMS)} />
            </Suspense>
          }
        />
        <Route
          path="/matic"
          element={
            <Suspense fallback={<></>}>
              <MaticApp
                queryParams={getQueryParams(location.search, MATIC_DEFAULTS_QUERY_PARAMS)}
              />
            </Suspense>
          }
        />
        <Route path="/alive" element={<span>Alive!</span>} />
        <Route path="/player/*" element={<Navigate to="/player" replace />} />
        <Route path="/editor/*" element={<Navigate to="/editor" replace />} />
        <Route path="/encoder/*" element={<Navigate to="/encoder" replace />} />
        <Route path="*" element={<Navigate to="/player" replace />} />

      </Routes>
    </main>
  );
};

export default Main;
