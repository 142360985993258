import { WideoDefVersion } from "../../core/model/WideoDef";
import ITransformer from "../ITransformer";

export default class TransformerToV72 implements ITransformer {
  readonly toVersion: WideoDefVersion = WideoDefVersion.V72;

  // tslint:disable-next-line:no-any
  public transformWideo(wideoDef: any) {

    // Update version and timestamp
    wideoDef.version = this.toVersion;
    wideoDef.timeStamp = Date.now();

		// Add sequence number to the wideo
		wideoDef.sequenceNumber = 0;

    for (const sceneDef of wideoDef.scenes) {
      this.transformScene(sceneDef);
    }

  }

  // tslint:disable-next-line:no-any
  transformScene(sceneDef: any): void {
    //Update version
    sceneDef.version = this.toVersion;
  }

}