import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

type Props = {
  onClick: () => void;  // Here we define onClick as a function that returns nothing
};

export default function Mp4DownloadButton({ onClick }: Props) {
  return (
    <Grid item xs sx={{ textAlign: 'center', marginTop: 1 }}>
      <Tooltip title="Export to mp4">
        <Button
          variant="contained"
          color="primary"
          onClick={onClick}
        >
          Export to mp4
        </Button>
      </Tooltip>
    </Grid>
  );
}