import React from 'react';

import * as Sentry from '@sentry/browser';
import EditorErrorPage from '../../editor/ui/layout/editorErrorPages/EditorErrorPage';
import { resourcesBaseUrl } from '../../editor/core/EditorConstants';

interface Props {
  children: React.ReactNode;
}

interface State {
    hasError: boolean;
    eventId: string;
}

/**
 * Default error boundary for sentry
 */
class SentryErrorBoundary extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { hasError: false, eventId: null };
    }

    static getDerivedStateFromError(error: Error) {
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        Sentry.withScope((scope: Sentry.Scope) => {
            scope.setExtra("stack", errorInfo.componentStack);
            const eventId = Sentry.captureException(error);
            this.setState({ eventId: eventId });
        });
        if (error && error.message && error.message.indexOf("Loading chunk") >= 0) {
            //Force a refresh 
            window.location.reload();
        }
    }

    render() {
        if (this.state.hasError) {
            const contentLoadError: string = "Something went wrong.  Please refresh this page to retry or go back to your profile page."
            const pathImgError: string = resourcesBaseUrl + '/img/editor/art-error.svg';
            //render fallback UI
            return (
                <EditorErrorPage
                    typeError="loadError"
                    title="Sorry!"
                    content={contentLoadError}
                    buttonText={'Report feedback'}
                    image={pathImgError}
                    handleOnClickError={() => Sentry.showReportDialog({ eventId: this.state.eventId })}
                    handleGoToHelpCenter={() => Sentry.showReportDialog({ eventId: this.state.eventId })}
                />
            );
        }

        //when there's not an error, render children untouched
        return this.props.children;
    }
}

export default SentryErrorBoundary