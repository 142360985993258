import { WideoDefVersion, Class } from "../../core/model/WideoDef";
import ITransformer from "../ITransformer";

export default class TransformerToV57 implements ITransformer {
  readonly toVersion: WideoDefVersion = WideoDefVersion.V57;

  // tslint:disable-next-line:no-any
  public async transformWideo(wideoDef: any): Promise<void> {

    //Update version and timestamp                             
    wideoDef.version = this.toVersion;
    wideoDef.timeStamp = Date.now();

    // tslint:disable-next-line:no-any
    await Promise.all(wideoDef.scenes.map( async (sceneDef: any) => {       
      return this.transformScene(sceneDef);
    }));

  }

  // tslint:disable-next-line:no-any
  transformScene(sceneDef: any): void {

    //Update version 
    sceneDef.version = this.toVersion;

    for (const objectDef of sceneDef.objects) {

      if (objectDef.class === Class.ImageObject ) { 
        this.transformImageObject(objectDef);
      }
    }
  }

  // tslint:disable-next-line:no-any
  transformImageObject(imageObjectDef: any): void {

    for (const componentDef of imageObjectDef.components) {
    
      if (componentDef.class === Class.ImageComponent && componentDef.src) {
        const extensionIndex: number = componentDef.src.lastIndexOf(".");
        const extension: string = componentDef.src.substr(extensionIndex + 1).toLowerCase();
        if (extension === "gif") {
          componentDef.animated = true; // Set to true for all GIFs, legacy (we don't want to decode the GIF here in the transformer to avoid problems)
          componentDef.loop = true;     // Set to true for all GIFs
        } else {
          componentDef.animated = false;
          if (typeof componentDef.loop !== 'undefined') {
            delete componentDef.loop;
          }            
        }
      }  
        
    }

  }

}