import ApiUtils from './ApiUtils';
import { environments } from '../common/Env';

export default class Api {

    protected _baseApiUrl: string;
    protected _accessToken: string;
    protected _environment: string;

    constructor(environment: string, accessToken: string, apiVersion?: number) {
        this._environment = environment;
        this._accessToken = accessToken;
        this._baseApiUrl = environments[environment].apiUrl + "/" + (apiVersion ? apiVersion : "2");
    }

    /** Perform HTTP GET request and deserialize/parse the expected JSON string response to a
     *  Javascript object.
     *
     *  On anything other than a 2xx response it throws an (async)exception/rejects the returned promise
     */
    public httpGetJson = async <R extends {}>(path: string, queryParams: { [s: string]: string; } = {}): Promise<R> => {
        return ApiUtils.httpGetJson<R>(this._baseApiUrl, path, queryParams);
    }

    /** Perform HTTP POST request. Deserializes/parse the
     *  expected JSON string response to a Javascript object.
     *
     *  On anything other than a 2xx response it throws an (async)exception/rejects the returned promise
     */
    public httpPost = async <R>(path: string, queryParams: { [s: string]: string; } = {}): Promise<R> => {
        return ApiUtils.httpPost<R>(this._baseApiUrl, path, queryParams);
    }

    /** Perform HTTP POST request by serializing the supplied body to a JSON string. Deserializes/parse the
     *  expected JSON string response to a Javascript object.
     *
     *  On anything other than a 2xx response it throws an (async)exception/rejects the returned promise
     */
    public httpPostJson = async <R, B>(path: string, queryParams: { [s: string]: string; } = {}, body: B): Promise<R> => {
        return ApiUtils.httpPostJson<R, B>(this._baseApiUrl, path, queryParams, body);
    }

    /** Perform HTTP POST request by serializing the supplied body to a JSON string. Deserializes/parse the
     *  expected JSON string response to a Javascript object.
     *
     *  On anything other than a 2xx response it throws an (async)exception/rejects the returned promise
     */
    public httpPostFormData = async <R>(path: string, formData: FormData, queryParams: { [s: string]: string; } = {}): Promise<R> => {
        return ApiUtils.httpPostFormData<R>(this._baseApiUrl, path, formData, queryParams);
    }

}
