import { v4 as uuid } from 'uuid';

import Logger from '../../common/log/Logger';

import { getExtension } from '../../editor/core/EditorConstants';

const bucketName = "mkt-screenrecording-videos.wideo.co";
const bucketRegion = "us-east-1";
const IdentityPoolId = "us-east-1:c2d458c5-3fa9-4b48-9ccf-5f431c99a610";


const constructS3Instance = async () => {
    const AWS_SDK_CLIENTS3 = await import("@aws-sdk/client-s3");
    const AWS_SDK_CLIENT_COG = await import("@aws-sdk/client-cognito-identity");
    const AWS_SDK_CLIENT_COG_ID = await import("@aws-sdk/credential-provider-cognito-identity");
    return new AWS_SDK_CLIENTS3.S3Client({
        region: bucketRegion,
        credentials: AWS_SDK_CLIENT_COG_ID.fromCognitoIdentityPool({
        client: new AWS_SDK_CLIENT_COG.CognitoIdentityClient({ region: bucketRegion }),
        identityPoolId: IdentityPoolId,
        }),
    });
}

/**
 * Upload a file to s3. 
 * @param file 
 * @returns an ID
 * 
 * To watch the file use: d1y5xfvzlo94cr.cloudfront.net/ID/ID.EXTENSION
 */
export const uploadFile = async (file: File): Promise<string[] | null> => {
    const fileExtension = `.${getExtension(file.name)}`

    //generate an id
    const fileId = uuid();
    const key = fileId + "/" + fileId + fileExtension;
    try {

        if (file) {
            const AWS_SDK_LIB_STORAGE = await import("@aws-sdk/lib-storage");
            const s3Client = await constructS3Instance();


            try {
                const upload = new AWS_SDK_LIB_STORAGE.Upload({
                  client: s3Client,
                  params: {
                    Bucket: bucketName,
                    Key: key,
                    Body: file,
                  }
                });
                await upload.done();
            } catch (error) {
                if (error.name === "RequestTimeTooSkewed") {
                  const now = Date.now();
                  const serverTime = Date.parse(error.ServerTime);
                  const newOffset = (serverTime - now);
                  s3Client.config.systemClockOffset = newOffset;
                  try {
                    console.warn("Client clock drift detected, offset " + newOffset);
                    const upload = new AWS_SDK_LIB_STORAGE.Upload({
                      client: s3Client,
                      params: {
                        Bucket: bucketName,
                        Key: key,
                        Body: file,
                      }
                    });
                    await upload.done();
                  } catch (error) {
                    Logger.error("Manual retry with clock skew correction failed. " + JSON.stringify(error));
                    throw error;
                  }
                } else {
                  throw error;
                }
            }


            return [fileId, fileExtension]
        }
    } catch (error) {
        Logger.error("An error ocurred uploading file to s3 with id:" + fileId, error);
    }

    return null;
}