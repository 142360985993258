import Part from '../api/model/Part';

export default interface EncoderClientListener {
  stateChanged( state: EncoderClientState, error: Object ): void;
  newPartReceived( part: Part ): void;
  frameGenerated( frameNo: number ): void;
  frameUploaded( frameNo: number, frame: ArrayBuffer ): void;
  partDone( part: Part ): void;
}

export enum EncoderClientState {
  Waiting = 'Waiting',
  Loading = 'Loading',
  Running = 'Running',
  Error = 'Error'
}
