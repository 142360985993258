import * as PIXI from 'pixi.js-legacy';
import Logger from '../log/Logger';

export default class EngineUtils {

    public static createImageFromObject( target: PIXI.DisplayObject, scale: number, renderer: PIXI.AbstractRenderer ): HTMLImageElement {
        try {
            const canvasElement: HTMLCanvasElement = EngineUtils.drawObjectToCanvasExtract( target, scale, renderer );
            if ( canvasElement ) {
                const image = new Image();
                image.src = canvasElement.toDataURL();    
                return image;
            }
        } catch (error) {
            throw new Error("Failed creating an HTMLImage from DisplayObject + " + target.name + ", reason: " + Logger.errorToString(error));
        }

        return null;
    }

    public static createImageDataURLFromObject( target: PIXI.DisplayObject, scale: number, renderer: PIXI.AbstractRenderer ): string {
        try {
            const canvasElement: HTMLCanvasElement = EngineUtils.drawObjectToCanvasExtract( target, scale, renderer );
            if ( canvasElement ) {
                return canvasElement.toDataURL();
            }
        } catch (error) {
            throw new Error("Failed creating an ImageDataURL from DisplayObject + " + target.name + ", reason: " + Logger.errorToString(error));
        }
        return null;
    }

    /**
     * Creates a copy of the target DisplayObject on a new HTMLCanvasElement
     * 
     * @param target 
     * @param renderer 
     */
    private static drawObjectToCanvasExtract(target: PIXI.DisplayObject, scale: number, renderer: PIXI.AbstractRenderer): HTMLCanvasElement {
        
        // Store the old parent, if any, and add the target under a temporary PIXI.Container
        const oldParent = target.parent as PIXI.Container;
        let oldIndex: number;
        if (oldParent) {  
            oldIndex = oldParent.getChildIndex(target);
            oldParent.removeChild(target);
        }
        const tempParent = new PIXI.Container();
        tempParent.addChild(target);
        target.scale.x *= scale;
        target.scale.y *= scale;

        // Perform the actual extract of the canvas
        let canvas: HTMLCanvasElement;
        try {
            canvas = renderer.plugins.extract.canvas(tempParent);
        }
        catch (error) {
            // Silently ignore the error and return an empty canvas
            canvas = document.createElement('canvas');
        }

        // Remove the target from the temp parent and re-add the target to the original parent if any      
        target.scale.x /= scale;
        target.scale.y /= scale;
        target.parent.removeChild(target);
        if (oldParent) {
            oldParent.addChildAt(target, oldIndex);
        }

        return canvas;
    }

}
