import {Def} from "../core/model/WideoDef";

import Transformer from "./Transformer";
import Scaler, { ScaleStrategy } from '../../common/serialization/Scaler';
import Converter from '../../converter/core/Converter';

/** The Deserializer reads a Wideo or Scene JSON (parsed as a Javascript object) in Flash or HTML5 version and converts and transforms it as neccesary
    to the current version of HTML5 WideoDef/WideoObjectDef.
    The deserialize also Scales the Def to the desired width and height*/
export default class Deserializer {

    public deserializeFromJson = async (environment: string, accessToken: string, def: Def, convert: boolean, width: number, height: number, optFromVersion?: number): Promise<Def> => {

      let actualDef: Def;

      if (convert) {
        // Convert Flash JSON to the latest version of HTML5 WideoDef
        actualDef = await new Converter(environment, accessToken).convert(def);
      } else {
        // Transform/Patch/Upgrade javascript object from whatever
        // HTML5 WideoDef version it is to the latest version of HTML5 WideoDef
        const transformer = new Transformer(environment, accessToken);
        await transformer.transform(def, optFromVersion);

        actualDef = def;
      }

      if (width && height) {
        // Scale the Wideo/Scene from its original resolution to the desired scale
        new Scaler().scale(actualDef, width, height, ScaleStrategy.Height);
      }

      return actualDef;

    }

}
