import * as React from 'react'
import { Theme } from '@mui/material/styles';
import { Snackbar, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Error from '@mui/icons-material/Error';
import Done from '@mui/icons-material/Done';
import Logger from '../../../../common/log/Logger';
/* import theme from 'src/common/ui/theme'; */


export const getStyles = (theme: Theme) => ({
  h: theme.spacing(4),
  ht: theme.spacing(4),
});


const styles = {
  snackBarMessageCloseButton: {
/*     ...getStyles(theme) */
    }
};

interface Props {
  snackBarOpen: boolean,
  snackBarText: string,
  snackBarError: boolean,
  parentComponent: string,
  handleCloseSnackBar: () => void,
  duration?: number
}

type Styled = any

type PropsWithStyles = Props & Styled;

/**used to show notificacions snackb
**
*/
class NotificationSnackBar extends React.Component<PropsWithStyles>{

  snackBarMessage = (): JSX.Element =>  {
    if (this.props.snackBarError) {
      return <Typography cyid="snackTextError" variant="h5" style={{ color: '#ffffff' }}><Error style={{ color: '#ff0000' }} />{this.props.snackBarText}</Typography>
    }
    else {
      return <Typography cyid="snackTextSuccess" variant="h5" style={{ color: '#ffffff' }}><Done style={{ color: '#00cc00' }} />{this.props.snackBarText}</Typography>
    }
  }

  handleCloseSnackBarFromIconButton = () => {
    // Logger.debug("NotificationSnackBar::handleCloseSnackBarFromIconButton(), " + (this.props ? 'parentComponent: ' + this.props.parentComponent + ", snackBarText: " + this.props.snackBarText : ''));
    try {
      this.props.handleCloseSnackBar();
    } catch (error) {
      Logger.error("Failed to close snackbar from IconButton, " + (this.props ? 'parentComponent: ' + this.props.parentComponent + ", snackBarText: " + this.props.snackBarText : ''));
    }
  }

  handleCloseSnackBarFromSnackbar = () => {
    // Logger.debug("NotificationSnackBar::handleCloseSnackBarFromSnackbar(), " + (this.props ? 'parentComponent: ' + this.props.parentComponent + ", snackBarText: " + this.props.snackBarText : ''));
    try {
      this.props.handleCloseSnackBar();
    } catch (error) {
      Logger.error("Failed to close snackbar from Snackbar, " + (this.props ? 'parentComponent: ' + this.props.parentComponent + ", snackBarText: " + this.props.snackBarText : ''));
    }
  }

  render() {
    const { duration } = this.props

    return (
      <NotificationSnackBarErrorBoundary>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          open={this.props.snackBarOpen}
          autoHideDuration={duration ? duration : 6000}
          message={this.snackBarMessage()}
          onClose={this.handleCloseSnackBarFromSnackbar}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              style={styles.snackBarMessageCloseButton}
              onClick={this.handleCloseSnackBarFromIconButton}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </NotificationSnackBarErrorBoundary>
    )
  }
}

export default NotificationSnackBar;


// Helper class for debugging:
interface ErrorProps {
  children: any;
}

interface ErrorState {
  hasError: boolean;
}
class NotificationSnackBarErrorBoundary extends React.Component<ErrorProps, ErrorState> {
  constructor( props: ErrorProps ) {
      super( props );
      this.state = { hasError: false };
  }

  componentDidCatch( error: Error, info: Object ) {
      this.setState( { hasError: true } );
      Logger.error('NotificationSnackBarErrorBoundary', 
                  Logger.errorToString(error), 
                  info['componentStack'] );
  }

  render() {
      if ( this.state.hasError ) {
          return <span/>;
      }
      return this.props.children;
  }
}