import * as React from 'react';

import { 
    grey, 
    blue 
} from '@mui/material/colors';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Drawer from '@mui/material/Drawer'
import TextField from '@mui/material/TextField';
import { Theme } from '@mui/material/styles';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem'

import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Close from '@mui/icons-material/Close';

import AudioTrackIcon from '@mui/icons-material/Audiotrack';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import SvgIcon from '@mui/material/SvgIcon';
import Editor from '../../../../core/Editor';
import ConversionUtils from "../object/ConversionUtils";
import { drawerMdWidth, ICONS, toolbarMinHeight, getNumericSelection, setNumericSelectionRange, EditorMode } from "../../../../core/EditorConstants";
import { Accordion, AccordionSummary, AccordionDetails, Link, Slider, styled } from '@mui/material';
import { styleNumericInput } from '../object/AnimationPanelInOutEffect';
import NumericInput from "react-numeric-input";
import * as moment from 'moment';
import IEditorSelectionChangeListener from '../../../../core/selection/IEditorSelectionChangeListener';
import IEditorModeChangeListener from '../../../../core/listeners/IEditorModeChangeListener';
import EditorSelection from '../../../../core/selection/EditorSelection';
import AudioObject from '../../../../../common/core/AudioObject';
import EditorAudioObject from '../../../../core/objects/EditorAudioObject';

import { WithTranslation, withTranslation } from 'react-i18next';
import theme from 'src/common/ui/theme';
import { CustomSwitch } from '../../dialogs/ShareWideoDialog';

import { BrandContext } from 'src/common/ui/BrandContextProvider';

export const getMainColor = (theme: Theme) => ({
    color: theme.palette.primary.main,
  });

  export const getColor500 = (theme: Theme) => ({
    color: theme.palette.grey[500],
  });  

  export const getColor900 = (theme: Theme) => ({
    color: theme.palette.grey[900],
  });    
  
  export const getBackgroundColor400 = (theme: Theme) => ({
    backgroundColor: theme.palette.grey[400],
  });  

  export const getDrawerHeader = (theme: Theme) => ({
    drawerHeader: theme.mixins.toolbar,
  });  
  
  export const getMargin = (theme: Theme) => ({
    margin: theme.spacing(1),
  }); 

  export const getMarginLeft = (theme: Theme) => ({
    marginLeft: theme.spacing(1),
  }); 
  
  export const getMarginRight = (theme: Theme) => ({
    marginRight: theme.spacing(1),
  });   


const styles = {
    paper: {
        boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)',
        marginBottom: '10px',
    },
    drawerPaper: {
        top: toolbarMinHeight,
        width: drawerMdWidth,
        height: `calc(100% - ${toolbarMinHeight}px)`
    },
    drawerModal: {
        right: 0,
        width: drawerMdWidth,
        left: 'auto',
        top: toolbarMinHeight,
        zIndex: 1299
    },
    hide: {
        display: '',
    },
    ...getDrawerHeader(theme),
    IconActionDrawer: {
        margin: '10px 15px 0px auto',
    },
    textField: {
        ...getMarginLeft(theme),
        ...getMarginRight(theme),
        //width: 40
    },
    root: {
        boxShadow: 'none',
        marginBottom: '10px',
    },
    actionPanel: {
        boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)',
        minHeight: '52px',
        paddingRight: '12px'
    },
    expanded: {
        margin: '0',
    },
    content: {
        margin: '0',
    },
    iconButtonMd: {
        width: 30,
        height: 30,
    },
    input: {
        ...getMainColor(theme),
    },
    dividerMd: {
        margin: '15px 0',
    },
    list: {
        padding: '2px 0',
    },
    listItemIcon: {
        margin: '0',
    },
    listItem: {
        padding: '12px 24px',
    },
    listItemButton: {
        '&:hover': {
            backgroundColor: 'transparent',
        }
    },
    listItemText: {
        padding: '0 12px',
    },
    button: {
        ...getMargin(theme),
    },
    panelDetails: {
        display: 'block',
        paddingLeft: 0,
        paddingRight: 0,
    },
    labelItem: {
        color: grey['500'],
        margin: '10px 0',
    },
    buttonSmall: {
        width: 20,
        height: 20,
        minHeight: 'auto',
        padding: 0
    },
    textHelper: {
        margin: 0,
    },
    audioDuration: {
        paddingLeft: '16px',
    },
    displayTimer: {
        padding: '0',
    },
    enabled: {
        ...getColor900(theme)
    },
    disable: {
        ...getColor500(theme)
    },
};

const CustomDrawer = styled(Drawer)(() => ({
    '.MuiDrawer-modal': styles.drawerModal,
    '.MuiDrawer-paper': styles.drawerPaper,
  }));
  

export function formatMsToMMSS(ms: number) {
    const duration: moment.Duration = moment.duration(Math.round(ms / 1000), 'seconds');
    return moment.utc(Math.round(duration.asMilliseconds())).format("m:ss");
}

interface Props {
    editor: Editor;
}

interface State {
    open: boolean;
    openDialogDeleteAudio: boolean,
}


type PropsWithStyles = Props & WithTranslation;

class AudioAttributes extends React.Component<PropsWithStyles, State> implements IEditorSelectionChangeListener, IEditorModeChangeListener {


    constructor(props: PropsWithStyles) {
        super(props);
        this.state = {
            open: true,
            openDialogDeleteAudio: false
        };
    }

    static contextType = BrandContext;
    context: React.ContextType<typeof BrandContext>      

    onEditorModeChange(mode: EditorMode): void {
        this.forceUpdate();
    }

    handleChangeRepeat = (event: React.ChangeEvent<HTMLInputElement>) => {
        const repeat: boolean = event.target.checked;
        const audio: AudioObject = this.props.editor.getSelection().getSelectedAudio();
        const audioObject: EditorAudioObject = this.props.editor.getAudioSelection();

        if (audioObject) {
            this.props.editor.setAudioRepeat(audioObject, repeat);
        } else {
            this.props.editor.setAudioAttributeRepeat(audio, repeat);
        }

    }

    handleSelectEndTime = (e: React.SyntheticEvent<HTMLInputElement, Event>): void => {
        const result = getNumericSelection(e);
        setNumericSelectionRange(e, result);
    }

    handleChangeEndTime = (valueAsNumber: number, stringValue: string, input: HTMLInputElement) => {
        if (input.value.length > 3) {
            const audio = this.props.editor.getSelection().getSelectedAudio();
            const audioObject = this.props.editor.getAudioSelection();

            this.props.editor.setAudioAttributeEndTime(audioObject ?? audio, valueAsNumber);
        }
    }

    handleSelectStartTime = (e: React.SyntheticEvent<HTMLInputElement, Event>): void => {
        const result = getNumericSelection(e);
        setNumericSelectionRange(e, result);
    }

    handleChangeStartTime = (valueAsNumber: number, stringValue: string, input: HTMLInputElement) => {
        if (input.value.length > 3) {
            //validate repeat option
            const audio = this.props.editor.getSelection().getSelectedAudio();
            const audioObject = this.props.editor.getAudioSelection();

            this.props.editor.setAudioAttributeStartTime(audioObject ?? audio, valueAsNumber);
        }
    }

    handleRemoveAudio = (): void => {
        const audio = this.props.editor.getSelection().getSelectedAudio();
        const audioObject = this.props.editor.getAudioSelection();

        this.props.editor.removeAudioObject(audioObject ?? audio, !!audioObject);
    }

    handleChangeFadeOut = (event: React.ChangeEvent<HTMLInputElement>) => {
        const audio = this.props.editor.getSelection().getSelectedAudio();
        const audioObject = this.props.editor.getAudioSelection() as AudioObject;
        const fadeOut: boolean = event.target.checked;

        this.props.editor.setAudioAttributeFadeOut(audioObject ?? audio, fadeOut);
    }

    onVolumeChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const attributeValue: string = event.target.value;
        this.changeVolume(attributeValue);
    }

    changeVolume = (attributeValue: string) => {
        const audio = this.props.editor.getSelection().getSelectedAudio();
        const audioObject = this.props.editor.getAudioSelection();

        if (audioObject && ConversionUtils.isNumber(attributeValue)) {
            this.props.editor.setAudioVolume(audioObject, ConversionUtils.percentToDecimal(Number(attributeValue)));
            return;
        }

        if (audio && ConversionUtils.isNumber(attributeValue)) {

            this.props.editor.logAmplitudeEvent("CHANGE_MUSIC_VOLUME", {});
            this.props.editor.logGAEvent("CHANGE_MUSIC_VOLUME", null, false);

            this.props.editor.setAudioAttributeVolume(audio,
                ConversionUtils.percentToDecimal(Number(attributeValue)));
        }
    }

    onVolumeUpdate = (event: Event, newValue: number | number[]) => {
        if (typeof newValue === 'number') {
            this.changeVolume(newValue.toString());
          } 
    }

    toggleDrawer = (open: boolean) => {
        this.setState({
            open: open,
            openDialogDeleteAudio: false
        });
        if (!open) {
            this.props.editor.getSelection().clearSelectedAudio();
        }
    }

    handleClickOpenDialogDeleteAudio = () => {
        this.setState({ openDialogDeleteAudio: true });
    }

    handleCloseDialogDeleteAudio = () => {
        this.setState({ openDialogDeleteAudio: false });
    }

    componentDidMount() {
        this.props.editor.addEditorModeChangeListener(this);
        this.props.editor.addSelectionChangeListener(this);
    }

    componentWillUnmount() {
        this.props.editor.removeEditorModeChangeListener(this);
        this.props.editor.removeSelectionChangeListener(this);
    }

    onSelectionChange(selection: EditorSelection): void {
        this.toggleDrawer(true);
    }

    renderDialogDelete() {
        return <>
            <Dialog
                maxWidth='sm'
                fullWidth={true}
                open={this.state.openDialogDeleteAudio}
                onClose={this.handleCloseDialogDeleteAudio}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Your Audios"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this audio?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCloseDialogDeleteAudio} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={this.handleRemoveAudio} variant="contained" color="primary" autoFocus>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    }

    getMaxEndtime = () => {
        const { editor } = this.props;
        const audio = this.props.editor.getAudioSelection() ?? this.props.editor.getSelection().getSelectedAudio();
        const isAudioWideo = editor.getSelection().hasAudioSelected();
        const start = editor.getSelection().getSelectedScene().getStartTime();
        const end = editor.getSelection().getSelectedScene().getEndTime();
        const sceneLength = end - start;

        if(audio.isRepeat()) {
            return isAudioWideo ? editor.getWideoLength() : sceneLength;
        } 
        
        return (Math.max(
            isAudioWideo ? editor.getWideoLength() : sceneLength, 
            audio.getSoundDurationAsMs() + 
            audio.getStartTime()
        ))
    }

    render() {
        const { editor, t } = this.props;

        const brandConfig  = this.context;
        const primaryColor = brandConfig?.primaryColor ?? blue['A200'];

        const selection = editor.getSelection();
        const wideoObject = selection.getSelectedObject();
        const audio = this.props.editor.getAudioSelection() ?? this.props.editor.getSelection().getSelectedAudio();
        const isAudioWideo = selection.hasAudioSelected();
        const isAudioScene = selection.hasObjectSelected() && AudioObject.isAudioObject(wideoObject);


        if (isAudioWideo || isAudioScene && wideoObject.isUnlocked()) {
            return (
                <Box
                    zIndex={this.props.editor.isEditEditorMode() ? "modal" : "drawer"}
                    position="absolute"
                >
                    <CustomDrawer
                        anchor="right"
                        open={this.state.open}
                        onClose={() => { this.toggleDrawer(false) }}
                        variant="persistent"
                    >
                        <IconButton
                            onClick={() => { this.toggleDrawer(false) }}
                            onKeyDown={() => { this.toggleDrawer(false) }}
                            style={styles.IconActionDrawer}
                        >
                            <Close color="action" />
                        </IconButton>
                        <Box
                            cyid='panelAudioSettings'
                            mb={this.props.editor.isEditEditorMode() ? "0" : "152px"}
                            overflow='auto'
                        >
                            <form noValidate autoComplete="off">
                                <Accordion
                                    expanded={selection.hasAudioSelected() && !selection.hasKeyFrameSelected() && !selection.hasObjectSelected() || isAudioScene}
                                    disabled={selection.hasKeyFrameSelected() || (selection.hasObjectSelected() && !isAudioScene)}
                                    style={{
                                        ...styles.root
                                    }}
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon color="action" />}
                                        style={{
                                            ...styles.actionPanel,
                                            ...styles.expanded,
                                            ...styles.content,
                                        }}
                                    >
                                        <Grid container alignItems="center" spacing={1}>
                                            <Grid item>
                                                <AudioTrackIcon
                                                    color="action"
                                                    fontSize="small"
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="subtitle1">{t("rightbar.objects.audio.label")}</Typography>
                                            </Grid>
                                        </Grid>
                                    </AccordionSummary>
                                    <AccordionDetails
                                        style={{
                                            ...styles.panelDetails,
                                        }}
                                    >
                                        <Grid container alignItems="center" spacing={0}>
                                            <Grid item xs={12}>
                                                <List>
                                                    <ListItem>
                                                        <Grid container spacing={2} alignItems='center' justify='center'>
                                                            <Grid item xs={12}>
                                                                <Typography
                                                                    variant="caption"
                                                                    gutterBottom={true}
                                                                    cyid="cyAudioName"
                                                                >
                                                                    {this.props.editor.getAudioName(audio.getSrc())}
                                                                </Typography>
                                                                <Typography
                                                                    variant="body1"
                                                                    gutterBottom={true}
                                                                    style={{
                                                                        ...styles.labelItem,
                                                                    }}
                                                                >
                                                                    {t("rightbar.objects.audio.volume")}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={10}>
                                                                <Grid container spacing={2}>
                                                                    <Grid item xs={1}>
                                                                        <VolumeUpIcon
                                                                            color="action"
                                                                            fontSize="small"
                                                                            style={{
                                                                                ...styles.listItemIcon,
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={7}>
                                                                    <Slider
                                                                        id='volume'
                                                                        min={0}
                                                                        max={100}                                                                            
                                                                        size="small"
                                                                        defaultValue={Number(ConversionUtils.decimalToPercent(audio.getVolume()))}
                                                                        value={Number(ConversionUtils.decimalToPercent(audio.getVolume()))}
                                                                        onChange={this.onVolumeUpdate}
                                                                        aria-label="volume"
                                                                    />
                                                                    </Grid>
                                                                    <Grid item xs={3}>
                                                                        <TextField
                                                                            id="volume"
                                                                            fullWidth={true}
                                                                            InputProps={{
                                                                                endAdornment:
                                                                                    <InputAdornment
                                                                                        position="end"
                                                                                        style={{
                                                                                            ...styles.textHelper,
                                                                                        }}
                                                                                    >
                                                                                        %
                                                                                    </InputAdornment>,
                                                                            }}
                                                                            value={ConversionUtils.roundToInteger(ConversionUtils.decimalToPercent(audio.getVolume()))}
                                                                            onChange={this.onVolumeChange}
                                                                            margin="none"
                                                                            variant="standard"
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <Grid container spacing={0}>
                                                                    <Grid item xs={12}>
                                                                        <IconButton
                                                                            aria-label="Delete"
                                                                            onClick={() => { this.handleClickOpenDialogDeleteAudio() }}
                                                                            style={styles.buttonSmall}
                                                                        >
                                                                            <SvgIcon>
                                                                                <path d={ICONS.TRASH} />
                                                                            </SvgIcon>
                                                                        </IconButton>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </ListItem>
                                                </List>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Typography
                                                    variant="caption"
                                                    gutterBottom={true}
                                                    style={{ ...styles.audioDuration }}
                                                >
                                                    {t("rightbar.objects.audio.audio-duration")}:
                                                    <span cyid={"audioDuration"} style={{...styles.displayTimer, color: primaryColor}}>
                                                        {formatMsToMMSS(audio.getSoundDurationAsMs())}
                                                    </span>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={7}>
                                                <Link
                                                    href={"https://help.wideo.co/hc/articles/360034513732"}
                                                    target="_blank"
                                                    rel="noopener"
                                                    variant="caption"
                                                    align="left"
                                                >
                                                    <HelpOutlineOutlinedIcon
                                                        fontSize="small"
                                                    />
                                                </Link>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <List>
                                                    <ListItem>
                                                        <Grid container spacing={2} alignItems='center' justify='center'>
                                                            <Grid item xs={6}>
                                                                <Grid container alignItems="center" spacing={0}>
                                                                    <Grid item xs={12}>
                                                                        <NumericInput
                                                                            cyid="audioStarttime"
                                                                            value={formatMsToMMSS(audio.getStartTime())}
                                                                            onSelect={this.handleSelectStartTime}
                                                                            onChange={this.handleChangeStartTime}
                                                                            min={0}
                                                                            max={audio.getEndTime()}
                                                                            style={styleNumericInput(primaryColor)}
                                                                            mobile={false}
                                                                            format={(num: number) => {
                                                                                return formatMsToMMSS(num);
                                                                            }}
                                                                            parse={(stringValue: string) => {
                                                                                if (stringValue.indexOf(":") > 0) {
                                                                                    const tokens = stringValue.trim().split(":");
                                                                                    const duration = moment.duration({
                                                                                        minutes: Number(tokens[0]),
                                                                                        seconds: Number(tokens[1])
                                                                                    });
                                                                                    return duration.asMilliseconds();
                                                                                } else {
                                                                                    return 0;
                                                                                }
                                                                            }}
                                                                            strict={true}
                                                                            precision={0}
                                                                            step={1000}
                                                                            size={4}
                                                                            maxLength={4}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <Typography
                                                                            variant="caption"
                                                                            gutterBottom={true}
                                                                        >
                                                                            <Box color="rgba(0, 0, 0, 0.38)">{t("rightbar.objects.audio.start-time")}</Box>
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <Grid container alignItems="center" spacing={0}>
                                                                    <Grid item xs={12}>
                                                                        <NumericInput
                                                                            cyid="audioEndtime"
                                                                            value={formatMsToMMSS(audio.getEndTime())}
                                                                            onSelect={this.handleSelectEndTime}
                                                                            onChange={this.handleChangeEndTime}
                                                                            min={audio.getStartTime()}
                                                                            max={this.getMaxEndtime}
                                                                            style={styleNumericInput(primaryColor)}
                                                                            mobile={false}
                                                                            format={(num: number) => {
                                                                                return formatMsToMMSS(num);
                                                                            }}
                                                                            parse={(stringValue: string) => {
                                                                                if (stringValue.indexOf(":") > 0) {
                                                                                    const tokens = stringValue.trim().split(":");
                                                                                    const duration = moment.duration({
                                                                                        minutes: Number(tokens[0]),
                                                                                        seconds: Number(tokens[1])
                                                                                    });
                                                                                    return duration.asMilliseconds();
                                                                                } else {
                                                                                    return 0;
                                                                                }
                                                                            }}
                                                                            strict={true}
                                                                            precision={0}
                                                                            step={1000}
                                                                            size={4}
                                                                            maxLength={4}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <Typography
                                                                            variant="caption"
                                                                            gutterBottom={true}
                                                                        >
                                                                            <Box color="rgba(0, 0, 0, 0.38)">{t("rightbar.objects.audio.end-time")}</Box>
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </ListItem>
                                                    <ListItem>
                                                        <Grid container spacing={2} alignItems='center' justify='center'>
                                                            <Grid item xs={10}>
                                                                <Typography
                                                                    variant="body1"
                                                                    gutterBottom={true}
                                                                    cyclass={audio.isRepeat() ? "audioLoopEnabled" : "audioLoopDisabled"}
                                                                    style={!audio.isRepeat() ? styles.disable : styles.enabled}
                                                                >
                                                                    {t("rightbar.objects.audio.loop")}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <CustomSwitch
                                                                    cyid={"audioLoopSwitch"}
                                                                    disableRipple
                                                                    checked={audio.isRepeat()}
                                                                    onChange={this.handleChangeRepeat}
                                                                    value="repeat"
                                                                />
                                                            </Grid>
                                                            {isAudioWideo &&
                                                                <>
                                                                    <Grid item xs={10}>
                                                                        <Typography
                                                                            variant="body1"
                                                                            gutterBottom={true}
                                                                            style={!audio.isFadeOut() ? styles.disable : styles.enabled}
                                                                        >
                                                                            {t("rightbar.objects.audio.fade-out")}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={2}>
                                                                        <CustomSwitch
                                                                            disableRipple
                                                                            checked={audio.isFadeOut()}
                                                                            onChange={this.handleChangeFadeOut}
                                                                            value="fade"
                                                                        />
                                                                    </Grid>
                                                                </>
                                                            }
                                                        </Grid>
                                                    </ListItem>
                                                </List>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </form>
                        </Box>
                    </CustomDrawer >
                    {this.renderDialogDelete()}
                </Box >
            )
        } else { return null }
    }

}

export default withTranslation("rightbarObj")(AudioAttributes);
