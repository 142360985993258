
export default class ConversionUtils {

  static PRECISION_DIGITS = 1;
  static round(x: number): string {

    if (Number.isInteger(x)) {
      return x.toString();
    } else {
      return (Math.round(x * 10) / 10).toString();
    }

  }

  static roundAsNumber(x: number): number {

    if (Number.isInteger(x)) {
      return x;
    } else {
      return (Math.round(x * 10) / 10);
    }

  }

  static NUMBER_REGEXP = /^\s*-?[0-9]+(\.[0-9]+\s*$|\s*$)/;
  static isNumber(x: string): boolean {
    return Boolean(this.NUMBER_REGEXP.exec(x));
  }

  static millisToSeconds = (millis: number): number => {
    return millis / 1000
  };

  static secondsToMillis = (seconds: number): number => {
    return seconds * 1000;
  };

  static degreesToRadians = (degrees: number): number => {
    return degrees * (Math.PI / 180);
  };

  static radiansToDegrees = (radians: number): number => {
    return radians * 180 / Math.PI;
  };

  static decimalToPercent = (decimal: number): number => {
    return decimal * 100;
  }

  static percentToDecimal = (percent: number): number => {
    return percent / 100;
  }

  static roundToInteger = (value: number): number => {
    return Math.round(value);
  }  

  // static relativeToAbsolute = ( relative: number ): number => {
  //     return
  // }
  //
  // static absoluteToRelative = ( absolute: number ): number => {
  //     return
  // }

  static bytesToMegabytes = (bytes: number): number => {
    return Math.round(bytes / Math.pow(1024, 2));
  }


}
