
import * as PIXI from 'pixi.js-legacy';

import WideoObject from '../WideoObject';
import Attributes from '../Attributes';
import { ShapeComponentDef, ShapeType } from '../model/WideoDef';
import WideoContext from '../WideoContext';
import AbstractComponent from './AbstractComponent';
import * as ShapeUtils from '../ShapeUtils';
import EngineUtils from '../EngineUtils';

export default class ShapeComponent extends AbstractComponent {

  //shape props
  protected _shapeType: ShapeType;
  protected _borderColor: number;
  protected _borderAlpha: number;
  protected _fillColor: number;
  protected _fillAlpha: number;
  protected _borderThickness: number;
  protected _cornerRadius: number;

  protected _graphics: PIXI.Graphics;

  protected _width: number;
  protected _height: number;

  private previewImageDataURL: string = null;

  constructor(context: WideoContext, owner: WideoObject, def: ShapeComponentDef) {
    super(true, 'ShapeComponent-' + def.id);
    this._context = context;
    this._owner = owner;
    this._id = def.id;
    this._class = def.class;
    this._shapeType = def.shapeType;
    this._borderColor = def.borderColor ? parseInt(def.borderColor, 16) : null;
    this._borderAlpha = def.borderAlpha;
    this._borderThickness = def.borderThickness;
    this._cornerRadius = def.cornerRadius;
    this._fillColor = def.fillColor ? parseInt(def.fillColor, 16) : null;
    this._fillAlpha = def.fillAlpha;
    this._height = def.height;
    this._width = def.width;

    this._graphics = new PIXI.Graphics();
    this._displayObject.addChild(this._graphics);

    this.draw();

  }

  protected draw() {
    this._graphics.clear();

    if (this._fillColor !== null) {
      this._graphics.beginFill(this._fillColor, this._fillAlpha);
    } else {
      this._graphics.beginFill(0, 0);
    }
    if (this._borderColor !== null) {
      this._graphics.lineStyle(this._borderThickness, this._borderColor, this._borderAlpha);
    } else {
      this._graphics.lineStyle(0, 0, 0);
    }

    ShapeUtils.drawShape(this._graphics, this._shapeType, this._width, this._height, this._cornerRadius);

    this._graphics.endFill();
 
    // Lines do not actually have a height, so just ignore height in the centering
    if (this._shapeType === ShapeType.Line || this._shapeType === ShapeType.DottedLine) {
      this._graphics.x = -this._width * 0.5;
    } else {
      this._graphics.x = -this._width * 0.5;
      this._graphics.y = -this._height * 0.5;
    }

    // Hit testing for clicks does not work with graphics with only a line unless we set hitArea manually   
    this._graphics.hitArea = this._graphics.getLocalBounds();

  }

  setFillColor = (color: number): void => {
    this._fillColor = color;
    this.previewImageDataURL = null; // Clear cache of preview image
    this.draw();
  }

  getFillColor = (): number => {
    return this._fillColor
  }

  getStrokeColor = (): number => {
    return this._borderColor
  }

  setStrokeColor = (color: number): void => {
    this._borderColor = color;
    this.previewImageDataURL = null; // Clear cache of preview image
    this.draw();
  }

  getStrokeThickness = (): number => {
    return this._borderThickness;
  }

  setStrokeThickness = (strokeThickness: number): void => {
    this._borderThickness = strokeThickness;
    this.previewImageDataURL = null; // Clear cache of preview image
    this.draw();
  }

  update(): Attributes {
    return null;
  }

  public getObjectPreview() {
    const previewWidth = 200;
    const previewHeight = 200;
    const previewBorderWidth = 10;
    if (!this.previewImageDataURL) {  
      
      const previewGraphics = new PIXI.Graphics();

      if (this._fillColor !== null) {
        previewGraphics.beginFill(this._fillColor, this._fillAlpha);
      } else {
        previewGraphics.beginFill(0, 0);
      }
      if (this._borderColor !== null) {
        previewGraphics.lineStyle(previewBorderWidth, this._borderColor, this._borderAlpha);
      } else {
        previewGraphics.lineStyle(0, 0, 0);
      }
  
      ShapeUtils.drawShape(previewGraphics, this._shapeType, previewWidth, previewHeight);
  
      previewGraphics.endFill();

      this.previewImageDataURL = EngineUtils.createImageDataURLFromObject(previewGraphics, 1, this._context.getRenderer());   

      previewGraphics.destroy();
    }
    return this.previewImageDataURL;
  }

  public destroy(): void {
    if (this._graphics) {
      this._graphics.destroy();
    }
    super.destroy();
  }

  hexToRGBA(hex: number, alpha: number) {
    const r = (hex & 0xff0000) >> 16;
    const g = (hex & 0x00ff00) >> 8;
    const b = (hex & 0x0000ff);
    return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
  }

  hexToString(hex: number) {
    if (hex !== null) {
      return "0x" + ("00000" + hex.toString(16)).slice(-6) // hex string format 0xRRGGBB
    } else { return null }
  }

  serialize(): ShapeComponentDef {
    let retVal: ShapeComponentDef =
    {
      id: this._id,
      class: this._class,
      shapeType: this._shapeType,
      borderColor: this.hexToString(this._borderColor),
      borderAlpha: this._borderAlpha,
      width: this._width,
      height: this._height,
      fillColor: this.hexToString(this._fillColor),
      fillAlpha: this._fillAlpha,
      borderThickness: this._borderThickness
    }

    if (this._cornerRadius) {
      retVal = { ...retVal, cornerRadius: this._cornerRadius};
    }
    
    return retVal;
  }
}
