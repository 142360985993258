import { WideoObjectInteractivityDef } from "./model/WideoDef";

export default class WideoObjectInteractivity {

    private url: string;
    private newTab: boolean;

    constructor(def: WideoObjectInteractivityDef) {
        this.url = def.url;
        this.newTab = def.newTab;
    }

    getUrl() {
        return this.url;
    }

    setUrl(url: string) {
        this.url = url;
    }

    getNewTab() {
        return this.newTab;
    }

    setNewTab(newTab: boolean) {
        this.newTab = newTab;
    }

    public serialize = (): WideoObjectInteractivityDef => {
        return {
            url: this.url,
            newTab: this.newTab
        }
    }

}