import {WideoDefVersion, Class} from "../../core/model/WideoDef";
import ITransformer from "../ITransformer";
import { MIN_FONT_SIZE, MAX_FONT_SIZE } from "../../core/TextObject";

export default class TransformerToV51 implements ITransformer {
    readonly toVersion: WideoDefVersion = WideoDefVersion.V51;

    // tslint:disable-next-line:no-any
    public transformWideo( wideoDef: any ) {

      //Update version and timestamp
      wideoDef.version = this.toVersion;
      wideoDef.timeStamp = Date.now();

      for (const sceneDef of wideoDef.scenes) {
        this.transformScene(sceneDef);
      }

    }   

    // tslint:disable-next-line:no-any
    transformScene(sceneDef: any): void {
      
      //Update version and timestamp
      sceneDef.version = this.toVersion;

      for (const wideoObjectDef of sceneDef.objects) {
        this.transformWideoObject(wideoObjectDef);
      }
    }

    // tslint:disable-next-line:no-any
    transformWideoObject(wideoObjectDef: any): void {

      for (const innerObjectDef of wideoObjectDef.objects) {
          this.transformWideoObject(innerObjectDef);
      }

      for (const componentDef of wideoObjectDef.components) {
        this.transformComponent(componentDef);
      }

    }

    // tslint:disable-next-line:no-any
    transformComponent(componentDef: any): void {
      if (componentDef.class === Class.TextComponent) {
        if (componentDef.style) {
          if (!componentDef.style.fontSize) {
            delete componentDef.style.fontSize;
          } else if (componentDef.style.fontSize < MIN_FONT_SIZE) {
            componentDef.style.fontSize = MIN_FONT_SIZE;
          } else if (componentDef.style.fontSize > MAX_FONT_SIZE) {
            componentDef.style.fontSize = MAX_FONT_SIZE;
          }
        }
      }
    }
}