import { WideoDefVersion, Class } from "../../core/model/WideoDef";
import ITransformer from "../ITransformer";
import WideoDefFactory from "../../core/model/WideoDefFactory";
import { getAnimatedGroupDefs, assetsIDsOfAnimatedReplacedWithGifs, assetsIDsOfAnimatedReplacedWithAnimatedGroupObjects, wideoIDContainingOldAnimatedAssets, wideoIDContainingGroupDefCharacters } from "../../../editor/core/EditorConstants";
import { v4 as uuid } from 'uuid';
import TemplateObject from "../../../api/model/TemplateObject";
import EditorDefFactory from "../../../editor/core/model/EditorDefFactory";
import Logger from "../../../common/log/Logger";

export default class TransformerToV56 implements ITransformer {
  readonly toVersion: WideoDefVersion = WideoDefVersion.V56;

  private environment: string;
  private accessToken: string;
  private animatedDefs: TemplateObject[] = [];

  constructor(environment: string, accessToken: string) {
    this.environment = environment;
    this.accessToken = accessToken;
  }
  // tslint:disable-next-line:no-any
  public async transformWideo(wideoDef: any): Promise<void> {

    //to avoid infinite loop with the wideo with the animated definitions
    if (wideoDef.id !== wideoIDContainingOldAnimatedAssets && wideoDef.id !== wideoIDContainingGroupDefCharacters) {
      if (this.animatedDefs.length === 0) {
        this.animatedDefs = await getAnimatedGroupDefs(wideoIDContainingOldAnimatedAssets, this.environment, this.accessToken);
      }
      //Update version and timestamp                             
      wideoDef.version = this.toVersion;
      wideoDef.timeStamp = Date.now();

      // tslint:disable-next-line:no-any
      await Promise.all(wideoDef.scenes.map( async (sceneDef: any) => {       
        return this.transformScene(sceneDef);
      }));

    }

  }

  // tslint:disable-next-line:no-any
  async transformScene(sceneDef: any): Promise<void> {
    //scene favourites are transformed directly from scene
    if (this.animatedDefs.length === 0) {
      this.animatedDefs = await getAnimatedGroupDefs(wideoIDContainingOldAnimatedAssets, this.environment, this.accessToken);
    }
    //Update version 
    sceneDef.version = this.toVersion;

    for (const objectDef of sceneDef.objects) {
      this._transformObject(sceneDef, objectDef);
    }
  }

  // tslint:disable-next-line:no-any
  transformObject(objectDef: any): void {
    //not used
  }

  // tslint:disable-next-line:no-any
  _transformObject(parentObjectDef: any, objectDef: any): void {

    if (objectDef.class === 'animatedImageObject') {
      // tslint:disable-next-line:no-any
      let objectId: string;
      let indexOfAnimatedComponent = 0;
      //get id from component
      for (let index = 0; index < objectDef.components.length; index++) {
        if (objectDef.components[index].class === Class.AnimatedImageComponent) {
          objectId = objectDef.components[index].id;
          indexOfAnimatedComponent = index;
        }
      }

      //chequeo por el id y segun id hago el reemplazo o por gif o por group animated object
      if (objectId && assetsIDsOfAnimatedReplacedWithGifs.indexOf(objectId) > 0) {
        const newObjectDef = WideoDefFactory.CreateImageComponentDef(uuid(), "https://d3gijido82a16v.cloudfront.net/" + objectId + ".gif");
        //replace with gif image component
        objectDef.components.splice(indexOfAnimatedComponent, 1, newObjectDef);
        objectDef.class = Class.ImageObject;
        objectDef.attributes.scaleX *= 0.5; //animated old applied 0.5 to scale by default
        objectDef.attributes.scaleY *= 0.5;
      } else {
        //sino reemplazamos por animatedObjectGroup
        for (const assetIdOfAnimated of assetsIDsOfAnimatedReplacedWithAnimatedGroupObjects) {
          if (assetIdOfAnimated.id === objectId) {
            const index: number = parentObjectDef.objects.indexOf(objectDef);

            if (index !== -1) {
              if (this.animatedDefs[assetIdOfAnimated.order].def) {
                //creamos el nuevo animatedObjectGroup
                const componentDef = WideoDefFactory.CreateAnimatedObjectGroupComponentDef(uuid());
                const newObjectDef = EditorDefFactory.createWideoObjectDefFromComponentAndObject(Class.AnimatedGroupObject,
                  componentDef, parentObjectDef.endTime - parentObjectDef.startTime,
                  this.animatedDefs[assetIdOfAnimated.order].def, true);
                newObjectDef.startTime = objectDef.startTime;
                newObjectDef.attributes = objectDef.attributes;
                newObjectDef.attributes.scaleX *= 0.5; //animated old applied 0.5 to scale by default
                newObjectDef.attributes.scaleY *= 0.5;
                newObjectDef.loop = true;
                newObjectDef.hidden = objectDef.hidden;
                newObjectDef.locked = objectDef.locked;

                // solo copiamos components de animation y transition
                for (const component of objectDef.components) {
                  if (component.class === Class.AnimationComponent || component.class === Class.TransitionComponent) {
                    newObjectDef.components.push(component);
                  }
                }
                //agregamos a la escena el object de reemplazo
                parentObjectDef.objects.splice(index, 1, newObjectDef);
              }
              else {
                Logger.error("Problem converting old animated asset: %o, . Order invalid: %o", assetIdOfAnimated, assetIdOfAnimated.order);
              }
            }
            break;

          }
        }

      }
    }
  }


}
