import FontFaceObserver from 'fontfaceobserver';
import Logger from '../log/Logger';
import * as PIXI from 'pixi.js-legacy';
import { ExtendedPixiMetadata } from '../core/AssetsLoader';

export enum FontProvider {
  Wideo = 'Wideo',
  Google = 'Google'
}

export interface IFont {
  displayName: string; // To be used in GUI
  fontFamily: string;  // To be used in CSS (@font-face: font-family) and in TextStyle
  provider: FontProvider; // Wideo or Google
  // The mapping from fontFamily (and style) to font file is made in fonts.css
}

export const loadedFonts: IFont[] = [
  { displayName: 'ABeeZee', fontFamily: 'ABeeZee', provider: FontProvider.Google },
  { displayName: 'Abel', fontFamily: 'Abel', provider: FontProvider.Google },
  { displayName: 'Abril Fatface', fontFamily: 'Abril Fatface', provider: FontProvider.Google },
  { displayName: 'Acme', fontFamily: 'Acme', provider: FontProvider.Google },
  { displayName: 'Alegreya', fontFamily: 'Alegreya', provider: FontProvider.Google },
  { displayName: 'Alegreya Sans', fontFamily: 'Alegreya Sans', provider: FontProvider.Google },
  { displayName: 'Alfa Slab One', fontFamily: 'Alfa Slab One', provider: FontProvider.Google },
  { displayName: 'Amatic SC', fontFamily: 'Amatic SC', provider: FontProvider.Google },
  { displayName: 'Anton', fontFamily: 'Anton', provider: FontProvider.Google },
  { displayName: 'Architects Daughter', fontFamily: 'Architects Daughter', provider: FontProvider.Google },
  { displayName: 'Archivo Black', fontFamily: 'Archivo Black', provider: FontProvider.Google },
  { displayName: 'Archivo Narrow', fontFamily: 'Archivo Narrow', provider: FontProvider.Google },
  { displayName: 'Arimo', fontFamily: 'Arimo', provider: FontProvider.Google },
  { displayName: 'Armata', fontFamily: 'Armata', provider: FontProvider.Google },
  { displayName: 'Arvo', fontFamily: 'Arvo', provider: FontProvider.Google },
  { displayName: 'Bangers', fontFamily: 'Bangers', provider: FontProvider.Google },
  { displayName: 'BenchNine', fontFamily: 'BenchNine', provider: FontProvider.Google },
  { displayName: 'Bitter', fontFamily: 'Bitter', provider: FontProvider.Google },
  { displayName: 'Bree Serif', fontFamily: 'Bree Serif', provider: FontProvider.Google },
  { displayName: 'Cabin', fontFamily: 'Cabin', provider: FontProvider.Google },
  { displayName: 'Cabin Condensed', fontFamily: 'Cabin Condensed', provider: FontProvider.Google },
  { displayName: 'Candal', fontFamily: 'Candal', provider: FontProvider.Google },
  { displayName: 'Cantarell', fontFamily: 'Cantarell', provider: FontProvider.Google },
  { displayName: 'Cardo', fontFamily: 'Cardo', provider: FontProvider.Google },
  { displayName: 'Catamaran', fontFamily: 'Catamaran', provider: FontProvider.Google },
  { displayName: 'Changa One', fontFamily: 'Changa One', provider: FontProvider.Google },
  { displayName: 'Chewy', fontFamily: 'Chewy', provider: FontProvider.Google },
  { displayName: 'Cinzel', fontFamily: 'Cinzel', provider: FontProvider.Google },
  { displayName: 'Comfortaa', fontFamily: 'Comfortaa', provider: FontProvider.Google },
  { displayName: 'Coming Soon', fontFamily: 'Coming Soon', provider: FontProvider.Google },
  { displayName: 'Cookie', fontFamily: 'Cookie', provider: FontProvider.Google },
  { displayName: 'Courgette', fontFamily: 'Courgette', provider: FontProvider.Google },
  { displayName: 'Covered By Your Grace', fontFamily: 'Covered By Your Grace', provider: FontProvider.Google },
  { displayName: 'Crafty Girls', fontFamily: 'Crafty Girls', provider: FontProvider.Google },
  { displayName: 'Crete Round', fontFamily: 'Crete Round', provider: FontProvider.Google },
  { displayName: 'Crimson Text', fontFamily: 'Crimson Text', provider: FontProvider.Google },
  { displayName: 'Chronicle', fontFamily: 'Chronicle', provider: FontProvider.Wideo}, // Static, Added on request from user
  { displayName: 'Cuprum', fontFamily: 'Cuprum', provider: FontProvider.Google },
  { displayName: 'Din Pro', fontFamily: 'DINPro', provider: FontProvider.Wideo}, // Static, Legacy Font
  { displayName: 'Damion', fontFamily: 'Damion', provider: FontProvider.Wideo}, // Static, Legacy Font
  { displayName: 'Dancing Script', fontFamily: 'Dancing Script', provider: FontProvider.Google },
  { displayName: 'Didact Gothic', fontFamily: 'Didact Gothic', provider: FontProvider.Google },
  { displayName: 'Domine', fontFamily: 'Domine', provider: FontProvider.Google },
  { displayName: 'Dosis', fontFamily: 'Dosis', provider: FontProvider.Google },
  { displayName: 'EB Garamond', fontFamily: 'EB Garamond', provider: FontProvider.Google },
  { displayName: 'Economica', fontFamily: 'Economica', provider: FontProvider.Google },
  { displayName: 'Exo', fontFamily: 'Exo', provider: FontProvider.Google },
  { displayName: 'Exo 2', fontFamily: 'Exo 2', provider: FontProvider.Google },
  { displayName: 'Fira Sans', fontFamily: 'Fira Sans', provider: FontProvider.Google },
  { displayName: 'Fjalla One', fontFamily: 'Fjalla One', provider: FontProvider.Google },
  { displayName: 'Francois One', fontFamily: 'Francois One', provider: FontProvider.Google },
  { displayName: 'Fredoka One', fontFamily: 'Fredoka One', provider: FontProvider.Google },
  { displayName: 'Georgia', fontFamily: 'Georgia', provider: FontProvider.Wideo}, // Static, Legacy font
  { displayName: 'Gill Sans', fontFamily: 'GillSansLight', provider: FontProvider.Wideo}, // Static, Legacy font
  { displayName: 'Gotham', fontFamily: 'Gotham', provider: FontProvider.Wideo},       // Static, Added on request from user
  { displayName: 'Gloria Hallelujah', fontFamily: 'Gloria Hallelujah', provider: FontProvider.Google },
  { displayName: 'Gudea', fontFamily: 'Gudea', provider: FontProvider.Google },
  { displayName: 'Hammersmith One', fontFamily: 'Hammersmith One', provider: FontProvider.Google },
  { displayName: 'Handwritten', fontFamily: 'HandwrittenCrystal', provider: FontProvider.Wideo}, // Static, Legacy font
  { displayName: 'Helvetica', fontFamily: 'Helvetica_v2', provider: FontProvider.Wideo}, // Static, Legacy font
  { displayName: 'Hind', fontFamily: 'Hind', provider: FontProvider.Google },
  { displayName: 'Inconsolata', fontFamily: 'Inconsolata', provider: FontProvider.Google },
  { displayName: 'Indie Flower', fontFamily: 'Indie Flower', provider: FontProvider.Google },
  { displayName: 'Istok Web', fontFamily: 'Istok Web', provider: FontProvider.Google },
  { displayName: 'Josefin Sans', fontFamily: 'Josefin Sans', provider: FontProvider.Google },
  { displayName: 'Josefin Slab', fontFamily: 'Josefin Slab', provider: FontProvider.Google },
  { displayName: 'Kanit', fontFamily: 'Kanit', provider: FontProvider.Google },
  { displayName: 'Karla', fontFamily: 'Karla', provider: FontProvider.Google },
  { displayName: 'Kaushan Script', fontFamily: 'Kaushan Script', provider: FontProvider.Google },
  { displayName: 'Kreon', fontFamily: 'Kreon', provider: FontProvider.Google },
  { displayName: 'Lato', fontFamily: 'Lato', provider: FontProvider.Google },
  { displayName: 'Libre Baskerville', fontFamily: 'Libre Baskerville', provider: FontProvider.Google },
  { displayName: 'Libre Franklin', fontFamily: 'Libre Franklin', provider: FontProvider.Google },
  { displayName: 'Libre Franklin Light', fontFamily: 'Libre Franklin Light', provider: FontProvider.Wideo }, // Static font for Battersea client
  { displayName: 'Libre Franklin Semi-Bold', fontFamily: 'Libre Franklin Semi-Bold', provider: FontProvider.Wideo }, // Static font for Battersea client
  { displayName: 'Lobster', fontFamily: 'Lobster', provider: FontProvider.Google },
  { displayName: 'Lobster Two', fontFamily: 'Lobster Two', provider: FontProvider.Google },
  { displayName: 'Lora', fontFamily: 'Lora', provider: FontProvider.Google },
  { displayName: 'Luckiest Guy', fontFamily: 'Luckiest Guy', provider: FontProvider.Google },
  { displayName: 'Maven Pro', fontFamily: 'Maven Pro', provider: FontProvider.Google },
  { displayName: 'Merriweather', fontFamily: 'Merriweather', provider: FontProvider.Google },
  { displayName: 'Merriweather Sans', fontFamily: 'Merriweather Sans', provider: FontProvider.Google },
  { displayName: 'Monda', fontFamily: 'Monda', provider: FontProvider.Google },
  { displayName: 'Montserrat', fontFamily: 'Montserrat', provider: FontProvider.Google },
  { displayName: 'Muli', fontFamily: 'Muli', provider: FontProvider.Google },
  { displayName: 'Myriad Pro', fontFamily: 'MyriadPro_v2', provider: FontProvider.Wideo}, // Static, Legacy Font
  { displayName: 'Myriad', fontFamily: 'MyriadPro', provider: FontProvider.Wideo}, // Static, Legacy Font
  { displayName: 'Nanum Gothic', fontFamily: 'Nanum Gothic', provider: FontProvider.Google },
  { displayName: 'News Cycle', fontFamily: 'News Cycle', provider: FontProvider.Google },
  { displayName: 'Nobile', fontFamily: 'Nobile', provider: FontProvider.Google },
  { displayName: 'Noticia Text', fontFamily: 'Noticia Text', provider: FontProvider.Google },
  { displayName: 'Noto Color Emoji', fontFamily: 'Noto Color Emoji', provider: FontProvider.Google },
  { displayName: 'Noto Sans', fontFamily: 'Noto Sans', provider: FontProvider.Google },
  { displayName: 'Noto Sans KR', fontFamily: 'Noto Sans KR', provider: FontProvider.Google },
  { displayName: 'Noto Serif', fontFamily: 'Noto Serif', provider: FontProvider.Google },
  { displayName: 'Nunito', fontFamily: 'Nunito', provider: FontProvider.Google },
  { displayName: 'Nunito Sans', fontFamily: 'Nunito Sans', provider: FontProvider.Google },
  { displayName: 'Old Standard TT', fontFamily: 'Old Standard TT', provider: FontProvider.Google },
  { displayName: 'Open Sans', fontFamily: 'Open Sans', provider: FontProvider.Google },
  { displayName: 'Open Sans Condensed', fontFamily: 'Open Sans Condensed', provider: FontProvider.Google },
  { displayName: 'OpenSans Light', fontFamily: 'OpenSans_Light', provider: FontProvider.Wideo}, // Static, Legacy Font
  { displayName: 'Orbitron', fontFamily: 'Orbitron', provider: FontProvider.Google },
  { displayName: 'Oswald', fontFamily: 'Oswald', provider: FontProvider.Google },
  { displayName: 'Oswald Semi-Bold', fontFamily: 'Oswald Semi-Bold', provider: FontProvider.Wideo }, // Static font for Battersea client
  { displayName: 'Oxygen', fontFamily: 'Oxygen', provider: FontProvider.Google },
  { displayName: 'PT Sans', fontFamily: 'PT Sans', provider: FontProvider.Google },
  { displayName: 'PT Sans Caption', fontFamily: 'PT Sans Caption', provider: FontProvider.Google },
  { displayName: 'PT Sans Narrow', fontFamily: 'PT Sans Narrow', provider: FontProvider.Google },
  { displayName: 'PT Serif', fontFamily: 'PT Serif', provider: FontProvider.Google },
  { displayName: 'Pacifico', fontFamily: 'Pacifico', provider: FontProvider.Google },
  { displayName: 'Passion One', fontFamily: 'Passion One', provider: FontProvider.Google },
  { displayName: 'Pathway Gothic One', fontFamily: 'Pathway Gothic One', provider: FontProvider.Google },
  { displayName: 'Patua One', fontFamily: 'Patua One', provider: FontProvider.Google },
  { displayName: 'Permanent Marker', fontFamily: 'Permanent Marker', provider: FontProvider.Google },
  { displayName: 'Philosopher', fontFamily: 'Philosopher', provider: FontProvider.Google },
  { displayName: 'Play', fontFamily: 'Play', provider: FontProvider.Google },
  { displayName: 'Playfair Display', fontFamily: 'Playfair Display', provider: FontProvider.Google },
  { displayName: 'Poiret One', fontFamily: 'Poiret One', provider: FontProvider.Google },
  { displayName: 'Pontano Sans', fontFamily: 'Pontano Sans', provider: FontProvider.Google },
  { displayName: 'Poppins', fontFamily: 'Poppins', provider: FontProvider.Google },
  { displayName: 'Quattrocento', fontFamily: 'Quattrocento', provider: FontProvider.Google },
  { displayName: 'Quattrocento Sans', fontFamily: 'Quattrocento Sans', provider: FontProvider.Google },
  { displayName: 'Questrial', fontFamily: 'Questrial', provider: FontProvider.Google },
  { displayName: 'Quicksand', fontFamily: 'Quicksand', provider: FontProvider.Google },
  { displayName: 'Raleway', fontFamily: 'Raleway', provider: FontProvider.Google },
  { displayName: 'Righteous', fontFamily: 'Righteous', provider: FontProvider.Google },
  { displayName: 'Roboto', fontFamily: 'Roboto', provider: FontProvider.Google },
  { displayName: 'Roboto Condensed', fontFamily: 'Roboto Condensed', provider: FontProvider.Google },
  { displayName: 'Roboto Mono', fontFamily: 'Roboto Mono', provider: FontProvider.Google },
  { displayName: 'Roboto Slab', fontFamily: 'Roboto Slab', provider: FontProvider.Google },
  { displayName: 'Rock Salt', fontFamily: 'Rock Salt', provider: FontProvider.Google },
  { displayName: 'Rockwell', fontFamily: 'Rockwell', provider: FontProvider.Wideo}, // Static, Legacy Font
  { displayName: 'Rokkitt', fontFamily: 'Rokkitt', provider: FontProvider.Google },
  { displayName: 'Ropa Sans', fontFamily: 'Ropa Sans', provider: FontProvider.Google },
  { displayName: 'Rubik', fontFamily: 'Rubik', provider: FontProvider.Google },
  { displayName: 'Russo One', fontFamily: 'Russo One', provider: FontProvider.Google },
  { displayName: 'Scheherazade', fontFamily: 'Scheherazade', provider: FontProvider.Google },
  { displayName: 'Sanchez', fontFamily: 'Sanchez', provider: FontProvider.Google },
  { displayName: 'Sarabun', fontFamily: 'Sarabun', provider: FontProvider.Wideo},// Added on request from user
  { displayName: 'Satisfy', fontFamily: 'Satisfy', provider: FontProvider.Google },
  { displayName: 'Shadows Into Light', fontFamily: 'Shadows Into Light', provider: FontProvider.Google },
  { displayName: 'Sigmar One', fontFamily: 'Sigmar One', provider: FontProvider.Google },
  { displayName: 'Signika', fontFamily: 'Signika', provider: FontProvider.Google },
  { displayName: 'Slabo 27px', fontFamily: 'Slabo 27px', provider: FontProvider.Google },
  { displayName: 'Source Code Pro', fontFamily: 'Source Code Pro', provider: FontProvider.Google },
  { displayName: 'Source Sans Pro', fontFamily: 'Source Sans Pro', provider: FontProvider.Google },
  { displayName: 'Source Serif Pro', fontFamily: 'Source Serif Pro', provider: FontProvider.Google },
  { displayName: 'Special Elite', fontFamily: 'Special Elite', provider: FontProvider.Google },
  { displayName: 'SusaBold', fontFamily: 'SusaBold', provider: FontProvider.Wideo}, // Static, Legacy Font
  { displayName: 'Tangerine', fontFamily: 'Tangerine', provider: FontProvider.Google },
  { displayName: 'Tinos', fontFamily: 'Tinos', provider: FontProvider.Google },
  { displayName: 'Titillium Web', fontFamily: 'Titillium Web', provider: FontProvider.Google },
  { displayName: 'Ubuntu', fontFamily: 'Ubuntu', provider: FontProvider.Google },
  { displayName: 'Ubuntu Condensed', fontFamily: 'Ubuntu Condensed', provider: FontProvider.Google },
  { displayName: 'Varela Round', fontFamily: 'Varela Round', provider: FontProvider.Google },
  { displayName: 'Vollkorn', fontFamily: 'Vollkorn', provider: FontProvider.Google },
  { displayName: 'Work Sans', fontFamily: 'Work Sans', provider: FontProvider.Google },
  { displayName: 'Yanone Kaffeesatz', fontFamily: 'Yanone Kaffeesatz', provider: FontProvider.Google },
  { displayName: 'Do Hyeon', fontFamily: 'Do Hyeon', provider: FontProvider.Google },                      // Korean
  { displayName: 'Jua', fontFamily: 'Jua', provider: FontProvider.Google },                                // Korean
  { displayName: 'Nanum Pen Script', fontFamily: 'Nanum Pen Script', provider: FontProvider.Google },      // Korean
  { displayName: 'Nanum Myeongjo', fontFamily: 'Nanum Myeongjo', provider: FontProvider.Google },          // Korean
  { displayName: 'Nanum Brush Script', fontFamily: 'Nanum Brush Script', provider: FontProvider.Google },  // Korean
  { displayName: 'Dokdo', fontFamily: 'Dokdo', provider: FontProvider.Google },                            // Korean
  { displayName: 'El Messiri', fontFamily: 'El Messiri', provider: FontProvider.Google },                  // Arabic
  { displayName: 'Cairo', fontFamily: 'Cairo', provider: FontProvider.Google },                            // Arabic
  { displayName: 'Reem Kufi', fontFamily: 'Reem Kufi', provider: FontProvider.Google },                    // Arabic
  { displayName: 'Tajawal', fontFamily: 'Tajawal', provider: FontProvider.Google },                        // Arabic
  { displayName: 'Vibes', fontFamily: 'Vibes', provider: FontProvider.Google },                            // Arabic
  { displayName: 'Scheherazade', fontFamily: 'Scheherazade', provider: FontProvider.Google },              // Arabic
  { displayName: 'Lalezar', fontFamily: 'Lalezar', provider: FontProvider.Google },                        // Arabic
  { displayName: 'Noto Sans SC', fontFamily: 'Noto Sans SC', provider: FontProvider.Google },              // Chinese

];

// export const loadedFonts: IFont[] = [
//   { displayName: 'Chronicle', fontFamily: 'Chronicle', provider: FontProvider.Wideo }, // Static, Added on request from user
//   { displayName: 'Din Pro', fontFamily: 'DINPro', provider: FontProvider.Wideo  }, // Static, Legacy Font
//   { displayName: 'Damion', fontFamily: 'Damion', provider: FontProvider.Wideo  }, // Static, Legacy Font
//   { displayName: 'Georgia', fontFamily: 'Georgia', provider: FontProvider.Wideo  }, // Static, Legacy font
//   { displayName: 'Gill Sans', fontFamily: 'GillSansLight', provider: FontProvider.Wideo  }, // Static, Legacy font
//   { displayName: 'Gotham', fontFamily: 'Gotham', provider: FontProvider.Wideo  },       // Static, Added on request from user
//   { displayName: 'Handwritten', fontFamily: 'HandwrittenCrystal', provider: FontProvider.Wideo  }, // Static, Legacy font
//   { displayName: 'Helvetica', fontFamily: 'Helvetica_v2', provider: FontProvider.Wideo  }, // Static, Legacy font
//   { displayName: 'Myriad Pro', fontFamily: 'MyriadPro_v2', provider: FontProvider.Wideo  }, // Static, Legacy Font
//   { displayName: 'Myriad', fontFamily: 'MyriadPro', provider: FontProvider.Wideo  }, // Static, Legacy Font
//   { displayName: 'OpenSans Light', fontFamily: 'OpenSans_Light', provider: FontProvider.Wideo  }, // Static, Legacy Font
//   { displayName: 'Rockwell', fontFamily: 'Rockwell', provider: FontProvider.Wideo  } // Static, Legacy Font
// ];

type FontLoadedListener = (fontFamily: string) => void;
const fontLoadedListeners: FontLoadedListener[] = [];


/**
 * This function is called by the PIXI Resource Loader for BEFORE each resource is loaded
 *
 * @param resource the Resource that PIXI wants to load
 * @param next the next PIXI Middleware in the chain
 */
// tslint:disable-next-line:no-any
export const pixiFontPreMiddleware = async (resource: PIXI.ILoaderResource, next: (err?: any) => void): Promise<void> => {

  // We set the isFont metadata in the TextComponent to know which resources shall be handled
  // by this loader.
  const fontMetadata = resource.metadata as ExtendedPixiMetadata;
  if (fontMetadata.isFont) {
    try {
      await loadFontCssStyleSheet(fontMetadata.fontFamily)
      await loadFontFiles(fontMetadata.fontFamily, fontMetadata.fontWeight, fontMetadata.fontStyle, fontMetadata.text);
      // The font was successfully loaded
      
      // Mark the resource as completed since we used FontFaceObserver to download the
      // required font files
      resource.complete();

      //Always call next when the font loading has completed
      next();
    } catch (error) {
      // The font could not be loaded for some reason, trace a warning to the console
      Logger.warn('Could not load font: ' + JSON.stringify(fontMetadata) + ', reason: ' + error.message);

      // Mark the resource as completed to avoid that any other loader tries to download
      resource.complete();

      //Call next with Error reason
      next('Could not load font: ' + JSON.stringify(fontMetadata) + ', reason: ' + error.message);
    }
  } else {
    next();
  }

}

export const loadFontCssStyleSheet = async (fontFamily: string): Promise<void> => {
  const cssLoaded: boolean = loadedFonts.some( (fontEntry) => {
    return fontEntry.fontFamily === fontFamily;
  });
  
  if (cssLoaded) {
    return Promise.resolve();
  } else {
    return new Promise((resolve, reject) => {

      // Add the font family to the list of fonts so that we do not try to load it again on success OR failure
      loadedFonts.push({displayName: fontFamily, fontFamily: fontFamily, provider: FontProvider.Google});      

      // Build the URL that is used to get CSS rules for the Web Font from Google Fonts
      const url = 'https://fonts.googleapis.com/css?family=' + fontFamily.replace(/ /g, '+');

      // Create a <link> element and set it to point to the Google Font CSS above and append it to the DOM to load it
      const linkElement = document.createElement('link');
      linkElement.href = url;
      linkElement.type = 'text/css';
      linkElement.rel = 'stylesheet';
      linkElement.id = "dynamic-font-" + fontFamily.toLowerCase().replace(/ /g, '-');
      linkElement.addEventListener('load', function() {
        fireFontLoadedEvent(fontFamily);
        resolve();
      });      
      linkElement.addEventListener('error', function(error: ErrorEvent) {
        Logger.error("An error occurred trying to load google font " + fontFamily + " stylesheet");
        reject(error);
      });
      document.head.append(linkElement);

    });
  }

}

const fireFontLoadedEvent = (fontFamily: string) => {
  fontLoadedListeners.forEach( (listener) => {
    listener(fontFamily);
  })
}
export const addFontLoadedListener = (listener: FontLoadedListener) => {
  fontLoadedListeners.push(listener);
}

const loadFontFiles = async ( fontFamily: string, fontWeight: string, fontStyle: string, text: string): Promise<void> => {

    // Use FontFaceObserver to load the actual font
    const observer: FontFaceObserver = new FontFaceObserver(fontFamily,
      {
        weight: fontWeight,
        style: fontStyle
      });
    const promises = [observer.load(text + '|ÉqÅ', 15000)];

    // If the text also contains emojis load the "Noto Color Emoji" font
    const withEmojis = /\p{Extended_Pictographic}/u;
    if (withEmojis.test(text)) {
      const observerEmoji: FontFaceObserver = new FontFaceObserver("Noto Color Emoji",
        {
          weight: fontWeight,
          style: fontStyle
        });
      promises.push(observerEmoji.load(text + '🔥', 15000));
    }
    
    // Wait for all font files to be loaded
    await Promise.all(promises);

}

