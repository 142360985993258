import Logger from '../../common/log/Logger';

export default class WebSocketConnection {

  private webSocket: WebSocket;
   
  public async connect(url: string) {
    this.webSocket = new WebSocket(url);

    this.webSocket.addEventListener("error", this.onError);
    this.webSocket.addEventListener("close", this.onClose);

    const connectPromise: Promise<void> = new Promise( (resolve, reject) => {
      this.webSocket.addEventListener("open", (event: Event) => {
        resolve();
      });
      this.webSocket.addEventListener("error",  (event: Event) => {
        reject(event);
      });
      
    });

    await connectPromise;
  }

  public disconnect() {
    if (this.webSocket.readyState !== WebSocket.CLOSED && this.webSocket.readyState !== WebSocket.CLOSING) {
      this.webSocket.close();
    }
    this.removeListeners();
  }

  public removeListeners() {    
    this.webSocket.removeEventListener("error", this.onError);
    this.webSocket.removeEventListener("close", this.onClose);
  }

  public send(msg: string): void {
    this.webSocket.send(msg);
  }

  /**
   * Returns the next message received on the socket or times out. Then the listener is removed.
   * TODO: If this class shall be used by several different clients receiving at the same time this
   *       should be updated to filter out only the desired messages.
   */
  // tslint:disable-next-line:no-any
  public async recieve(timeout: number = 60000): Promise<any> {
    let listener = null;
    let timeoutId = null;
    const webSocket = this.webSocket;
    try {
      // Create a promise waiting for a new message from the websocket
      // tslint:disable-next-line:no-any
      const eventPromise: Promise<any> = new Promise( (resolve, reject) => {
        if (webSocket.readyState === WebSocket.OPEN) {
          listener = (event: MessageEvent) => {
            resolve(event.data);
          };
          webSocket.addEventListener("message", listener);        
        } else {
          reject(new Error("Failed receiving data from the websocket " + webSocket.url + ". Not in state OPEN, state: " + webSocket.readyState));
        }
      });

      // Create a promise that will reject if timeout occurs
      const timeoutPromise = new Promise((_resolve, reject) => {
        timeoutId = setTimeout(reject, timeout, new Error("Timed out"));
      });

      // Race between first message and timeout
      return await Promise.race([eventPromise, timeoutPromise]);
    } catch (error) {
      Logger.error(error);
      throw error;
    } finally {
      // Make sure we cleanup our event listener
      if (listener) {
        webSocket.removeEventListener("message", listener);
      }
      // And the timeout
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    }
  }

  //An event listener to be called when an error occurs. This is a simple event named "error".
  private onError(event: Event): void {
    Logger.error("WebsocketConnection received error.", event);
    throw new Error("An error occured in websocket connection. event: " + JSON.stringify(event));
  }
  
  //An event listener to be called when the WebSocket connection's readyState changes to CLOSED.
  private onClose(event: CloseEvent): void {
    Logger.info("WebsocketConnection closed. code: " + event.code + ", reason: " + event.reason, event);
    this.removeListeners();
  }


}