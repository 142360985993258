import { FlashIntro, FlashOutro, FlashObject } from './model/WideoDefFlash';
import { Tween, ShapeType, WideoObjectDef, Class, MaskType } from "../../common/core/model/WideoDef";
import BackgroundsApi from "../../api/BackgroundsApi";
import AssetsApi from "../../api/AssetsApi";
import AudiosApi from "../../api/AudiosApi";
import WideoDefFactory from "../../common/core/model/WideoDefFactory";
import Logger from '../../common/log/Logger';
import { loadedFonts } from '../../common/fonts/FontLoader';
import { defaultTextFontFamily } from '../../editor/core/EditorConstants';

export const flashMovieWidth = 960;
export const flashMovieHeight = 540;
const defaultKoreanTextFontFamily = 'Nanum Gothic';

export default class ConverterUtils {

  public static flashFontFamilyToHtml5FontFamily(flashFontName: string): string {
    const fontFamily = (flashFontName === 'OpenSans_v2' ? 'OpenSans_Light' : flashFontName);
    for (const availableFont of loadedFonts) {
      if (availableFont.fontFamily === fontFamily) {
        return fontFamily;
      } else {
        if (ConverterUtils.isKoreanFlashFont(fontFamily)) {
          // If it is an unsupported korean font return our default korean font
          return ConverterUtils.koreanFlashFontToHtml5Font(fontFamily);
        }
      }
    }
    Logger.warn("Font not found: %s, using %s as default.", flashFontName, defaultTextFontFamily);
    return defaultTextFontFamily;
  }

  private static isKoreanFlashFont(fontFamily: string) {
    switch (fontFamily) {
      case 'DoHyeon':
      case 'Godo':
      case 'Hanna':
      case 'Jua':
      case 'NanumPen':
      case 'NanumMyeongjo':
      case 'NanumGothic':
      case 'NanumBrushScript':
      case 'NanumBarunGothic':
      case 'Makgeolli':
      case 'DaraehandBasic':
      case 'DokdoChe':
      case 'HGSS':
        return true;
      default:
        return false;
    }
  }

  private static koreanFlashFontToHtml5Font(fontFamily: string): string {
    switch (fontFamily) {
      case 'DoHyeon':
        return 'Do Hyeon';
      case 'Jua':
        return 'Jua';
      case 'NanumPen':
        return 'Nanum Pen Script';
      case 'NanumMyeongjo':
        return 'Nanum Myeongjo';
      case 'NanumGothic':
        return 'Nanum Gothic';
      case 'NanumBrushScript':
        return 'Nanum Brush Script';
      case 'DokdoChe':
        return 'Dokdo'
      case 'Godo':
      case 'Hanna':
      case 'NanumBarunGothic':
      case 'Makgeolli':
      case 'DaraehandBasic':
      case 'HGSS':
      default:
        Logger.warn("Korean font not found: %s, using %s as default.", fontFamily, defaultKoreanTextFontFamily);
        return defaultKoreanTextFontFamily;
    }
  }

  public static flashFontSizeAndLeadingToHTML5LineHeightFactor(fontSize: number, leading: number): number {
    const lineHeight = fontSize * 1.2 + leading; //TODO: Test this with different line spacing in Flash
    return lineHeight / fontSize;
  }

  public static flashTimeToHtmlTime(parentObjectDef: WideoObjectDef, time: number) {
    if (!parentObjectDef || parentObjectDef.class === Class.Scene) {
      return time * 1000; // In flash the times for objects inside scenes are already relative to the scene
    } else if (parentObjectDef.class === 'objectGroup' ||
      parentObjectDef.class === 'placeholder' ||
      parentObjectDef.class === 'maskedObject') {
      return time * 1000 - parentObjectDef.startTime;
    } else {
      Logger.warn("Unknown parent object class: " + parentObjectDef.class + ", using relative time as default.");
      return time * 1000 - parentObjectDef.startTime;
    }
  }

  public static calculateX = (flashX: number, parentObjectDef: WideoObjectDef): number => {
    //in flash, objects childs of scenes have x,y absolute to the scenes.
    //But, objects inside groups, have  x,y values relative to the group that they belongs.

    //flashShit ===true when flashX is from an object inside another wideoObject object not scene (groups)

    const flashShit: boolean = parentObjectDef.class !== Class.Scene;
    if (flashShit) {
      return flashX;
    }
    else {//magic numbers
      return (flashMovieWidth * 0.5 - flashX) * -1;
    }
  }
  public static calculateY = (flashY: number, parentObjectDef: WideoObjectDef): number => {
    //in flash, objects childs of scenes have x,y absolute to the scenes.
    //But, objects inside groups, have  x,y values relative to the group that they belongs.

    //flashShit ===true when flashY is from an object inside another wideoObject object not scene (groups)
    const flashShit: boolean = parentObjectDef.class !== Class.Scene;
    if (flashShit) {
      return flashY;
    }
    else {//magic numbers
      return (flashMovieHeight * 0.5 - flashY) * -1;
    }
  }

  public static getTweenIntro = (intro: FlashIntro): Tween => {
    let tween: Tween;
    if (intro.type === "HandSceneTransition" || intro.type === "HandObjectTransition") {
      switch (intro.effect) {
        case "handLeftIntro": { tween = Tween.HandLeft; break; }
        case "handUpIntro": { tween = Tween.HandUp; break; }
        case "handRightIntro": { tween = Tween.HandRight; break; }
        case "handDownIntro": { tween = Tween.HandDown; break; }
        case "slideFromLeft": { tween = Tween.HandLeft; break; }
        case "slideFromRight": { tween = Tween.HandRight; break; }
        case "slideFromBottom": { tween = Tween.HandDown; break; }
        case "slideFromTop": { tween = Tween.HandUp; break; }
        default: { Logger.warn("Unknown intro effect: " + intro.effect + ", defaulting to FadeIn"); tween = Tween.FadeIn; break; }
      }
    } else {
      switch (intro.effect) {
        case "fadeIn": { tween = Tween.FadeIn; break; }
        case "slideFromLeft": { tween = Tween.SlideRight; break; }
        case "slideFromRight": { tween = Tween.SlideLeft; break; }
        case "slideFromBottom": { tween = Tween.SlideUp; break; }
        case "slideFromTop": { tween = Tween.SlideDown; break; }
        case "slideLeft": { tween = Tween.SlideLeft; break; }
        case "slideRight": { tween = Tween.SlideRight; break; }
        case "slideDown": { tween = Tween.SlideDown; break; }
        case "slideUp": { tween = Tween.SlideUp; break; }
        case "slideLeftIntro": { tween = Tween.SlideLeft; break; }
        case "slideRightIntro": { tween = Tween.SlideRight; break; }
        case "slideDownIntro": { tween = Tween.SlideDown; break; }
        case "slideUpIntro": { tween = Tween.SlideUp; break; }
        case "popIn": { tween = Tween.PopIn; break; }
        case "growIn": { tween = Tween.EnlargeIn; break; }
        case "flyIn": { tween = Tween.ZoomIn; break; }
        case "scaleIn": { tween = Tween.ScaleIn; break; }
        case "typeIn": { tween = Tween.AutoType; break; }
        default: { Logger.warn("Unknown intro effect: " + intro.effect + ", defaulting to FadeIn"); tween = Tween.FadeIn; break; }
      }
    }
    return tween;
  }

  public static getTweenOutro = (outro: FlashOutro): Tween => {
    let tween: Tween;
    if (outro.type === "HandSceneTransition" || outro.type === "HandObjectTransition") {
      switch (outro.effect) {
        case "handUp": { tween = Tween.HandUp; break; }
        case "handDown": { tween = Tween.HandDown; break; }
        case "handLeft": { tween = Tween.HandLeft; break; }
        case "handRight": { tween = Tween.HandRight; break; }
        case "slideToLeft": { tween = Tween.HandLeft; break; }
        case "slideToRight": { tween = Tween.HandRight; break; }
        case "slideToBottom": { tween = Tween.HandDown; break; }
        case "slideToTop": { tween = Tween.HandUp; break; }
        default: { Logger.warn("Unknown outro effect: " + outro.effect + ", defaulting to FadeOut"); tween = Tween.FadeOut; break; }
      }
    } else {
      switch (outro.effect) {
        case "fadeOut": { tween = Tween.FadeOut; break; }
        case "slideLeft": { tween = Tween.SlideLeft; break; }
        case "slideRight": { tween = Tween.SlideRight; break; }
        case "slideDown": { tween = Tween.SlideDown; break; }
        case "slideUp": { tween = Tween.SlideUp; break; }
        case "slideToLeft": { tween = Tween.SlideLeft; break; }
        case "slideToRight": { tween = Tween.SlideRight; break; }
        case "slideToBottom": { tween = Tween.SlideDown; break; }
        case "slideToTop": { tween = Tween.SlideUp; break; }
        case "popOut": { tween = Tween.PopOut; break; }
        case "growOut": { tween = Tween.EnlargeOut; break; }
        case "flyOut": { tween = Tween.ZoomOut; break; }
        case "scaleOut": { tween = Tween.ScaleOut; break; }
        case "scaleIn": { tween = Tween.ScaleIn; break; }
        case "typeOut": { tween = Tween.AutoType; break; }
        default: { Logger.warn("Unknown outro effect: " + outro.effect + ", defaulting to FadeOut"); tween = Tween.FadeOut; break; }
      }
    }
    return tween;

  }

  public static getMaskType = (shapeFlashType: string): MaskType => {
    let maskType: MaskType;
    switch (shapeFlashType) {
      case "ELLIPSE": { maskType = MaskType.Ellipse; break; }
      case "ROUNDED_RECTANGLE": { maskType = MaskType.RoundedRect; break; }
      case "ROUNDED_SQUARE": { maskType = MaskType.RoundedRect; break; }
      case "RECTANGLE": { maskType = MaskType.Rectangle; break; }
      case "SQUARE": { maskType = MaskType.Rectangle; break; }
      case "DIAMOND": { maskType = MaskType.Diamond; break; }
      //Unimplemented shapes:
      // case "ARROW": { maskType = MaskType.Arrow; break; }
      // case "STAR": { maskType = MaskType.Star; break; }
      // case "HEXAGON": { maskType = MaskType.Hexagon; break; }
      // case "LINE": { maskType = MaskType.Line; break; }
      // case "MULTI_STAR": { maskType = MaskType.MultipleStar; break; }
      // case "TRIANGLE": { maskType = MaskType.Triangle; break; }
      // case "RECT_TRIANGLE": { maskType = MaskType.TriangleRectangle; break; }
      // case "DOTTED_LINE": { maskType = MaskType.DottedLine; break; }

      default: {
        Logger.warn('Unsupported mask type for flash shape: ' + shapeFlashType + ' creating rectangle mask.');
        maskType = MaskType.Rectangle;
        break;
      }
    }
    return maskType;
  }

  public static getShapeType = (shapeFlashType: string): ShapeType => {
    let shapeType: ShapeType;
    switch (shapeFlashType) {
      case "ARROW": { shapeType = ShapeType.Arrow; break; }
      case "ELLIPSE": { shapeType = ShapeType.Ellipse; break; }
      case "DIAMOND": { shapeType = ShapeType.Diamond; break; }
      case "STAR": { shapeType = ShapeType.Star; break; }
      case "HEXAGON": { shapeType = ShapeType.Hexagon; break; }
      case "LINE": { shapeType = ShapeType.Line; break; }
      case "MULTI_STAR": { shapeType = ShapeType.MultipleStar; break; }
      case "ROUNDED_RECTANGLE": { shapeType = ShapeType.RoundedRect; break; }
      case "ROUNDED_SQUARE": { shapeType = ShapeType.RoundedSquare; break; }
      case "TRIANGLE": { shapeType = ShapeType.Triangle; break; }
      case "RECT_TRIANGLE": { shapeType = ShapeType.TriangleRectangle; break; }
      case "DOTTED_LINE": { shapeType = ShapeType.DottedLine; break; }
      case "RECTANGLE": { shapeType = ShapeType.Rectangle; break; }
      case "SQUARE": { shapeType = ShapeType.Square; break; }

      default: break;
    }
    return shapeType;
  }

  private static componentToHex(c: number): string {
    const hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  }

  // The normal rules in getColorRGB does apparently not apply to text color of old text assets
  public static flashFontTextColorToHexString(rgb: number) {
    const r: number = rgb >> 16 & 255;
    const g: number = rgb >> 8 & 255;
    const b: number = rgb >> 0 & 255;

    const hexString =  ConverterUtils.componentToHex(r) + ConverterUtils.componentToHex(g) + ConverterUtils.componentToHex(b);
    return hexString;
  }

  public static getColorRGB = (flashARGBColorInt: number): string => {
    if (flashARGBColorInt === 1) {
      //FlaSH SHIT. 1 means black color
      return "000000";
    }
    else if (flashARGBColorInt === 0) {
      return null; //another flash shit. zero means transparent
    }
    const r: number = flashARGBColorInt >> 16 & 255;
    const g: number = flashARGBColorInt >> 8 & 255;
    const b: number = flashARGBColorInt >> 0 & 255;

    const hexString =  ConverterUtils.componentToHex(r) + ConverterUtils.componentToHex(g) + ConverterUtils.componentToHex(b);
    return hexString;
  }

  public static getImage = async (url: string): Promise<HTMLImageElement> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img)
      img.onerror = reject;
      img.src = url;
    })
  }

  public static calculateScaleXOfAssetImage = (originalFlashWidth: number, flashScaleX: number, originalAssetWidth: number): number => {
    const desiredWidth = originalFlashWidth * flashScaleX;
    return desiredWidth / originalAssetWidth;
  }

  public static calculateScaleYOfAssetImage = (originalFlashHeight: number, flashScaleY: number, originalAssetHeight: number): number => {
    const desiredHeight = originalFlashHeight * flashScaleY;
    return desiredHeight / originalAssetHeight;
  }

  public static getAssetImageUrl = async (environment: string, accessToken: string, assetId: string): Promise<string> => {
    const api: AssetsApi = new AssetsApi(environment, accessToken);
    return api.getImageUrl(assetId);
  }

  public static getBackgroundImageUrl = async (environment: string, accessToken: string, backId: string): Promise<string> => {
    const api: BackgroundsApi = new BackgroundsApi(environment, accessToken);
    return api.getBackgroundUrl(backId);
  }

  public static getAudioUrl = async (environment: string, accessToken: string, audioId: string): Promise<string> => {
    const api: AudiosApi = new AudiosApi(environment, accessToken);
    return api.getAudioUrl(audioId);
  }

  public static createObjectGroupDef = (flashObject: FlashObject, parentObjectDef: WideoObjectDef): WideoObjectDef => {
    const wideoObjectDef: WideoObjectDef = WideoDefFactory.CreateEmptyWideoObjectDef(
      Class.ObjectGroup,
      ConverterUtils.flashTimeToHtmlTime(parentObjectDef, flashObject.endTime),
      flashObject.objectId,
      ConverterUtils.flashTimeToHtmlTime(parentObjectDef, flashObject.startTime) );

    wideoObjectDef.attributes = {
      x: ConverterUtils.calculateX(flashObject.transform.x, parentObjectDef),
      y: ConverterUtils.calculateY(flashObject.transform.y, parentObjectDef),
      alpha: flashObject.asset ? flashObject.asset.opacity : 1,
      rotation: flashObject.transform.angle,
      scaleX: flashObject.transform.scaleX,
      scaleY: flashObject.transform.scaleY,
    }

    return wideoObjectDef;
  }

  public static createPlaceholderDef = (flashObject: FlashObject, parentObjectDef: WideoObjectDef): WideoObjectDef => {
    const wideoObjectDef: WideoObjectDef = WideoDefFactory.CreateEmptyWideoObjectDef(
      Class.Placeholder,
      ConverterUtils.flashTimeToHtmlTime(parentObjectDef, flashObject.endTime),
      flashObject.objectId,
      ConverterUtils.flashTimeToHtmlTime(parentObjectDef, flashObject.startTime) );

    wideoObjectDef.attributes = {
      x: ConverterUtils.calculateX(flashObject.transform.x, parentObjectDef),
      y: ConverterUtils.calculateY(flashObject.transform.y, parentObjectDef),
      alpha: flashObject.asset ? flashObject.asset.opacity : 1,
      rotation: flashObject.transform.angle,
      scaleX: flashObject.transform.scaleX,
      scaleY: flashObject.transform.scaleY,
    }

    return wideoObjectDef;
  }
  public static createMaskedObjectDef = (flashObject: FlashObject, parentObjectDef: WideoObjectDef): WideoObjectDef => {
    const wideoObjectDef: WideoObjectDef = WideoDefFactory.CreateEmptyWideoObjectDef(
      Class.MaskedObject,
      ConverterUtils.flashTimeToHtmlTime(parentObjectDef, flashObject.endTime),
      flashObject.objectId,
      ConverterUtils.flashTimeToHtmlTime(parentObjectDef, flashObject.startTime) );

    wideoObjectDef.attributes = {
      x: ConverterUtils.calculateX(flashObject.transform.x, parentObjectDef),
      y: ConverterUtils.calculateY(flashObject.transform.y, parentObjectDef),
      alpha: flashObject.asset ? flashObject.asset.opacity : 1,
      rotation: flashObject.transform.angle,
      scaleX: flashObject.transform.scaleX,
      scaleY: flashObject.transform.scaleY,
    }

    return wideoObjectDef;
  }

}
