/**
 * Created by mdavids on 31/10/2017.
 */
import * as PIXI from 'pixi.js-legacy';
import IClockListener from "./IClockListener";
import { filterObjs } from '../../editor/core/EditorConstants';

export default class Clock {
  public elapsedTime: number = 0;

  public listeners: IClockListener[] = [];
  public ticker: PIXI.Ticker;

  constructor() {
    //Global elapsed time in milliseconds
    this.elapsedTime = 0;

    //Listeners to global elapsed time in milliseconds
    this.listeners = [];

    //Create a Pixi Ticker to get updates of deltaTime
    //The Ticker manages RequestAnimationFrame() internally
    this.ticker = new PIXI.Ticker();
    this.ticker.autoStart = false;
    this.ticker.add(this.update, this);
  }

  /**
   * Start clock
   */
  public start(): void {
    this.ticker.start();
  }

  /**
   * Stop clock
   */
  public stop(): void {
    this.ticker.stop();
  }

  /**
   * Seek to time milliseconds
   */
  public seek(time: number): void {
    this.elapsedTime = time;
    if (!this.ticker.started) {
      this.update(); //Send an extra update
    }
  }

  public getCurrentTime(): number {
    return this.elapsedTime;
  };

  public getFps(): number {
    return this.ticker.FPS;
  };

  /*
     * Called periodically by Ticker (if running) with the delta time
     * in milliseconds since last tick. Implements Tickers deltaTime
     * listener interface
     */
  public update(): void {
    //Accumulate elapsed time, this.ticker.elapsedMS holds the number of millis since last update
    if (this.ticker.started) {
      this.elapsedTime += this.ticker.elapsedMS;
    }

    //Call each listener
    this._callListeners();
  }

  //---------------------- Listener logic --------------

  /**
   * Add a new listener
   *
   * @param {IClockListener} listener
   */
  public addListener(listener: IClockListener): void {
    this.listeners.push(listener);
  }

  public removeListener(listener: IClockListener): void {
    this.listeners = filterObjs((item: IClockListener) => item !== listener)(this.listeners);
  }

  public destroy() {
    // If there are any listeners registered remove them
    for (const listener of this.listeners) {
      this.removeListener(listener);
    }
    this.listeners = null;

    // Remove ourself from the PIXI ticker and destroy it
    if (this.ticker) {
      this.ticker.remove(this.update, this);
      this.ticker.destroy();
      this.ticker = null;
    }
  };

  /**
   * Call each registered listener
   */
  protected _callListeners(): void {
    //Call each listener
    for (const listener of this.listeners) {
      listener.update(this.elapsedTime);
    }
  }
}
