import React from 'react';
import ReactDOM from 'react-dom';
import "@fontsource/roboto";
import './index.css';
import App from './common/ui/App';
import { BrowserRouter } from 'react-router-dom';
import SentryErrorBoundary from './common/ui/SentryErrorBoundary';
import { sentryInit } from './common/log/SentryWrapper';
import translationsInit from './common/translations/translationsInit';
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";

translationsInit();
sentryInit();

ReactDOM.render(
  <React.StrictMode>
    <SentryErrorBoundary>
      <BrowserRouter>
        <I18nextProvider i18n={i18next}>
          <App />
        </I18nextProvider>
      </BrowserRouter>
    </SentryErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);
