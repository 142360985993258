import { WideoDefVersion } from "../../core/model/WideoDef";
import ITransformer from "../ITransformer";
import IdManager from "../../core/ids/IdManager";

export default class TransformerToV59 implements ITransformer {
  readonly toVersion: WideoDefVersion = WideoDefVersion.V59;

  // tslint:disable-next-line:no-any
  public async transformWideo(wideoDef: any): Promise<void> {

    //Update version and timestamp                             
    wideoDef.version = this.toVersion;
    wideoDef.timeStamp = Date.now();

    // tslint:disable-next-line:no-any
    wideoDef.scenes.forEach((sceneDef: any) => {       
      this.transformScene(sceneDef);
    });
    return Promise.resolve();
  }

  // tslint:disable-next-line:no-any
  transformScene(sceneDef: any): void {

    //Update version 
    sceneDef.version = this.toVersion;

    // Replace all ids of children to this scene (https://3.basecamp.com/3459151/buckets/3812629/todos/2998789327)
    // Don't replace id of the scene though since sceneIds are used as part of unique keys User Favorite Scenes
    new IdManager().replaceIds(sceneDef, false);
    
  }

}