import * as PIXI from 'pixi.js-legacy';
import { parseGIF, decompressFrames } from 'gifuct-js'

import WideoObject from "../WideoObject";
import { Class } from '../model/WideoDef';
import { PendingAssetResource, ExtendedPixiILoaderOptions} from "../AssetsLoader";
import { ImageComponentDef } from '../model/WideoDef';
import AbstractComponent from './AbstractComponent';
import WideoContext from '../WideoContext';
import Attributes from "../Attributes";
import Logger from '../../../common/log/Logger';

export default class ImageComponent extends AbstractComponent {

  protected _src: string;

  protected _sprite: PIXI.Sprite;
  protected _frames: PIXI.Texture[] = [];

  protected _animated: boolean = false; // animated is set to true when image has more than 1 frame (decided when loading the image)
  protected _loop: boolean = true;      // loop is hard coded to true for now, only serialized if animated = true
                                        // (If changed we need to change Lambda Asset Optimizer to check this flag)

  protected _currentFrameIndex: number = 0;
  protected _frameDelay: number = 0;
  protected _totalAnimTime: number = 0;

  constructor(context: WideoContext, owner: WideoObject, def: ImageComponentDef) {
    super(true, 'ImageComponent-' + def.id);

    this._context = context;
    this._owner = owner;
    this._class = def.class;
    this._id = def.id;
    this._src = def.src;
    this._loop = def.loop;

    if (this._src) {

      //check if src has query string parameters
      //force image
      const options: ExtendedPixiILoaderOptions = {};
      if (this._src.indexOf('?') > 0) {
        options.loadType = PIXI.LoaderResource.LOAD_TYPE.IMAGE;
        options.xhrType = PIXI.LoaderResource.XHR_RESPONSE_TYPE.BLOB;
      }

      this._context.getAssetsLoader().push(
        {
          id: this._src, // Use src as id in assets loader always to avoid some problem???
          src: this._src,
          content: null,
          contentGif: null
        },
        () => {
          this.addContent(this._src);
        },
        options
      );
    }

  }

  public serialize(): ImageComponentDef {
    let component = {
      id: this._id,
      class: this._class
    };

    if (this._src) {
      component = Object.assign(component, { src: this._src });
      if (this._class === Class.ImageComponent) {
        component = Object.assign(component, { animated: this._animated });
        if (this._animated) {  
          component = Object.assign(component, { loop: this._loop });
        }
      }
    }    
    return component;

  }

  public getSrc = (): string => {
    return this._src;
  }

  protected addContent(key: string): void {
    const asset: PendingAssetResource = this._context.getAssetsLoader().getAsset(key);
    const content: PIXI.ILoaderResource = asset.content;
    const extensionIndex: number = this._src.lastIndexOf(".");
    const extension: string = this._src.substr(extensionIndex + 1).toLowerCase();
    if (asset.content) {
      if (extension === "gif") {
        this.buildGifFrames(asset.contentGif);
      } else {
        this._frames.push(content.texture);
      }

      if (this._frames.length > 0) {
        this._sprite = new PIXI.Sprite(this._frames[0]);
        this._sprite.name = 'Sprite-' + this._id;
        this._sprite.anchor.set(0);
        this._sprite.x -= this._sprite.width * 0.5;
        this._sprite.y -= this._sprite.height * 0.5;

        this._displayObject.addChild(this._sprite);
      }

      // This is an animated GIF if it has more than one frame
      this._animated = this._frames.length > 1;

    }
  }

  public enable(): void {
    this._sprite.visible = true;
    this._isEnabled = true;
  }

  public disable(): void {
    this._sprite.visible = false;
    this._isEnabled = false;
  }

  public update(elapsedTime: number): Attributes {
    if (this._isEnabled && this._animated) {
      this._sprite.texture = this._frames[this._currentFrameIndex];

      const nextFrameIndex: number = Math.floor((this._frames.length - 1) * ((elapsedTime - this._owner.getStartTime()) / this._totalAnimTime));

      if (this._loop) {
        this._currentFrameIndex = nextFrameIndex % this._frames.length;
      } else {
        this._currentFrameIndex = Math.min(nextFrameIndex, this._frames.length - 1);
      }
    }
    return null;
  }

  public destroy(): void {
    if (this._sprite) {
      this._sprite.destroy();
    }

    super.destroy();
  }

  /* tslint:disable-next-line */
  private buildGifFrames(content: any): void {
    try {
      if (content) {
        const gif = parseGIF(content); 
        const gifFrames = decompressFrames(gif, true);

        if (gifFrames.length <= 0) {
          return;
        }

        // Details on the GIF format here:
        // https://github.com/matt-way/gifuct-js/blob/master/demo/demo.js
        // https://www.matthewflickinger.com/lab/whatsinagif/

        this._frameDelay = gifFrames[0].delay; //Assumes all frames have the same delay... not true!
        this._totalAnimTime = this._frameDelay * gifFrames.length;

        // gif patch canvas is used to draw the "patch" of one frame (can be the whole area or partial)
        const tempCanvas = document.createElement('canvas');
        const tempCtx = tempCanvas.getContext('2d');
        // full gif canvas (canvas used during generation of each frame, always whole area)
        const gifCanvas = document.createElement('canvas');
        const gifCtx = gifCanvas.getContext('2d');
        gifCanvas.width = gif.lsd.width;
        gifCanvas.height = gif.lsd.height;

        let frameImageData: ImageData;
        for (const frame of gifFrames) {
          try {
            const dims = frame.dims;

            // If this is the first frame or a frame with diferent dimensions than the previous
            // allocate a new ImageData to write the patch to.
            if (!frameImageData || dims.width !== frameImageData.width || dims.height !== frameImageData.height) {
              tempCanvas.width = dims.width;
              tempCanvas.height = dims.height;
              frameImageData = tempCtx.createImageData(dims.width, dims.height);
            }

            // set the patch data as an override
            frameImageData.data.set(frame.patch);

            // draw the patch back over the canvas
            tempCtx.putImageData(frameImageData, 0, 0);

            // draw the patch over the whole gifCtxt
            gifCtx.drawImage(tempCanvas, dims.left, dims.top);

            // Create a new Canvas for each frame for now, I am not sure this is necesary but
            // it seems like PIXI uses the Canvas as backing store for the Texture.
            const frameCanvas = document.createElement('canvas');
            const frameCtx = frameCanvas.getContext('2d');
            frameCanvas.width = gif.lsd.width;
            frameCanvas.height = gif.lsd.height;

            frameCtx.drawImage(gifCanvas, 0, 0);
            // Create a new Texture for each frame backed by the frameCanvas
            this._frames.push(PIXI.Texture.from(frameCanvas));

            // Clear the gifCtx on disposalType = 2
            if (frame.disposalType === 2) {
              gifCtx.clearRect(dims.left, dims.top, dims.width, dims.height);
            }

          } catch (error) {
            Logger.error("A problem ocurred drawing gif frame for image " + this._id + ", stack: " + Logger.errorToString(error));
          }
        }

      }
    } catch (error) {
      Logger.error("A problem ocurred drawing gif image " + this._id + ", stack: " + Logger.errorToString(error));
    }
  }
}
