import {WideoDefVersion, Class} from "../../core/model/WideoDef";
import Logger from "../../log/Logger";
import ITransformer from "../ITransformer";

export default class TransformerToV40 implements ITransformer {
    readonly toVersion: WideoDefVersion = WideoDefVersion.V40;

    constructor() {
      // Intentionally blank
    }

/*
"objects": [
  {
    "id": "text-box-mask1",
    "class": Class.TextObject,
    "startTime": 0,
    "endTime": 5000,
    "hidden": false,
    "locked": false,
    "attributes": {
      "x": -280,
      "y": -170,
      "rotation": 0,
      "scaleX": 1,
      "scaleY": 1,
      "alpha": 1
    },
    "components": [
      {
        "class": Class.MaskComponent,
        "type": MaskType.Rectangle,
        "width": 1920,
        "height": 200
      }
    ],
    "objects": [ <--- This inner object is removed completly, the TextComponent is moved out to the same WideoObjects as the MaskComponent
      {
        "id": "text-1",
        "class": Class.WideoObject,
        "startTime": 0,
        "endTime": 5000,
        "hidden": false,
        "locked": false,
        "attributes": {
          "x": 0, <--- This was used before to align text
          "y": 0, <--- This was used before to align text
          "rotation": 0,
          "scaleX": 1,
          "scaleY": 1,
          "alpha": 1
        },
        "components": [
          {
            "id": 'text-text-1',
            "class": Class.TextComponent,
            "text": "An animated text, An animated text, An animated text, An animated text, other text",
            "width": 1920,
            "height": 36,
            "brightness": 0.5,
            "style": {
              "align": "left",
              "fontSize": 30,
              "fontWeight": "bold",
              "fontFamily": "Helvetica_v2",
              "fontStyle": "normal",
              "lineHeight": 36,
              "fill": "#ff0099"
            }
          },
        ],
        "objects": []
      },
    ]
  }


  ----->


  "objects": [
    {
      "id": "text-text-1",
      "class": Class.TextObject,
      "startTime": 0,
      "endTime": 5000,
      "hidden": false,
      "locked": false,
      "attributes": {
        "x": -280,
        "y": -170,
        "rotation": 0,
        "scaleX": 1,
        "scaleY": 1,
        "alpha": 1
      },
      "components": [
        {
          "class": Class.MaskComponent,
          "type": MaskType.Rectangle,
          "width": 1920,
          "height": 200
        },
        {
          "id": 'text-text-1',
          "class": Class.TextComponent,
          "text": "An animated text, An animated text, An animated text, An animated text, other text",
          "width": 1920,
          "height": 36,
          "brightness": 0.5,
          "style": {
            "align": "left",
            "fontSize": 30,
            "fontWeight": "bold",
            "fontFamily": "Helvetica_v2",
            "fontStyle": "normal",
            "lineHeight": 36,
            "fill": "#ff0099"
          }
        }
      ],
      "objects": []
    }

  */

    // tslint:disable-next-line:no-any
    public transformWideo( wideoDef: any ) {

      //Update version and timestamp
      wideoDef.version = this.toVersion;
      wideoDef.timeStamp = Date.now();

      for (const sceneDef of wideoDef.scenes) {
        this.transformScene(sceneDef);
      }

    }

    // tslint:disable-next-line:no-any
    transformScene(sceneDef: any): void {
      
      //Update version
      sceneDef.version = this.toVersion;
      
      for (const wideoObjectDef of sceneDef.objects) {
        this.transformWideoObject(wideoObjectDef);
      }
    }

    // tslint:disable-next-line:no-any
    transformWideoObject(wideoObjectDef: any): void {

      if ( wideoObjectDef.class === 'textObject') { //TODO: Dependencies to Editor defined objects, how to handle???
        this.transformTextObject(wideoObjectDef);
      } else {
        for (const innerObjectDef of wideoObjectDef.objects) {
          this.transformWideoObject(innerObjectDef);
        }
      }
    }

    // tslint:disable-next-line:no-any
    transformTextObject(textObjectDef: any): void {

      // Sanity check, TextObject must have exactly one child with one component
      if (textObjectDef.objects.length === 1 &&
          textObjectDef.objects[0].components.length === 1 &&
          textObjectDef.objects[0].components[0].class === Class.TextComponent) {

        // Copy the TextComponent from within the first object child to this object
        textObjectDef.components.push(textObjectDef.objects[0].components.pop());

        // Remove the child wideoobject
        textObjectDef.objects.pop();

      } else {
        Logger.error('Tranform to ' + this.toVersion + ' failed in tranformTextObject. %s', JSON.stringify(textObjectDef));
        throw new Error('Tranform to ' + this.toVersion + ' failed in tranformTextObject');
      }

    }

}
