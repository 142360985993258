
import Logger from '../../common/log/Logger';

type Task = () => Promise<void>;

export default class ConcurrentQueue {
 
  private running: number = 0;
  private tasks: Task[] = [];
  private concurrency: number;

  constructor(concurrency: number)  {
    this.concurrency = concurrency;
  }

  public enqueue =  async (task: Task): Promise<void> => {
    this.tasks.push(task);
    if (this.running >= this.concurrency) {
      return;
    }

    this.running++;
    while (this.tasks.length) {
        try {
            await this.tasks.shift()();
        } catch(err) {
            Logger.error(err);
            throw(err);
        }
    }
    this.running--;

  }

}