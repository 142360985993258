import { Class, Def, CURRENT_WIDEO_DEF_VERSION } from "../core/model/WideoDef";

import ITransformer from "./ITransformer";
import TransformerToV40 from "./transformers/TransformerToV40";
import TransformerToV41 from "./transformers/TransformerToV41";
import TransformerToV411 from "./transformers/TransformerToV411";
import TransformerToV42 from "./transformers/TransformerToV42";
import TransformerToV43 from "./transformers/TransformerToV43";
import TransformerToV44 from "./transformers/TransformerToV44";
import TransformerToV45 from "./transformers/TransformerToV45";
import TransformerToV46 from "./transformers/TransformerToV46";
import TransformerToV47 from "./transformers/TransformerToV47";
import TransformerToV48 from "./transformers/TransformerToV48";
import TransformerToV49 from "./transformers/TransformerToV49";
import TransformerToV50 from "./transformers/TransformerToV50";
import TransformerToV51 from "./transformers/TransformerToV51";
import TransformerToV52 from "./transformers/TransformerToV52";
import TransformerToV53 from "./transformers/TransformerToV53";
import TransformerToV54 from "./transformers/TransformerToV54";
import TransformerToV55 from "./transformers/TransformerToV55";
import TransformerToV56 from "./transformers/TransformerToV56";
import TransformerToV57 from "./transformers/TransformerToV57";
import TransformerToV58 from "./transformers/TransformerToV58";
import TransformerToV59 from "./transformers/TransformerToV59";
import TransformerToV60 from "./transformers/TransformerToV60";
import TransformerToV70 from "./transformers/TransformerToV70";
import TransformerToV71 from "./transformers/TransformerToV71";
import TransformerToV72 from "./transformers/TransformerToV72";

export default class Transformer {

  private transforms: ITransformer[];

  constructor(environment: string, accessToken: string) {
    //The transforms need to be designed and applied in order
    this.transforms = [
      new TransformerToV40(),
      new TransformerToV41(),
      new TransformerToV411(),
      new TransformerToV42(),
      new TransformerToV43(),
      new TransformerToV44(environment, accessToken),
      new TransformerToV45(),
      new TransformerToV46(),
      new TransformerToV47(),
      new TransformerToV48(),
      new TransformerToV49(),
      new TransformerToV50(),
      new TransformerToV51(),
      new TransformerToV52(),
      new TransformerToV53(),
      new TransformerToV54(),
      new TransformerToV55(),
      new TransformerToV56(environment, accessToken),
      new TransformerToV57(), 
      new TransformerToV58(),
      new TransformerToV59(),
      new TransformerToV60(),
      new TransformerToV70(),
      new TransformerToV71(),
      new TransformerToV72()
    ];

  }

  // tslint:disable-next-line:no-any
  public async transform(def: Def, optFromVersion?: number): Promise<void> {

    const fromVersion = def.version || optFromVersion;
    const toVersion = CURRENT_WIDEO_DEF_VERSION;

    if (fromVersion && (toVersion >= fromVersion) && (fromVersion >= 3.8)) {
      for (const transform of this.transforms) {
        if (def.version < transform.toVersion) {

          if (def.class === Class.Wideo) {
            //Logger.debug('Running transform %f on wideo: %s', transform.toVersion, def.id);
            await transform.transformWideo(def);
          } else if (def.class === Class.Scene) {
            //Logger.debug('Running transform %f on scene %s', transform.toVersion, def.id);
            await transform.transformScene(def);
          } else {
            throw new Error('Failed to transform Wideo JSON for def of class ' + def.class);
          }

        }

      }
    } else {
      throw new Error('Failed to transform Wideo JSON from ' + fromVersion + ' to ' + toVersion);
    }
  }
}
