
import {WideoDefVersion, Class} from "../../core/model/WideoDef";
import ITransformer from "../ITransformer";

export default class TransformerToV45 implements ITransformer {
    readonly toVersion: WideoDefVersion = WideoDefVersion.V45;


    // tslint:disable-next-line:no-any
    public transformWideo( wideoDef: any ) {

      //Update version and timestamp
      wideoDef.version = this.toVersion;
      wideoDef.timeStamp = Date.now();

      for (const sceneDef of wideoDef.scenes) {
        this.transformScene(sceneDef);
      }

    }

    // tslint:disable-next-line:no-any
    public transformScene(sceneDef: any): void {
        
      sceneDef.version = this.toVersion;

      for (const wideoObjectDef of sceneDef.objects) {
        this.transformWideoObject(sceneDef, wideoObjectDef);
      }
    }

    // tslint:disable-next-line:no-any
    private transformWideoObject(parentWideoObjectDef: any, wideoObjectDef: any): void {

      if ( wideoObjectDef.class === Class.MaskObject) { //TODO: Dependencies to Editor defined objects, how to handle???
        this.transformMaskObject(parentWideoObjectDef, wideoObjectDef);
      } else {
        for (const innerObjectDef of wideoObjectDef.objects) {
          this.transformWideoObject(wideoObjectDef, innerObjectDef);
        }
      }
    }

    // tslint:disable-next-line:no-any
    private transformMaskObject(parentWideoObjectDef: any, maskObjectDef: any): void {

      // Find the id of the WideoObject that uses this mask
      maskObjectDef.targetId = TransformerToV45.findTargetForMaskId(parentWideoObjectDef, maskObjectDef.id); 

      for (const component of maskObjectDef.components) {
        if (component.class === Class.MaskComponent ) {
          maskObjectDef.width = component.width;
          maskObjectDef.height = component.height;
          maskObjectDef.type = component.type;
        }
      }
      
      // tslint:disable-next-line:no-any
      maskObjectDef.components = maskObjectDef.components.filter( function(component: any){
        return component.class !== Class.MaskComponent;    
      });
    
      
    }

    // tslint:disable-next-line:no-any
    private static findTargetForMaskId(object: any, maskId: string): string {
        
        // Stop criteria, we found the target
        if (object.maskId === maskId) {
            return object.id;
        }

        // Search all children recursively
        let targetId;
        for (const child of object.objects) {
            targetId = TransformerToV45.findTargetForMaskId(child, maskId);
            if (targetId) {
                return targetId;
            }
        }

        // If none of the children was the target return null
        return undefined;
    }
    

}