import Api from './Api';
import OptimizeAssetApi from './OptimizeAssetApi';
import UserAsset from "./model/UserAsset";
import WideoFile from '../common/core/model/WideoFile';
import WideoAssetImage from './model/WideoAssetImage';
import AssetFactory from './model/AssetFactory';
import { videoFileTypes } from "../editor/core/EditorConstants";
import AssetFav from './model/AssetFav';

interface AssetImage {
  assetID: number;
  assetType: string;
  assetName: string;
  userID: number;
  categID: number;
  wideoID?: string;
  assetUrl: string;
  assetAlternativeUrl: string;
  assetAlternativeUrl2: string;
  assetPreviewUrl: string;
  assetUrlSecure: string;
  assetAlternativeUrlSecure: string;
  assetAlternativeUrl2Secure: string;
  assetIDasString: string;
  fav: boolean;
}

export default class AssetsApi extends Api {

  private isSupportedVideo = (name) => {
    return videoFileTypes.some((extension) => {
      return (name.toLowerCase().indexOf('.' + extension) !== -1);
    });
  };

  // tslint:disable-next-line:no-any (when a new version of Wideo API is created we can change any to that type)
  public async saveUserAsset(wideoId: string, inputFile: File | WideoFile): Promise<UserAsset> {

    // Optimize videos (all video mimetypes will be converted to "video/mp4" with standard codec parameters)
    if (this.isSupportedVideo(inputFile.name)) {
      // Upload the optimized asset using and then use normal download flow to optimize and save
      const optimizeAssetApi: OptimizeAssetApi = new OptimizeAssetApi(this._environment);
      const url = await optimizeAssetApi.uploadAssetForOptimization(inputFile);
      return this.download(wideoId, url, inputFile.type);
    }

    const path: string = '/assets';
    const body: FormData = new FormData();
    body.append('asset_file', inputFile, inputFile.name);
    const params: { [s: string]: string; } = {
      accessToken: this._accessToken,
      wideoID: wideoId
    };

    // tslint:disable-next-line:no-any (when a new version of Wideo API is created we can change any to that type)
    const response: any = await this.httpPostFormData<any>(path, body, params);
    return AssetFactory.CreateAsset(
      response.assetType,
      response.assetIDasString,
      response.assetIDasString,
      response.assetUrlSecure,
      response.userID,
      response.assetPreviewUrl,
      null, false);
  }

  public getAssetFavs = async (): Promise<AssetFav[]> => {
    const path: string = '/assets/fav';
    const response: AssetFav[] = await this.httpGetJson<AssetFav[]>(path, { accessToken: this._accessToken });
    return response;
  }

  public async saveAsFav(assetId: string) {
    const path: string = '/assets/fav/' + assetId + '/save';
    const params: { [s: string]: string; } = { accessToken: this._accessToken };
    return this.httpPost<Object>(path, params);
  }

  public async deleteAsFav(assetId: string) {
    const path: string = '/assets/fav/' + assetId + '/delete';
    const params: { [s: string]: string; } = { accessToken: this._accessToken };
    return this.httpPost<Object>(path, params);
  }

  public getAssetShared = async (): Promise<AssetFav[]> => {
    const path: string = '/assets/fav';
    const response: AssetFav[] = await this.httpGetJson<AssetFav[]>(path, { accessToken: this._accessToken });
    return response;
  }

  public async saveAsShared(assetId: string) {
    const path: string = '/assets/fav/' + assetId + '/save';
    const params: { [s: string]: string; } = { accessToken: this._accessToken };
    return this.httpPost<Object>(path, params);
  }

  public async deleteAsShared(assetId: string) {
    const path: string = '/assets/fav/' + assetId + '/delete';
    const params: { [s: string]: string; } = { accessToken: this._accessToken };
    return this.httpPost<Object>(path, params);
  }

  public async getImageUrl(assetId: string): Promise<string> {
    const path: string = '/assets/' + assetId;
    const params: { [s: string]: string; } = {
      accessToken: this._accessToken,
    };

    const asset: AssetImage = await this.httpGetJson<AssetImage>(path, params);
    if (asset.userID === 0) {
      return asset.assetAlternativeUrlSecure;
    } else {
      return asset.assetUrlSecure;
    }

  }

  public async deleteImage(assetId: string): Promise<Object> {
    const path: string = '/assets/' + assetId + '/DELETE';
    const params: { [s: string]: string; } = { accessToken: this._accessToken };
    return this.httpPost<Object>(path, params);
  }

  private async internalDownload(wideoId: string, url: string, mimeType: string): Promise<UserAsset> {
    const path: string = '/assets/download';

    const params: { [s: string]: string; } = {
      wideoID: wideoId,
      accessToken: this._accessToken,
      url: url,
      mimeType: mimeType
    };
    // tslint:disable-next-line:no-any (when a new version of Wideo API is created we can change any to that type)
    const response: any = await this.httpPost<any>(path, params);
    return AssetFactory.CreateAsset(
      response.assetType,
      response.assetIDasString,
      response.assetIDasString,
      response.assetUrlSecure,
      response.userID,
      response.assetPreviewUrl,
      null,
      false);

  }

  public async download(wideoId: string, url: string, mimeType: string): Promise<UserAsset> {
    let urlOptimized = url;
    let mimeTypeOptimized = mimeType
    if (mimeType.startsWith("video/") || this.isSupportedVideo(url)) {
      const optimizeAssetApi: OptimizeAssetApi = new OptimizeAssetApi(this._environment);
      const optimized = await optimizeAssetApi.optimizeAsset(url);
      urlOptimized = optimized.url;
      mimeTypeOptimized = optimized.mimeType
    }
    return this.internalDownload(wideoId, urlOptimized, mimeTypeOptimized);
  }

  public getAnimatedImages = async (): Promise<WideoAssetImage[]> => {
    const path: string = '/assets/animated';
    const response: AssetImage[] = await this.httpGetJson<AssetImage[]>(path, { accessToken: this._accessToken });
    const result: WideoAssetImage[] = [];
    response.map((asset: AssetImage) => {
      result.push(new WideoAssetImage(asset.assetIDasString,
        asset.assetIDasString,
        asset.assetUrlSecure,
        asset.userID.toString(),
        asset.assetPreviewUrl, null, false));
    });

    return result;

  }

}
