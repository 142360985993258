import { v4 as uuid } from 'uuid';

import { filterObjs } from '../../../editor/core/EditorConstants';
import Player from '../../../player/core/Player';
import { hexToInt, intToHex } from '../../ColorUtils';
import MaskObject from '../MaskObject';
import MaskedObject from '../MaskedObject';
import Placeholder from '../Placeholder';
import ShapeObject from '../ShapeObject';
import TextObject from '../TextObject';
import Wideo from '../Wideo';
import WideoFactory from '../WideoFactory';
import WideoObject from '../WideoObject';
import AnimatedGroupComponent from '../components/AnimatedGroupComponent';
import AnimatedImageComponent from '../components/AnimatedImageComponent';
import AudioComponent from '../components/AudioComponent';
import BackgroundComponent from '../components/BackgroundComponent';
import ImageComponent from '../components/ImageComponent';
import ShapeComponent from '../components/ShapeComponent';
import VideoComponent from '../components/VideoComponent';
import { Class, ObjectFit, WideoObjectDef } from '../model/WideoDef';
import WideoDefFactory from '../model/WideoDefFactory';

export default class ReplaceManager {

  /**
   * Get all replaceable WideoObjects, e.g. all WideoObjects that have their name property set. 
   *
   * @param {Player} player
   * @returns {WideoObject[]}
   * @memberof ReplaceManager
   */
 

  public getReplaceableWideoObjects (player: Player): WideoObject[] {
    let replaceableWideoObjects: WideoObject[] = [];
      
    for (const scene of player.getWideo().getScenes()) {
      const objects: WideoObject[] = scene.getWideoObjects();
      objects.forEach((object)=> { this.getReplaceableObjectsRecursively(object, replaceableWideoObjects)})
    }
     
    // filter Placeholder or TextObject objects
    replaceableWideoObjects = replaceableWideoObjects.filter(object => {
      return TextObject.isTextObject(object) || Placeholder.isPlaceholder(object)
    });       
    
    return replaceableWideoObjects;
  }

  private getReplaceableObjectsRecursively(object: WideoObject, result: WideoObject[]): WideoObject[] {
    if(object.getReplaceableName()) {
      result.push(object) 
    }
    else {
      object.getWideoObjects().forEach((_object)=> { this.getReplaceableObjectsRecursively(_object, result)})
    }
    return result
  }  


  /**
   * 
   * @param {Scene} scene the scene where to replace colors
   * @param {number} fromColor the color to be replaced (rgb)
   * @param {number} toColor the color to be applied (rgb)
   */
  public replaceColor(wideo: Wideo, fromColor: number, toColor: number): void {
    for (const scene of wideo.getScenes()) {
      //replace back colors
      const back: BackgroundComponent = scene.getComponentByClass(Class.BackgroundComponent) as BackgroundComponent;

      if (back && back.getColor() === fromColor) {
        back.replaceColor(toColor);
      }

      const objects: WideoObject[] = scene.getWideoObjects();

      this.replaceColorsRecursively(objects, fromColor, toColor);
    }

  }

  private replaceColorsRecursively = (objects: WideoObject[], fromColor: number, toColor: number) => {
    //replace object colors
    for (const object of objects) {
      if (TextObject.isTextObject(object)) {
        if (hexToInt(object.getTextObjectFillColor()) === fromColor) {
          object.setTextObjectFillColor(intToHex(toColor));
        }
      }

      if (ShapeObject.isShapeObject(object)) {
        if (object.getShapeObjectFillColor() === fromColor) {
          object.setShapeObjectFillColor(toColor);
        }
        if (object.getShapeObjectStrokeColor() === fromColor) {
          object.setShapeObjectStrokeColor(toColor);
        }
      }

      if (object.getClass() === Class.ObjectGroup ||
        object.getClass() === Class.Placeholder ||
        object.getClass() === Class.MaskedObject) {
        this.replaceColorsRecursively(object.getWideoObjects(), fromColor, toColor);
      }
    }
  }


  /**
   *
   *
   * @param {TextObject} originalObject The text object with the text to be replaced
   * @param {string} newText the new test
   * @param {Player} player a reference to the player that holds the original object
   * @memberof ReplaceManager
   */
  public replaceText(originalObject: TextObject, newText: string, player: Player) {
    originalObject.fastSetText(newText);
    let newHeight = originalObject.measureTextHeight();
    while (newHeight > originalObject.getHeight()) {
      originalObject.setFontSize(originalObject.getFontSize() - 2);
      newHeight = originalObject.measureTextHeight();
    }
  }

  /**
   *
   *
   * @param {WideoObject} originalObject the Placeholder (or any other replaceable object really) with the object to be replaced
   * @param {string} newSrc the new image source URL
   * @param {Player} player a reference to the player that holds the original object
   * @returns {Promise<WideoObject>}
   * @memberof ReplaceManager
   */
  public async replaceObjectWithSrc(originalObject: WideoObject, newSrc: string, extension: string, player: Player): Promise<WideoObject> {
    let newObjectDef: WideoObjectDef;

    switch (extension.toLowerCase()) {
      case 'jpg':
      case 'jpeg':
      case 'gif':
      case 'png':
      case 'webp':
        newObjectDef = WideoDefFactory.CreateImageWideoObjectDef(0, 0, 0, uuid(), newSrc);
        break;
      case 'mp4':
        newObjectDef = WideoDefFactory.CreateVideoWideoObjectDef(0, 0, uuid(), newSrc);
        break;
      case 'mp3':
        newObjectDef = WideoDefFactory.CreateAudioWideoObjectDef(newSrc);
        break;
      default:
        throw new Error("Not supported file extension: " + extension);
    }
    return this.replaceObject(originalObject, newObjectDef, player);
  }



  /**
   *
   *
   * @param {WideoObject} originalObject the WideoObject to be replaced
   * @param {WideoObjectDef} newObjectDef the new WideoObjectDef to replace the contents of the WideoObject with
   * @param {Player} player a reference to the player that holds the original object
   * @returns {Promise<WideoObject>}
   * @memberof ReplaceManager
   */

  public async replaceObject(originalObject: WideoObject, newObjectDef: WideoObjectDef, player: Player): Promise<WideoObject> {
    // 1. Store original width and height to be able to size the new object equal to the original
    const originalWidth = originalObject.getLocalBounds().width;
    const originalHeight = originalObject.getLocalBounds().height;
    const originalScaleX = originalObject.getAttributes().scaleX;
    const originalScaleY = originalObject.getAttributes().scaleY;

    // 2. Store the original parent and the objects index within the parent
    const parent = originalObject.getParentWideoObject();
    const index = parent.getObjectIndex(originalObject);

    // 3. Create a new object def that is a the merge of the original object and the new objectdef
    const mergedbjectDef: WideoObjectDef = this.cloneAndMerge(originalObject, newObjectDef);
    
    // delete mergedbjectDef.name;
    
    // 4. Create and load the new WideoObject
    const newObject: WideoObject = WideoFactory.CreateWideoObject(player.getWideoContext(), parent, mergedbjectDef);
    await player.getWideoContext().applyAndLoad('ReplaceManager.replaceObject()');

    // 5. Remove the original object
    parent.removeWideoObject(originalObject);
    originalObject.destroy();

    // 6. insert the new object in the same location in the parent
    parent.addWideoObject(newObject);
    parent.setObjectIndex(newObject, index);

    // 7. Update the new object to the current time of the wideo (this might be a problem in Matic, set it to the middle of the lifetime of the object?)
    // player.update(newObject.getGlobalStartTime() + (newObject.getGlobalEndTime() - newObject.getGlobalStartTime()) / 2);

    // 7. Calculate and set the scale in the new object to fit the same area as the original object
    if (MaskedObject.isMaskedObject(newObject)) {
      // If this is a MaskedObject we scale the object inside to cover the entire the mask
      const otherObject = newObject.getOtherObject();
      const maskObject = newObject.getMaskObject();

      // Temporary unapply the mask to get the full size of the other object to be able to scale it to be equal to the
      // mask object.
      maskObject.unapply();

      // Calculate the scale
      const scaleX = (maskObject.getLocalBounds().width * maskObject.getAttributes().scaleX) / otherObject.getLocalBounds().width;
      const scaleY = (maskObject.getLocalBounds().height * maskObject.getAttributes().scaleY) / otherObject.getLocalBounds().height;
      const scale = this.objectFitScale(newObject.getReplaceObjectFit(), scaleX, scaleY);
      // Re-apply the mask again and set the scale
      maskObject.apply();
      otherObject.setAttributes({
        ...otherObject.getAttributes(), ...{
          x: 0,
          y: 0,
          scaleX: scale.scaleX,
          scaleY: scale.scaleY
        }
      });
    } else {
      // If this is another type of object than MaskedObject scale it to fit into the size of
      // the object that it is replacing.
      const scaleX = (originalWidth * originalScaleX) / newObject.getLocalBounds().width;
      const scaleY = (originalHeight * originalScaleY) / newObject.getLocalBounds().height;
      const scale = this.objectFitScale(newObject.getReplaceObjectFit(), scaleX, scaleY);
      newObject.setAttributes({
        ...newObject.getAttributes(), ...{
          scaleX: scale.scaleX,
          scaleY: scale.scaleY
        }
      });
    }

    return newObject;

  }




  private cloneAndMerge(originalObject: WideoObject, replacingObjectDef: WideoObjectDef): WideoObjectDef {

    switch (originalObject.getClass()) {
      case Class.ObjectGroup:
        return this.cloneAndMergeObjectGroup(originalObject, replacingObjectDef);
      case Class.MaskedObject:
        return this.cloneAndMergeMaskedObject(originalObject, replacingObjectDef);
      case Class.Placeholder:
        return this.cloneAndMergePlaceholder(originalObject, replacingObjectDef);
      case Class.ImageObject:
        return this.cloneAndMergeImageObject(originalObject, replacingObjectDef);
      case Class.ShapeObject:
        return this.cloneAndMergeShapeObject(originalObject, replacingObjectDef);
      case Class.AnimatedImageObject:
        return this.cloneAndMergeAnimatedImageObject(originalObject, replacingObjectDef);
      case Class.AnimatedGroupObject:
        return this.cloneAndMergeAnimatedGroupObject(originalObject, replacingObjectDef);
      case Class.VideoObject:
        return this.cloneAndMergeVideoObject(originalObject, replacingObjectDef);
      case Class.AudioObject:
          return this.cloneAndMergeAudioObject(originalObject, replacingObjectDef);
      default:
        throw new Error("Replace is not supported for source WideoObject of class: " + originalObject.getClass() + " id: " + originalObject.getId());
    }

  }

  private cloneAndMergePlaceholder(originalObject: WideoObject, objectDef: WideoObjectDef) {

    // Replace exactly as a MaskedObject 
    const newMaskedObjectDef = this.cloneAndMergeMaskedObject(originalObject, objectDef);

    // Then change the class to MaskedObject before returning
    newMaskedObjectDef.class = Class.MaskedObject;

    return newMaskedObjectDef;
  }

  private cloneAndMergeMaskedObject(originalObject: WideoObject, objectDef: WideoObjectDef) {
    const otherObjects: WideoObject[] = filterObjs((object: WideoObject) => !MaskObject.isMaskObject(object))(originalObject.getWideoObjects());

    if (otherObjects.length === 1) {
      const otherObject = otherObjects[0];
      const newOtherObjectDef = this.cloneAndMerge(otherObject, objectDef);

      originalObject.removeWideoObject(otherObject);
      otherObject.destroy();

      const newMaskedObjectDef = originalObject.serialize();
      newMaskedObjectDef.objects.push(newOtherObjectDef);

      return newMaskedObjectDef;

    } else {
      throw new Error('Trying to replace a MaskedObject with no other object inside it. ObjectId: ' + originalObject.getId());
    }
  }


  private cloneAndMergeAnimatedGroupObject(originalObject: WideoObject, objectDef: WideoObjectDef) {

    // Remove the AnimatedGroupComponent
    const component = originalObject.getComponentByClass(Class.AnimatedGroupComponent) as AnimatedGroupComponent
    originalObject.removeComponent(component);
    component.destroy();

    // Remove all contained WideoObjects
    const removedObjects = originalObject.removeAllWideoObjects();
    removedObjects.forEach((removedObject: WideoObject) => {
      removedObject.destroy();
    });

    // Return the an WideoObjectDef with all the characteristics of this WideoObject but with
    // components and objects from supplied objectDef
    return this.merge(originalObject.serialize(), objectDef);

  }


  private cloneAndMergeAnimatedImageObject(originalObject: WideoObject, objectDef: WideoObjectDef) {
    const componentToReplace = originalObject.getComponentByClass(Class.AnimatedImageComponent) as AnimatedImageComponent;
    originalObject.removeComponent(componentToReplace);
    componentToReplace.destroy();

    return this.merge(originalObject.serialize(), objectDef);
  }


  private cloneAndMergeImageObject(originalObject: WideoObject, objectDef: WideoObjectDef) {
    const componentToReplace = originalObject.getComponentByClass(Class.ImageComponent) as ImageComponent;
    originalObject.removeComponent(componentToReplace);
    componentToReplace.destroy();
    return this.merge(originalObject.serialize(), objectDef);
  }

  private cloneAndMergeObjectGroup(originalObject: WideoObject, objectDef: WideoObjectDef) {

    // Remove all contained WideoObjects
    const removedObjects = originalObject.removeAllWideoObjects();
    removedObjects.forEach((removedObject: WideoObject) => {
      removedObject.destroy();
    });

    return this.merge(originalObject.serialize(), objectDef);
  }

  private cloneAndMergeShapeObject(originalObject: WideoObject, objectDef: WideoObjectDef) {
    const componentToReplace = originalObject.getComponentByClass(Class.ShapeComponent) as ShapeComponent;
    originalObject.removeComponent(componentToReplace);
    componentToReplace.destroy();
    return this.merge(originalObject.serialize(), objectDef);
  }

  private cloneAndMergeVideoObject(originalObject: WideoObject, objectDef: WideoObjectDef) {
    const componentToReplace = originalObject.getComponentByClass(Class.VideoComponent) as VideoComponent;
    originalObject.removeComponent(componentToReplace);
    componentToReplace.destroy();
    return this.merge(originalObject.serialize(), objectDef);
  }

  private cloneAndMergeAudioObject(originalObject: WideoObject, objectDef: WideoObjectDef) {
    const componentToReplace = originalObject.getComponentByClass(Class.AudioComponent) as AudioComponent;
    originalObject.removeComponent(componentToReplace);
    componentToReplace.destroy();
    return this.merge(originalObject.serialize(), objectDef);
  }

  /** Merges an WideoObjectDef into another WideoObjectDef by replacing the class and adding all components and objects */
  private merge(originalObjectDef: WideoObjectDef, replacingObjectDef: WideoObjectDef) {
    originalObjectDef.components.push(...replacingObjectDef.components);
    originalObjectDef.objects.push(...replacingObjectDef.objects);
    originalObjectDef.class = replacingObjectDef.class;
    originalObjectDef.loop = replacingObjectDef.loop;
    return originalObjectDef;
  }


  private objectFitScale(objectFit: ObjectFit, scaleX: number, scaleY: number): { scaleX: number, scaleY: number } {
    let scale = 1;
    switch (objectFit) {
      case ObjectFit.Cover:
        scale = Math.max(Math.abs(scaleX), Math.abs(scaleY))
        return { scaleX: Math.sign(scaleX) * scale, scaleY: Math.sign(scaleY) * scale };

      case ObjectFit.None:
        scale = 1;
        return { scaleX: Math.sign(scaleX) * 1, scaleY: Math.sign(scaleY) * 1 };

      case ObjectFit.Fill:
        return { scaleX: Math.sign(scaleX) * scaleX, scaleY: Math.sign(scaleY) * scaleY }

      case ObjectFit.ScaleDown:
        scale = Math.min(Math.min(Math.abs(scaleX), Math.abs(scaleY)), 1);
        return { scaleX: Math.sign(scaleX) * scale, scaleY: Math.sign(scaleY) * scale };

      case ObjectFit.Contain: // Fall through intentional
      default:
        scale = Math.min(Math.abs(scaleX), Math.abs(scaleY));
        return { scaleX: Math.sign(scaleX) * scale, scaleY: Math.sign(scaleY) * scale };
    }
  }

}