import IAsset from "./IAsset";
import { ACLResourceType } from "./ACLResourceType";

export default abstract class Asset implements IAsset {
  userId: string;
  id: string;
  name: string;
  url: string;
  preview: string;
  duration: number;
  fullWidthPreview: boolean;
  wideoId?: string;
  order?: number;

  constructor(
    id: string,
    name: string,
    url: string,
    userId: string,
    preview: string,
    duration: number,
    fullWidthPreview: boolean,
    wideoId?: string,
    order?: number) {

    this.id = id
    this.name = name
    this.url = url
    this.userId = userId
    this.preview = preview
    this.duration = duration
    this.fullWidthPreview = fullWidthPreview;
    this.wideoId = wideoId;
    this.order = order;
  }

  public abstract getAssetType: (() => string);
  public abstract getACLResourceType: (() => ACLResourceType);
}
