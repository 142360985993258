import UserAsset from "./UserAsset";
import Asset from "./Asset";
import { ACLResourceType } from "./ACLResourceType";

export default class UserAssetVideo extends UserAsset {

  public getAssetType = () => {
    return "userVideos";
  }

  public getACLResourceType = () => {
    return ACLResourceType.ASSET_VIDEO;
  }
}

export const isUserAssetVideo = (asset: Asset): asset is UserAssetVideo => {
  return (asset['getAssetType'] && asset['getAssetType']() === 'userVideos')
}