import WideoObject from './WideoObject';
import { Class } from './model/WideoDef';
import AudioComponent from './components/AudioComponent';

export default class AudioObject extends WideoObject {

  public fadeOut(currentTime: number): void {
    const audioComponent = this.getComponentByClass(Class.AudioComponent) as AudioComponent;
    if (audioComponent) {
      audioComponent.fadeOut(currentTime);
    }
    return null;    
  }
  
  public getUrl(): string {
    return this.getSrc();
  }

  public getSrc(): string {
    const audioComponent = this.getComponentByClass(Class.AudioComponent) as AudioComponent;
    if (audioComponent) {
      return audioComponent.getSrc();
    }
    return null;
  }
  
  public getSoundDurationAsMs(): number {
    const audioComponent = this.getComponentByClass(Class.AudioComponent) as AudioComponent;
    if (audioComponent) {
      return audioComponent.getSoundDurationAsMs();
    }
    return null;
  }
  public setRepeat(repeat: boolean): void {
    const audioComponent = this.getComponentByClass(Class.AudioComponent) as AudioComponent;
    if (audioComponent) {
      audioComponent.setRepeat(repeat);
    }
  }

  public isRepeat(): boolean {
    const audioComponent = this.getComponentByClass(Class.AudioComponent) as AudioComponent;
    if (audioComponent) {
      return audioComponent.isRepeat();
    }
    return null;
  }

  public isFadeOut(): boolean {
    const audioComponent = this.getComponentByClass(Class.AudioComponent) as AudioComponent;
    if (audioComponent) {
      return audioComponent.isFadeOut();
    }
    return null;
  }

  public setFadeOut(fadeOut: boolean) {
    const audioComponent = this.getComponentByClass(Class.AudioComponent) as AudioComponent;
    if (audioComponent) {
      audioComponent.setFadeOut(fadeOut);
    }
  }

  public getVolume(): number {
    const audioComponent = this.getComponentByClass(Class.AudioComponent) as AudioComponent;
    if (audioComponent) {
      return audioComponent.getVolume();
    }
    return null;
  }


  public setVolume(volume: number) {
    const audioComponent = this.getComponentByClass(Class.AudioComponent) as AudioComponent;
    if (audioComponent) {
      audioComponent.setVolume(volume);
    }
  }

  public static isAudioObject = (object: WideoObject): object is AudioObject => {
    return (object && object.getClass() === Class.AudioObject)
  }
}