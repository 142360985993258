import WideoObject from './WideoObject';
import { Class } from './model/WideoDef';
import ImageComponent from './components/ImageComponent';

export default class ImageObject extends WideoObject {

  public getUrl(): string {
    const imageComponent = this.getComponentByClass(Class.ImageComponent) as ImageComponent;
    if (imageComponent) {
      return imageComponent.getSrc();
    }
    return null;
  }
  
  public static isImageObject = (object: WideoObject): object is ImageObject => {
    return (object && object.getClass() === Class.ImageObject)
  }
}