import * as PIXI from 'pixi.js-legacy';

import Tweener from "../animations/Tweener";
import WideoObject from "../WideoObject";
import { TransitionComponentDef, Class, TransitionType, Tween } from '../model/WideoDef';
import WideoContext from '../WideoContext';
import Attributes from '../Attributes';
import AbstractComponent from './AbstractComponent';
import { PendingAssetResource } from '../AssetsLoader';
import TextObject from '../TextObject';
import TextComponent from './TextComponent';
import { resourcesBaseUrl } from '../../../editor/core/EditorConstants';

export default class TransitionComponent extends AbstractComponent {

  protected _type: TransitionType;
  protected _tween: Tween;

  protected _length: number = 0;

  protected _foregroundObject: PIXI.Sprite;

  protected _easingStrategies: Object = {
    [Tween.PopIn]: (elapsed: number): Attributes => { return this.pop(elapsed, 0.05, 0.95, false); },
    [Tween.PopOut]: (elapsed: number): Attributes => { return this.pop(elapsed, 1, -0.95, true); },
    [Tween.EnlargeIn]: (elapsed: number): Attributes => { return this.enlarge(elapsed, 0.05, 0.95); },
    [Tween.EnlargeOut]: (elapsed: number): Attributes => { return this.enlarge(elapsed, 1, -0.95); },
    [Tween.FadeIn]: (elapsed: number): Attributes => { return this.fade(elapsed, 0, 1); },
    [Tween.FadeOut]: (elapsed: number): Attributes => { return this.fade(elapsed, 1, -1); },
    [Tween.SlideLeft]: (elapsed: number): Attributes => { return this.slideLeft(elapsed); },
    [Tween.SlideRight]: (elapsed: number): Attributes => { return this.slideRight(elapsed); },
    [Tween.SlideUp]: (elapsed: number): Attributes => { return this.slideUp(elapsed); },
    [Tween.SlideDown]: (elapsed: number): Attributes => { return this.slideDown(elapsed); },
    [Tween.ZoomIn]: (elapsed: number): Attributes => { return this.zoom(elapsed, 1, -1); },
    [Tween.ZoomOut]: (elapsed: number): Attributes => { return this.zoom(elapsed, 0, 1); },
    [Tween.ScaleOut]: (elapsed: number): Attributes => { return this.scale(elapsed, 1, -1, 1, -1); },
    [Tween.ScaleIn]: (elapsed: number): Attributes => { return this.scale(elapsed, 1, -1, 1, 1); },
    [Tween.HandLeft]: (elapsed: number): Attributes => { return this.handLeft(elapsed); },
    [Tween.HandRight]: (elapsed: number): Attributes => { return this.handRight(elapsed); },
    [Tween.HandUp]: (elapsed: number): Attributes => { return this.handUp(elapsed); },
    [Tween.HandDown]: (elapsed: number): Attributes => { return this.handDown(elapsed); },
    [Tween.AutoType]: (elapsed: number): Attributes => { return this.autoType(elapsed); },
    [Tween.AutoTypeWord]: (elapsed: number): Attributes => { return this.autoTypeWord(elapsed); },

  }

  private _handAnchorPointY: number;

  public constructor(context: WideoContext, owner: WideoObject, def: TransitionComponentDef) {
    super(false);

    this.priority = 3000;

    this._context = context;
    this._owner = owner;

    this._class = def.class;
    this._type = def.type; // Intro/Outro
    this._tween = def.tween;

    this._length = def.length;

    this.initTween();

  }

  private initTween() {

    // The Hand transition gets special treatment since it requires rendering a DisplayObject on top of the Scene
    if (this._tween === Tween.HandLeft ||
      this._tween === Tween.HandRight ||
      this._tween === Tween.HandUp ||
      this._tween === Tween.HandDown) {

      // The hand shall be created only when this transition is an Outro or the owning WideoObject is NOT a Scene
      if (this._type === TransitionType.Outro || this._owner.getClass() !== Class.Scene) {

        this._context.getAssetsLoader().push(
          {
            id: 'TransitionComponent-Hand',
            src: resourcesBaseUrl + "/img/editor/hand.png",
            content: null,
            contentGif: null
          },
          () => {
            this.addContent('TransitionComponent-Hand');
          }
        );

      }
    }
  }

  protected addContent(key: string): void {
    const asset: PendingAssetResource = this._context.getAssetsLoader().getAsset(key);
    const content: PIXI.ILoaderResource = asset.content;

    this._foregroundObject = new PIXI.Sprite(content.texture);
    this._foregroundObject.name = this._owner.getClass() + '-' + this._owner.getId() + '.' + this._class + '-' + this._type + '-' + this._tween;
    this._foregroundObject.visible = false;

    // The hand points with fingers upwards in all cases except for HandUp
    if (this._tween === Tween.HandUp) {
      this._foregroundObject.rotation = Math.PI;
    }

    // The base position of the hand is at the center of the foreground container of the scene
    this._foregroundObject.x = 0;
    this._foregroundObject.y = 0;

    // Anchor point of hand (in the center horizontally and at the knuckels vertically)
    // x = 390 / 650 = 0.6
    // y = 450 / 2250 = 0.2
    this._foregroundObject.anchor = new PIXI.Point(0.6, 0.2) as PIXI.ObservablePoint; //UGLY CAST HACK SINCE THERE IS A BUG IN PIXI TYPE DEFINITIONS

    // Scale of hand (make the height of the hand and arm occupy more or less the whole height of the scene)
    // hand height = 2250, or about 2160 to make it divisible with 1080, 720, 540 etc.
    const scale = 1.5 * this._owner.getScene().getHeight() / 2160;
    this._foregroundObject.scale = new PIXI.ObservablePoint(null, null, scale, scale);

    this._handAnchorPointY = 450 * scale;

    this._owner.getScene().addToForeground(this._foregroundObject);
  }

  serialize(): TransitionComponentDef {
    return {
      class: this._class,
      length: this._length,
      type: this._type,
      tween: this._tween
    }
  }

  public isScaledOrRotated(): boolean {
    switch (this.getTween()) {
      case Tween.PopIn:
      case Tween.PopOut:
      case Tween.EnlargeIn:
      case Tween.EnlargeOut:
      case Tween.ZoomIn:
      case Tween.ZoomOut:
      case Tween.ScaleOut:
      case Tween.ScaleIn:
        return true;
      default:
        return false;
    }
  }

  public getType(): TransitionType {
    return this._type;
  }

  public getTween(): Tween {
    return this._tween;
  }

  public setTween(tween: Tween): void {
    this._tween = tween;
    this.initTween();
  }

  public getStartTime(): number {
    if (this._type === TransitionType.Outro) {
      if (this._owner.getClass() === Class.Scene) {
        return this._owner.getEndTime(); // See time bleeding, Outros on Scenes actually execute after the scene has ended
      } else {
        return this._owner.getEndTime() - this._length;
      }
    } else {
      return this._owner.getStartTime();
    }
  }

  public getEndTime(): number {
    return this.getStartTime() + this.getLength();
  }

  public setLength(length: number) {
    this._length = length;
  }

  public getLength(): number {
    return this._length;
  }

  public enable(): void {
    if (!this._isEnabled) {
      this.addTimeBleeding();
    }

    this._isEnabled = true;
  }

  public disable(): void {
    if (this._isEnabled) {
      this.removeTimeBleeding();
    }

    this._isEnabled = false;
  }

  public beforeUpdate(): void {
    // This is only needed for the Hand case. We need to hide the hand when we are outside
    // of lifetime of the transition.
    if (this._foregroundObject) {
      this._foregroundObject.visible = false;
    }

    // This is a hack for resetting the text of textcomponents to cover the case
    // where we need to reset the text when we are outside the lifetime of both
    // intro and outro
    if (this._tween === Tween.AutoType || this._tween === Tween.AutoTypeWord) {
      const textComponent = this._owner.getComponentByClass(Class.TextComponent) as TextComponent;
      if (textComponent) {
        textComponent.drawText(textComponent.getText());
      }
    }
  }

  public update(elapsedTime: number): Attributes {
    if (this._isEnabled) {
      if (elapsedTime >= this.getStartTime() &&
        elapsedTime <= this.getEndTime()) {

        const localElapsedTime = elapsedTime - this.getStartTime();

        return this._easingStrategies[this._tween](localElapsedTime);
      }
    }
    return null;
  }

  public onAdded(): void {
    if (this._isEnabled) {
      this.addTimeBleeding();
    }
  }

  public onRemoved(): void {
    this.removeTimeBleeding();
  }

  private addTimeBleeding(): void {
    if (this._owner.getClass() === Class.Scene) {
      if (this._type === TransitionType.Outro) {
        this._owner.setTimeBleeding(this._length);
      }
    }
  }

  private removeTimeBleeding(): void {
    if (this._owner.getClass() === Class.Scene) {
      if (this._type === TransitionType.Outro) {
        this._owner.setTimeBleeding(0);
      }
    }
  }

  protected pop(elapsedTime: number, from: number, change: number, inverse: boolean): Attributes {
    let weight: number = 1;
    if (inverse) {
      weight = Tweener.EaseInElastic(from, change, elapsedTime, this._length);
    } else {
      weight = Tweener.EaseOutElastic(from, change, elapsedTime, this._length);
    }
    return new Attributes({
      x: 0,
      y: 0,
      rotation: 0,
      scaleX: weight,
      scaleY: weight,
      alpha: 1
    });
  }

  protected enlarge(elapsedTime: number, from: number, change: number): Attributes {
    const weight = Tweener.EaseOutQuad(from, change, elapsedTime, this._length);
    return new Attributes({
      x: 0,
      y: 0,
      rotation: 0,
      scaleX: weight,
      scaleY: weight,
      alpha: 1
    });
  }

  protected zoom(elapsedTime: number, from: number, change: number): Attributes {
    let localPos: PIXI.Point = new PIXI.Point(this._owner.currentAttributes.x, this._owner.currentAttributes.y);
    const globalPos: PIXI.Point = this._toOwnerParentPoint(localPos);
    const globalBounds = this._toOwnerParentBounds(this._owner.getLocalBounds());

    const targetCorner = new PIXI.Point(
      this._owner.currentAttributes.x < 0 ? 0 : this._owner.getScene().getWidth(),
      this._owner.currentAttributes.y < 0 ? 0 : this._owner.getScene().getHeight());

    const offset: PIXI.Point = new PIXI.Point(targetCorner.x - globalPos.x, targetCorner.y - globalPos.y);

    const weight = Tweener.Linear(from, change, elapsedTime, this._length);

    const scaleWeight = 1 + weight;

    globalPos.x = (offset.x + globalBounds.width * 0.5 * Math.sign(offset.x)) * weight + this._owner.getScene().getWidth() * 0.5;
    globalPos.y = (offset.y + globalBounds.height * 0.5 * Math.sign(offset.y)) * weight + this._owner.getScene().getHeight() * 0.5;

    localPos = this._toOwnerLocalPoint(globalPos);

    return new Attributes({
      x: localPos.x,
      y: localPos.y,
      rotation: 0,
      scaleX: scaleWeight,
      scaleY: scaleWeight,
      alpha: 1
    });
  }

  protected slideUp(elapsedTime: number): Attributes {
    const localPos: PIXI.Point = new PIXI.Point(this._owner.currentAttributes.x, this._owner.currentAttributes.y);
    const globalPos: PIXI.Point = this._toOwnerParentPoint(localPos);
    const globalBounds = this._toOwnerParentBounds(this._owner.getLocalBounds());

    if (this._type === TransitionType.Intro) {
      const heightOffset = this._owner.getScene().getHeight() - globalPos.y + globalBounds.height * 0.5;
      return this.slide(elapsedTime, this._length, 0, heightOffset);
    } else {
      const heightOffset = -globalPos.y - globalBounds.height * 0.5;
      return this.slide(elapsedTime, this._length, 0, heightOffset);
    }
  }

  protected slideDown(elapsedTime: number): Attributes {
    const localPos: PIXI.Point = new PIXI.Point(this._owner.currentAttributes.x, this._owner.currentAttributes.y);
    const globalPos: PIXI.Point = this._toOwnerParentPoint(localPos);
    const globalBounds = this._toOwnerParentBounds(this._owner.getLocalBounds());

    if (this._type === TransitionType.Outro) {
      const heightOffset = this._owner.getScene().getHeight() - globalPos.y + globalBounds.height * 0.5;
      return this.slide(elapsedTime, this._length, 0, heightOffset);
    } else {
      const heightOffset = -globalPos.y - globalBounds.height * 0.5;
      return this.slide(elapsedTime, this._length, 0, heightOffset);
    }
  }

  protected slideLeft(elapsedTime: number): Attributes {
    const localPos: PIXI.Point = new PIXI.Point(this._owner.currentAttributes.x, this._owner.currentAttributes.y);
    const globalPos: PIXI.Point = this._toOwnerParentPoint(localPos);
    const ownerLocalBounds = this._owner.getLocalBounds();
    const globalBounds = this._toOwnerParentBounds(ownerLocalBounds);

    if (this._type === TransitionType.Intro) {
      const widthOffset = this._owner.getScene().getWidth() - globalPos.x + globalBounds.width * 0.5;
      return this.slide(elapsedTime, this._length, widthOffset, 0);
    } else {
      const widthOffset = -globalPos.x - globalBounds.width * 0.5;
      return this.slide(elapsedTime, this._length, widthOffset, 0);
    }
  }

  protected slideRight(elapsedTime: number): Attributes {
    const localPos: PIXI.Point = new PIXI.Point(this._owner.currentAttributes.x, this._owner.currentAttributes.y);
    const globalPos: PIXI.Point = this._toOwnerParentPoint(localPos);
    const globalBounds = this._toOwnerParentBounds(this._owner.getLocalBounds());

    if (this._type === TransitionType.Outro) {
      const widthOffset = this._owner.getScene().getWidth() - globalPos.x + globalBounds.width * 0.5;
      return this.slide(elapsedTime, this._length, widthOffset, 0);
    } else {
      const widthOffset = -globalPos.x - globalBounds.width * 0.5;
      return this.slide(elapsedTime, this._length, widthOffset, 0);
    }
  }

  protected fade(elapsedTime: number, from: number, change: number): Attributes {

    const alpha = Tweener.EaseOutQuad(from, change, elapsedTime, this._length);
    return new Attributes({
      x: 0,
      y: 0,
      rotation: 0,
      scaleX: 1,
      scaleY: 1,
      alpha: alpha
    });
  }

  protected scale(elapsedTime: number, fromAlpha: number, changeAlpha: number, fromScale: number, changeScale: number): Attributes {
    let localPos: PIXI.Point = new PIXI.Point(this._owner.currentAttributes.x, this._owner.currentAttributes.y);
    const globalPos: PIXI.Point = this._toOwnerParentPoint(localPos);
    const globalBounds = this._toOwnerParentBounds(this._owner.getLocalBounds());

    const alpha = Tweener.EaseOutQuad(fromAlpha, changeAlpha, elapsedTime, this._length);
    const scale = Tweener.Linear(fromScale, changeScale, elapsedTime, this._length);

    //update position to scale centered
    globalPos.x = (globalBounds.width * 0.5 - globalBounds.width * 0.5) * scale;
    globalPos.y = (globalBounds.height * 0.5 - globalBounds.height * 0.5) * scale;
    localPos = this._toOwnerLocalPoint(globalPos);

    return new Attributes({
      x: localPos.x,
      y: localPos.y,
      rotation: 0,
      scaleX: scale,
      scaleY: scale,
      alpha: alpha
    });

  }

  protected handLeft(elapsedTime: number): Attributes {
    if (!this._foregroundObject && this._type === TransitionType.Outro) {
      // If the hand texture has not finished loading yet, just return
      return null;
    }

    if (this._owner.getClass() === Class.Scene) {
      return this.handLeftScene(elapsedTime);
    } else {
      return this.handLeftObject(elapsedTime);
    }
  }

  protected handRight(elapsedTime: number): Attributes {
    if (!this._foregroundObject && this._type === TransitionType.Outro) {
      // If the hand texture has not finished loading yet, just return
      return null;
    }

    if (this._owner.getClass() === Class.Scene) {
      return this.handRightScene(elapsedTime);
    } else {
      return this.handRightObject(elapsedTime);
    }
  }

  protected handUp(elapsedTime: number): Attributes {
    if (!this._foregroundObject && this._type === TransitionType.Outro) {
      // If the hand texture has not finished loading yet, just return
      return null;
    }

    if (this._owner.getClass() === Class.Scene) {
      return this.handUpScene(elapsedTime);
    } else {
      return this.handUpObject(elapsedTime);
    }
  }

  protected handDown(elapsedTime: number): Attributes {
    if (!this._foregroundObject && this._type === TransitionType.Outro) {
      // If the hand texture has not finished loading yet, just return
      return null;
    }

    if (this._owner.getClass() === Class.Scene) {
      return this.handDownScene(elapsedTime);
    } else {
      return this.handDownObject(elapsedTime);
    }
  }

  protected handLeftObject(elapsedTime: number): Attributes {
    // NOTE: This transition type assumes no rotation or scale of the Scene
    const localPos: PIXI.Point = new PIXI.Point(this._owner.currentAttributes.x, this._owner.currentAttributes.y);
    const globalBounds = this._toOwnerParentBounds(this._owner.getLocalBounds());
    if (this._foregroundObject) {
      const handGlobalBounds = this._foregroundObject.getLocalBounds();
    
      // Phase 1
      if (elapsedTime <= this._length / 2) {

        this._foregroundObject.visible = true;
        if (this._type === TransitionType.Intro) {
          // Intro - Hand and object enter from left
          const widthOffset = this._owner.getScene().getWidth() / 2 + localPos.x + Math.max(globalBounds.width, handGlobalBounds.width) * 0.5;
          const deltaAttr = this.slide(elapsedTime, this._length / 2, -widthOffset, 0);
          this._foregroundObject.x = localPos.x + deltaAttr.x;
          this._foregroundObject.y = localPos.y + deltaAttr.y;
          return deltaAttr;
        } else {
          // Outro - Hand enters from below 
          const handOffsetY = this._handAnchorPointY + this._owner.getScene().getHeight() / 2 - localPos.y; //(vertical movement)        
          this._foregroundObject.x = localPos.x;
          const tweenedY = Tweener.EaseOutQuad(handOffsetY, -handOffsetY, elapsedTime, this._length / 2);
          this._foregroundObject.y = localPos.y + tweenedY;
          return null;
        }
      }
      // Phase 2 
      else { //if (elapsedTime > this._length / 2) { 

        this._foregroundObject.visible = true;
        if (this._type === TransitionType.Intro) {
          // Hand leaves down
          const handOffsetY = this._handAnchorPointY + this._owner.getScene().getHeight() / 2 - localPos.y; //(vertical movement)        
          const tweenedY = Tweener.EaseOutQuad(0, handOffsetY, elapsedTime - this._length / 2, this._length / 2);
          this._foregroundObject.x = localPos.x;
          this._foregroundObject.y = localPos.y + tweenedY;
          return null;
        }
        else { //if (this._type === TransitionType.Outro) {
          // Hand leaves with object to the left
          const widthOffset = this._owner.getScene().getWidth() / 2 + localPos.x + Math.max(globalBounds.width, handGlobalBounds.width) * 0.5;
          const deltaAttr = this.slide(elapsedTime - this._length / 2, this._length / 2, -widthOffset, 0);
          this._foregroundObject.x = localPos.x + deltaAttr.x;
          this._foregroundObject.y = localPos.y + deltaAttr.y;
          return deltaAttr;
        }
      }
    } else { return null }
  }

  protected handRightObject(elapsedTime: number): Attributes {
    // NOTE: This transition type assumes no rotation or scale of the Scene
    const localPos: PIXI.Point = new PIXI.Point(this._owner.currentAttributes.x, this._owner.currentAttributes.y);
    const globalBounds = this._toOwnerParentBounds(this._owner.getLocalBounds());
    if (this._foregroundObject) {
      
      const handGlobalBounds = this._foregroundObject.getLocalBounds();
    
      // Phase 1
      if (elapsedTime <= this._length / 2) {

        this._foregroundObject.visible = true;
        if (this._type === TransitionType.Intro) {
          // Intro - Hand and object enter from right
          const widthOffset = this._owner.getScene().getWidth() / 2 - localPos.x + Math.max(globalBounds.width, handGlobalBounds.width) * 0.5;
          const deltaAttr = this.slide(elapsedTime, this._length / 2, widthOffset, 0);
          this._foregroundObject.x = localPos.x + deltaAttr.x;
          this._foregroundObject.y = localPos.y + deltaAttr.y;
          return deltaAttr;
        } else {
          // Outro - Hand enters from below 
          const handOffsetY = this._handAnchorPointY + this._owner.getScene().getHeight() / 2 - localPos.y; //(vertical movement)        
          this._foregroundObject.x = localPos.x;
          const tweenedY = Tweener.EaseOutQuad(handOffsetY, -handOffsetY, elapsedTime, this._length / 2);
          this._foregroundObject.y = localPos.y + tweenedY;
          return null;
        }
      }
      // Phase 2 
      else { //if (elapsedTime > this._length / 2) { 

        this._foregroundObject.visible = true;
        if (this._type === TransitionType.Intro) {
          // Hand leaves down
          const handOffsetY = this._handAnchorPointY + this._owner.getScene().getHeight() / 2 - localPos.y; //(vertical movement)        
          const tweenedY = Tweener.EaseOutQuad(0, handOffsetY, elapsedTime - this._length / 2, this._length / 2);
          this._foregroundObject.x = localPos.x;
          this._foregroundObject.y = localPos.y + tweenedY;
          return null;
        }
        else { //if (this._type === TransitionType.Outro) {
          // Hand leaves with object to the right
          const widthOffset = this._owner.getScene().getWidth() / 2 - localPos.x + Math.max(globalBounds.width, handGlobalBounds.width) * 0.5;
          const deltaAttr = this.slide(elapsedTime - this._length / 2, this._length / 2, widthOffset, 0);
          this._foregroundObject.x = localPos.x + deltaAttr.x;
          this._foregroundObject.y = localPos.y + deltaAttr.y;
          return deltaAttr;
        }
      }
    } else { return null }

  }

  protected handUpObject(elapsedTime: number): Attributes {
    // NOTE: This transition type assumes no rotation or scale of the Scene
    const localPos: PIXI.Point = new PIXI.Point(this._owner.currentAttributes.x, this._owner.currentAttributes.y);
    const globalBounds = this._toOwnerParentBounds(this._owner.getLocalBounds());

    if (this._foregroundObject) {
      // Phase 1
      if (elapsedTime <= this._length / 2) {

        this._foregroundObject.visible = true;
        if (this._type === TransitionType.Intro) {
          // Intro - Hand and object enter from top
          const heightOffset = this._owner.getScene().getHeight() / 2 + localPos.y + Math.max(globalBounds.height * 0.5, this._handAnchorPointY);
          const deltaAttr = this.slide(elapsedTime, this._length / 2, 0, -heightOffset);
          this._foregroundObject.x = localPos.x + deltaAttr.x;
          this._foregroundObject.y = localPos.y + deltaAttr.y;
          return deltaAttr;
        } else {
          // Outro - Hand enters from top 
          const handOffsetY = this._handAnchorPointY + this._owner.getScene().getHeight() / 2 + localPos.y; //(vertical movement)        
          const tweenedY = Tweener.EaseOutQuad(-handOffsetY, handOffsetY, elapsedTime, this._length / 2);
          this._foregroundObject.x = localPos.x;
          this._foregroundObject.y = localPos.y + tweenedY;
          return null;
        }
      }
      // Phase 2 
      else { //if (elapsedTime > this._length / 2) { 

        this._foregroundObject.visible = true;
        if (this._type === TransitionType.Intro) {
          // Hand leaves up
          const handOffsetY = this._handAnchorPointY + this._owner.getScene().getHeight() / 2 + localPos.y; //(vertical movement)        
          const tweenedY = Tweener.EaseOutQuad(0, -handOffsetY, elapsedTime - this._length / 2, this._length / 2);
          this._foregroundObject.x = localPos.x;
          this._foregroundObject.y = localPos.y + tweenedY;
          return null;
        }
        else { //if (this._type === TransitionType.Outro) {
          // Hand leaves with object to the top
          const heightOffset = this._owner.getScene().getHeight() / 2 + localPos.y + Math.max(globalBounds.height * 0.5, this._handAnchorPointY);
          const deltaAttr = this.slide(elapsedTime - this._length / 2, this._length / 2, 0, -heightOffset);
          this._foregroundObject.x = localPos.x + deltaAttr.x;
          this._foregroundObject.y = localPos.y + deltaAttr.y;
          return deltaAttr;
        }
      }
    }
    else { return null }
  }

  protected handDownObject(elapsedTime: number): Attributes {
    // NOTE: This transition type assumes no rotation or scale of the Scene
    const localPos: PIXI.Point = new PIXI.Point(this._owner.currentAttributes.x, this._owner.currentAttributes.y);
    const globalBounds = this._toOwnerParentBounds(this._owner.getLocalBounds());
    if (this._foregroundObject) {

      // Phase 1
      if (elapsedTime <= this._length / 2) {

        this._foregroundObject.visible = true;
        if (this._type === TransitionType.Intro) {
          // Intro - Hand and object enter from below
          const heightOffset = this._owner.getScene().getHeight() / 2 - localPos.y + Math.max(globalBounds.height * 0.5, this._handAnchorPointY);
          const deltaAttr = this.slide(elapsedTime, this._length / 2, 0, heightOffset);
          this._foregroundObject.x = localPos.x + deltaAttr.x;
          this._foregroundObject.y = localPos.y + deltaAttr.y;
          return deltaAttr;
        } else {
          // Outro - Hand enters from below 
          const handOffsetY = this._handAnchorPointY + this._owner.getScene().getHeight() / 2 - localPos.y; //(vertical movement)        
          this._foregroundObject.x = localPos.x;
          const tweenedY = Tweener.EaseOutQuad(handOffsetY, -handOffsetY, elapsedTime, this._length / 2);
          this._foregroundObject.y = localPos.y + tweenedY;
          return null;
        }
      }
      // Phase 2 
      else { //if (elapsedTime > this._length / 2) { 

        this._foregroundObject.visible = true;
        if (this._type === TransitionType.Intro) {
          // Hand leaves down
          const handOffsetY = this._handAnchorPointY + this._owner.getScene().getHeight() / 2 - localPos.y; //(vertical movement)        
          const tweenedY = Tweener.EaseOutQuad(0, handOffsetY, elapsedTime - this._length / 2, this._length / 2);
          this._foregroundObject.x = localPos.x;
          this._foregroundObject.y = localPos.y + tweenedY;
          return null;
        }
        else { //if (this._type === TransitionType.Outro) {
          // Hand leaves with object to below
          const heightOffset = this._owner.getScene().getHeight() / 2 - localPos.y + Math.max(globalBounds.height * 0.5, this._handAnchorPointY);
          const deltaAttr = this.slide(elapsedTime - this._length / 2, this._length / 2, 0, heightOffset);
          this._foregroundObject.x = localPos.x + deltaAttr.x;
          this._foregroundObject.y = localPos.y + deltaAttr.y;
          return deltaAttr;
        }
      }
    } else { return null }
  }

  protected handLeftScene(elapsedTime: number): Attributes {
    if (this._foregroundObject || this._type === TransitionType.Intro) {

      // Phase 1 - Hand enters from below
      if (elapsedTime <= this._length / 2) {
        if (this._foregroundObject) {
          this._foregroundObject.visible = true;
          const handOffsetY = this._handAnchorPointY + this._owner.getScene().getHeight() / 2; //(vertical movement)        
          this._foregroundObject.y = Tweener.EaseOutQuad(handOffsetY, -handOffsetY, elapsedTime, this._length / 2);
        }
        return null;
      }
      // Phase 2 - Scene slides to the left (with the hand)
      else { //if (elapsedTime > this._length / 2) { 
        if (this._foregroundObject) {
          this._foregroundObject.visible = true;
        }
        const widthOffset = (this._type === TransitionType.Intro ? this._owner.getScene().getWidth() : -this._owner.getScene().getWidth());
        return this.slide(elapsedTime - this._length / 2, this._length / 2, widthOffset, 0);
      }
    } else { return null }
  }

  protected handRightScene(elapsedTime: number): Attributes {
    if (this._foregroundObject || this._type === TransitionType.Intro) {

      // Phase 1 - Hand enters from below
      if (elapsedTime <= this._length / 2) {
        if (this._foregroundObject) {
          this._foregroundObject.visible = true;
          const handOffsetY = this._handAnchorPointY + this._owner.getScene().getHeight() / 2; //(vertical movement)        
          this._foregroundObject.y = Tweener.EaseOutQuad(handOffsetY, -handOffsetY, elapsedTime, this._length / 2);
        }
        return null;
      }
      // Phase 2 - Scene slides to the left (with the hand)
      else { //if (elapsedTime > this._length / 2) { 
        if (this._foregroundObject) {
          this._foregroundObject.visible = true;
        }
        const widthOffset = (this._type === TransitionType.Intro ? -this._owner.getScene().getWidth() : this._owner.getScene().getWidth());
        return this.slide(elapsedTime - this._length / 2, this._length / 2, widthOffset, 0);
      }
    } else { return null }
  }

  protected handUpScene(elapsedTime: number): Attributes {
    if (this._foregroundObject || this._type === TransitionType.Intro) {

      // Phase 1 - Hand enters from above
      if (elapsedTime <= this._length / 2) {
        if (this._foregroundObject) {
          this._foregroundObject.visible = true;
          const handOffsetY = this._handAnchorPointY + this._owner.getScene().getHeight() / 2;
          this._foregroundObject.y = Tweener.EaseOutQuad(-handOffsetY, handOffsetY, elapsedTime, this._length / 2);
        }
        return null;
      }
      // Phase 2 - Scene slides up (with the hand)
      else { //if (elapsedTime > this._length / 2) { 
        if (this._foregroundObject) {
          this._foregroundObject.visible = true;
        }
        const heightOffset = (this._type === TransitionType.Intro ? this._owner.getScene().getHeight() : -this._owner.getScene().getHeight());
        return this.slide(elapsedTime - this._length / 2, this._length / 2, 0, heightOffset);
      }
    } else { return null }
  }

  protected handDownScene(elapsedTime: number): Attributes {
    if (this._foregroundObject || this._type === TransitionType.Intro) {

      // Phase 1 - Hand enters from below
      if (elapsedTime <= this._length / 2) {
        if (this._foregroundObject) {
          this._foregroundObject.visible = true;
          const handOffsetY = this._handAnchorPointY + this._owner.getScene().getHeight() / 2;
          this._foregroundObject.y = Tweener.EaseOutQuad(handOffsetY, -handOffsetY, elapsedTime, this._length / 2);
        }
        return null;
      }
      // Phase 2 - Scene slides down (with the hand)
      else { //if (elapsedTime > this._length / 2) { 
        if (this._foregroundObject) {
          this._foregroundObject.visible = true;
        }
        const heightOffset = (this._type === TransitionType.Intro ? -this._owner.getScene().getHeight() : this._owner.getScene().getHeight());
        return this.slide(elapsedTime - this._length / 2, this._length / 2, 0, heightOffset);
      }
    } else { return null }
  }


  public destroy(): void {
    if (this._foregroundObject) {
      this._foregroundObject.destroy();
      // this._foregroundObject = null;  
    }
    // this._context = null;
    // this._owner = null;
    super.destroy();
  }

  // For internal use only
  private _toOwnerParentPoint(localPoint: PIXI.Point): PIXI.Point {
    const parentWideoObject: WideoObject = this._owner.getParentWideoObject();
    if (parentWideoObject) {
      return parentWideoObject.toLocalPoint(localPoint, this._owner, false) as PIXI.Point;
    } else {
      // If there is no parent this is the Scene, return scene coordinates
      return localPoint.clone();
    }
  }

  private _toOwnerParentBounds(localBounds: PIXI.Rectangle): PIXI.Rectangle {
    const parentWideoObject: WideoObject = this._owner.getParentWideoObject();
    if (parentWideoObject) {
      return parentWideoObject.toLocalBounds(localBounds, this._owner, false);
    } else {
      // If there is no parent this is the Scene, return scene coordinates
      return localBounds.clone();
    }
  }

  private _toOwnerLocalPoint(point: PIXI.Point): PIXI.Point {
    const parentWideoObject: WideoObject = this._owner.getParentWideoObject();
    if (parentWideoObject) {
      return this._owner.toLocalPoint(point, this._owner.getParentWideoObject());
    } else {
      // If there is no parent this is the Scene, return scene coordinates
      return point.clone();
    }

  }

  private slide(elapsedTime: number, length: number, offsetX: number, offsetY: number): Attributes {

    const weight = Tweener.EaseOutQuad(0, 1, elapsedTime, length);

    let accX: number;
    let accY: number;
    if (this._type === TransitionType.Intro) {
      accX = offsetX * (1 - weight);
      accY = offsetY * (1 - weight);
    }
    else {
      accX = offsetX * weight;
      accY = offsetY * weight;
    }

    // Rounding to a integer coordinates here is a fix to To-Do: https://3.basecamp.com/3459151/buckets/3812629/todos/3591818163. 
    // On the cost of maybe slightly less fluid animation we get a pixel perfect cut between scenes in slide transitions
    // The problem that two adjacent shapes dont completly touch is only visible in the canvas renderer
    // Using WebGL this fix is not necessary.
    if (this._context.getRenderer().type === PIXI.RENDERER_TYPE.CANVAS) {
      accX = Math.round(accX);
      accY = Math.round(accY);
    }

    return new Attributes({
      x: accX,
      y: accY,
      rotation: 0,
      scaleX: 1,
      scaleY: 1,
      alpha: 1
    });
  }

  private autoType(elapsedTime: number): Attributes {

    // Side effect (change text temporarily)
    const textComponent = (this._owner as TextObject).getComponentByClass(Class.TextComponent) as TextComponent;
    const tween = Tweener.EaseOutQuad(0, 1, elapsedTime, this._length);
    const completeText = textComponent.getText();
    const completeTextLength = completeText.length;

    let partialText: string;
    let partialTextLength: number;
    if (this._type === TransitionType.Intro) {
      partialTextLength = Math.ceil(completeTextLength * tween);
      partialText = completeText.substring(0, partialTextLength);
    } else {
      partialTextLength = Math.floor(completeTextLength * (1 - tween));
      partialText = completeText.substring(0, partialTextLength);
    }
    // To avoid that one word jumps from one line to the next during transitions we measure to make
    // sure that the whole word fits before adding part of a word to the text
    const restOfWord = completeText.substring(partialTextLength, completeText.length).split(/\s/, 1)[0];
    const partialTextFullWord = partialText + restOfWord;
    const partialHeight = textComponent.measureTextHeight(partialText);
    const partialFullWordHeight = textComponent.measureTextHeight(partialTextFullWord);
    if (partialFullWordHeight > partialHeight) {
      const indexOfLastSpace = partialText.lastIndexOf(' ');
      partialText = partialText.substr(0, indexOfLastSpace) + '\n' + partialText.substr(indexOfLastSpace + '\n'.length);
    }
    textComponent.drawText(partialText);

    // No change of the "normal" attributes
    return null;
  }

  private autoTypeWord(elapsedTime: number): Attributes {

    // Side effect (change text temporarily)
    const textComponent = (this._owner as TextObject).getComponentByClass(Class.TextComponent) as TextComponent;
    const tween = Tweener.EaseOutQuad(0, 1, elapsedTime, this._length);
    const completeText = textComponent.getText();
    const words: string[] = completeText.split(' ');

    if (this._type === TransitionType.Intro) {
      const noOfWords = Math.ceil(words.length * tween);
      const partialText = words.slice(0, noOfWords).join(' ');
      textComponent.drawText(partialText);
    } else {
      const noOfWords = Math.floor(words.length * (1 - tween));
      const partialText = words.slice(0, noOfWords).join(' ');
      textComponent.drawText(partialText);
    }

    // No change of the "normal" attributes
    return null;
  }
}


