import {WideoDefVersion, Class} from "../../core/model/WideoDef";
import ITransformer from "../ITransformer";
import {defaultTextLineHeightFactor} from '../../../editor/core/EditorConstants';

export default class TransformerToV40 implements ITransformer {
    readonly toVersion: WideoDefVersion = WideoDefVersion.V41;

    constructor() {
      // Intentionally blank
    }
/*
          {
            "id": 'text-text-1',
            "class": Class.TextComponent,
            "text": "An animated text, An animated text, An animated text, An animated text, other text",
            "width": 1920,
            "height": 36,
            "brightness": 0.5,
            "style": {
              "align": "left",
              "fontSize": 30,
              "fontWeight": "bold",
              "fontFamily": "Helvetica_v2",
              "fontStyle": "normal",
              "lineHeight": 36,
              "wordWrap": true,
              "wordWrapWidth": 600,
              "breakWords": true,
              "fill": "#ff0099"
            }

  ----->

        {
          "id": 'text-text-1',
          "class": Class.TextComponent,
          "text": "An animated text, An animated text, An animated text, An animated text, other text",
          "width": 1920,
          "height": 36,
          "brightness": 0.5,
          "style": {
            "align": "left",
            "fontSize": 30,
            "fontWeight": "bold",
            "fontFamily": "Helvetica_v2",
            "fontStyle": "normal",
            //"lineHeight": 36, <--- DELETE (is handled by lineHeightFactor instead)
            "lineHeightFactor": 1.2, // <--- lineHeightFactor = lineHeight / fontSize
            //"wordWrap": true,  <--- DELETE (is true by default)
            //"wordWrapWidth": 600,  <--- DELETE (is handled by width of the textComponent (avoid redundancy))
            //"breakWords": true,  <--- DELETE (is true by default)
            "fill": "#ff0099"
          }

  */

    // tslint:disable-next-line:no-any
    public transformWideo( wideoDef: any ): void {

      //Update version and timestamp
      wideoDef.version = this.toVersion;
      wideoDef.timeStamp = Date.now();

      for (const sceneDef of wideoDef.scenes) {
        this.transformScene(sceneDef);
      }

    }

    // tslint:disable-next-line:no-any
    transformScene(sceneDef: any): void {
      
      //Update version 
      sceneDef.version = this.toVersion;

      for (const wideoObjectDef of sceneDef.objects) {
        this.transformWideoObject(wideoObjectDef);
      }
    }

    // tslint:disable-next-line:no-any
    transformWideoObject(wideoObjectDef: any): void {

      if ( wideoObjectDef.class === 'textObject') { //TODO: Dependencies to Editor defined objects, how to handle???
        this.transformTextObject(wideoObjectDef);
      } else {
        for (const innerObjectDef of wideoObjectDef.objects) {
          this.transformWideoObject(innerObjectDef);
        }
      }
    }

    // tslint:disable-next-line:no-any
    transformTextObject(textObjectDef: any): void {
      for (const component of textObjectDef.components) {
        if (component.class === Class.TextComponent ) {
          this.transformTextComponent(component);
        }
      }
    }

    // tslint:disable-next-line:no-any
    transformTextComponent(textComponentDef: any): void {

      if (typeof textComponentDef.style.lineHeight !== 'undefined') {
        if (typeof textComponentDef.style.fontSize !== 'undefined') {
          textComponentDef.style.lineHeightFactor = textComponentDef.style.lineHeight / textComponentDef.style.fontSize;
        } else {
          textComponentDef.style.lineHeightFactor = defaultTextLineHeightFactor;
        }
        delete textComponentDef.style.lineHeight;
      }
      if (typeof textComponentDef.style.breakWords !== 'undefined') {
        delete textComponentDef.style.breakWords;
      }
      if (typeof textComponentDef.style.wordWrap !== 'undefined') {
        delete textComponentDef.style.wordWrap;
      }
      if (typeof textComponentDef.style.wordWrapWidth !== 'undefined') {
        delete textComponentDef.style.wordWrapWidth;
      }
    }

}
