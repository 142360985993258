import { v4 as uuid } from 'uuid';

import * as Sentry from '@sentry/browser';

export interface ILogger {
  debug(...x: any[]): void; // tslint:disable-line:no-any
  info(...x: any[]): void; // tslint:disable-line:no-any
  log(...x: any[]): void; // tslint:disable-line:no-any
  warn(...x: any[]): void; // tslint:disable-line:no-any
  error(...x: any[]): void; // tslint:disable-line:no-any
  time(timerName: string): void; // tslint:disable-line:no-any
  timeEnd(timerName: string): void // tslint:disable-line:no-any
}

export default class Logger {

  private static _instance = new Logger();

  private _loggingSessionId: string = uuid();
  public static getLoggingSessionId(): string {
    return this._instance._loggingSessionId;
  }
  
  public static debug(...x: any[]): void { // tslint:disable-line:no-any
    Logger._instance.debug(...x);
  }
  public static info(...x: any[]): void { // tslint:disable-line:no-any
    Logger._instance.info(...x);
  }
  public static log(...x: any[]): void { // tslint:disable-line:no-any
    Logger._instance.log(...x);
  }
  public static warn(...x: any[]): void { // tslint:disable-line:no-any
    Logger._instance.warn(...x);
  }
  public static error(...x: any[]): void { // tslint:disable-line:no-any
    Logger._instance.error(...x);
  }
  public static time(timerName: string) {
    Logger._instance.time(timerName);
  }
  public static timeEnd(timerName: string) {
    Logger._instance.timeEnd(timerName);
  }
  public static leaveBreadcrumb(message: string, metadata?: object) {
    Logger._instance.leaveBreadcrumb(message, metadata);
  }

  public debug(...x: any[]): void { // tslint:disable-line:no-any
    console.debug(...x); // tslint:disable-line:no-console
  }
  public info(...x: any[]): void { // tslint:disable-line:no-any
    console.info(...x); // tslint:disable-line:no-console
  }
  public log(...x: any[]): void { // tslint:disable-line:no-any
    console.log(...x); // tslint:disable-line:no-console
  }
  public warn(...x: any[]): void { // tslint:disable-line:no-any
    console.warn(...x); // tslint:disable-line:no-console
  }
  public error(...x: any[]): void { // tslint:disable-line:no-any
    console.error(...x); // tslint:disable-line:no-console
  }
  public time(timerName: string) {
    console.time(timerName); // tslint:disable-line:no-console
  }
  public timeEnd(timerName: string) {
    console.timeEnd(timerName); // tslint:disable-line:no-console
  }

  public leaveBreadcrumb(message: string, metadata?: object) {
    Sentry.addBreadcrumb({
      message: message,
      level: 'info',
      data: metadata
    });
  }
  

  public static errorToString(error: Error) {
    const errorObj = {
      message: (error.message ? error.message : null),
      name: (error.name ? error.name : null),
      stack: (error.stack ? error.stack : null),
      description: (error['description'] ? error['description'] : null),
      number: (error['number'] ? error['number'] : null),
      fileName: (error['fileName'] ? error['fileName'] : null),
      lineNumber: (error['lineNumber'] ? error['lineNumber'] : null),
      columnNumber:(error['columnNumber'] ? error['columnNumber'] : null),
    };
    return JSON.stringify(errorObj);
  }

}
