/**
 * Created by mdavids on 31/10/2017.
 */
import * as PIXI from 'pixi.js-legacy';
import WideoObject from "../WideoObject";
import { MaskComponentDef, MaskType } from '../model/WideoDef';
import * as ShapeUtils from '../ShapeUtils';
import WideoContext from '../WideoContext';
import Attributes from "../Attributes";
import AbstractComponent from './AbstractComponent'

export default class MaskComponent extends AbstractComponent {
  readonly _type: MaskType;
  protected _width: number;
  protected _height: number;

  protected _localMask: PIXI.Graphics; // Transient

  constructor(context: WideoContext, owner: WideoObject, def: MaskComponentDef) {
    super(true, "MaskComponent");
    this.priority = 0;
    this._context = context;
    this._owner = owner;
    this._class = def.class;
    this._type = def.type;
    this._width = def.width;
    this._height = def.height;
    this._localMask = new PIXI.Graphics();

    this.drawMask();

    this._owner.getChildAt(0).mask = this._localMask as any;

  }

  getDisplayObject(): PIXI.DisplayObject {
    return this._localMask;
  }

  serialize(): MaskComponentDef {
    return {
      class: this._class,
      type: this._type,
      width: this._width,
      height: this._height
    }
  }

  public getWidth(): number {
    return this._width;
  }

  public setWidthAndHeight(width: number, height: number) {
    this._width = width;
    this._height = height;
    this.drawMask();
  }

  public setWidth(width: number) {
    this._width = width;
    this.drawMask();
  }

  public getHeight(): number {
    return this._height;
  }

  public setHeight(height: number): void {
    this._height = height;
    this.drawMask();
  }

  public getType(): MaskType {
    return this._type;
  }

  public getMask(): PIXI.Graphics {
    return this._localMask;
  }

  private drawMask(): void {
    this._localMask.cacheAsBitmap = false;
    this._localMask.clear();
    this._localMask.name = this._class + '-' + this._owner.getId();
    this._localMask.beginFill(0xffffff, 1);
    this._localMask.lineStyle(0, 0, 0);

    ShapeUtils.drawShape(this._localMask, ShapeUtils.maskTypeToShapeType(this._type), this._width, this._height);

    // Center graphics to the component container
    this._localMask.x = -this._localMask.width * 0.5;
    this._localMask.y = -this._localMask.height * 0.5;

    this._localMask.endFill();
    this._localMask.cacheAsBitmap = true;
  }

  public enable(): void {
    this._owner.getChildAt(0).mask = this._localMask as any;
    this._localMask.visible = true; // Explicitly set the Mask to be visible

    this._isEnabled = true;
  }

  public disable(): void {
    this._owner.getChildAt(0).mask = null;
    this._localMask.visible = false; // Explicitly set the Mask to be invisible

    this._isEnabled = false;
  }

  public getLocalBounds(rect?: PIXI.Rectangle): PIXI.Rectangle {
    return this._localMask.getLocalBounds(rect);
  }

  public update(deltaTime: number): Attributes {
    return null;
  }

  public destroy(): void {
    if (this._localMask) {
      this._localMask.destroy();
    }
    super.destroy();
  }

}
