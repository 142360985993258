import WideoDefFactory from "../../../common/core/model/WideoDefFactory";
import {
  Class,
  WideoObjectDef,
  ShapeType,
  ComponentDef
} from "../../../common/core/model/WideoDef";

import { v4 as uuid } from 'uuid';

export default class EditorDefFactory {

  public static CreateShapeObjectDef(x: number, y: number, endTime: number, shapeType: ShapeType,
    width?: number, height?: number, borderColor?: string, borderAlpha?: number, fillColor?: string,
    fillAlpha?: number, borderThickness?: number,
    scaleX?: number, scaleY?: number, rotation?: number, startTime?: number, hidden?: boolean, id?: string, maskId?: string): WideoObjectDef {

    return {
      id: id ? id : uuid(),
      class: Class.ShapeObject,
      startTime: startTime ? startTime : 0,
      endTime: endTime,
      attributes: {
        x: x,
        y: y,
        scaleX: scaleX ? scaleX : 1,
        scaleY: scaleY ? scaleY : 1,
        rotation: rotation ? rotation : 0,
        alpha: 1, //alpha of shape components applies in the component, not in wideoObject
      },
      components: [
        WideoDefFactory.CreateShapeComponentDef(shapeType, width, height, borderColor, borderAlpha, fillColor, fillAlpha, borderThickness)
      ],
      objects: [],
      hidden: hidden ? hidden : false,
      maskId: maskId ? maskId : undefined,
      locked: false
    };
  }


  public static CreateAnimatedImageWideoObjectDef(x: number, y: number, endTime: number,
    assetId: string, assetUrl: string, scaleX?: number, scaleY?: number,
    rotation?: number, alpha?: number, startTime?: number, hidden?: boolean, id?: string): WideoObjectDef {
    return {
      id: id ? id : uuid(),
      class: Class.AnimatedImageObject,
      startTime: startTime ? startTime : 0,
      endTime: endTime,
      attributes: {
        x: x,
        y: y,
        scaleX: scaleX ? scaleX : 1,
        scaleY: scaleY ? scaleY : 1,
        rotation: rotation ? rotation : 0,
        alpha: alpha ? alpha : 1,
      },
      components: [
        WideoDefFactory.CreateAnimatedImageComponentDef(assetId, assetUrl)
      ],
      objects: [],
      hidden: hidden ? hidden : false,
      locked: false
    };
  }

  public static createWideoObjectDefFromComponentAndObject(clazz: Class, componentDef: ComponentDef, endTime: number, objectDef: WideoObjectDef, loop: boolean): WideoObjectDef {
    const def: WideoObjectDef = {
      id: uuid(),
      class: clazz,
      startTime: 0,
      endTime: endTime,
      attributes: {
        x: 0,
        y: 0,
        scaleX: 1,
        scaleY: 1,
        rotation: 0,
        alpha: 1
      },
      components: [
        componentDef
      ],
      objects: objectDef ? [ objectDef ] : [],
      hidden: false,
      locked: false,
      loop: loop
    };
    return def;
  }



}
