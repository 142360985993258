import ConcurrentQueue from './ConcurrentQueue';
import Logger from '../../common/log/Logger';
import { ObjectCannedACL } from '@aws-sdk/client-s3';

const defaultAccessKeyId = 'AKIAZSJTG4QMVJW2QUSU';
const defaultBucket = 'encoder.wideo.co';
const defaultRegion = 'us-east-1';

export default class S3Uploader {

  private queue: ConcurrentQueue = new ConcurrentQueue(5);
  private s3Key: string;
  private bucket: string = defaultBucket;
  private accessKeyId: string = defaultAccessKeyId;

  constructor(s3KeyParam: string, accessKeyIdParam?: string, bucketParam?: string) {
    this.s3Key = s3KeyParam;

    if (accessKeyIdParam) {
      this.accessKeyId = accessKeyIdParam;
    }

    if (bucketParam) {
      this.bucket = bucketParam;
    }

  }

  private constructS3Instance = async (AWS_SDK_CLIENTS3) => {
    return new AWS_SDK_CLIENTS3.S3Client(
      {
        credentials: {
          accessKeyId: this.accessKeyId,
          secretAccessKey: this.s3Key
        },
        region: defaultRegion,
        maxAttempts: 3
        //retryStrategy: 
        //retryDelayOptions: {base: 1000}, // Retries on 1000 ms, 2000 ms and 4000 ms 
      });

  }

  public uploadToS3 = async (arrayBuffer: ArrayBuffer, key: string, contentType: string): Promise<void> => {
    Logger.log("Enqueueing frame: " + key);
    return this.queue.enqueue(async () => {
      Logger.log("Start upload frame: " + key);
      await this._uploadToS3(arrayBuffer, key, contentType);
      Logger.log("Finished uploading frame: " + key);
    });
  }

  private _uploadToS3 = async (arrayBuffer: ArrayBuffer, key: string, contentType: string): Promise<void> => {

    const AWS_SDK_CLIENTS3 = await import("@aws-sdk/client-s3");

    const uploadParams = {
      Bucket: this.bucket,
      Key: key,
      Body: new Blob([arrayBuffer]),
      ACL: ObjectCannedACL.private,
      ContentType: contentType
    };
    try {
      const s3Client = await this.constructS3Instance(AWS_SDK_CLIENTS3);
      await s3Client.send(new AWS_SDK_CLIENTS3.PutObjectCommand(uploadParams));
    } catch (error) {
      if (error.name === "RequestTimeTooSkewed") {
        const now = Date.now();
        const serverTime = Date.parse(error.ServerTime);
        const newOffset = (serverTime - now);
        const s3Client = await this.constructS3Instance(AWS_SDK_CLIENTS3);
        s3Client.config.systemClockOffset = newOffset;
        try {
          console.warn("Client clock drift detected, offset " + newOffset);
          await s3Client.send(new AWS_SDK_CLIENTS3.PutObjectCommand(uploadParams));
        } catch (error) {
          Logger.error("Manual retry with clock skew correction failed. " + JSON.stringify(error));
          throw error;
        }
      } else {
        throw error;
      }
    }
  }

}