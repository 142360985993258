export interface BrandConfig {
  /** The base URL of the brand (Scheme, domain, path, search params) */
  baseUrl: string;
  /** The host of the client (can be custom domain or a wideo subdomain) */
  host: string;
  /** A unique id of the brand (URL Safe!) */
  brandId: string;
  /** A human readable name of the brand */
  brandName: string;
  /** The logo of the client, will be shown in header and signin/up */
  logo: string;
  /** Favicon for browser tabs */
  icon: string;
  /** Used to set the brand colors */
  primaryColor: string;
  secondaryColor: string;
  helpUrl: string;
}

interface WebUrlConfig {
  "base": string;
  "login": string;
  "myProfile": string;
  "templates": string;
  "view": string;
  "edit": string;
  "embed": string;
  "download": string;
  "youtube": string;
  "facebook": string;
  "hubspot": string;
}
export interface EnvironmentConfig {
  apiUrl: string;
  webUrl: WebUrlConfig;
  editorUrl: string;
  amplitudeKey: string;
  intercomKey: string;
  automationApiUrl: string;
  googleAnalyticsUA: string;
  region: string;
  identityPoolId: string;
  assetUploadsBucket: string;
  optimizeAssetApiUrl: string;
  enqueueUrl: string;
  maticUrl: string;
  brandConfig?: BrandConfig;
}

interface Environments {
  whitelabel?: EnvironmentConfig;
  local: EnvironmentConfig;
  develepe: EnvironmentConfig;
  prod: EnvironmentConfig;
}

export const environments: Environments = {
  local: {
    apiUrl: 'http://dev.wideo.co:8082/wideoo-api-rest',
    webUrl: {
      base: "http://dev.wideo.co:8080",
      login: "/login",
      myProfile: "/account/myprofile",
      templates: "/video-templates",
      view: "/view",
      edit: "/edit",
      embed: "/embed",
      download: "/download",
      youtube: "/share/youtube/petition",
      facebook: "/share/facebook/petition",
      hubspot: "/share/hubspot/petition"
    },
    editorUrl: 'http://dev.wideo.co:3000/editor',
    maticUrl: 'https://editordev.wideo.co/matic',
    amplitudeKey: '6054ea1522e279b22b01fd8bb77fae46',
    intercomKey: 'uwg0gbbj',
    automationApiUrl: 'https://automationapi.dev.wideo.co',
    googleAnalyticsUA: 'G-V78E9N3JMF',
    region: "us-east-1",
    identityPoolId: 'us-east-1:601f8d87-b37d-4f13-80c7-c755109b851f',
    assetUploadsBucket: 'dev.asset-uploads.wideo.co',
    optimizeAssetApiUrl: 'wss://eys4pyr3ik.execute-api.us-east-1.amazonaws.com/dev',
    enqueueUrl: 'https://mhsk8lbcmh.execute-api.us-east-1.amazonaws.com/dev/batch/enqueue'
  },
  develepe: {
    apiUrl: 'https://develepe.wideo.co/wideoo-api-rest',
    webUrl: {
      base: "https://develepe.wideo.co",
      login: "/login",
      myProfile: "/account/myprofile",
      templates: "/video-templates",
      view: "/view",
      edit: "/edit",
      embed: "/embed",
      download: "/download",
      youtube: "/share/youtube/petition",
      facebook: "/share/facebook/petition",
      hubspot: "/share/hubspot/petition"
    },
    editorUrl: 'https://editordev.wideo.co/editor',
    maticUrl: 'https://editordev.wideo.co/matic',
    amplitudeKey: '6054ea1522e279b22b01fd8bb77fae46',
    intercomKey: 'uwg0gbbj',
    automationApiUrl: 'https://automationapi.dev.wideo.co',
    googleAnalyticsUA: 'G-V78E9N3JMF',
    region: "us-east-1",
    identityPoolId: 'us-east-1:601f8d87-b37d-4f13-80c7-c755109b851f',
    assetUploadsBucket: 'dev.asset-uploads.wideo.co',
    optimizeAssetApiUrl: 'wss://eys4pyr3ik.execute-api.us-east-1.amazonaws.com/dev',
    enqueueUrl: 'https://mhsk8lbcmh.execute-api.us-east-1.amazonaws.com/dev/batch/enqueue'
  },
  prod: {
    apiUrl: 'https://papi.wideo.co',
    webUrl: {
      base: "https://app.wideo.co",
      login: "/login",
      myProfile: "/account/myprofile",
      templates: "/video-templates",
      view: "/view",
      edit: "/edit",
      embed: "/embed",
      download: "/download",
      youtube: "/share/youtube/petition",
      facebook: "/share/facebook/petition",
      hubspot: "/share/hubspot/petition"
    },    
    editorUrl: 'https://editor.wideo.co/editor',
    maticUrl: 'https://editor.wideo.co/matic',
    amplitudeKey: '8b9fe25b9c9fcc062243e6b95b0964a6',
    intercomKey: 'mte3ekjy',
    automationApiUrl: 'https://automationapi.wideo.co',
    googleAnalyticsUA: 'G-K579ZYENM7',
    region: "us-east-1",
    identityPoolId: 'us-east-1:2f8cb59b-0753-4782-a590-89c4d0e2d1fd',
    assetUploadsBucket: 'asset-uploads.wideo.co',
    optimizeAssetApiUrl: 'wss://f5f6jfzs34.execute-api.us-east-1.amazonaws.com/prod',
    enqueueUrl: 'https://09bcoidb07.execute-api.us-east-1.amazonaws.com/prod/batch/enqueue'
  }
}
