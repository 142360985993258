import { WideoDefVersion } from "../../core/model/WideoDef";
import ITransformer from "../ITransformer";

export default class TransformerToV54 implements ITransformer {
  readonly toVersion: WideoDefVersion = WideoDefVersion.V54;

  constructor() {
    // Intentionally blank
  }

  // tslint:disable-next-line:no-any
  public transformWideo(wideoDef: any): void {

    //Update version and timestamp
    wideoDef.version = this.toVersion;
    wideoDef.timeStamp = Date.now();

    for (const sceneDef of wideoDef.scenes) {
      this.transformScene(sceneDef);
    }

    for (const audioDef of wideoDef.audioComponents) {
      this.transformAudio(audioDef);
    }
  }

  // tslint:disable-next-line:no-any
  transformAudio(audioDef: any): void {
    audioDef.startTime = 0; // default to start wideo
    audioDef.endTime = null; // null means all audio length
    audioDef.fadeOut = true; //fadeout by default
  }

  // tslint:disable-next-line:no-any
  transformScene(sceneDef: any): void {

    //Update version 
    sceneDef.version = this.toVersion;

  }

}
