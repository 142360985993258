import * as React from 'react';

import Player from '../../player/core/Player';
import { PlayState } from '../../player/core/PlayerConstants';
import IPlayerQueryParams from './IPlayerQueryParams';
import { WithClock, withClock } from '../../common/ui/WithClock';

interface Props {
    player: Player;
    playState: PlayState;
    seekedTime: number;
    queryParams: IPlayerQueryParams;
    currentTime: number;
    fps: number;
}

/**
 * PlayerDebug shows debug information about the currently running PlayerApp
 */
class PlayerDebug extends React.Component<Props & WithClock> {

  render() {
        return (
                    <div className="debug">
                        <p>
                            PlayState: {this.props.playState}
                        </p>
                        <p>
                            CurrentTime: {  Math.floor(this.props.currentTime) } ms<br/>
                            FPS: { Math.ceil(this.props.player.getFps()) } frames/s
                        </p>
                        <p>
                            Query string parameters:
                         </p>
                            <pre>
                                {JSON.stringify(this.props.queryParams, null, '  ')}
                            </pre>
                        <p>
                            Wideo State:
                        </p>
                        <pre>
                            {
                                this.props.player.getWideo() &&
                                JSON.stringify( this.props.player.getWideo().serialize() , null, '  ')
                            }
                        </pre>
                    </div>
        );
  }

}

export default withClock(PlayerDebug);
