import WideoObject from "./WideoObject";
import MaskedObject from './MaskedObject';
import { Class } from "./model/WideoDef";

export default class Placeholder extends MaskedObject {

  public static isPlaceholder = (object: WideoObject): object is Placeholder => {
    return (object && object.getClass() === Class.Placeholder);
  }

}

