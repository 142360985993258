import * as React from 'react';
import { Theme } from '@mui/material/styles';

import { blue } from '@mui/material/colors';

import Box from '@mui/material/Box';
import { Rnd, ResizeEnable, DraggableData } from 'react-rnd';
import KeyFrame from '../../../../common/core/animations/KeyFrame';
import injectStyleKeyframeAnimation from '../utils/injectStyleKeyframeAnimation';
import Editor from '../../../core/Editor';
import Moment from 'react-moment';
import { 
  //resourcesBaseUrl, 
  timelineOffsetWidth } from "../../../core/EditorConstants";
import { scrollToElement } from "../utils/ScrollTo";
import theme from 'src/common/ui/theme';

import CircleIcon from '@mui/icons-material/Circle';

import { BrandContext } from 'src/common/ui/BrandContextProvider';

export const getBackgroundColor = (theme: Theme) => ({
  backgroundColor: theme.palette.common.white,
});

const keyframesStyle = `
        @-webkit-keyframes hvr-push {
          50% {
            -webkit-transform: scale(0.8);
            transform: scale(0.8);
          }
          100% {
            -webkit-transform: scale(1);
            transform: scale(1);
          }
        }
    `;

injectStyleKeyframeAnimation(keyframesStyle);

const styles = {
  keyframe_indicator: {
    position: 'absolute' as 'absolute',
    top: '20px',
    zIndex: 6,
    filter: 'drop-shadow(0 0 2px rgba(0; 0; 0; 0.3))',
    width: '12px',
  },
  enableAnimation: {
    '&:hover': {
      '-webkit-animation-name': 'hvr-push',
      'animation-name': 'hvr-push',
      '-webkit-animation-duration': '.4s',
      'animation-duration': '.4s',
      '-webkit-animation-timing-function': 'ease-in-out',
      'animation-timing-function': 'ease-in-out',
      '-webkit-animation-iteration-count': '1',
      'animation-iteration-count': '1',
    },
  },
  keyframe_indicator_inner_head: {
    height: '15px',
    width: '15px',
    left: '-9px',
    position: 'absolute' as 'absolute',
    top: '-20px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '0 0',
    ...getBackgroundColor(theme),
    display: 'inline-block',
    transition: 'all 0.4s ease',
    border: '3px solid #414141',
    borderRadius: '50%',
    '&:hover': {
      //'backgroundImage': 'url(' + resourcesBaseUrl + '/img/editor/circle-fill-selected.svg)!important',
    },
    cursor: 'pointer'
    }
};

interface Props {
  keyFrame: KeyFrame;
  time: number;
  deltaTime: number;
  durationScene: number;
  onClick: (e: MouseEvent, keyFrame: KeyFrame, seek: Number, selected: boolean) => void;
  onContextMenu: (keyFrame: KeyFrame, element: HTMLElement) => void;
  onDrag: (e: MouseEvent, deltaTime: number) => void;
  selected: boolean;
  editor: Editor;
}

interface State {
  isDragging: boolean,
  currentTime: number;
}

type PropsWithStyles = Props;

/**
 * KeyFrameMarker displays a KeyFrame marker over the timeline
 */
class KeyFrameMarker extends React.Component<PropsWithStyles, State>  {


  constructor(props: PropsWithStyles) {
    super(props);
    this.state = {
      isDragging: false,
      currentTime: props.time
    };
  }

  static contextType = BrandContext;
  context: React.ContextType<typeof BrandContext>;    

  private toPx = (ms: number) => {
    const px = (this.handleResizeTimelineWidth() * ms) / this.props.durationScene;
    return px;
  };

  toMs = (px: number) => {
    return px * this.props.durationScene / this.handleResizeTimelineWidth();
  }

  onContextMenu = (event: React.MouseEvent<HTMLElement>) => {
    this.props.onContextMenu(this.props.keyFrame, event.currentTarget);
    event.stopPropagation();
    event.preventDefault();
  }

  getIndicatorIcon = (): React.ReactNode => { 
    const brandConfig = this.context;
    const primaryColor = brandConfig?.primaryColor ?? blue['A200'];

    return this.props.selected ? <CircleIcon htmlColor={primaryColor} sx={{ position: 'absolute', top: -2, right: -2, width: '0.79em', height: '0.79em' }} /> : null;
  };  

  componentDidMount() {
    this.handleResizeTimelineWidth();
    window.addEventListener('resize', this.handleResizeTimelineWidth.bind(this));
  }

  handleResizeTimelineWidth() {
    return window.innerWidth - timelineOffsetWidth;
  }

  handleSetObjectKeyFrameTime(event: MouseEvent, keyFrame: KeyFrame, deltaTime: number) {
    const selection = this.props.editor.getSelection();
    const keyframes = selection.getSelectedKeyFrames();


    if(deltaTime === 1 && !event.shiftKey) {
      selection.setSelectedKeyFrame(keyFrame);
      return
    }

    if (selection.getSelectedKeyFramesCount() > 0) {
      keyframes.forEach((item) => {
        this.props.editor.setObjectKeyFrameTime(
          selection.getSelectedObject(),
          item,
          item.getTime() + deltaTime
        ); 
      });
    }
  }

  enableResizing(): ResizeEnable {
    return {
      top: false,
      right: false,
      bottom: false,
      left: false,
      topRight: false,
      bottomRight: false,
      bottomLeft: false,
      topLeft: false
    }
  }

  render() {
    const { time, deltaTime, selected } = this.props;
    const _deltaTime = selected ? deltaTime : 0
    const positionX = this.toPx(time + _deltaTime + 1)

    return (
      <Rnd
        key={time}
        style={styles.keyframe_indicator}
        enableResizing={this.enableResizing()}
        dragAxis='x'
        bounds="parent"
        position={{ x: positionX, y: 20 }}
        onDrag={(e: MouseEvent, d: DraggableData) => {
          const newTime = this.toMs(d.x)
          this.setState({ currentTime: newTime })
          this.props.onDrag(e, newTime - time)
        }}
        onDragStart={(e: MouseEvent, d: DraggableData) => {
          const seek = 0
          this.props.onClick(e, this.props.keyFrame, seek, selected)
          this.setState({ isDragging: true })
        }}
        onDragStop={(e: MouseEvent, d: DraggableData) => {
          const seek = 1
          const newTime = this.toMs(d.x)
          this.handleSetObjectKeyFrameTime(e, this.props.keyFrame, newTime - time)
          this.props.onClick(e, this.props.keyFrame, seek, selected)
          this.setState({ isDragging: false })
          this.props.editor.fireShowObjectAttributesChangeEvent()
          setTimeout(() => scrollToElement('keyframeProperties'), 500)
        }}
        cancel='.MuiPopover-root'
      >
        {this.state.isDragging &&
          <Box
            bgcolor="grey.800"
            color="common.white"
            border={1}
            borderColor="common.white"
            borderRadius={1}
            px={0.5}
            position="absolute"
            bottom={40}
            left={"-20px"}
            zIndex="tooltip"
          >
            <Moment date={this.state.currentTime} format="s.SS" />
          </Box>
        }
        <Box
          position="relative"
          left={0}
          bgcolor="#414141"
          width={'3px'}
          height={16}
        >
          <div 
            onContextMenu={this.onContextMenu}
            className='timelineIndicator'
            style={{ 
              ...styles.keyframe_indicator_inner_head, 
              ...(this.state.isDragging ? {} : styles.enableAnimation)
            }}
          >
            {this.getIndicatorIcon()}
          </div>
        </Box>
      </Rnd>
    );
  }

}

export default KeyFrameMarker;

