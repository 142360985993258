import Api from './Api';

export default class UsersApi extends Api {

    /**
     * get all info of user by userID from 2/users/me
     */
    // tslint:disable-next-line:no-any (when a new version of Wideo API is created we can change any to that type)
    async getAll(): Promise<any> {
        const path: string = '/users/me';
        // tslint:disable-next-line:no-any (when a new version of Wideo API is created we can change any to that type)
        return this.httpGetJson<any>(path, { accessToken: this._accessToken });
    }

    /**
   * get all info of user by userID from 3/users/me
   */
    // tslint:disable-next-line:no-any (when a new version of Wideo API is created we can change any to that type)
    async getAll_V3(): Promise<any> {
        const path: string = '/users/me';
        // tslint:disable-next-line:no-any (when a new version of Wideo API is created we can change any to that type)
        return this.httpGetJson<any>(path, { accessToken: this._accessToken, includeStyles: "true", includeBackgrounds: "true", includeAudios: "true", includeWideoos: "true" });
    }

}
