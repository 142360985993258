import { environments } from "src/common/Env";
import UsersApi from "./UsersApi";

export interface GetReplaceInfoResponse {
    statusCode: number,
    body: { [key: string]: string }
}

export interface SaveReplaceInfoResponse {
    statusCode: number,
    body: { id: string }
}

export default class AutomationApi {

    enqueueUrl: string
    apiUrl: string
    environment: string

    constructor(environment: string) {
        this.environment = environment
        this.enqueueUrl = environments[environment].enqueueUrl
        this.apiUrl = environments[environment].apiUrl
    }

    async invoke(accessToken: string, parameters: Object): Promise<Object> {
        const body = await this.generateAutomationBody(parameters, accessToken);
        return fetch(this.enqueueUrl,
            { method: "POST", body: JSON.stringify(body), headers: {'x-api-key': accessToken }})
            .then(async function (response: Response) {
                if (response.ok) {
                    const jsonResponse = await response.json()
                    jsonResponse["userEmail"] = body.feedback.email
                    return jsonResponse;
                }
                throw new Error('Automation error.');
            })
    }

    async generateAutomationBody(parameters: Object, accessToken: string) {
        const userApi = new UsersApi(this.environment,accessToken)
        const userObject = await userApi.getAll()
        parameters["download"] = {
            fileName: "wideo-matic"
        }
        const body = {
            clientId: 'Matic',
            feedback: {
                email: userObject.user.email,
            },
            videos: [parameters]
        }
        return body

    }
}