import WideoDef, {
  Class,
  SceneDef,
  WideoObjectDef
} from '../../../common/core/model/WideoDef';

export default class WideoValidator {

  public validateNoOfObjects(def: WideoDef | SceneDef, clazz: string, noOfNewObjects: number, maxNoOfObjects: number): boolean {
    // Always allow when no new objects are added
    if (noOfNewObjects === 0) {
      return true;
    }
    
    let noOfObjects = 0;
    if (def.class === Class.Wideo) {
      noOfObjects = this.countObjectsInWideo(def as WideoDef, clazz);
    } else {
      noOfObjects = this.countObjectsInWideoObject(def as WideoObjectDef, clazz);
    }
    return (noOfObjects + noOfNewObjects <= maxNoOfObjects);
  }

  public countObjectsInWideo(wideoDef: WideoDef, clazz: string): number {
    return wideoDef.scenes.map((sceneDef: SceneDef) => {
      return this.countObjects(sceneDef, clazz);
    }).reduce(this.sum, 0);
  }
  public countObjectsInWideoObject(objectDef: WideoObjectDef, clazz: string): number {
    return this.countObjects(objectDef, clazz);
  }

  private sum = (accumulator: number, currentValue: number) => accumulator + currentValue;
  
  private countObjects = ( (objectDef: WideoObjectDef | SceneDef, clazz: string): number => {
    let noOfObjects: number = objectDef.objects.map( (child: WideoObjectDef) => {
      return this.countObjects(child, clazz);
    }).reduce(this.sum, 0);
    
    if (objectDef.class === clazz) {
      noOfObjects++;
    }
    return noOfObjects;
  });

}