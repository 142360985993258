/**
 * Created by mdavids on 13/11/2017.
 */
import * as PIXI from 'pixi.js-legacy';
import ImageComponent from "./ImageComponent";
import WideoObject from "../WideoObject";
import Rectangle = PIXI.Rectangle;
import { BackgroundComponentDef } from '../model/WideoDef';
import WideoContext from '../WideoContext';
import Scene from '../Scene';

export default class BackgroundComponent extends ImageComponent {

  protected _color: number;
  protected _graphics: PIXI.Graphics;

  protected _area: Rectangle;

  protected _keepRatio: boolean = false;

  constructor(context: WideoContext, owner: WideoObject, def: BackgroundComponentDef) {
    super(context, owner, def);
    this.priority = 1;
    this._keepRatio = def.keepRatio;

    this._color = def.color ? parseInt(def.color, 16) : 0xffffff;

    this.updateAreaBounds();

    this.drawPlainColor();

  }

  public serialize(): BackgroundComponentDef {
    let imageComponentDef: BackgroundComponentDef = super.serialize() as BackgroundComponentDef;

    if (this._src) {
      imageComponentDef = Object.assign(imageComponentDef, {
        keepRatio: this._keepRatio
      });
    }
    else {
      imageComponentDef = Object.assign(imageComponentDef, {
        color: "0x" + ("00000" + this._color.toString(16)).slice(-6), // hex string format 0xRRGGBB
      });
    }

    return imageComponentDef;
  }

  protected updateAreaBounds(): void {
    const owner = this._owner as Scene;
    this._area = new Rectangle(-owner.getWidth() * 0.5, -owner.getHeight() * 0.5, owner.getWidth(), owner.getHeight());

    if (this._sprite) {
      this._sprite.x = this._area.x;
      this._sprite.y = this._area.y;

      const scaleX = this._area.width / this._sprite.texture.width;
      const scaleY =  this._area.height / this._sprite.texture.height;
      const scale = Math.max(scaleX, scaleY);
      
      if (this._keepRatio) {
        this._sprite.width = this._sprite.texture.width * scale;
        this._sprite.height = this._sprite.texture.height * scale;
        this._sprite.x -= this._sprite.width * 0.5 - this._area.width * 0.5;
        this._sprite.y -= this._sprite.height * 0.5 - this._area.height * 0.5;
      } else {
        this._sprite.width = this._area.width;
        this._sprite.height = this._area.height;
      }
    }
  }


  protected addContent(key: string): void {
    super.addContent(key);
    this.updateAreaBounds();
  }

  protected drawPlainColor(): void {
    if (this._graphics) {
      this._graphics.destroy();
      this._graphics = null;
    }

    this._graphics = new PIXI.Graphics();
    this._graphics.name = 'SolidBackgroundGraphics';
    this._displayObject.addChildAt(this._graphics, 0);

    this._graphics.clear();
    this._graphics.beginFill(this._color, 1);
    this._graphics.drawRect(this._area.x, this._area.y, this._area.width, this._area.height);
    this._graphics.endFill();
  }

  public enable(): void {
    super.enable();
    this._graphics.visible = true;
  }

  public disable(): void {
    super.disable();
    this._graphics.visible = false;
  }

  public getColor(): number {
    return this._color;
  }

  public replaceColor(color: number) {
    this._color = color;
    this.redraw();
  }

  public destroy(): void {
    if (this._graphics) {
      this._graphics.destroy();
    }

    if (this._area) {
      this._area = null;
    }
  }

  public redraw(): void {
    this.updateAreaBounds();
    this.drawPlainColor();
  }

}
