import * as React from 'react'


import { styled } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Switch, { SwitchPropsSizeOverrides } from '@mui/material/Switch';

import { WithTranslation, withTranslation } from 'react-i18next';
//import { getBackgroundMainColor } from '../dialogs/FontDialog';
//import theme from 'src/common/ui/theme';

import { OverridableStringUnion } from '@mui/types';

interface Props {
  label?: string,
  value: string | boolean,
  checked: boolean,
  labelColor?: string 
  disabled?: boolean
  bgColor?: string,
  variant?: string,
  activeColor?: string,
  labelRight?: string,
  size?: OverridableStringUnion<"small" | "medium", SwitchPropsSizeOverrides>, 
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
}

interface State {
}

const CustomSwitch = styled(Switch)(({ theme, disabled, style, size }) => ({
  width: size === 'medium' ? 42 : 28,
  height: size === 'medium' ? 26 : 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: size === 'medium' ? 24 : 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: size === 'medium' ? 0 : 2,
    margin: size === 'medium' ? 2 : 0,
    backgroundColor: 'transparent!important',    
    '&.Mui-checked': {
      transform: `${size === 'medium' ? 'translateX(17px)' : 'translateX(12px)'}`,
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#4285F4',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: size === 'medium' ? 22 : 12,
    height: size === 'medium' ? 22 : 12,
    borderRadius: size === 'medium' ? 12 : 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: size === 'medium' ? 26 / 2 : 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark' ? '#bdbdbd' : style ? style.backgroundColor : '#bdbdbd',
    boxSizing: 'border-box',
    border: `1px solid ${theme.palette.common.white}`,
  },
}));


type PropsWithStyles = Props & WithTranslation;

//type PropsWithStyles = Props & WithTranslation & WithStyles<''>

class SwitchButton extends React.Component<PropsWithStyles, State>{

  renderLabel = (label: string, checked: boolean) => {
    const { activeColor, labelColor } = this.props
    const _labelColor = labelColor ?? "common.white"


    return (
      <Box color={checked && !labelColor ? activeColor : _labelColor}>
        <Typography
          variant="caption"
          color="inherit">
          {label}
        </Typography>
      </Box>
    )
  }

  render() {
    const { label, labelRight, checked, disabled, bgColor, size } = this.props;

    return (
      <Box width="100%">
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          {label &&
            <Box pr={1}>
              {this.renderLabel(label, checked)}
            </Box>
          }
          <Box pr={0.5}>
            <CustomSwitch
              size={size ?? 'small'}
              checked={checked}
              onChange={this.props.handleChange}
              value={this.props.value} 
              disabled={disabled ?? false}
              style={{backgroundColor: bgColor ?? '#bdbdbd'}}
              />
          </Box>
          {labelRight &&
            <Box pr={2}>
              {this.renderLabel(labelRight, !checked)}
            </Box>
          }
        </Box>
      </Box>
    );
  }
}

export default withTranslation("leftbar")(SwitchButton);

