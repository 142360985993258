import Attributes from '../Attributes';
import { KeyFrameDef } from '../model/WideoDef';
import Tweener, { EasingFunction } from './Tweener';
import { Easing } from '../model/WideoDef';

const easing2Function = {
  [Easing.Linear]: Tweener.Linear,
  [Easing.QuadraticIn]: Tweener.EaseInQuad,
  [Easing.QuadraticOut]: Tweener.EaseOutQuad,
  [Easing.QuadraticInOut]: Tweener.EaseInOutQuad,
  [Easing.ElasticIn]: Tweener.EaseInElastic,
  [Easing.ElasticOut]: Tweener.EaseOutElastic
}

const function2Easing = {  
  [Tweener.Linear.name]: Easing.Linear,
  [Tweener.EaseInQuad.name]: Easing.QuadraticIn,
  [Tweener.EaseOutQuad.name]: Easing.QuadraticOut,
  [Tweener.EaseInOutQuad.name]: Easing.QuadraticInOut,
  [Tweener.EaseInElastic.name]: Easing.ElasticIn,
  [Tweener.EaseOutElastic.name]: Easing.ElasticOut
}

const DEFAULT_EASING: EasingFunction = Tweener.EaseOutQuad;

export default class KeyFrame {

  private _id: string;
  private _time: number = 0;
  private _easing: EasingFunction;
  private _attributes: Attributes;

  constructor(keyFrame: KeyFrameDef) {
    this._id = keyFrame.id;
    this._time = keyFrame.time;
    // No easing set in the KeyFrameDef means we need to use the default easing
    // This is to save space in the JSON
    this._easing = (keyFrame.easing ? easing2Function[keyFrame.easing] : DEFAULT_EASING);
    this._attributes = new Attributes(keyFrame.attributes);
  }

  public serialize(): KeyFrameDef {
    const keyFrameDef: KeyFrameDef = {
      id: this._id,
      attributes: this._attributes.serialize(),
      time: this._time
    };
    if (this._easing.name !== DEFAULT_EASING.name) {
      keyFrameDef.easing = function2Easing[this._easing.name];
    }
    return keyFrameDef;
  }

  public getAttributes(): Attributes {
    return this._attributes;
  }

  public setAttributes(attributes: Attributes): void {
    this._attributes.x = attributes.x;
    this._attributes.y = attributes.y;
    this._attributes.scaleX = attributes.scaleX;
    this._attributes.scaleY = attributes.scaleY;
    this._attributes.rotation = attributes.rotation;
    this._attributes.alpha = attributes.alpha;
  }

  public getId(): string {
    return this._id;
  }

  public getTime(): number {
    return this._time;
  }

  public setTime(time: number): void {
    this._time = time;
  }

  public getEasing(): EasingFunction {
    return this._easing;
  }

  public setEasing(easing: Easing): void {
    this._easing = (easing ? easing2Function[easing] : DEFAULT_EASING);
  }

  public applyEasing(from: number, change: number, currentTime: number, endTime: number): number {
    return this._easing(from, change, currentTime, endTime);
  }

  public destroy(): void {
    this._time = 0;
    //this._attributes = undefined; // In object list we have references to this after it has been destroyed. Why?
  }
}
