import { WideoDefVersion, Class} from "../../core/model/WideoDef";
import ITransformer from "../ITransformer";
import Logger from "../../log/Logger";
import { v4 as uuid } from 'uuid';

export default class TransformerToV58 implements ITransformer {
  readonly toVersion: WideoDefVersion = WideoDefVersion.V58;

  // tslint:disable-next-line:no-any
  public async transformWideo(wideoDef: any): Promise<void> {

    //Update version and timestamp                             
    wideoDef.version = this.toVersion;
    wideoDef.timeStamp = Date.now();

    // tslint:disable-next-line:no-any
    await Promise.all(wideoDef.scenes.map( async (sceneDef: any) => {       
      return this.transformScene(sceneDef);
    }));

  }

  // tslint:disable-next-line:no-any
  transformScene(sceneDef: any): void {

    //Update version 
    sceneDef.version = this.toVersion;

    for (const objectDef of sceneDef.objects) {

      if (objectDef.class === Class.MaskedObject ||
          objectDef.class === Class.Placeholder ) { 
        this.transformPlaceholderOrMaskedObject(objectDef);
      } else {
        this.transformObject(objectDef);
      }
    }
  }

  // tslint:disable-next-line:no-any
  transformObject(objectDef: any): void {
    for (const childObjectDef of objectDef.objects) {

      if (childObjectDef.class === Class.MaskedObject ||
          childObjectDef.class === Class.Placeholder ) { 

        this.transformPlaceholderOrMaskedObject(childObjectDef);

      } else {

        this.transformObject(childObjectDef);

      }
    }

  }

  // tslint:disable-next-line:no-any
  transformPlaceholderOrMaskedObject(objectDef: any): void {
    // tslint:disable-next-line:no-any
    let maskObject: any;
    // tslint:disable-next-line:no-any
    let otherObject: any;
    
    for (const childObjectDef of objectDef.objects) {
      if (childObjectDef.class === Class.MaskObject) {
        maskObject = childObjectDef;
      } else {
        otherObject = childObjectDef;
      }
    }

    if (maskObject && otherObject) {
      maskObject.id = uuid();
      otherObject.id = uuid();
      maskObject.targetId = otherObject.id;
      otherObject.maskId = maskObject.id;
    } else {
      Logger.warn("Placeholder or MaskedObject without mask or other object: %o", objectDef);
    }


  }

}