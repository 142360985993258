import EncoderApi from '../api/EncoderApi';
import Part from '../api/model/Part';
import Logger from '../../common/log/Logger';
import Encoder, { ImageFormat } from '../core/Encoder';
import EncoderClientListener from '../core/EncoderClientListener';
import { EncoderClientState } from '../core/EncoderClientListener';

/** The encoder client is responsible for on regular intervals request jobs/parts
    to encode from the video encoder server. */
export default class EncoderClient {

  private encoderId: string;
  private waitPeriod: number = 5000;
  private errorWaitPeriod: number = 30000;
  private environment: string;
  private accessToken: string;
  private imageFormat: ImageFormat = ImageFormat.jpg;
  private forceCanvas: boolean;
  private antialias: boolean;
  private encoder: Encoder;
  private api: EncoderApi = null;
  private supervisorWorker: Worker = null;
  private listeners: EncoderClientListener[] = [];
  private canvas: HTMLCanvasElement;
  private jsonUrl: string;

  public init = (encoderId: string, baseUrl: string, environment: string, accessToken: string, pingPort: number, imageFormat: ImageFormat, forceCanvas: boolean, antialias: boolean, jsonUrl: string) => {
    this.encoderId = encoderId;
    this.environment = environment;
    this.accessToken = accessToken;
    this.imageFormat = imageFormat;
    this.forceCanvas = forceCanvas;
    this.antialias = antialias;
    this.api = new EncoderApi(baseUrl);
    this.jsonUrl = jsonUrl;

    this.canvas = document.createElement('canvas');

    // If browser supports web workers and we were started by a process supervisor
    if (Worker && pingPort) {
      this.supervisorWorker = new Worker("/enc/supervisor-worker.js");
      this.supervisorWorker.addEventListener('error', (event) => {
        Logger.error("onerror: ", JSON.stringify(event));
      });
      this.supervisorWorker.postMessage(
        {
          type: 'init',
          endpoint: 'ws://localhost:' + pingPort + '/supervise/websocket'
        }
      );
    }

  }

  public run = async (): Promise<void> => {

    // Request a new job / part from the encoder server

    try {
      const part = await this.api.requestJob(this.encoderId, 'waiting');

      if (part) {
        this.fireStateChanged(EncoderClientState.Loading);
        this.fireNewPartReceived(part);
        await this.handlePart(part);
        this.fireNewPartReceived(null);
        //Request next part imediately
        if (!this.supervisorWorker) {
          window.setTimeout(this.run, 0);
        }

      } else {
        this.fireStateChanged(EncoderClientState.Waiting);
        // There was no part to process
        this.fireNewPartReceived(null);
        if (this.supervisorWorker) {
          this.supervisorWorker.postMessage(
            {
              type: 'newPart',
              processId: '',
              partId: ''
            });
        }

        window.setTimeout(this.run, this.waitPeriod);
      }
    }
    catch (error) {
      Logger.error("There was an error trying to get a new Part to process: " + Logger.errorToString(error));
      this.fireStateChanged(EncoderClientState.Error, error);
      //Retry in 30 seconds
      window.setTimeout(this.run, this.errorWaitPeriod);
    }
  }

  private handlePart = async (part: Part): Promise<void> => {
    // For now, recreate the whole Encoder     
    this.encoder = new Encoder();

    if (this.supervisorWorker) {
      this.supervisorWorker.postMessage(
        {
          type: 'newPart',
          processId: part.processId,
          partId: part.partId
        }
      );
    }

    // Loading wideo from
    try {
      await this.encoder.init(this.canvas,
        this.environment,
        this.accessToken,
        part.wideoId,
        null,
        null,
        null,
        null,
        part.width,
        part.height,
        this.forceCanvas,
        this.antialias, null, null,
        this.jsonUrl);

      // Hack to force an update everytime we load a new wideo to solve a problem where transitions were not acting correctly at first load                           
      // See BUG: Intro Slide does not work with scaled object, makes jumps in the encoded video 
      // (https://3.basecamp.com/3459151/buckets/3812629/todos/2165438367)
      await this.encoder.syncSeek((part.ranges[0].from * (1 / part.fps) * 1000));

    }
    catch (error) {
      Logger.error("There was an error trying load the wideo: %s", Logger.errorToString(error));
      this.fireStateChanged(EncoderClientState.Error, error);
      if (this.supervisorWorker) {
        this.supervisorWorker.postMessage(
          {
            type: 'error',
            error: "There was an error trying load the wideo: " + Logger.errorToString(error)
          }
        );
      }
      return;
    }

    this.fireStateChanged(EncoderClientState.Running);

    if (part.jobType.toLowerCase() === 'keyframes') {
      try {
        await this.handleKeyframes(part);
      }
      catch (error) {
        if (this.supervisorWorker) {
          this.supervisorWorker.postMessage(
            {
              type: 'error',
              error: "There was an error handling a keyframes part: " + Logger.errorToString(error)
            }
          );
        }
        return;
      }
    }
    else if (part.jobType.toLowerCase() === 'sound') {
      // TODO: For now the sounds are handled by the WideoEncoder java process. So
      //       this will never be executed. In the future it would be nice to also
      //       handle the manipulation, merging and encoding of sounds here.
    }
    else {
      Logger.warn('Unknown jobtype: ' + part.jobType);
    }

    this.firePartDone(part);

    if (this.supervisorWorker) {
      // Notify the ProcessSupervisor that we have finished processing the
      // part
      this.supervisorWorker.postMessage({ type: 'done' });
    }

    // TODO: Test to see if it is an issue with encoder instances ocupying all the memory
    this.encoder.destroy();
    this.encoder = null;
  }

  private handleKeyframes = async (part: Part): Promise<void> => {

    // Generate all the frames in the part and upload them
    for (let frameIdx = part.ranges[0].from; frameIdx <= part.ranges[0].to; frameIdx++) {

      await this.handleFrame(part, frameIdx);

    }

  }

  private handleFrame = async (part: Part, frameIdx: number): Promise<void> => {

    let frame: ArrayBuffer = null;
    try {
      frame = await this.encoder.generateFrameNo(this.imageFormat, frameIdx, part.fps);
      this.fireFrameGenerated(frameIdx);
    } catch (error) {
      Logger.error('There was an error trying to generate frame ' + frameIdx + ' in part ' + part.partId + ' of process ' + part.processId + ': ' + Logger.errorToString(error));
      throw new Error('There was an error trying to generate frame ' + frameIdx + ' in part ' + part.partId + ' of process ' + part.processId + ': ' + Logger.errorToString(error));
    }

    try {
      // TODO: Parallelize this so that we can start rendering the next frame while we upload this one
      await this.api.uploadFrame(new Blob([frame]), this.imageFormat, frameIdx, part, this.encoderId);
      this.fireFrameUploaded(frameIdx, frame);
    }
    catch (error) {
      Logger.error('There was an error uploading frame ' + frameIdx + ' in part ' + part.partId + ' of process ' + part.processId + ': ' + Logger.errorToString(error));
      throw new Error('There was an error uploading frame ' + frameIdx + ' in part ' + part.partId + ' of process ' + part.processId + ': ' + Logger.errorToString(error));
    }

  }

  public addEncoderClientListener = (listener: EncoderClientListener): void => {
    this.listeners.push(listener);
  }

  public removeStateChangeListener = (listener: EncoderClientListener): void => {
    this.listeners = this.listeners.filter((item) => {
      return item !== listener;
    });
  }

  fireStateChanged(state: EncoderClientState, error: Object = null): void {
    for (const listener of this.listeners) {
      listener.stateChanged(state, error);
    }
  }
  fireNewPartReceived(part: Part): void {
    for (const listener of this.listeners) {
      listener.newPartReceived(part);
    }
  }
  firePartDone(part: Part): void {
    for (const listener of this.listeners) {
      listener.partDone(part);
    }
  }
  fireFrameGenerated(frameNo: number): void {
    for (const listener of this.listeners) {
      listener.frameGenerated(frameNo);
    }
  }
  fireFrameUploaded(frameNo: number, frame: ArrayBuffer): void {
    for (const listener of this.listeners) {
      listener.frameUploaded(frameNo, frame);
    }
  }

}
