import { toolbarMinHeight } from '../../../../editor/core/EditorConstants';

export default {
  regular: {
  '@media (min-width:600px)': {
    minHeight: toolbarMinHeight
  }
},
  gutters: {
  '@media (min-width:600px)': {
  paddingLeft: 20,
  paddingRight: 20
  }
},
  dense: {
  paddingLeft: 12,
  paddingRight: 12
}

};
