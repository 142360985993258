import * as React from 'react';


//import { blue } from '@mui/material/colors';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import PlayIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import PauseIcon from '@mui/icons-material/Pause';
import SvgIcon from '@mui/material/SvgIcon';
import Videocam from '@mui/icons-material/Videocam'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';


import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';

import TimeSliderPlayer from '../../player/ui/TimeSliderPlayer';
import TimeDisplay from './TimeDisplay';
import { PlayState } from '../../player/core/PlayerConstants';
import Player from '../../player/core/Player';
import { Slider, Typography } from '@mui/material';
import keydown from 'react-keydown';
import { 
  ICONS, 
  resourcesBaseUrl 
} from '../../editor/core/EditorConstants';
import { isMobileOnly } from 'react-device-detect';

import { withTranslation, WithTranslation } from 'react-i18next';
import { getWhiteColor } from 'src/editor/ui/layout/dialogs/FontDialog';
import { grey } from '@mui/material/colors';
import theme from 'src/common/ui/theme';
import fscreen from 'fscreen';


// tslint:disable-next-line:no-any
function detectFullScreen(elem: any) {
  return (elem.requestFullscreen || elem.mozRequestFullScreen || elem.webkitRequestFullscreen || elem.msRequestFullscreen);
}

const visible = {
  default: 0,
  hover: 1
};

const styles = {
  controls: {
    transition: 'opacity .25s cubic-bezier(0.0,0.0,0.2,1)',
    width: '100%',
    height: '48px',
    position: 'absolute' as 'absolute',
    bottom: '0px',
    zIndex: 1001,
  },
  gradientPlayer: {
    width: '100%',
    position: 'absolute' as 'absolute',
    backgroundRepeat: 'repeat-x',
    backgroundImage: 'url(' + resourcesBaseUrl + '/img/player/gradientPlayer.png)',
    transition: 'opacity .25s cubic-bezier(0.0,0.0,0.2,1)',
    height: '44px',
    bottom: 0,
    backgroundPosition: 'bottom',
  },
  icon: {
    opacity: .9,
    ...getWhiteColor(theme),
    padding: '8px',
/*     [theme.breakpoints.down('sm')]: {
      padding: '8px 2px',
      width: '24px'
    }, */
  },
  iconDisabled: {
    opacity: .5,
  },
  numbers: {
    padding: '0 10px',
    opacity: .9,
    color: grey['300'],
    textShadow: '0 0 2px rgba(0,0,0,.5)',
  },
  hidden: {
    display: 'none',
  }
};

interface KeyDown {
  event: KeyboardEvent;
}

interface Props {
  onPlay: () => void;
  onPause: () => void;
  onReplay: () => void;
  onStop: () => void;
  onSeek: (seekedTime: number) => void;
  onPreviousScenePresentationMode: () => void;
  onNextScenePresentationMode: () => void;
  player: Player;
  wideoLength: number;
  playState: PlayState;
  fullScreenRootHtmlElement: React.MutableRefObject<HTMLElement>;
  presentationMode: boolean;
  onTogglePresentationMode: () => void;
  keydown?: KeyDown;
  playerControlsEl: boolean;
  volume: number;
  onVolumeChange: (volume: number) => void;
  isMuted: boolean;
  onToggleMute: () => void;
  onTimeSliderMouseDown: () => void;
  onTimeSliderMouseUp: () => void;
  onTimeSliderMouseEnter: () => void;
  onTimeSliderMouseLeave: () => void;
  handleShowSliderPlayer: boolean;
  noFullScreenButton: boolean;
  noToggleModeButton: boolean;
}

interface State {
  isFullscreen: boolean; 
}

const KEYS = [37, 39];

type Styled = any

type PropsWithStylesWithTranslation = Props & WithTranslation & Styled;

//type PropsWithStylesWithTranslation = Props & WithTranslation & WithStyles<'controls' | 'gradientPlayer' | 'icon' | 'iconDisabled' | 'numbers' | 'hidden'>;

/**
 * PlayerControls handles the play, pause and stop buttons as well as the time display and slider
 */

@keydown(KEYS)


class PlayerControls extends React.Component<PropsWithStylesWithTranslation, State> {


  constructor(props: PropsWithStylesWithTranslation) {
    super(props);
    this.state = {
      isFullscreen: false,
    };    

  }

  toggleFullscreen = () => {
    const appElement = this.props.fullScreenRootHtmlElement.current;
    if (fscreen.fullscreenEnabled) {
      if (fscreen.fullscreenElement !== null) {
        fscreen.exitFullscreen();
        this.setState({ isFullscreen: false });
      } else {
        fscreen.requestFullscreen(appElement);
        this.setState({ isFullscreen: true });
      }
    }
  };  

  fullscreenButton = (): JSX.Element => {
    if (!this.props.noFullScreenButton) {
      if (detectFullScreen(this.props.fullScreenRootHtmlElement.current)) {
        return (
            <IconButton style={styles.icon}
              onClick={() => this.toggleFullscreen()}
            >
             {this.props.playerControlsEl ? (
              !this.state.isFullscreen ? <FullscreenIcon /> : <FullscreenExitIcon />
            ) : (
              <span></span>
            )}
          </IconButton>
        );
      } else {
        return <span />
      }
    } else {
      return <span />;
    }
  };

  handleTogglePresentationMode = () => {
    this.props.onTogglePresentationMode();
  }

  isPlayDisabled = (): boolean => {
    return this.props.playState === PlayState.Loading ||
      this.props.playState === PlayState.Error ||
      this.props.playState === PlayState.Playing ||
      this.props.playState === PlayState.Ended
  }
  isPauseDisabled = (): boolean => {
    return this.props.playState === PlayState.Loading ||
      this.props.playState === PlayState.Error ||
      this.props.playState === PlayState.Paused ||
      this.props.playState === PlayState.Stopped ||
      this.props.playState === PlayState.Ended ||
      this.props.playState === PlayState.Loaded;
  }

  isReplayDisabled = (): boolean => {
    return this.props.playState !== PlayState.Ended
  }

  isStopDisabled = (): boolean => {
    return this.props.playState === PlayState.Loading ||
      this.props.playState === PlayState.Error ||
      this.props.playState === PlayState.Paused ||
      this.props.playState === PlayState.Stopped ||
      this.props.playState === PlayState.Loaded ||
      this.props.playState === PlayState.Ended;
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.keydown !== this.props.keydown) {
      if (this.props.keydown.event) {
        // inspect the keydown event and decide what to do
        switch (this.props.keydown.event.which) {

          case 37: {
            this.props.onPreviousScenePresentationMode();
            break;
          }

          case 39: {
            this.props.onNextScenePresentationMode();
            break;
          }

          default: { break; }
        }
      }
    }
  }


  renderPlayIconButton = () => {
    if (!this.isPlayDisabled()) {
      return (
        <Grid key={0} item>
          <IconButton style={styles.icon} id={'play'} onClick={this.props.onPlay} disabled={this.isPlayDisabled()} aria-label="Play">
            <PlayIcon />
          </IconButton>
        </Grid>
      );
    }
    else {
      return (<span key={0} />);
    }
  }

  renderPauseIconButton = () => {
    if (!this.isPauseDisabled()) {
      return (
        <Grid key={1} item>
          <IconButton style={styles.icon} onClick={this.props.onPause} disabled={this.isPauseDisabled()} aria-label="Pause">
            <PauseIcon />
          </IconButton>
        </Grid>
      );
    }
    else {
      return (<span key={1} />);
    }
  }

  renderReplayIconButton = () => {
    if (!this.isReplayDisabled()) {
      return (
        <Grid key={2} item>
          <IconButton style={styles.icon} onClick={this.props.onReplay} disabled={this.isReplayDisabled()} aria-label="Replay">
            <PlayIcon />
          </IconButton>
        </Grid>
      );
    }
    else {
      return (<span key={2} />);
    }
  }

  renderStopIconButton = () => {
    return (
      <Grid key={3} item>
        <IconButton
          style={{
            ...styles.icon,
            ...(this.isStopDisabled() ? styles.iconDisabled : {})
          }}
          onClick={this.props.onStop}
          disabled={this.isStopDisabled()}
          aria-label="Stop"
        >
          <StopIcon />
        </IconButton>
      </Grid>
    );
  }

  renderVolumeIconButton = () => {
    const { t } = this.props;
    return (
      <Grid key={4} item>
        <IconButton style={styles.icon} id={'volume'} onClick={e => this.props.onToggleMute()} aria-label={t("player.controls.volumen.label-icon")}>
          {(!this.props.isMuted) &&
            <VolumeUpIcon
              aria-label={t("player.controls.volumen.label-up")}
            />}
          {(this.props.isMuted) &&
            <VolumeOffIcon
              aria-label={t("player.controls.volumen.label-down")}
            />}
        </IconButton>
      </Grid>
    );
  }

  renderVolumeControl = () => {
    return (
      <Grid key={5} item xs={2} sm={1}>
        <Slider
            id='volume'
            min={0}
            max={100}                
            size="small"
            defaultValue={Number(this.props.volume)}
            value={Number(this.props.volume)}
            onChange={(_event: Event, newValue: number | number[]) => this.props.onVolumeChange(newValue)}
            aria-label="volume"
        />         
      </Grid>
    );
  }

  renderVideoModeControls(): JSX.Element[] {
    return ([
      <this.renderPlayIconButton key={0} />,
      <this.renderPauseIconButton key={1} />,
      <this.renderReplayIconButton key={2} />,
      <this.renderStopIconButton key={3} />,
      <this.renderVolumeIconButton key={4} />,
      <this.renderVolumeControl key={5} />,
      <Grid key={6} item>
        <TimeDisplay player={this.props.player} wideoLength={this.props.wideoLength} variant={isMobileOnly ? 'caption' : 'body1'} />
      </Grid>
    ]
    )
  }

  renderPresentationModeControls(): JSX.Element[] {
    const { t } = this.props;
    return ([
      <Grid key={0} item>
        <IconButton style={styles.icon} onClick={this.props.onPreviousScenePresentationMode}
          disabled={this.props.player.getCurrentScenePresentationMode() === 0} aria-label={t("player.controls.presentation-mode.label-prev")}>
          <KeyboardArrowLeft />
        </IconButton>
      </Grid>,

      <Grid key={1} item>
        <Typography cyid='scenesQty' style={styles.numbers} noWrap>
          {(this.props.player.getCurrentScenePresentationMode() + 1)} /
          {this.props.player.getWideo().getScenesQty()}
        </Typography>
      </Grid>,

      <Grid key={2} item>
        <IconButton
          cyid="onNextScenePresentationMode"
          style={styles.icon}
          onClick={this.props.onNextScenePresentationMode}
          aria-label={t("player.controls.presentation-mode.label-next")}
        >
          <KeyboardArrowRight />
        </IconButton>
      </Grid>
    ]);
  }  

  render() {
    const combinedStyles = {
      ...styles.gradientPlayer,
      opacity: this.props.playerControlsEl ? visible.hover : visible.default,
      transition: this.props.playerControlsEl
        ? 'opacity .1s cubic-bezier(0.4, 0.0, 1, 1)'
        : 'opacity .25s cubic-bezier(0.0, 0.0, 0.2, 1)',
    };

    const combinedControlStyles = {
      ...styles.controls,
      opacity: this.props.playerControlsEl ? visible.hover : visible.default,
      transition: this.props.playerControlsEl
        ? 'opacity .1s cubic-bezier(0.4, 0.0, 1, 1)'
        : 'opacity .25s cubic-bezier(0.0, 0.0, 0.2, 1)',
    };    

    return (
      <div>
        <div
          style={combinedStyles}
        >
          <span style={styles.hidden}>gradientPlayer</span>
        </div>
        <div
          id="controls-pointer-capture"
          style={combinedControlStyles}>
          <div
            id="controls-wrapper"
            style={{
              width: '100%',
              position: 'absolute',
              bottom: '0px'
            }}>
            <div
              style={{
                width: '100%',
                height: '100%',
                position: 'absolute',
                top: '0px',
                left: '0px',
              }} />
            <Grid id="controls"
              style={{
                position: 'relative',
                width: '100%',
              }}
              container
              alignItems='center'
              justify="space-between"
              spacing={0}
            >
              <Grid item xs={12}>
                <TimeSliderPlayer
                  player={this.props.player} start={0}
                  end={this.props.wideoLength} onSeek={this.props.onSeek}
                  onTimeSliderMouseDown={this.props.onTimeSliderMouseDown}
                  onTimeSliderMouseUp={this.props.onTimeSliderMouseUp}
                  onTimeSliderMouseEnter={this.props.onTimeSliderMouseEnter}
                  onTimeSliderMouseLeave={this.props.onTimeSliderMouseLeave}
                  handleShowSliderPlayer={this.props.handleShowSliderPlayer}
                />
              </Grid>
              <Grid item xs={9}>
                <Grid alignItems='center' container spacing={0}>
                  {!this.props.presentationMode && this.renderVideoModeControls()}
                  {this.props.presentationMode && this.renderPresentationModeControls()}
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <Grid container justifyContent="flex-end">
                  {!this.props.noToggleModeButton &&
                  <Grid item>
                    <IconButton style={styles.icon} onClick={this.handleTogglePresentationMode}>
                    {this.props.playerControlsEl ? this.props.presentationMode ? <Videocam /> : <SvgIcon>
                            <path d={ICONS.BOARD} />
                          </SvgIcon> : null }
                    </IconButton>
                  </Grid>}
                  <Grid item>
                    <this.fullscreenButton />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation("player")(PlayerControls);
