import { WideoDefVersion } from "../../core/model/WideoDef";
import ITransformer from "../ITransformer";

export default class TransformerToV43 implements ITransformer {
  readonly toVersion: WideoDefVersion = WideoDefVersion.V43;
  
  constructor() {
    // Intentionally blank
  }
  
  /*
    add presentationMode setting
  */

  // tslint:disable-next-line:no-any
  public transformWideo( wideoDef: any ): void {

    //Update version and timestamp
    wideoDef.version = this.toVersion;
    wideoDef.timeStamp = Date.now();

    wideoDef.presentationMode = false; // <---- added presentationMode default setting

    for (const sceneDef of wideoDef.scenes) {
      this.transformScene(sceneDef);
    }

  }

  // tslint:disable-next-line:no-any
  public transformScene(sceneDef: any): void {
    //Update version 
    sceneDef.version = this.toVersion;
  }
  
}
