import * as React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';

import Avatar from '@mui/material/Avatar';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import SettingsIcon from '@mui/icons-material/Settings';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

import PlayerApp from '../../player/ui/PlayerApp';

import UploadButton from './UploadButton';
import InputField from './InputField';

import Logger from '../../common/log/Logger';
import IMaticQueryParams from '../../matic/ui/IMaticQueryParams';
import Player from '../../player/core/Player';
import IPlayerStateChangeListener from '../../player/core/IPlayerStateChangeListener';
import { PlayState } from '../../player/core/PlayerConstants';
import WideoObject from '../../common/core/WideoObject';
import TextObject from '../../common/core/TextObject';
import ImageObject from '../../common/core/ImageObject';
import VideoObject from '../../common/core/VideoObject';
import Placeholder from '../../common/core/Placeholder';
import { environments } from '../../common/Env';
import { hexToInt } from '../../common/ColorUtils';
import AutomationReplaceApi from '../../api/AutomationReplaceApi';
import { ObjectFit, WideoColorDef } from '../../common/core/model/WideoDef';

import { ChromePicker } from 'react-color';

import { imageFileTypes, videoFileTypes, getExtension, getSupportedMediaExtension } from 'src/editor/core/EditorConstants';

import { uploadFile } from '../services/s3Connector';

import { isMobileOnly } from 'react-device-detect';
import MaskedObject from 'src/common/core/MaskedObject';

import BrandThemeProvider from 'src/common/ui/BrandThemeProvider';

const hexaCssReg: RegExp = new RegExp(/#([a-f]|[A-F]|[0-9]){6}/);

interface State {
  wideoId: string;
  player: Player;
  parameters: Parameter[];
  replaceId: string;
  loading: {};
  displayColorPicker: {};
  color: {
    r: string,
    g: string,
    b: string,
    a: string,    
  };
  hasError: boolean;
}

interface Props {
  queryParams: IMaticQueryParams;
}

interface Parameter {
  name: string;
  value: string;
  type: string;
  current?: string;
  objectFit?: ObjectFit;
  newName?: string | unknown;
  repeat?: number | unknown;
  subtype?: 'image' | 'video' | 'audio';
}

type Styled = any

type PropsWithStyles = Props & Styled;

import Mp4DownloadComponent from './Components/Mp4DownloadComponent/Mp4DownloadComponent'
import { Helmet } from 'react-helmet';

class MaticApp extends React.Component<PropsWithStyles, State> implements IPlayerStateChangeListener {

  private accessToken: string = ''

  constructor(props: PropsWithStyles) {
    super(props);
    // this.authorize(props);
    this.state = {
      wideoId: props.queryParams.wideoId,
      player: new Player(true),
      parameters: [],
      replaceId: null,
      loading: false,
      displayColorPicker: false,
      color: {
        r: '0',
        g: '0',
        b: '0',
        a: '1',
      },
      hasError: false,
      accessToken: this.accessToken
    };
  }



  componentDidMount(): void {
    this.state.player.addStateChangeListener(this);
    window.addEventListener('message', this.replaceParameterNames);
    document.body.style.overflowX = 'hidden';
  }

  componentWillUnmount(): void {
    this.state.player.removeStateChangeListener(this);
    window.removeEventListener('message', this.replaceParameterNames);
  }

  public canvasCallback = async (canvasElement: HTMLCanvasElement): Promise<void> => {
    if (canvasElement) {

      await this.state.player.init(
        canvasElement,
        this.props.queryParams.environment,
        this.props.queryParams.accessToken,
        this.props.queryParams.wideoId,
        undefined,
        undefined,
        this.props.queryParams.convert,
        this.props.queryParams.repeat,
        undefined,
        undefined,
        this.props.queryParams.forceCanvas,
        this.props.queryParams.antialias,
        this.props.queryParams.replace);

      canvasElement.style.width = '100%';
      canvasElement.style.height = '100%';
      canvasElement.style.objectFit = 'contain';

    }
  }

  replaceParameterNames = (event: MessageEvent) => {
    Logger.info("MaticApp replace parameter names");
    const { parameters } = this.state
    const eventName = event.data[0];
    const parameterNames = event.data[1];     
    if(eventName === 'parameterNames') {
      Object.entries(parameterNames).forEach(([key, value]) => { 
          const parameterIndex = parameters.findIndex((parameter) => parameter.name === key)

          if (parameterIndex !== -1) {
            this.state.parameters[parameterIndex].newName = value;
             this.setState({ parameters: this.state.parameters });
          }
      })
    }
    event.preventDefault();
  }  

  public onStateChange = (state: PlayState) => {

    if (state === PlayState.Loaded) {
      Logger.info("MaticApp got Wideo Loaded");
      const replaceManager = this.state.player.getReplaceManager();
      const wideoObjects: WideoObject[] = replaceManager.getReplaceableWideoObjects(this.state.player);
      const resultObjects: Parameter[] = this.getParameters(wideoObjects)
      //join colors
      const wideoColorsDef: WideoColorDef[] = this.state.player.getWideo().getColors();
      let resultColors: Parameter[] = [];
      if (wideoColorsDef) {
          resultColors = wideoColorsDef.map((color) => {
          return {
            name: color.name,
            value: color.color,
            type: 'color',
            current: color.color
          }
        })   
      };

      const result = resultObjects.concat(resultColors)

      const parametersFiltered = result.filter(
        (v,i,a) => {
          const repeatCount = this.parameterOccurrenceCount(result, v.name)
          a[i].repeat = repeatCount   
          return a.findIndex(t => {
            return (t.name.toLowerCase() === v.name.toLowerCase())
          }) === i
        }
      ) 

      this.setState({ parameters: parametersFiltered });

      if(!this.props.queryParams.redirect) {
        window.parent.postMessage(['parameters', parametersFiltered, 'wideoId', this.props.queryParams.wideoId], '*');
      }
        
      //await this.state.player.syncSeek(this.props.queryParams.startTime ?? 0);
    }
  }

  private parameterOccurrenceCount = (parameters: Parameter[], value: string) => {
    let res = 0;
    const n = parameters.length;

    for (let i=0; i<n; i++) {
      if (value === parameters[i].name) {
        res++;
      }
    }
    return res;    
  }

  private getParameters(wideoObjects: WideoObject[]): Parameter[] {
    return wideoObjects.map((object: WideoObject) => {
      if (TextObject.isTextObject(object)) {
        return {
          name: object.getReplaceableName(),
          value: object.getText(),
          type: 'text'
        };
      }
      if (Placeholder.isPlaceholder(object)) {
        const otherObject: WideoObject = object.getOtherObject();
        const isImageObject = ImageObject.isImageObject(otherObject);
        const isVideoObject = VideoObject.isVideoObject(otherObject);

        const url = (
          ImageObject.isImageObject(otherObject) ||
            VideoObject.isVideoObject(otherObject)
            ? otherObject.getUrl() : '');
        return {
          name: object.getReplaceableName(),
          value: url,
          type: 'object',
          objectFit: object.getReplaceObjectFit(),
          subtype: isImageObject ? 'image' : isVideoObject ? 'video' : null
        };
      }
      Logger.warn("Tried to replace not supported object class: " + object.getClass() + ", " + object.getId());
      return null;
    });
  }

  private async generateReplaceId(): Promise<string> {
    const env = this.props.queryParams.environment ? this.props.queryParams.environment : "develepe";
    const api = new AutomationReplaceApi(env);
    const response = await api.saveReplaceInfo(this.getParametersForReplaceId());
    return response.body.id;
  }

  private getParametersAsJson(spaces: number) {
    return JSON.stringify(this.getParametersForReplaceId(), null, spaces);
  }

  private getParametersForReplaceId() {
    const object = { data: {} };
    for (const parameter of this.state.parameters) {
      if (parameter.type === 'object') {
        object.data[parameter.name] = this.getRemoteAssetUrl(parameter.value);
      } else if (parameter.type === 'text') {
        object.data[parameter.name] = parameter.value;
      } else {
        //color
        object.data[parameter.name] = parameter.value;
      }
    }
    return object
  }

  private getPlayerUrl = (): string => {
    const env = this.props.queryParams.environment ? this.props.queryParams.environment : "develepe";
    return environments[env].automationApiUrl + '/automation/replace/player?wideoId=' + this.state.wideoId + '&replaceId=' + this.state.replaceId;
  }

  private getRemoteAssetUrl = (url: string): string => {
    const env = this.props.queryParams.environment ? this.props.queryParams.environment : "develepe";
    return environments[env].apiUrl + "/v3/awg/remoteasset?url=" + encodeURIComponent(url);
  }

  private handleClickColorPicker = (index: number) => {     
    this.setState({ displayColorPicker: { [index]: true } });
  };  

  private handleCloseColorPicker = (index: number) => {  
    this.setState({ displayColorPicker: { [index]: false } });
  };  

  // tslint:disable-next-line:no-any
  private handleChangeColor = (color: any) => {
    this.setState({ color: color.rgb })
  }; 
  
  private onChangeInputColor = async (value: string, index: number, parameter: Parameter) => {   
    if (hexaCssReg.test(value)) {
      const player = this.state.player;
      player.getReplaceManager().replaceColor(player.getWideo(), hexToInt(parameter.current), hexToInt(value));
      await this.state.player.syncSeek(this.state.player.getCurrentTime());
      this.state.parameters[index].current = value;
    }
    //in the case of color, after replace we need to update the key too
    this.state.parameters[index].value = value;
    this.setState({ parameters: this.state.parameters });
  };  

  private handleReplace = async (index: number, parameter: Parameter, value: string) => {
    const _value = this.getRemoteAssetUrl(value);
    const player = this.state.player;
    const wideo = player.getWideo();
    const matchingObjects: WideoObject[] = wideo.getObjectsByName(parameter.name);
    const object = matchingObjects[0];

    if (Placeholder.isPlaceholder(object) || MaskedObject.isMaskedObject(object)) {
      const extension = await getSupportedMediaExtension(this.state.parameters[index].value);
      const isValid = imageFileTypes.indexOf(extension.toLowerCase()) !== -1 ||  videoFileTypes.indexOf(extension.toLowerCase()) !== -1
   

      if(!isValid) {
        Logger.error('File not supported')
        return
      }

      await Promise.all(matchingObjects.map(async (obj) => {
        await player.getReplaceManager().replaceObjectWithSrc(obj, _value, extension, player);
      }));   

      this.state.parameters[index].value = value;
      this.setState({ parameters: this.state.parameters }); 
      await this.state.player.syncSeek(this.state.player.getCurrentTime());

    } else {
      Logger.warn("Trying to replace an image that is not a Placeholder, MaskedObject. Object: %o", object);
    }
  };
  
  private handleSave = async (_actionId: string) => {
    this.setState({ loading: { [_actionId]: true } });
    const replaceId = await this.generateReplaceId();
    await this.updateState(replaceId, _actionId);
  };  

  private updateState = async (id: string, _actionId): Promise<void> => {
    return new Promise<void>((resolve) => {
      this.setState({ replaceId: id, loading: { [_actionId]: false } }, () => {
        resolve();
      });
    });
  };
  
  private handleEdit = async (id: string) => {
    await this.handleSave(id)
    if(!this.props.queryParams.redirect) {
      window.parent.postMessage(['replaceId', this.state.replaceId , 'wideoId', this.props.queryParams.wideoId], '*');
      return
    }
    this.handleRedirect() 
  };  

  private handleRedirect = () => {
    const env = this.props.queryParams.environment ?? "develepe";
    const url = `${environments[env].automationApiUrl}/automation/replace/reuse?wideoId=${this.state.wideoId}&replaceId=${this.state.replaceId}`;
    window.open(url, '_blank')
  }
  
  private uploadFiles = async (event: React.SyntheticEvent<HTMLInputElement>) => {
    const target= event.target as HTMLInputElement;
    const id = target.id
    const file: File = (target.files as FileList)[0];

    if (file) {

      this.setState({ loading: { [id]: true } });
      const url = await this.handleSaveFile(file, id)

      if (url) {
        const index = this.state.parameters.map((e) => e.name).indexOf(id);
        const parameter = this.state.parameters[index];
  
        await this.handleReplace(index, parameter, url)
  
        this.setState({
          hasError: false,
        })
      }
      this.setState({ loading: { [id]: false } });
    }
  }   

  private handleSaveFile = async (file: File, id: string): Promise<string> => {
    try {
        const fileExtension = getExtension(file.name);
        const isValidExtension = imageFileTypes.includes(fileExtension.toLowerCase()) || videoFileTypes.includes(fileExtension.toLowerCase());
        if (isValidExtension) {
          const [_id, extension] = await uploadFile(file);
          const url = `https://d1y5xfvzlo94cr.cloudfront.net/${_id}/${_id}${extension}`  
          return url;  
        }
      }
    catch (error) {
      Logger.error("MaticApp fail to save image:", error);
    }
    return null;
  }   

  render() {
    const { brandName, icon } = BrandThemeProvider.getBrandInfo(this.props.queryParams);

    const { parameters, loading } = this.state; 
    const gridDirection =  isMobileOnly ? "column-reverse" : "row"

    return (
      <>
        <Helmet>
          <title>{brandName} - Matic</title>
          <link rel="icon" href={icon} />
        </Helmet>
        <BrandThemeProvider queryParams={this.props.queryParams}>
          <Box bgcolor={this.props.queryParams.redirect ? '#f6f6f6' : "#e8e8e8"} pt={2}>
            <Container 
                fixed
                style={{ maxWidth: "calc(100% - 8px)"}}>
              <Grid container direction={gridDirection} spacing={3}>
                <Grid container direction={gridDirection} item spacing={2}>
                  <Grid 
                    item 
                    xs={12} 
                    md={6} 
                    style={{ marginTop: isMobileOnly ? "70vw" : 0, padding: isMobileOnly ? '8px 0' : 'inherit' }}>
                      <Card>
                        <CardContent> 
                          <Box py={2}>

                            {parameters.map((parameter: Parameter, index: number) => {                               
                              
                              if (parameter.type === 'text') {

                                // remove white spaces, blank lines and final line break 
                                let parameterValue = parameter.value.replace(/^(?=\n)$|^\s*|\s*$|\n\n+/gm,"")  

                                const isDefaultValue = 
                                  parameterValue.startsWith('{') && parameterValue.endsWith('}') || 
                                  parameterValue.startsWith('[') && parameterValue.endsWith(']')

                                if(isDefaultValue) {
                                  parameterValue = ''
                                }

                                return (
                                  <Grid key={`${parameter.name}-text-${index}`} container alignItems='center' item spacing={this.props.queryParams.superUser ? 0 : 4}>
                                      <Grid item xs={this.props.queryParams.superUser ? 11 : 12}>
                                        <InputField
                                          id={parameter.name}
                                          label={parameter.newName ?? parameter.name.toUpperCase()} 
                                          value={parameterValue === '' ? parameterValue : parameter.value}
                                          onChange={async (event: React.ChangeEvent<HTMLInputElement>) => {
                                            const value = event.target.value;

                                            const player = this.state.player;
                                            const wideo = player.getWideo();
                                            const objs: WideoObject[] = wideo.getObjectsByName(parameter.name);                                        
                                            
                                            objs.forEach(object => {
                                              player.getReplaceManager().replaceText(object as TextObject, value, player);
                                            });                                           

                                            await this.state.player.syncSeek(this.state.player.getCurrentTime());
                                            // TODO: HACK, do the state change properly
                                            this.state.parameters[index].value = value;
                                            this.setState({ parameters: this.state.parameters });
                                          }}
                                        /> 
                                      </Grid>
                                      {this.props.queryParams.superUser && 
                                        <Grid item xs={1}>   
                                          <Tooltip title="Play Lifetime">
                                            <IconButton
                                                color="primary"
                                                onClick={async () => {
                                                  const objs: WideoObject[] = this.state.player.getWideo().getObjectsByName(parameter.name);
                                                  const object = objs[0];
                                                  await this.state.player.playObject(object);
                                                }}
                                                style={{ marginTop: 5 }}>
                                                  <PlayCircleOutlineIcon />
                                              </IconButton>  
                                          </Tooltip>
                                        </Grid>
                                      }
                                  </Grid>
                                );
                              } else if (parameter.type === 'object') {
                                return (
                                  <Grid key={`${parameter.name}-object-${index}`} container item spacing={this.props.queryParams.superUser ? 0 : 4}>
                                      <Grid item xs={this.props.queryParams.superUser ? 11 : 12}>
                                            <InputField
                                              id={`|${parameter.name}`} 
                                              label={`(${parameter.newName ?? parameter.name}${parameter.objectFit ? ' (' + parameter.objectFit + ')' : ''})`.toUpperCase()}
                                              value={parameter.value}
                                              onChange={async (event: React.ChangeEvent<HTMLInputElement>) => {
                                                  const value = event.target.value;
                                                  //const _value =  this.getRemoteAssetUrl(value);

                                                  // TODO: HACK, do the state change properly
                                                  this.state.parameters[index].value = value;
                                                  this.setState({ 
                                                    parameters: this.state.parameters
                                                  });

                                                  await this.handleReplace(index, parameter, parameter.value)
                                              }}
                                              />  
                                            
                                            <Box m={0.5} display="flex">
                                              {parameter.subtype !== 'video' && 
                                                <>
                                                  {loading[parameter.name] ? 
                                                    <Avatar 
                                                      variant="square"
                                                      style={{ width: 50, height: 50, marginRight: 5, backgroundColor: 'snow' }}>
                                                        <CircularProgress size={18} color='primary' />
                                                    </Avatar> : 
                                                    <Avatar
                                                      src={parameter.value}
                                                      variant="square"
                                                      style={{ width: 50, height: 50, marginRight: 5 }}
                                                    />   
                                                  }    
                                                </>
                                              }

                                              <UploadButton 
                                                uploadFiles={this.uploadFiles} 
                                                id={parameter.name} 
                                                text={parameter.subtype} />                                             

                                              {this.props.queryParams.superUser && 
                                                <Tooltip title="Replace">
                                                  <IconButton
                                                      color="primary"
                                                      onClick={() => this.handleReplace(index, parameter, parameter.value)}>
                                                      <SettingsIcon />
                                                    </IconButton>  
                                                </Tooltip>
                                              }                                          
                                            </Box>
                                      </Grid>
                                      {this.props.queryParams.superUser && 
                                        <Grid item xs={1}>   
                                          <Tooltip title="Play Lifetime">
                                            <IconButton
                                                color="primary"
                                                onClick={async () => {
                                                  const objs: WideoObject[] = this.state.player.getWideo().getObjectsByName(parameter.name);
                                                  const object = objs[0];
                                                  await this.state.player.playObject(object);
                                                }}
                                                style={{ marginTop: 15}}
                                              >
                                                <PlayCircleOutlineIcon />
                                              </IconButton>  
                                          </Tooltip> 
                                        </Grid>
                                      }                                       
                                  </Grid>
                                );
                              } else if (parameter.type === 'color') {                          

                                return (
                                  <Grid key={`${parameter.name}-color-${index}`} container item spacing={4}>
                                    <Grid item xs={this.props.queryParams.superUser ? 11 : 12}>
                                          <InputField
                                            id={parameter.name}
                                            label={parameter.newName ?? parameter.name.toUpperCase()} 
                                            value={parameter.value}
                                            onChange={async (event: React.ChangeEvent<HTMLInputElement>) => {
                                              const value = event.target.value;

                                              await this.onChangeInputColor(value, index, parameter)
                                            }}
                                            />    
                                          { this.state.displayColorPicker[index] &&
                                            <Box position='absolute' zIndex={2}>
                                              <Box position='fixed' top={0} right={0} bottom={0} left={0} onClick={() => this.handleCloseColorPicker(index) }/>
                                              <Box position='absolute' left={0}>
                                                <ChromePicker
                                                  color={ this.state.color }
                                                  disableAlpha={true}
                                                  // tslint:disable-next-line:no-any
                                                  onChangeComplete={async (color: any) => {
                                                    this.handleChangeColor(color)
                                                    await this.onChangeInputColor(color.hex, index, parameter)

                                                  }}
                                                />
                                              </Box>
                                            </Box>
                                          }
                                          <Tooltip title="New value"> 
                                            <Box margin='2px 5px 5px 0px' display='inline-flex'>
                                              <Button
                                                aria-label="New value" 
                                                onClick={() => this.handleClickColorPicker(index) }
                                                color="primary"
                                                size="small"
                                                style={{
                                                  textDecoration: 'underline' 
                                                }}
                                                startIcon={<span
                                                    style={{ 
                                                      border: "1px solid gray", 
                                                      backgroundColor: parameter.value,
                                                      width: "20px", 
                                                      height: "20px", 
                                                      display: "block",
                                                      borderRadius: '50%',
                                                      cursor: 'pointer' 
                                                  }} /> }
                                              >
                                                Replace color
                                              </Button>  
                                            </Box> 
                                          </Tooltip>
                                      </Grid>
                                  </Grid>)
                              } else {
                                return null;
                              }
                            })
                            }
                          </Box> 
                      </CardContent>
                      </Card>
                  </Grid>
                  <Grid 
                    item xs={12} 
                    md={6}
                    style={{ padding: isMobileOnly ? '8px 0' : 'inherit' }}>    
                        <Card 
                          style={{ 
                            top: isMobileOnly ? 0 : "1rem",
                            zIndex: isMobileOnly ? 1 : 0,
                            width: isMobileOnly ? "calc(100% - 24px)" : "auto",
                            position: isMobileOnly ? "fixed" : "sticky"
                          }}>
                          <CardContent style={{ padding: isMobileOnly ? "0 0 24px" : "16px"  }}> 
                            <Grid container item alignItems="center" spacing={2}>
                                <Grid item xs={12}>
                                  <Box 
                                    position="sticky"
                                    margin='auto'
                                    width='100%'
                                    paddingBottom='56.25%'                                  
                                  >
                                    <PlayerApp 
                                      player={this.state.player}
                                      queryParams={{
                                        autoplay: false,
                                        repeat: false,
                                        startTime: this.props.queryParams.startTime ?? 0,
                                        debug: this.props.queryParams.debug,
                                        wideoId: this.props.queryParams.wideoId,
                                        environment: this.props.queryParams.environment,
                                        hideControls: false,
                                        accessToken: this.props.queryParams.accessToken,
                                        convert: false,
                                        forceCanvas: this.props.queryParams.forceCanvas,
                                        antialias: this.props.queryParams.antialias,
                                        replace: null,
                                        replaceId: null,
                                        jsonUrl: null,
                                        superUser: false,
                                        export: this.props.queryParams.export
                                      }} 
                                    />
                                  </Box>            
                                </Grid>
                                <Grid container justify="center" spacing={2}>
                                    {!this.props.queryParams.superUser && this.props.queryParams.showCTA &&
                                      <Grid item xs sx={{ textAlign: 'center', marginTop: 1 }}>
                                        <Tooltip title="Edit video">
                                          <Button 
                                            variant="contained"
                                            color="primary"
                                            disableElevation
                                            startIcon={ loading['edit'] ? <CircularProgress size={12} color='inherit' /> : <EditIcon />}
                                            onClick={() => this.handleEdit('edit')}
                                            >
                                            Edit video
                                          </Button>
                                        </Tooltip>  
                                      </Grid>
                                    }
                                    { this.props.queryParams.export &&   
                                      <Mp4DownloadComponent 
                                        accessToken={this.accessToken} 
                                        parameters={parameters} 
                                        templateId={this.props.queryParams.wideoId} 
                                        environment={this.props.queryParams.environment} />
                                    }
                                    { this.props.queryParams.superUser &&   
                                      <Grid item xs style={{ textAlign: this.state.replaceId ? 'left' : 'right' }}>
                                        <Tooltip title="Save and generate replaceId">
                                          <Button 
                                            variant="contained"
                                            color="primary"
                                            disableElevation
                                            startIcon={ loading['save'] ? <CircularProgress size={12} color='inherit' /> : <SaveIcon />}
                                            onClick={() => this.handleSave('save')}>
                                            { loading['save'] ? 'Saving' : 'Save'}
                                          </Button>
                                        </Tooltip>  
                                      </Grid> 
                                    }                               
                                    {this.state.replaceId && this.props.queryParams.superUser &&
                                      <Grid item xs style={{ textAlign: 'right' }}>
                                        <Tooltip title="Open Wideo in Player">
                                          <Button 
                                            variant="outlined"
                                            color="primary"
                                            disableElevation
                                            startIcon={<OpenInNewIcon />}
                                            onClick={() => { window.open(this.getPlayerUrl(), '_blank'); }}>
                                            Open in Player
                                          </Button> 
                                        </Tooltip>
                                      </Grid>     
                                    }    
                                </Grid>
                                  {this.state.replaceId && this.props.queryParams.superUser && 
                                    <Box width='100%'>
                                      <Box my={2}>
                                        <TextField
                                          label="Url to player:"
                                          multiline
                                          rows={3}
                                          value={this.getPlayerUrl()}
                                          variant="outlined"
                                          inputProps={{
                                            readOnly: true
                                          }}
                                          fullWidth
                                        />
                                      </Box>
                                      <Box my={2}>
                                        <TextField
                                          label="Replace ID JSON:"
                                          multiline
                                          rows={20}
                                          value={this.getParametersAsJson(2)}
                                          variant="outlined"
                                          inputProps={{
                                            readOnly: true
                                          }}
                                          fullWidth
                                        />
                                      </Box>  
                                      <Box my={2}>
                                        <TextField
                                          label="Wideo JSON"
                                          multiline
                                          rows={50}
                                          value={JSON.stringify((this.state.player.getWideo() ? this.state.player.getWideo().serialize() : {}), null, 2)}
                                          variant="outlined"
                                          inputProps={{
                                            readOnly: true
                                          }}
                                          fullWidth
                                        /> 
                                      </Box>
                                    </Box> 
                                  }      
                            </Grid>
                        </CardContent>
                        </Card>     
                  </Grid>          
                </Grid>    
              </Grid>
            </Container>
        </Box>
        </BrandThemeProvider>
      </>
    );
  }
}

export default MaticApp;