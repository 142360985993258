import Logger from '../../common/log/Logger';

//import * as fs from 'fs';

import { ImageFormat } from './Encoder';

export default class EncoderFfmpeg {

  public encode2Mp4WorkerFs = async (fps: number, imageFormat: ImageFormat,
    buffers: ArrayBuffer[], width: number, height: number): Promise<ArrayBuffer> => {
    return new Promise<ArrayBuffer>((resolve, reject) => {

      // Load the worker code
      Logger.time("Loading worker");
      const worker: Worker = new Worker("https://resources.wideo.co/wideo-html5-react/enc/ffmpeg-worker-mp4.js");
      Logger.timeEnd("Loading worker");

      // Just log errors
      worker.onerror = (ev: ErrorEvent) => {
        Logger.error("Worker exited with onerror(): %o\n", ev);
      }

      worker.onmessage = function (e: MessageEvent) {
        const msg = e.data;
        switch (msg.type) {
          case "ready":
            Logger.log("FFMPEG Worker ready...\n");
            break;
          case "run":
            Logger.log("FFMPEG Worker running...\n");
            break;
          case "stdout":
            Logger.log(msg.data + "\n");
            break;
          case "stderr":
            Logger.warn(msg.data + "\n");
            break;
          case "exit":
            Logger.log("FFMPEG Worker exited: %o\n", e);
            if (e.data.data !== 0) {
              Logger.error("FFMPEG failed encoding, exit value: %d", e.data.data);
              reject(new Error("FFMPEG failed encoding, exit value: " + e.data.data));
            }
            break;
          case "done":
            Logger.log("FFMPEG Worker done: %o\n", e);
            Logger.timeEnd('ffmpeg');
            worker.terminate();
            resolve(msg.data);
            break;
          default:
            Logger.error("Unexpected message from FFMPEG webworker: %o", msg);
            break;
        }
      }

      // Create a memory file system of all the frames
      const blobs = buffers.map((data, i) => {
        const name: string = `${i}.` + imageFormat;
        return { name: name, data: new Blob([data]) }
      });
      const args = [
        "-f", "image2",
        "-r", "" + fps,
        "-s", width + "x" + height,
        //"-start_number", "0",
        "-i", "/frames/%d." + imageFormat, // Fails with .png because of: https://github.com/Kagami/ffmpeg.js/issues/20
        //"-t", "1",
        //"-r", "30",
        "-c:v", "libx264",
        "-pix_fmt", "yuv420p",
        "-profile:v", "baseline",
        "-sws_flags", "lanczos+accurate_rnd",
        "-movflags", "+faststart",
        "out.mp4"
      ];

      Logger.log("Running FFMPEG with args: %o", args);

      // Start the actual encoding of  frames to video
      Logger.time('ffmpeg');
      worker.postMessage(
        {
          type: "run",
          arguments: args,
          mounts: [
            {
              type: "WORKERFS",
              opts: {
                blobs: blobs,
                files: []
              },
              mountpoint: "/frames",
            },
          ],
          TOTAL_MEMORY: 536870912
        }
      );
    });
  }

}
