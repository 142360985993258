import Logger from '../common/log/Logger';

export default class AwsApi {

  private identityPoolId: string;
  private region: string;

  constructor(identityPoolId: string, region: string) {
    this.identityPoolId = identityPoolId;
    this.region = region;
  }

  private async constructS3Instance() {
    const AWS_SDK_CLIENTS3 = await import("@aws-sdk/client-s3");
    const AWS_SDK_CLIENT_COG = await import("@aws-sdk/client-cognito-identity");
    const AWS_SDK_CLIENT_COG_ID = await import("@aws-sdk/credential-provider-cognito-identity");
    return new AWS_SDK_CLIENTS3.S3Client({
      region: this.region,
      credentials: AWS_SDK_CLIENT_COG_ID.fromCognitoIdentityPool({
        client: new AWS_SDK_CLIENT_COG.CognitoIdentityClient({ region: this.region }),
        identityPoolId: this.identityPoolId,
      }),
    });
  }

  public async uploadToS3(bucketName: string, key: string, file: Blob): Promise<void> {
    const AWS_SDK_LIB_STORAGE = await import("@aws-sdk/lib-storage");
    const s3Client = await this.constructS3Instance();
    try {
      const upload = new AWS_SDK_LIB_STORAGE.Upload({
        client: s3Client,
        params: {
          Bucket: bucketName,
          Key: key,
          Body: file,
        }
      });
      await upload.done();
    } catch (error) {
      if (error.name === "RequestTimeTooSkewed") {
        const now = Date.now();
        const serverTime = Date.parse(error.ServerTime);
        const newOffset = (serverTime - now);
        s3Client.config.systemClockOffset = newOffset;
        try {
          console.warn("Client clock drift detected, offset " + newOffset);
          const upload = new AWS_SDK_LIB_STORAGE.Upload({
            client: s3Client,
            params: {
              Bucket: bucketName,
              Key: key,
              Body: file,
            }
          });
          await upload.done();
        } catch (error) {
          Logger.error("Manual retry with clock skew correction failed. " + JSON.stringify(error));
          throw error;
        }
      } else {
        throw error;
      }
    }
  }
}