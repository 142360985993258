import React, { useEffect, useMemo, useState } from 'react';


import { Theme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import Editor from '../../../../editor/core/Editor';
import { GoogleFontItem } from '../../../../api/FontsApi';

import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import CardActionArea from '@mui/material/CardActionArea';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import AddBoxIcon from '@mui/icons-material/AddBox';
import SearchIcon from '@mui/icons-material/Search';

import { Card, CardContent, Typography, Grid, Tooltip } from '@mui/material';

import Placeholder from '../utils/Placeholder';
import NotificationSnackBar from '../utils/NotificationSnackBar';

import { fontCategories, fontLanguages } from '../../../../editor/core/EditorConstants';
import Logger from '../../../../common/log/Logger';
import SwitchButton from '../utils/SwitchButton';

export const getGreyColor = (theme: Theme) => ({
  color: theme.palette.grey['500'],
});

export const getWhiteColor = (theme: Theme) => ({
  color: theme.palette.common.white,
});

export const getCheckedColor = (theme: Theme) => ({
  color: theme.palette.primary.main,
});

export const getBorderWhiteColor = (theme: Theme) => ({
  borderColor: theme.palette.common.white,
});

export const getBackgroundMainColor = (theme: Theme) => ({
  backgroundColor: theme.palette.primary.main,
});

export const getBackgroundGreyColor = (theme: Theme) => ({
  backgroundColor: theme.palette.grey[500],
});

export const getBorderGreyColor = (theme: Theme) => ({
  border: `1px solid ${theme.palette.grey[500]}`,
});

export const getTransitionColor = (theme: Theme) => ({
  transition: theme.transitions.create(['background-color', 'backgroundColor']),
});

interface Props {
  editor: Editor;
  onClose: () => void;
}

type PropsWithStyles = Props;

interface FontDialogFontItem {
  family: string;
  subsets: string[];
  category: string;
  variants: string[];
  popularityIndex: number;
}

interface FontCardProps {
  font: FontDialogFontItem;
  onClick: (fontFamily: string) => void;
}


const FontDialog = (props: PropsWithStyles) => {
  const { t } = useTranslation('dialogs');

  const [filter, setFilter] = useState<string>('');
  const [fonts, setFonts] = useState<FontDialogFontItem[]>([]);
  const [showFonts, setShowFonts] = useState<number>(6);
  const [sort, setSort] = useState('popularity');
  const [category, setCategory] = useState('all');
  const [language, setLanguage] = useState('all');
  const [checked, setChecked] = useState({});
  const [fontsAdded, setFontsAdded] = useState(true);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fontNameAdded, setFontNameAdded] = useState('');

  useEffect(() => {
    setLoading(true)
    props.editor.getFontHandler().getFonts().then((allGoogleFonts: GoogleFontItem[]) => {
      const allFonts: FontDialogFontItem[] = allGoogleFonts.map((googleFont: GoogleFontItem, index: number): FontDialogFontItem => {
        return {
          family: googleFont.family,
          subsets: googleFont.subsets.slice(),
          category: googleFont.category,
          variants: googleFont.variants.slice(),
          popularityIndex: index
        }
      });
      setFonts(allFonts);
      setLoading(false)
    }).catch((error) => {
      Logger.error("FontDialog failed loading fonts.", error);
      setLoading(false)
    });
  }, []);

  const Loading = () => (
    <Box display="flex" justifyContent="center">
      <Box my={6}>
        <CircularProgress size={30} thickness={5} />
      </Box>
    </Box>
  )

  const SwitchFontsAdded = () => (
    <SwitchButton
      value={t("dialogs.font.added.label")}
      checked={Object.keys(checked).length && fontsAdded}
      disabled={!Object.keys(checked).length}
      labelColor="common.black"
      activeColor="common.white"
      handleChange={handleChangeFontsAdded}
    />
  );

  const FontCard = ({ font }: FontCardProps) => {
    const id = "dynamic-font-" + font.family.toLowerCase().replace(/ /g, '-')
    const url = 'https://fonts.googleapis.com/css?family=' + font.family.replace(/ /g, '+');

    return (
      <>
        <link href={url} type="text/css" rel="stylesheet" id={id} />
        <Card style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', width: '100%' }}>
          <CardActionArea onClick={() => handleClickCard(id, font.family)}>
            <CardContent>
              <Box display="flex" alignItems="center">
                <Box flexGrow={1}>
                  <Typography variant="h6">
                    {font.family}
                  </Typography>
                </Box>
                <Box>
                  <Tooltip title={t("dialogs.font.add")}>
                    <Checkbox
                      checked={checked[id]}
                      name={id}
                      inputProps={{ 'aria-label': 'font checkbox' }}
                      value={font.family}
                      color="primary"
                      icon={<AddBoxIcon />}
                    />
                  </Tooltip>
                </Box>
              </Box>
              <Typography noWrap variant="subtitle1" component="div" style={{ textTransform: 'capitalize' }}>
                {font.category}
              </Typography>
              <Tooltip title={font.subsets.join(", ")}>
                <Typography noWrap variant="subtitle2" component="div" style={{ textTransform: 'capitalize' }}>
                  {font.subsets.join(", ")}
                </Typography>
              </Tooltip>
              <Typography variant="h4" style={{ fontFamily: font.family }}>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </>
    );
  };

  const handleClose = () => {
    props.onClose();
  }

  const handleOpenSnackbar = () => {
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleClickCard = async (name: string, fontFamily: string) => {
    const isChecked = checked[name]
    setChecked({ ...checked, [name]: true });

    if (!isChecked) {
      try {
        await handleClickFont(fontFamily)
        setFontNameAdded(fontFamily)
        handleOpenSnackbar()

        props.editor.logAmplitudeEvent("NEW_FONT_ADDED", { "fontFamily": fontFamily });
        props.editor.logGAEvent("NEW_FONT_ADDED", null, false);
        
      } catch (error) {
        Logger.error("FontDialog failed adding fonts.", error);
      }
    };
  }

  const handleClickFont = async (fontFamily: string) => {
    await props.editor.getFontHandler().loadFont(fontFamily);
  }

  const handleMoreClick = () => {
    setShowFonts(Math.min(mappedFontList.length, showFonts + 6));
  }

  const handleChangeFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value);
  }

  const handleChangeSort = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSort(event.target.value);
    setFontsAdded(false)
  }

  const handleChangeCategory = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCategory(event.target.value);
    setFontsAdded(false)
  }

  const handleChangeLanguage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLanguage(event.target.value);
    setFontsAdded(false)
  }

  const handleChangeFontsAdded = () => {
    setFontsAdded(!fontsAdded);
  }

  const handleSortFontsByOrder = () => {
    return fonts.sort((x, y) => {
      const a = x.family.toLowerCase(),
        b = y.family.toLowerCase();
      return a === b ? 0 : a > b ? 1 : -1;
    });
  }

  const handleSortFontsByPopularity = () => {
    return fonts.sort((x, y) => {
      return x.popularityIndex - y.popularityIndex;
    });
  }

  const handleFilterFontsByCategory = (_category: string) => {
    return fonts.filter((font) => {
      return font.category.toLowerCase() === _category.toLowerCase()
    });
  }

  const handleFilterFontsByLanguage = (_language: string) => {
    return fonts.filter((font) => {
      return font.subsets.some((subset) => subset.toLowerCase().includes(_language.toLowerCase()))
    });
  }

  const handleSortFontsByAdded = () => {
    return fonts.sort((a, b) => {
      const _a = "dynamic-font-" + a.family.toLowerCase().replace(/ /g, '-')
      const _b = "dynamic-font-" + b.family.toLowerCase().replace(/ /g, '-')

      return Object.keys(checked).indexOf(_b) - Object.keys(checked).indexOf(_a);
    });
  }

  const mappedFontList = useMemo(() => {
    // Apply the filter/search, filter on both font family and language (subset)
    let filteredFonts = fonts.filter((font) => {
      return font.family.toLowerCase().includes(filter.toLowerCase()) ||
        font.subsets.some((subset) => subset.toLowerCase().includes(filter.toLowerCase())) ||
        font.category.toLowerCase().includes(filter.toLowerCase())
    });

    if (filter === '') {

      if (sort === 'alphabetical') {
        filteredFonts = handleSortFontsByOrder();
      }

      if (sort === 'popularity') {
        filteredFonts = handleSortFontsByPopularity();
      }

      if (category !== 'all') {
        filteredFonts = handleFilterFontsByCategory(category)
      }

      if (language !== 'all') {
        filteredFonts = handleFilterFontsByLanguage(language)
      }

      if (Object.keys(checked).length > 0 && fontsAdded) {
        filteredFonts = handleSortFontsByAdded()
      }
    }
    return filteredFonts;
  }, [fonts, checked, filter, sort, category, language, fontsAdded]);

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={true}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <Box bgcolor="common.white">
          <DialogTitle id="form-dialog-title" disableTypography>
            <Typography variant="h5">
              {t("dialogs.font.title")}
            </Typography>
            <Box
              position="absolute"
              right={24}
              top={24}
            > 
              <Grid container alignItems="center">
                <Grid item>
                  <SwitchFontsAdded />
                </Grid>
                <Grid item>
                  <Typography variant="body2">
                    {t("dialogs.font.added.label")}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </DialogTitle>
        </Box>
        <Box display="flex" pl={2} pr={4}>
          <Box p={1} flexGrow={1}>
            <Grid container alignItems="flex-end" spacing={2}>
              <Grid item>
                <SearchIcon />
              </Grid>
              <Grid item>
                <FormControl>
                  <TextField
                    autoFocus
                    id="filter"
                    name="filter"
                    variant="standard"
                    placeholder={t("dialogs.font.placeholder")}
                    fullWidth={true}
                    value={filter}
                    onChange={handleChangeFilter} />
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl variant="standard">
                  <Select
                    value={sort}
                    onChange={handleChangeSort}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label for font sort' }}>
                    <MenuItem value="alphabetical">Alphabetical</MenuItem>
                    <MenuItem value="popularity">Popularity</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
          <Box p={1}>
            <FormControl variant="standard">
              <Select
                value={category}
                onChange={handleChangeCategory}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label for font category' }}>
                {fontCategories.map(
                  ({ name, value }, index) => (
                    <MenuItem key={`${index}-${value}`} value={value}>{name}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Box>
          <Box p={1}>
            <FormControl variant="standard">
              <Select
                value={language}
                onChange={handleChangeLanguage}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label for font languages' }}>
                {fontLanguages.map(
                  ({ name, value }, index) => (
                      <MenuItem key={`${index}-${value}`} value={value}>{name}</MenuItem>
                    ))
                  }
              </Select>
            </FormControl>
          </Box>
        </Box>
        <DialogContent>
          <Box maxHeight={500}>
            {loading ? (
              <Loading />
            ) : (
                <Grid container spacing={1}>
                  {mappedFontList.slice(0, showFonts).map((font, index) => {
                    return (
                      <Grid item xs={12} sm={4} key={"font-" + index} style={{ display: 'flex' }}>
                        <FontCard font={font} onClick={handleClickFont} />
                      </Grid>
                    )
                  })}
                  <Grid item xs={12}>
                    {mappedFontList.length ? (
                      <Button
                        onClick={handleMoreClick}
                        disabled={showFonts >= mappedFontList.length}
                        color="primary"
                        fullWidth>
                        {t("dialogs.font.more")}
                      </Button>) : (
                      <Placeholder
                        icon={<SearchIcon htmlColor="#bbbbbb" fontSize="inherit" />}
                        text={t("dialogs.font.message")}
                      />)}
                  </Grid>
                </Grid>
              )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            cyid="closeButton"
            variant="contained"
            color="primary"
            style={{ marginTop: 12, marginRight: 12 }}
          >
            {t("dialogs.font.actions.done")}
          </Button>
        </DialogActions>
        {openSnackbar &&
          <NotificationSnackBar
            snackBarOpen={openSnackbar}
            snackBarText={`${fontNameAdded} ${t("dialogs.font.added.snackbar")}`}
            snackBarError={false}
            handleCloseSnackBar={handleCloseSnackbar}
            parentComponent={'FontDialog'}
            duration={2000} />
        }
      </Dialog>
    </>
  );
}

export default FontDialog;
