import * as React from 'react';

import { Theme } from '@mui/material/styles';


import { grey } from '@mui/material/colors';
import Toolbar from '@mui/material/Toolbar';

import Editor from '../../../../editor/core/Editor';
import { Class } from '../../../../common/core/model/WideoDef';
import { Dialog, DialogContent, Tabs, AppBar, Tab, FormControl, TextField, Grid, Box, Button, Typography, Divider, MenuItem, Switch, styled, SwitchProps } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Link from '@mui/material/Link';
import CircularProgress from '@mui/material/CircularProgress';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CodeIcon from '@mui/icons-material/Code';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import IconButton from '@mui/material/IconButton';
import SvgIcon from '@mui/material/SvgIcon';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ChangeEvent } from 'react';
import { environments } from '../../../../common/Env';
import NotificationSnackBar from '../utils/NotificationSnackBar';
import { ICONS, gifMaxDuration, filterObjs } from "../../../core/EditorConstants";
import WideoValidator from '../../../core/validation/WideoValidator';
import { WithTranslation, withTranslation } from 'react-i18next';
import theme from 'src/common/ui/theme';
import { getBackgroundColor } from '../bottombar/KeyFrameMarker';
import { getWhiteColor } from './FontDialog';
import { getBackgroundColor400 } from './search/SearchProvider';

import { BrandContext } from 'src/common/ui/BrandContextProvider';

export const getMargin = (theme: Theme, number: number | number[]) => ({
    margin: Array.isArray(number) ? theme.spacing.apply(null, number) : theme.spacing(number),
});

export const getContrastColor= (theme: Theme) => ({
    color: theme.palette.getContrastText(grey[100]),
});

export const getColor500 = (theme: Theme) => ({
    color: theme.palette.grey['500'],
});
  
export const getTransition = (theme: Theme) => ({
    transition: theme.transitions.create(['background-color', 'backgroundColor']),
});

const styles = {
    dialogAppBar: {
        backgroundColor: grey[200],
        boxShadow: 'none'
    },
    scrollPaper: {
        alignItems: 'flex-start' as React.CSSProperties['alignItems'],
    },
    toolbar: {
        textAlign: 'center' as React.CSSProperties['textAlign'],
        ...getBackgroundColor(theme),
        color: grey[600],
        display: 'block',
        padding: '30px 5px 0'
    },
    tab: {
        backgroundColor: grey[200],
        ...getContrastColor(theme)
    },
    rootSwitch: {
        width: 42,
        height: 26,
        padding: 0,
        ...getMargin(theme, 1),
    },
    switchBase: {
        padding: 1,
        "&.Mui-checked": {
            transform: "translateX(16px)",
            ...getWhiteColor(theme),
            "& + .MuiSwitch-track": {
                backgroundColor: theme.palette.primary.main,
                opacity: 1,
                border: "none"
            }
        }
    },
    thumb: {
        width: 24,
        height: 24
    },
    track: {
        borderRadius: 13,
        ...getBackgroundColor400(theme),
        opacity: 1,
        ...getTransition(theme),
    },
    checked: {},
    disable: {
        ...getColor500(theme)
    },
    buttonInInput: {
        marginTop: '11px',
        borderRadius: '0px 5px 5px 0px'
    },
    bgDialog: {
        backgroundColor: grey[200]
    },
    fontWeightTypography: {
        fontWeight: 100,
    },
    wrapperList: {
        ...getMargin(theme, 2),
    },
    loading: {
        cursor: 'wait'
    },
    wrapperButton: {
        ...getMargin(theme, 1),
        position: 'relative' as 'relative',
        textAlign: 'center' as 'center'
    },
    buttonLoading: {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    buttonYoutube: {
        width: 70,
        height: 70
    },
    buttonFacebook: {
        width: 70,
        height: 70
    },
    buttonHubSpot: {
        width: 70,
        height: 70
    }
};

export const CustomSwitch = styled((props: SwitchProps) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary.main : theme.palette.primary.main,
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: theme.palette.primary.main,
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#bdbdbd' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));

export const CustomDialog = styled(Dialog)(() => ({
    '.MuiDialog-scrollPaper': styles.scrollPaper,
    '.MuiDialog-root': styles.loading,
    '.MuiDialog-paper': styles.bgDialog,
}));

const YoutubeButton = styled(IconButton)`
  && {
    background-color: transparent;
    color: #ff0100;
    width: 100%;
    &:hover {
      background-color: transparent;
    }
  }
`;


const FacebookIconButton = styled(IconButton)({
    root: {
        backgroundColor: 'transparent',
        color: '#4469b0',
        width: '100%',
        '&:hover': {
            backgroundColor: 'transparent',
        }
      }
});


const HubSpotIconButton = styled(IconButton)({
    root: {
        backgroundColor: 'transparent',
        color: '#4469b0',
        width: '100%',
        '&:hover': {
            backgroundColor: 'transparent',
        }
      }
});

const FacebookButton = styled(Button)({
    root: {
        backgroundColor: '#1877F2',
        '&:hover': {
            backgroundColor: '#2E497B',
        }
      }
});

const TwitterButton = styled(Button)({
    root: {
        backgroundColor: '#29a3ef',
        '&:hover': {
            backgroundColor: '#1f99e4'
        }
      }
});

interface ShareSizeOption {
    id: number,
    name: string,
    width?: number,
    height?: number
}

const shareSizeOptions: ShareSizeOption[] = [
    { id: 0, name: '560x315', width: 560, height: 315 },
    { id: 1, name: '768x432', width: 768, height: 432 },
    { id: 2, name: '960x540', width: 960, height: 540 },
    { id: 3, name: '1024x576', width: 1024, height: 576 },
    { id: 4, name: 'Custom', width: 1920, height: 1080 },
    { id: 5, name: 'Responsive' }
];

interface Props {
    editor: Editor;
    isOpen: boolean;
    onClose: () => void;
}

interface State {
    tab: number,
    shareUrl: string,
    shareUrlCopied: boolean,
    shareEmbedIframe: string,
    shareEmbedSize: number,
    shareEmbedWidth: number,
    shareEmbedHeight: number,
    shareEmbedAutoplay: boolean,
    shareEmbedHideControls: boolean,
    shareEmbedLoop: boolean,
    goToDownload: boolean,
    goToYoutube: boolean,
    goToFacebook: boolean,
    goToHubSpot: boolean,
    snackBarOpen: boolean,
    snackBarText: string

}

type Styled = any

type PropsWithTranslationsAndStyles = Props & WithTranslation & Styled;

class ShareWideoDialog extends React.Component<PropsWithTranslationsAndStyles, State> {

    constructor(props: PropsWithTranslationsAndStyles) {
        super(props);
        this.state = {
            tab: 0,
            shareUrl: environments[this.props.editor.getEnvironment()].webUrl.base +
                environments[this.props.editor.getEnvironment()].webUrl.view + "/" + props.editor.getWideoId() +
                "?utm_source=CopyPaste&utm_medium=share&utm_campaign=sharebox",
            shareUrlCopied: false,
            shareEmbedIframe: this.buildShareEmbedIframe(560, 315, false, false, true),
            shareEmbedSize: 0,
            shareEmbedWidth: 560,
            shareEmbedHeight: 315,
            shareEmbedAutoplay: false,
            shareEmbedHideControls: false,
            shareEmbedLoop: false,
            goToDownload: false,
            goToYoutube: false,
            goToFacebook: false,
            goToHubSpot: false,
            snackBarOpen: false,
            snackBarText: ''
        }
    }

    static contextType = BrandContext;
    context: React.ContextType<typeof BrandContext>;    

    handleClose = () => {
        this.props.onClose();
        this.setState({ goToDownload: false });
        this.setState({ goToYoutube: false });
        this.setState({ goToFacebook: false });
        this.setState({ goToHubSpot: false });
    }

    //handles tab click, value change
    handleChange = (event: React.ChangeEvent<{}>, value: number) => {
        this.setState(() => {
            return { tab: value, shareUrlCopied: false }
        });
    }

    handleShareFb = () => {
        const wideoId: string = this.props.editor.getWideoId();
        const title = this.props.editor.getWideoTitle();
        this.props.editor.logAmplitudeEvent("SHARE-FB", { "wideoID": wideoId });
        this.props.editor.logGAEvent("SHARE-FB", null, false);
        environments[this.props.queryParams.environment].webUrl.base
        window.open("https://www.facebook.com/sharer/sharer.php?u=" + environments[this.props.editor.getEnvironment()].webUrl.base + 
        environments[this.props.editor.getEnvironment()].webUrl.view + "/" +
            wideoId + "?utm_source=facebook&utm_medium=share&utm_campaign=sharebox&utm_content=" +
            title);
    }

    handleShareTw = () => {
        const wideoId: string = this.props.editor.getWideoId();
        const title: string = this.props.editor.getWideoTitle();
        this.props.editor.logAmplitudeEvent("SHARE-TW", { "wideoID": wideoId });
        this.props.editor.logGAEvent("SHARE-TW", null, false);

        const urlSharing: string = environments[this.props.editor.getEnvironment()].webUrl.base + 
        environments[this.props.editor.getEnvironment()].webUrl.view + "/" +
            wideoId + "?utm_source=twitter&utm_medium=share&utm_campaign=sharebox&utm_content=" + wideoId;
        const twText: string = title; // + " - Created with Wideo, wideo.co @MrWideo";
        window.open("https://twitter.com/share?text=" + twText + "&url=" + encodeURIComponent(urlSharing) + "&count=none&lang=en"); //&via=MrWideo&hashtags=wideo");
    }

    handleChangeIframeShareUrl = () => {
        this.setState((prevState: State, prevProps: Props) => {
            return {
                shareEmbedIframe:
                    this.buildShareEmbedIframe(
                        prevState.shareEmbedWidth,
                        prevState.shareEmbedHeight,                        
                        prevState.shareEmbedAutoplay,
                        prevState.shareEmbedHideControls,
                        prevState.shareEmbedLoop                      
                    )
            }
        });
    }

    buildShareEmbedIframe = (
        shareEmbedWidth: number, 
        shareEmbedHeight: number,         
        shareEmbedAutoplay: boolean, 
        shareEmbedHideControls: boolean, 
        shareEmbedLoop: boolean       
    ) => {
        const wideoId: string = this.props.editor.getWideoId();

        const isResponsiveShare = (this.state?.shareEmbedSize === 5) ?? false

        const size = !isResponsiveShare ? `width=${shareEmbedWidth}&height=${shareEmbedHeight}` : ''

        const src: string = `${environments[this.props.editor.getEnvironment()].webUrl.base + environments[this.props.editor.getEnvironment()].webUrl.embed}/${wideoId}?${size}&repeat=${shareEmbedLoop}&autoplay=${shareEmbedAutoplay}&hideControls=${shareEmbedHideControls}`;

        if(isResponsiveShare) {
            return `<div class="wideo-wrapper"><iframe class="wideo-responsive-iframe" src="${src}"></iframe></div><style>.wideo-wrapper { position: relative;
overflow: hidden;width: 100%;padding-top: 56.25%;}.wideo-responsive-iframe {position: absolute; top: 0; left: 0; bottom: 0; right: 0; width: 100%; height: 100%; }</style>`;}

        return `<iframe allowfullscreen="allowfullscreen" frameborder="no" width="${shareEmbedWidth}" height="${shareEmbedHeight}" src="${src}"></iframe>`;
    }

    handleChangeEmbedSize = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({ shareEmbedSize: Number(event.target.value) }, this.processSizes);
    }

    handleChangeLoopOption = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({ shareEmbedLoop: event.target.checked }, this.handleChangeIframeShareUrl);
    }

    handleChangeControlsOption = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({ shareEmbedHideControls: event.target.checked }, this.handleChangeIframeShareUrl);
    }    

    handleChangeAutoPlayOption = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({ shareEmbedAutoplay: event.target.checked }, this.handleChangeIframeShareUrl);
    }

    handleChangeShareEmbedHeight = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({ shareEmbedHeight: Number(event.target.value) }, this.handleChangeIframeShareUrl);
    }

    handleChangeShareEmbedWidth = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({ shareEmbedWidth: Number(event.target.value) }, this.handleChangeIframeShareUrl);

    }

    validateGIF = (): boolean => {
        return this.props.editor.getWideo().getWideoLength() <= gifMaxDuration;
    }

    validSize = (w: number, h: number) => {
        const isValid: boolean = w && h &&
            (w / h) === 16 / 9;

        return isValid;
    }

    processSizes = (sizesChange?: string) => {
        //agarrar el nuevo size
        let w: number = this.state.shareEmbedWidth;
        let h: number = this.state.shareEmbedHeight;

        //if isn't custom or responsive
        if (this.state.shareEmbedSize !== 4 && this.state.shareEmbedSize !== 5) {

            const newSize: ShareSizeOption[] = filterObjs((sizeOption: ShareSizeOption) => sizeOption.id === this.state.shareEmbedSize)(shareSizeOptions);

            w = newSize[0].width;
            h = newSize[0].height;
        }

        if (!w || !h) {
            return;
        }

        //shitty code from share popup in angularjs

        if (sizesChange) {
            if (sizesChange === 'width') {
                h = w * 9 / 16;
            }
            else {
                if (sizesChange === 'height') {
                    w = h * 16 / 9;
                }
            }

            while (!this.validSize(w, h)) {
                if (sizesChange === 'width') {
                    w++;
                    h = w * 9 / 16;
                }
                else {
                    if (sizesChange === 'height') {
                        h++;
                        w = h * 16 / 9;
                    }
                }
            }

        }

        if (w > 0 && h > 0) {
            this.setState({ 
                shareEmbedWidth: w, 
                shareEmbedHeight: h 
            },
                this.handleChangeIframeShareUrl
            );
        }
    }

    goDownload = (profile: string) => {
        const validator: WideoValidator = new WideoValidator();
        const noOfVideos = validator.countObjectsInWideo(this.props.editor.getWideo().serialize(), Class.VideoObject);
        this.props.editor.logAmplitudeEvent("POPUP-SHARE-DOWNLOAD", { source: "share-box-editor-html5", wideoID: this.props.editor.getWideoId(), noOfMP4s: noOfVideos, profile: profile });
        this.props.editor.logGAEvent("POPUP-SHARE-DOWNLOAD", null, false);

        this.setState({ goToDownload: true }, async () => {
            await this.props.editor.saveVideo();
            this.props.editor.disableConfirmationOnLeavePage();
            window.location.href = environments[this.props.editor.getEnvironment()].webUrl.base + environments[this.props.editor.getEnvironment()].webUrl.download + "?wideoID=" + this.props.editor.getWideoId() + "&backUrl=" + encodeURIComponent(window.location.href) + "&profile=" + profile + "&source=editorPopup";
        });
    }

    goYoutube = () => {
        this.props.editor.logAmplitudeEvent("POPUP-SHARE-YOUTUBE", { source: "share-box-editor-html5", wideoID: this.props.editor.getWideoId() });
        this.props.editor.logGAEvent("POPUP-SHARE-YOUTUBE", null, false);

        this.setState({ goToYoutube: true }, async () => {
            await this.props.editor.saveVideo();
            this.props.editor.disableConfirmationOnLeavePage();
            window.location.href = environments[this.props.editor.getEnvironment()].webUrl.base + environments[this.props.editor.getEnvironment()].webUrl.youtube + "?wideoID=" + this.props.editor.getWideoId() + "&backUrl=" + encodeURIComponent(window.location.href) + "&source=editorPopup";
        });
    }

    goFacebook = () => {
        this.props.editor.logAmplitudeEvent("POPUP-SHARE-FACEBOOK", { source: "share-box-editor-html5", wideoID: this.props.editor.getWideoId() });
        this.props.editor.logGAEvent("POPUP-SHARE-FACEBOOK", null, false);

        this.setState({ goToFacebook: true }, async () => {
            await this.props.editor.saveVideo();
            this.props.editor.disableConfirmationOnLeavePage();
            window.location.href = environments[this.props.editor.getEnvironment()].webUrl.base + environments[this.props.editor.getEnvironment()].webUrl.facebook + "?wideoID=" + this.props.editor.getWideoId() + "&backUrl=" + encodeURIComponent(window.location.href) + "&source=editorPopup";
        });
    }

    goHubSpot = () => {
        this.props.editor.logAmplitudeEvent("POPUP-SHARE-HUBSPOT", { source: "share-box-editor-html5", wideoID: this.props.editor.getWideoId() });
        this.props.editor.logGAEvent("POPUP-SHARE-HUBSPOT", null, false);

        this.setState({ goToHubSpot: true }, async () => {
            await this.props.editor.saveVideo();
            this.props.editor.disableConfirmationOnLeavePage();
            window.location.href = environments[this.props.editor.getEnvironment()].webUrl.base + environments[this.props.editor.getEnvironment()].webUrl.hubspot + "?wideoID=" + this.props.editor.getWideoId() + "&backUrl=" + encodeURIComponent(window.location.href) + "&source=editorPopup";
        });
    }

    openSnackbarMessage = (message: string) => {
        this.setState(() => {
            return {
                snackBarOpen: true,
                snackBarText: message,
            }
        })
    }

    closeSnackBarMessage = () => {
        this.setState(() => { return { snackBarOpen: false, snackBarText: '' } })
    }

    componentDidUpdate(props: PropsWithTranslationsAndStyles) {
        if (this.props.isOpen !== props.isOpen) {
            this.setState({ tab: 0, shareUrlCopied: false })
        }
    }

    renderShareTab = () => {
        const { t } = this.props;
        return (
            <Grid container>
                <Grid item container justifyContent="center"
                    direction="row" alignItems="center" spacing={0}>
                    <Grid item xs={10}>
                        <FormControl
                            fullWidth
                            margin="normal"
                        >
                            <TextField
                                onFocus={() => this.setState({ shareUrlCopied: false })}
                                id="shareUrl"
                                label={t("dialogs.share.actions.url.label")}
                                name="shareUrl"
                                readOnly
                                value={this.state.shareUrl}
                                variant="outlined"
                                margin="dense"
                                size='small'
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <CopyToClipboard text={this.state.shareUrl}
                            onCopy={() => this.setState({ shareUrlCopied: true })}>
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                style={{
                                    ...styles.buttonInInput
                                }}
                            >
                                {this.state.shareUrlCopied ? t("dialogs.share.actions.url.url.states.copied") : t("dialogs.share.actions.url.url.states.copy")}
                            </Button>
                        </CopyToClipboard>
                    </Grid>
                    <Grid item xs={12}>
                        <Box my={3}>
                            <Divider />
                        </Box>
                    </Grid>
                </Grid>
                <Grid item container xs={12} justifyContent="center"
                    direction="row" alignItems="center" spacing={2}>
                    <Grid item>
                        <FacebookButton
                            onClick={this.handleShareFb}
                            variant="contained"
                            color="primary"
                            size="large"
                            startIcon={
                                <SvgIcon fontSize="small"
                                    viewBox="0 0 1365.3333 1365.3333">
                                    <path d="M1365.3332992 682.6666504C1365.3332992 305.63999316 1059.69330684 8e-7 682.6666496 8e-7S0 305.63999316 0 682.6666504c0 340.73865815 249.6413271 623.15985109 575.9999856 674.37342314V879.9999788h-173.333329V682.6666504h173.333329V532.26665416c0-171.09332906 101.91733079-265.59999336 257.85332689-265.59999336 74.68933146 0 152.81332951 13.333333 152.81332951 13.333333v167.9999958h-86.08266451c-84.80399788 0-111.25066389 52.62266535-111.25066389 106.60933067V682.6666504h189.3333286l-30.26666591 197.3333284H789.3333136v477.04009474c326.3586585-51.21357205 575.9999856-333.634765 575.9999856-674.37342314" fill="#fff" />
                                </SvgIcon>
                            }
                        >
                            {t("dialogs.share.actions.url.options.facebook")}
                        </FacebookButton>
                    </Grid>
                    <Grid item>
                        <TwitterButton
                            onClick={this.handleShareTw}
                            variant="contained"
                            color="primary"
                            size="large"
                            sx={{ backgroundColor: '#29a3ef' }}
                            startIcon={
                                <SvgIcon fontSize="small">
                                    <path d={ICONS.TWITTER} />
                                </SvgIcon>
                            }
                        >
                            {t("dialogs.share.actions.url.options.twitter")}
                        </TwitterButton>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    private getAvailableShareSizes() {
        return shareSizeOptions.map((option: ShareSizeOption) => {
            return (
                <MenuItem key={option.id} value={option.id}>
                    {option.name}
                </MenuItem>
            );
        });
    }

    renderEmbedTab = () => {
        const { t } = this.props;

        return (
            <>
                <Grid container>
                    <Grid item container justifyContent="center"
                        direction="row" alignItems="center" spacing={0}>
                        <Grid item xs={10}>
                            <FormControl
                                fullWidth
                                margin="normal"
                            >
                                <TextField
                                    id="shareEmbedIframe"
                                    label={t("dialogs.share.actions.embed.url.label")}
                                    name="shareEmbedIframe"
                                    readOnly
                                    value={this.state.shareEmbedIframe}
                                    variant="outlined"
                                    margin="dense"
                                    size='small'
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                            <CopyToClipboard text={this.state.shareEmbedIframe}
                                onCopy={() => this.setState({ shareUrlCopied: true })}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    style={{
                                        ...styles.buttonInInput
                                    }}
                                >
                                    {this.state.shareUrlCopied ? t("dialogs.share.actions.embed.url.states.copied") : t("dialogs.share.actions.embed.url.states.copy")}
                                </Button>
                            </CopyToClipboard>
                        </Grid>
                    </Grid>
                    <Grid item container justifyContent="center"
                        direction="row" alignItems="center" spacing={1}>
                        <Grid item xs={3}>
                            <FormControl
                                fullWidth
                                margin="normal"
                            >
                                <TextField
                                    select
                                    label={t("dialogs.share.actions.embed.settings.size.label")}
                                    value={this.state.shareEmbedSize}
                                    onChange={this.handleChangeEmbedSize}
                                    variant="outlined"
                                    margin="dense"
                                    size='small'
                                >
                                    {this.getAvailableShareSizes()}
                                </TextField>

                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                            {this.state.shareEmbedSize === 4 &&
                                <FormControl
                                    fullWidth
                                    margin="normal"
                                >
                                    <TextField
                                        id="shareEmbedWidth"
                                        label={t("dialogs.share.actions.embed.settings.size.width")}
                                        name="shareEmbedWidth"
                                        onBlur={() => { this.processSizes('width') }}
                                        onChange={this.handleChangeShareEmbedWidth}
                                        value={this.state.shareEmbedWidth}
                                        variant="outlined"
                                        margin="dense"
                                    />
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={2}>
                            {this.state.shareEmbedSize === 4 &&
                                <FormControl
                                    fullWidth
                                    margin="normal"
                                >
                                    <TextField
                                        id="shareEmbedHeight"
                                        label={t("dialogs.share.actions.embed.settings.size.height")}
                                        name="shareEmbedHeight"
                                        onBlur={() => { this.processSizes('height') }}
                                        onChange={this.handleChangeShareEmbedHeight}
                                        value={this.state.shareEmbedHeight}
                                        variant="outlined"
                                        margin="dense"
                                    />
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={5}>
                            <Link
                                href={"https://help.wideo.co/hc/articles/217002646-What-does-embedding-mean-"}
                                target="_blank"
                                rel="noopener" // https://material-ui.com/components/links/#security
                                variant="caption"
                                display="block"
                                align="right"
                            >
                                <Grid item container justifyContent="flex-end"
                                    direction="row" alignItems="center" spacing={1}>
                                    <Grid item>
                                        {t("dialogs.share.actions.embed.help")}
                                    </Grid>
                                    <Grid item>
                                        <Box fontSize={14} mt={1}>
                                            <InfoOutlinedIcon
                                                fontSize="inherit"
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Link>
                        </Grid>
                    </Grid>
                </Grid>
                <List style={styles.wrapperList}>
                    <Divider />
                    <ListItem disableGutters>
                        <ListItemText primary={<Typography style={{...!this.state.shareEmbedAutoplay && styles.disable}}>
                            {t("dialogs.share.actions.embed.settings.autoplay")}
                        </Typography>} />
                        <ListItemSecondaryAction>
                            <CustomSwitch
                                disableRipple
                                checked={this.state.shareEmbedAutoplay}
                                onChange={this.handleChangeAutoPlayOption}
                                value={this.state.shareEmbedAutoplay}
                            />
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                    <ListItem disableGutters>
                        <ListItemText primary={<Typography style={{...!this.state.shareEmbedLoop && styles.disable}}>
                            {t("dialogs.share.actions.embed.settings.loop")}
                        </Typography>} />
                        <ListItemSecondaryAction>
                            <CustomSwitch
                                disableRipple
                                checked={this.state.shareEmbedLoop}
                                onChange={this.handleChangeLoopOption}
                                value={this.state.shareEmbedLoop}
                            />
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                    <ListItem disableGutters>
                        <ListItemText primary={<Typography style={{...!this.state.shareEmbedHideControls && styles.disable}}>
                            {t("dialogs.share.actions.embed.settings.controls")}
                        </Typography>} />
                        <ListItemSecondaryAction>
                            <CustomSwitch
                                disableRipple
                                checked={this.state.shareEmbedHideControls}
                                onChange={this.handleChangeControlsOption}
                                value={this.state.shareEmbedHideControls}
                            />
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>
            </>
        )
    }

    renderExportTab = () => {
        const { t } = this.props;
        return (
            <Grid container>
                <Grid item container justifyContent="center"
                    direction="row" alignItems="center" spacing={1}>

                    <Grid item xs={12}>
                        <Typography variant="body2" gutterBottom align="center" color="textSecondary">{t("dialogs.share.actions.export.options.download.text-superuser")}</Typography>
                    </Grid>

                    {this.props.editor.isDiscoveryUser() &&

                        <Grid item xs={12} sm={3}>
                            <Tooltip title={t("dialogs.share.actions.export.options.download.tooltip.mov")}>
                                <div style={styles.wrapperButton}>
                                    <Box>
                                        <Button
                                            cyid={"discoveryMovDownload"}
                                            id="Jest-discoveryMovDownload"
                                            style={{ ...this.state.goToDownload && styles.loading }}
                                            onClick={() => { this.goDownload("TV") }}
                                            disabled={this.state.goToDownload}
                                            color="primary"
                                            variant="contained"
                                            fullWidth={true}
                                            size="large"
                                            startIcon={
                                                <SvgIcon fontSize="small">
                                                    <path d={ICONS.QUICKTIME.TOP} />
                                                    <path d={ICONS.QUICKTIME.BOTTOM} />
                                                </SvgIcon>
                                            }
                                        >
                                            MOV
                                        </Button>
                                    </Box>
                                    {this.state.goToDownload &&
                                        <CircularProgress size={24} style={styles.buttonLoading} color="primary" />
                                    }
                                </div>
                            </Tooltip>
                        </Grid>
                    }

                    <Grid item xs={12} sm={3}>
                        <Tooltip title={t("dialogs.share.actions.export.options.download.tooltip.mp4")}>
                            <div style={styles.wrapperButton}>
                                <Box>
                                    <Button
                                        cyid={"mp4Download"}
                                        id="Jest-mp4Download"
                                        style={{ ...this.state.goToDownload && styles.loading }}
                                        onClick={() => { this.goDownload("WEB") }}
                                        disabled={this.state.goToDownload}
                                        color="primary"
                                        variant="contained"
                                        fullWidth={true}
                                        size="large"
                                        startIcon={
                                            <SvgIcon fontSize="small">
                                                <path d={ICONS.PLAY} />
                                            </SvgIcon>
                                        }
                                    >
                                        MP4
                                    </Button>
                                </Box>
                                {this.state.goToDownload &&
                                    <CircularProgress size={24} style={styles.buttonLoading} color="primary" />
                                }
                            </div>
                        </Tooltip>
                    </Grid>

                    <Tooltip title={this.validateGIF() ? t("dialogs.share.actions.export.options.download.tooltip.gif.validated") : t("dialogs.share.actions.export.options.download.tooltip.gif.not-validated")}>
                        <Grid item xs={12} sm={3}>
                            <div style={styles.wrapperButton}>
                                <Box>
                                    <Button
                                        cyid={"gifDownload"}
                                        id="Jest-discoveryGifDownload"
                                        style={{ ...this.state.goToDownload && styles.loading }}
                                        onClick={() => { this.goDownload("GIF") }}
                                        disabled={this.state.goToDownload || !this.validateGIF()}
                                        color="secondary"
                                        variant="contained"
                                        fullWidth={true}
                                        size="large"
                                        startIcon={
                                            <SvgIcon fontSize="small">
                                                <path d={ICONS.FOLDER} />
                                            </SvgIcon>
                                        }
                                    >
                                        GIF
                                    </Button>
                                </Box>
                                {this.state.goToDownload &&
                                    <CircularProgress size={24} style={styles.buttonLoading} color="secondary" />
                                }
                            </div>
                        </Grid>
                    </Tooltip>

                    <Grid item xs={12}>
                        <Box my={2}>
                            <Divider />
                        </Box>
                    </Grid>

                    <Grid item xs={12} container justifyContent="center"
                        direction="row" alignItems="center" spacing={1}>
                        <Grid item xs={4} sm={4}>
                            <Typography variant="body2" gutterBottom align="center" color="textSecondary">{t("dialogs.share.actions.export.options.upload-yt.text")}</Typography>
                            <div style={styles.wrapperButton}>
                                <YoutubeButton
                                    style={{ ...this.state.goToYoutube && styles.loading }}
                                    onClick={this.goYoutube}
                                    disabled={this.state.goToYoutube}
                                    disableFocusRipple={true}
                                    disableRipple={true}
                                    cyid={"youtubeUploadButton"}
                                >
                                    <YouTubeIcon size="inherit" color="inherit" style={{ ...styles.buttonYoutube }} />
                                </YoutubeButton>
                                {this.state.goToYoutube &&
                                    <CircularProgress size={24} style={styles.buttonLoading} color="primary" />
                                }
                            </div>

                        </Grid>

                        <Grid item xs={4} sm={4}>
                            <Typography variant="body2" gutterBottom align="center" color="textSecondary">{t("dialogs.share.actions.export.options.upload-fb.text")}</Typography>
                            <div style={styles.wrapperButton}>
                                <FacebookIconButton
                                    style={{ ...this.state.goToFacebook && styles.loading }}
                                    onClick={this.goFacebook}
                                    disabled={this.state.goToFacebook}
                                    disableFocusRipple={true}
                                    disableRipple={true}
                                >

                                    <SvgIcon
                                        style={styles.buttonFacebook}
                                        viewBox="0 0 1365.3333 1365.3333" >
                                        <path d="M1365.3332992 682.6666504C1365.3332992 305.63999316 1059.69330684 8e-7 682.6666496 8e-7S0 305.63999316 0 682.6666504c0 340.73865815 249.6413271 623.15985109 575.9999856 674.37342314V879.9999788h-173.333329V682.6666504h173.333329V532.26665416c0-171.09332906 101.91733079-265.59999336 257.85332689-265.59999336 74.68933146 0 152.81332951 13.333333 152.81332951 13.333333v167.9999958h-86.08266451c-84.80399788 0-111.25066389 52.62266535-111.25066389 106.60933067V682.6666504h189.3333286l-30.26666591 197.3333284H789.3333136v477.04009474c326.3586585-51.21357205 575.9999856-333.634765 575.9999856-674.37342314" fill="#1877f2" />
                                        <path d="M948.39997629 879.9999788l30.26666591-197.3333284H789.3333136V554.60932027c0-53.98666532 26.446666-106.60933067 111.25066389-106.60933067H986.666642V279.9999938s-78.12399805-13.333333-152.81332951-13.333333c-155.9359961 0-257.85332689 94.5066643-257.85332689 265.59999336V682.6666504h-173.333329v197.3333284h173.333329v477.04009474c34.75599913 5.45311986 70.3786649 8.29322646 106.666664 8.29322646 36.2879991 0 71.91066487-2.8401066 106.666664-8.29322646V879.9999788h159.06666269" fill="#fff" />
                                    </SvgIcon>

                                </FacebookIconButton>
                                {this.state.goToFacebook &&
                                    <CircularProgress size={24} style={styles.buttonLoading} color="primary" />
                                }
                            </div>
                        </Grid>

                        <Grid item xs={4} sm={4}>
                            <Typography variant="body2" gutterBottom align="center" color="textSecondary">{t("dialogs.share.actions.export.options.upload-hubspot.text")}</Typography>
                            <div style={styles.wrapperButton}>
                                <HubSpotIconButton
                                    style={{ ...this.state.goToHubSpot && styles.loading }}
                                    onClick={this.goHubSpot}
                                    disabled={this.state.goToHubSpot}
                                    disableFocusRipple={true}
                                    disableRipple={true}
                                >

                                    <SvgIcon
                                        style={(styles.buttonHubSpot)}
                                        viewBox="0 0 512 512" >
                                        <g id="_x31_68-hubspot">
                                            <g>
                                                <path d="M266.197,216.109c-22.551,21.293-36.655,51.48-36.655,84.991c0,26.326,8.714,50.582,23.359,70.08    l-44.473,44.74c-3.953-1.438-8.176-2.245-12.579-2.245c-9.702,0-18.776,3.774-25.605,10.602    c-6.828,6.827-10.602,15.989-10.602,25.696c0,9.701,3.773,18.775,10.602,25.605c6.829,6.826,15.993,10.42,25.605,10.42    c9.703,0,18.777-3.505,25.695-10.42c6.829-6.83,10.602-15.994,10.602-25.605c0-3.774-0.538-7.369-1.707-10.873l44.923-45.102    c19.765,15.183,44.381,24.169,71.244,24.169c64.599,0,116.797-52.38,116.797-116.977c0-58.578-42.854-107.093-99.007-115.628    v-55.343c15.723-6.65,25.335-21.384,25.335-38.545c0-23.449-18.777-43.034-42.227-43.034c-23.448,0-41.956,19.585-41.956,43.034    c0,17.161,9.613,31.895,25.335,38.545v54.983c-13.655,1.887-26.593,6.019-38.362,12.219    c-24.796-18.778-105.565-76.997-151.746-112.126c1.078-3.953,1.798-8.085,1.798-12.397c0-25.875-21.113-46.898-47.078-46.898    c-25.875,0-46.898,21.023-46.898,46.898c0,25.965,21.023,46.988,46.898,46.988c8.805,0,16.98-2.606,24.078-6.828L266.197,216.109z     M346.606,363.095c-34.229,0-61.991-27.763-61.991-61.994c0-34.229,27.762-61.99,61.991-61.99c34.23,0,61.992,27.761,61.992,61.99    C408.599,335.332,380.837,363.095,346.606,363.095z" fill="#FF7A59"/>
                                            </g>
                                        </g>
                                        <g id="Layer_1"/>
                                    </SvgIcon>

                                </HubSpotIconButton>
                                {this.state.goToHubSpot &&
                                    <CircularProgress size={24} style={styles.buttonLoading} color="primary" />
                                }
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid >
        );
    }

    render() {
        const { t } = this.props;    
        return (
            <>
                <CustomDialog
                    open={this.props.isOpen}
                    onClose={this.handleClose}
                    maxWidth='sm'
                    fullWidth={true}
                    style={{
                        ...(this.state.goToDownload || this.state.goToYoutube) && styles.loading
                    }}
                    id="Jest-shareDialog"
                    cyid={"shareWideoDialog"}
                >
                    <AppBar position="static" style={styles.dialogAppBar}>
                        <Toolbar style={{ ...styles.toolbar }}>
                            <Typography
                                variant="h5"
                                color="inherit"
                                style={{
                                    ...styles.fontWeightTypography
                                }}
                            >
                                {t("dialogs.share.title")}
                            </Typography>
                        </Toolbar>
                        <Tabs
                            value={this.state.tab}
                            onChange={this.handleChange}
                            indicatorColor="primary"
                            variant="fullWidth"
                            style={{
                                ...styles.tab
                            }}
                        >
                            <Tab key="export" label={t("dialogs.share.actions.export.label")} icon={<FolderOutlinedIcon />} id="Jest-shareTabExport" />
                            <Tab key="embed" label={t("dialogs.share.actions.embed.label")} icon={<CodeIcon />} />
                            <Tab
                                key="share"
                                label={t("dialogs.share.actions.url.label")}
                                icon={
                                    <SvgIcon>
                                        <path d={ICONS.REDO} />
                                    </SvgIcon>
                                }
                            />

                        </Tabs>
                    </AppBar>
                    <DialogContent>
                        <Box mt={2} pb={4}>
                            {this.state.tab === 0 &&
                                this.renderExportTab()
                            }
                            {this.state.tab === 1 &&
                                this.renderEmbedTab()
                            }
                            {this.state.tab === 2 &&
                                this.renderShareTab()
                            }
                        </Box>
                    </DialogContent>
                    <NotificationSnackBar snackBarOpen={this.state.snackBarOpen}
                        snackBarText={this.state.snackBarText}
                        snackBarError={false}
                        handleCloseSnackBar={this.closeSnackBarMessage}
                        parentComponent={'ShareWideoDialog'} />
                </CustomDialog>
            </>
        );
    }
}

export default withTranslation("dialogs")(ShareWideoDialog);
