import React from 'react';
import styled from '@emotion/styled';
import { withClock, WithClock } from '../../common/ui/WithClock';
import { css } from '@emotion/react';

import { blue } from '@mui/material/colors';

import { BrandContext } from 'src/common/ui/BrandContextProvider';

const getThemeStyles = (primaryColor: string) => ({
  time_wrap: {},
  time_wrap_range_full: {
    '& .time_range': {
      height: '8px',
      '&::-webkit-slider-thumb': {
        width: '16px',
        height: '16px',
        '-webkit-appearance': 'none',
        position: 'relative' as 'relative',
        margin: '-0.25em',
        border: 'none',
        borderRadius: '50%',
        background: primaryColor,
        boxShadow: `inset 0 0 0 0.25em ${primaryColor}`,
      },
    },
  },
  time_range: {
    '-webkit-appearance': 'none',
    cursor: 'pointer',
    height: '3px',
    margin: '0',
    transition: 'height 0.1s ease-in',
    verticalAlign: 'bottom',
    width: '100%',
    '&:focus': {
      outline: 'none',
    },
    '&::-webkit-slider-thumb': {
      '-webkit-appearance': 'none',
      background: '#aeaeae',
      borderRadius: '8px',
      boxShadow: 'inset 0 0 0 5px #eaeaea',
      height: 0,
      transition: 'height 0.1s ease-in',
      width: 0,
    },
  },
});

const TimeWrap = styled.div<{ themeStyle: any }>`
  ${({ themeStyle }) => themeStyle}
`;
interface TimeRangeInputProps {
  currentTime: number;
  toPx: (ms: number) => number;
  end: number;
  primaryColor: string;
}

const TimeRangeInput = styled.input<TimeRangeInputProps>`
  ${({ primaryColor }) => css(getThemeStyles(primaryColor).time_range)}
  ${({ currentTime, toPx, end, primaryColor }) => css`
    background: linear-gradient(
      to right,
      ${primaryColor} 0%,
      ${primaryColor} ${toPx(currentTime)}px,
      #777 ${toPx(currentTime)}px,
      #777 ${toPx(currentTime + 500)}px,
      #444 ${toPx(currentTime + 500)}px, #444 ${toPx(end)}px
    );
  `}
`;

interface Props {
  start: number;
  end: number;
  onSeek: (seekedTime: number) => void;
  currentTime: number;
  onTimeSliderMouseDown: () => void;
  onTimeSliderMouseUp: () => void;
  onTimeSliderMouseEnter: () => void;
  onTimeSliderMouseLeave: () => void;
  handleShowSliderPlayer: boolean;
}

type Styled = any;

type PropsWithStylesWithClock = Props & WithClock & Styled & { theme?: string };

class TimeSliderPlayer extends React.Component<PropsWithStylesWithClock> {
  static contextType = BrandContext;
  context: React.ContextType<typeof BrandContext>;   

  toPx = (ms: number) => {
    return (this.handleResizeTimelineWidth() * ms) / (this.props.end - this.props.start);
  };

  componentDidMount() {
    this.handleResizeTimelineWidth();
    window.addEventListener('resize', this.handleResizeTimelineWidth.bind(this));
  }

  handleResizeTimelineWidth() {
    return window.innerWidth;
  }

  render() {
    const { start, end, currentTime, handleShowSliderPlayer } = this.props;

    const brandConfig = this.context;
    const primaryColor = brandConfig?.primaryColor ?? blue['A200']; 
    
    const themeStyle = getThemeStyles(primaryColor).time_wrap_range_full;

    return (
      <TimeWrap themeStyle={handleShowSliderPlayer ? themeStyle : {}}>
        <TimeRangeInput
          className="time_range"
          type="range"
          min={start}
          max={end - 1}
          step="1"
          value={currentTime}
          onChange={this.onSeek}
          list="tickmarks"
          id={'timeSlider'}
          currentTime={currentTime}
          toPx={this.toPx}
          end={end}
          onMouseDown={this.props.onTimeSliderMouseDown}
          onMouseUp={this.props.onTimeSliderMouseUp}
          onTouchStart={this.props.onTimeSliderMouseDown}
          onTouchEnd={this.props.onTimeSliderMouseUp}
          onMouseEnter={this.props.onTimeSliderMouseEnter}
          onMouseLeave={this.props.onTimeSliderMouseLeave}
          primaryColor={primaryColor}
        />
      </TimeWrap>
    );
  }

  private onSeek = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const seekedTime = parseInt(event.target.value, 10);
    this.props.onSeek(seekedTime);
  }
}

export default withClock(TimeSliderPlayer);
