import { WideoDefVersion } from "../../core/model/WideoDef";
import ITransformer from "../ITransformer";

export default class TransformerToV60 implements ITransformer {
  readonly toVersion: WideoDefVersion = WideoDefVersion.V60;

  // tslint:disable-next-line:no-any
  public transformWideo(wideoDef: any) {

    //Update version and timestamp
    wideoDef.version = this.toVersion;
    wideoDef.timeStamp = Date.now();

    for (const sceneDef of wideoDef.scenes) {
      this.transformScene(sceneDef);
    }

  }

  // tslint:disable-next-line:no-any
  transformScene(sceneDef: any): void {

    //Update version and timestamp
    sceneDef.version = this.toVersion;

    for (const wideoObjectDef of sceneDef.objects) {
      this.transformWideoObject(wideoObjectDef, true);
    }
  }

  // tslint:disable-next-line:no-any
  transformWideoObject(wideoObjectDef: any, applyScale: boolean): void {

    // Replace scaleXInverted and scaleYInverted with a negative scale 
    // directly on the attributes of the object
    if (applyScale) {
      if (wideoObjectDef.scaleXInverted) {
        wideoObjectDef.attributes.scaleX *= -1;
      }
      if (wideoObjectDef.scaleYInverted) {
        wideoObjectDef.attributes.scaleY *= -1;
      }
    }
    delete wideoObjectDef.scaleXInverted;
    delete wideoObjectDef.scaleYInverted;

    for (const innerObjectDef of wideoObjectDef.objects) {
      // This is a AnimatedObjectGroup do not apply the scale of any children,
      // just invert scale on the topmost object.
      if (wideoObjectDef.class === 'animatedGroupObject') {
        this.transformWideoObject(innerObjectDef, false);
      } else {
        this.transformWideoObject(innerObjectDef, applyScale);
      }
    }

  }
}