/**
 * local WideoFile class polyfill for File class. IE support
 */
export default class WideoFile extends Blob {
    public lastModifiedDate: Date;
    public lastModified: number;
    public name: string;

    constructor(filename: string, buf: ArrayBuffer[], options?: BlobPropertyBag) {
        super(buf,options);
        this.lastModifiedDate = new Date();
        this.lastModified = + this.lastModifiedDate;
        this.name = filename;
    }
}