import { Class } from './model/WideoDef';
import TextComponent from './components/TextComponent';
import MaskComponent from './components/MaskComponent';
import WideoObject from './WideoObject';

export const MAX_FONT_SIZE = 896;
export const MIN_FONT_SIZE = 1;

export default class TextObject extends WideoObject {

  public getText(): string {
    const textComponent: TextComponent = this.getTextComponent();
    if (textComponent) {
      return textComponent.getText();
    }

    return null;

  }

  public fastSetText = (text: string): void => {
    const textComponent: TextComponent = this.getTextComponent();
    textComponent.fastSetText(text);
  }

  public setText = (text: string): void => {
    const textComponent: TextComponent = this.getTextComponent();
    textComponent.setText(text);
  }

  public setTextObjectFillColor(colorHex: string) {
    const textComponent: TextComponent = this.getTextComponent();
    textComponent.setTextStyle('fill', colorHex);
  }

  public measureTextHeight(text?: string): number {
    const textComponent: TextComponent = this.getTextComponent();
    return textComponent.measureTextHeight(text);
  }

  public getHeight(): number {
    const textComponent: TextComponent = this.getTextComponent();
    return textComponent.getHeight();
  }

  public getTextObjectFillColor(): string {
    const textComponent: TextComponent = this.getTextComponent();
    return textComponent.getTextStyle().fill;
  }

  protected getTextComponent(): TextComponent {
    return this.getComponentByClass(Class.TextComponent) as TextComponent
  }

  protected getMaskComponent(): MaskComponent {
    return this.getComponentByClass(Class.MaskComponent) as MaskComponent;
  }

  public static isTextObject = (object: WideoObject): object is TextObject => {
    return (object && object.getClass() === Class.TextObject);
  }

  public setFontSize(size: number): boolean {
    const textComponent: TextComponent = this.getTextComponent();

    if (size && size >= MIN_FONT_SIZE && size <= MAX_FONT_SIZE) {
      textComponent.setFontSize(size);
      return true;
    }
    return false;
  }

  public getFontSize(): number {
    const textComponent: TextComponent = this.getTextComponent();
    return textComponent.getFontSize();
  }

}
