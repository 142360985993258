import AbstractTemplate from "./AbstractTemplate";
import { WideoObjectDef } from "../../common/core/model/WideoDef";

export default class TemplateObject extends AbstractTemplate{
    def: WideoObjectDef; //not persisted

    constructor(id: string,
        wideoId: string,
        chargeTypeId: number,
        lang: string,
        category: string, basePreviewUrl: string, def?: WideoObjectDef) {
        super();
        this.id = id.toString();
        this.wideoId = wideoId;
        this.chargeTypeId = chargeTypeId;
        this.lang = lang;
        this.category = category;
        this.def = def;
        this.basePreviewUrl = basePreviewUrl;
    }
}
