import UserAsset from "./UserAsset";
import { ACLResourceType } from "./ACLResourceType";

export default class UserAssetImage extends UserAsset {

  public getAssetType = () => {
    return "userImages";
  }

  public getACLResourceType = () => {
    return ACLResourceType.ASSET_IMAGE;
  }
}
