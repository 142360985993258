import { sound } from '@pixi/sound';

import Engine from '../../common/core/Engine';
import Clock from '../../common/core/Clock';
import Wideo from '../../common/core/Wideo';
import Logger from '../../common/log/Logger';
import WideoObject from '../../common/core/WideoObject';
import IClockListener from '../../common/core/IClockListener';

import { PlayState } from './PlayerConstants';
import IPlayerClockListener from './IPlayerClockListener';
import IPlayerStateChangeListener from './IPlayerStateChangeListener';
import WideoDef, { Def, WideoColorDef } from '../../common/core/model/WideoDef';
import ReplaceManager from '../../common/core/replace/ReplaceManager';
import WideoApi from '../../api/WideoApi';
import Deserializer from '../../common/serialization/Deserializer';
import WideoContext from '../../common/core/WideoContext';
import WideoFactory from '../../common/core/WideoFactory';

import WideoDefFactory from '../../common/core/model/WideoDefFactory';
import { fadeOutAudioStartTime, getSupportedMediaExtension, resourcesBaseUrl } from '../../editor/core/EditorConstants';
import WideoInfoLight from '../../api/model/WideoInfoLight';
import MaticParameters from '../../common/core/matic/MaticParameters';
import TextObject from '../../common/core/TextObject';
import Placeholder from '../../common/core/Placeholder';
import AutomationReplaceApi from '../../api/AutomationReplaceApi';
import { hexToInt } from '../../common/ColorUtils';
import GenericApi from '../../api/GenericApi';
import AudioObject from '../../common/core/AudioObject';

const DefaultWideoWidth: number = 1920;
const DefaultWideoHeight: number = 1080;

const replaceColorStartsWith1 = "#";
const replaceColorStartsWith2 = "_color_";
export default class Player implements IClockListener {

  public engine: Engine;
  protected clock: Clock;

  protected wideo: Wideo;

  protected stateChangeListeners: IPlayerStateChangeListener[];
  protected wideoContext: WideoContext;
  protected replaceManager: ReplaceManager;

  protected startTime: number = 0;
  protected stopTime: number = 0;
  protected presentationMode: boolean = false;
  protected presentationModeCurrentScene: number = 0;

  protected playState: PlayState = PlayState.Loading;
  protected loop: boolean;

  protected isFadeOutAudio: boolean;
  protected watermark: boolean; //true if user has branded wideos or not
  protected htmlWatermark: boolean; //true if watermark is showed as html or PIXI regardless of watermark attr. Showed as PIXI image only in encoder for now.
  protected watermarkType: string; //default or edu
  protected chargeTypeId: number; // chargeTypeId of this wideo


  constructor(loadFullVideos: boolean) {
    this.wideoContext = WideoFactory.CreateWideoContext(loadFullVideos);
    this.replaceManager = new ReplaceManager();
    this.engine = new Engine();

    //Create a Clock and add Player as a listener
    this.clock = new Clock();
    this.clock.addListener(this);

    this.stateChangeListeners = [];
    this.isFadeOutAudio = true;

    // Expose player as a global on the window object to be able to access it from an injected script
    // TODO: This can be done nicier if we eject from create-react-app:
    // https://stackoverflow.com/questions/34210274/how-to-execute-a-webpack-module-from-a-script
    (window as any).wideo = (window as any).wideo || {}; // tslint:disable-line:no-any
    (window as any).wideo.player = this; // tslint:disable-line:no-any
    this.htmlWatermark = true;
    this.watermarkType = "default";
  }

  private loadJson = async (environment: string, accessToken: string, wideoId: string, convertParam?: boolean, width?: number, height?: number, jsonUrl?: string): Promise<WideoDef> => {
    let convert: boolean = convertParam;

    const wideoApi: WideoApi = new WideoApi(environment, accessToken);
    let wideoDef: Def

    if (jsonUrl) {
      wideoDef = await GenericApi.httpGetJson(decodeURIComponent(jsonUrl)) as Def;
      // Sanity check, make sure that WideoId matches the id inside the JSON
      if (wideoId !== wideoDef.id) {
        throw new Error("Failed reading JSON from URL: " + decodeURIComponent(jsonUrl));
      }
    } else {
      try {
        wideoDef = await wideoApi.getJson(wideoId) as Def;
      } catch (error) {
        Logger.warn("Could not find HTML5 wideo, will fallback to flash if convert.")
      }      
    }
    
    //if html5 json exists, don't do convertion
    if (wideoDef && wideoDef.version && wideoDef.version >= 3.6) {
      convert = false;
    }
    else {
      if (convert) {
        wideoDef = await wideoApi.getJsonFlash(wideoId) as Def;
      }
    }

    if (wideoDef) {
      const deserializedWideoDef: WideoDef = await new Deserializer().deserializeFromJson(environment, accessToken, wideoDef, convert, width, height) as WideoDef;
      
      return deserializedWideoDef;
    } else {
      return null
    }
    
  }

  public init = async (view: HTMLCanvasElement, environment: string, accessToken: string,
    wideoId: string, wideoDef?: WideoDef, newWideo?: boolean, convert?: boolean, loop?: boolean,
    width?: number, height?: number, forceCanvas?: boolean, antialias?: boolean, replace?: string, replaceId?: string, jsonUrl?: string): Promise<void> => {
    try {
      const wideoApi: WideoApi = new WideoApi(environment, accessToken);
      const wideoInfoLightPromise: Promise<void | WideoInfoLight> = wideoApi.getInformation(wideoId, jsonUrl, true).catch((error) => {
        Logger.warn("Failed getting information for wideo: " + wideoId);
      });

      let actualWideoDef: WideoDef;
      if (wideoDef) {
        // If the wideoDef is passed directly in to the Player (Like in the case of Wideo Preview inside the editor)
        actualWideoDef = wideoDef;
      } else if (newWideo) {
        let alreadyExistsJsonDef: WideoDef;
        try {
          alreadyExistsJsonDef = await this.loadJson(environment, accessToken, wideoId, convert, width, height, jsonUrl) as WideoDef;
        } catch (e) {
          if (e.message.includes('resource.not.found.exception') &&
            e.message.includes('The wideo could not be found')) {
            //do nothing. Wideo json doesn't exists
          } else {
            // Any other error should fail loading of the editor as usual
            throw e;
          }
        }
        if (!alreadyExistsJsonDef) { // new param is present but, the wideo was saved. Avoid problem with back button
          // Create a new WideoDef from Scratch if json doesn't exists
          actualWideoDef = WideoDefFactory.CreateEmptyWideoDefWithDefaultScene(wideoId, DefaultWideoWidth, DefaultWideoHeight);
        } else {
          actualWideoDef = alreadyExistsJsonDef;
        }
      } else {
        // Create the WideoDef from loading the Wideo JSON from API and the deserializing (transform/convert and scale) to WideoDef
        actualWideoDef = await this.loadJson(environment, accessToken, wideoId, convert, width, height, jsonUrl) as WideoDef;
      }

      if (!actualWideoDef) {
        throw new Error("Wideo not found: " + wideoId);
      }

      //BUGFIX: Force change the wideoId in the wideo so that it corresponds to the loaded wideoID, to avoid problems
      //        with clone. Clone leaves the original wideoId in the wideo.
      actualWideoDef.id = wideoId;

      this.addWebGLContextListeners(view); // Just for debugging 

      // Initialize the engine
      this.engine.init(view, forceCanvas, antialias, actualWideoDef.width, actualWideoDef.height);
      this.wideoContext.setRenderer(this.engine.renderer);

      const wideoInfoLight = await wideoInfoLightPromise; // Sync with Wideo Information request from API
      if (wideoInfoLight) {
        this.watermark = wideoInfoLight.watermark;
        this.watermarkType = wideoInfoLight.watermarkType;
        this.chargeTypeId = wideoInfoLight.chargeTypeId;
        if (!this.htmlWatermark && this.watermark) {
          //if watermark need to be added in PIXI
          if (this.watermarkType === "edu") {
            this.wideoContext.setWatermarkSrc(resourcesBaseUrl + "/img/editor/icons/watermark-edu.png");
          } else {
            this.wideoContext.setWatermarkSrc(resourcesBaseUrl + "/img/editor/icons/watermark.png");
          }
        }
      }
      this.wideo = WideoFactory.CreateWideo(this.wideoContext, actualWideoDef);
      await this.wideoContext.applyAndLoad('Player.init()');

      //video automatization code
      if (replace || replaceId) {
        await this.processVideoAutomation(environment, replace, replaceId);
      }
      //End automatization code

      this.engine.addWideo(this.wideo);
      this.playState = PlayState.Loaded;
      this.loop = loop;
      this.presentationMode = this.wideo.isPresentationMode()

      this.fireStateChangeEvent(PlayState.Loaded);

    }
    catch (error) {
      this.playState = PlayState.Error;
      this.fireStateChangeEvent(PlayState.Error);
      Logger.warn("Error in Player.init(), error:", error);
      throw error;
    }

  }

  private parameterHasValue = (parametersToReplace: MaticParameters, parameter: string): boolean => {
    return parametersToReplace &&
      parametersToReplace[parameter] &&
      typeof parametersToReplace[parameter] === 'string' &&
      parametersToReplace[parameter].toLowerCase().trim() !== "";
  }

  private parameterIsColor = (parameter: string): boolean => {
    return parameter.startsWith(replaceColorStartsWith1) || parameter.toLocaleLowerCase().startsWith(replaceColorStartsWith2)
  }

  private processVideoAutomation = async (environment: string, replace: string, replaceId: string) => {
    try {
      Logger.log("process video automation...");
      // Get all replaceable objects and colors in this wideo
      const replacableObjects: WideoObject[] = this.getReplaceManager().getReplaceableWideoObjects(this);
      const replaceableColors = this.getWideo().getColors();
      const parametersToReplace: MaticParameters = await this.getParamsToReplace(replaceId, environment, replace);
      // Loop over all parameters to replace, find all matching objects or colors and replace them
      await this.replaceParameters(parametersToReplace, replacableObjects, replaceableColors);
    } catch (e) {
      Logger.error("MATIC: A problem ocurred trying to replace parameters with MATIC", e);
      throw e;
    }
  }

  private async replaceParameters(parametersToReplace: MaticParameters, replacableObjects: WideoObject[], replaceableColors: WideoColorDef[]) {
    Logger.log(`replacing parameters...`);
    for (const parameterName in parametersToReplace) {
      if (parametersToReplace.hasOwnProperty(parameterName) && this.parameterHasValue(parametersToReplace, parameterName)) {
        Logger.log(`replacing ${parameterName}`);
        await this.replaceObjects(replacableObjects, parameterName, parametersToReplace);
        this.replaceColors(parameterName, parametersToReplace, replaceableColors);
      } else {
        Logger.warn("MATIC: There is no parameter in the template with the name: " + parameterName);
      }
    }
  }

  private replaceColors(parameterName: string, parametersToReplace: MaticParameters, replaceableColors: WideoColorDef[]) {
    // Handle Legacy Color replace (parameter names matching "_color_xxxxxx", or "#xxxxxx")
    if (this.parameterIsColor(parameterName)) {
      Logger.log(`replacing _color field...`);
      let originalColor: string = this.getOriginalColor(parameterName)
      if (parameterName.toLowerCase().startsWith(replaceColorStartsWith2)) {
        //extract color from variable name ex: _color_ff0000 generates #ff0000
        originalColor = replaceColorStartsWith1 + parameterName.toLowerCase().slice(replaceColorStartsWith2.length);
      }
      const fromColor = hexToInt(originalColor);
      const toColor = hexToInt(parametersToReplace[parameterName].toLowerCase());
      this.getReplaceManager().replaceColor(this.getWideo(), fromColor, toColor);
      Logger.log("Automation: Legacy replaced color:  " + originalColor + ", with value: " + parametersToReplace[parameterName]);
    }
    // If there is a replaceable color with the same name as this parameter
    const color = this.getReplacebleColor(replaceableColors, parameterName)
    if (color) {
      Logger.log(`replace ${parameterName} color...`);
      const fromColor = hexToInt(color.color);
      const toColor = hexToInt(parametersToReplace[parameterName]);
      this.getReplaceManager().replaceColor(this.getWideo(), fromColor, toColor);
      Logger.log("Automation: replaced color:  " + color.color + ", with value: " + parametersToReplace[parameterName]);
    }
  }

  private getReplacebleColor(replaceableColors: WideoColorDef[], parameterName: string) { // TODO: return first or undefined
    return replaceableColors?.find((color)=>{
      return color.name === parameterName
    })
  }

  private getOriginalColor(parameterName: string): string {
    let originalColor: WideoColorDef
    if (this.getWideo().getColors()) {
      originalColor = this.getWideo().getColors().find((color) => {
        return color.name === parameterName;
      })
    }
    return (originalColor) ? originalColor.color : ""
  }

  private async replaceObjects(replacableObjects: WideoObject[], parameterName: string, parametersToReplace: MaticParameters) {
    Logger.log(`replacing objects...`);
    const matchingObjects = replacableObjects.filter(object => {
      return object.getReplaceableName().toLowerCase() === parameterName.toLowerCase();
    });
    // For all matching objects (same paramter name)
    for (const object of matchingObjects) {
      // The matching objects is a placeholder
      if (Placeholder.isPlaceholder(object)) {
        Logger.log(`replacing placeholder with ${parametersToReplace[parameterName]}`);
        const extension = await this.getUrlExtension(parametersToReplace, parameterName);
        await this.getReplaceManager().replaceObjectWithSrc(object, parametersToReplace[parameterName], extension, this);
        // Else the matching object is a Text Object
      } else if (TextObject.isTextObject(object)) {
        Logger.log(`replacing text object with ${parametersToReplace[parameterName]}`);
        this.getReplaceManager().replaceText(object, parametersToReplace[parameterName], this);
      } else {
        Logger.warn('Automation: Trying to replace an object of a non suported class, class: ' + object.getClass());
      }
    }
  }

  private async getUrlExtension(parametersToReplace: MaticParameters, parameterName: string): Promise<string> {
    let extension = await getSupportedMediaExtension(parametersToReplace[parameterName]);
    // Hack to get the extension of a "RemoteAsset url"
    if (parametersToReplace[parameterName].includes('/automation/replace/wrapurl?url=')) {
      const remoteUrl = decodeURIComponent(parametersToReplace[parameterName].split('/automation/replace/wrapurl?url=')[1]);
      extension = await getSupportedMediaExtension(remoteUrl);
    }
    //OLD HACK - Remove after migration to new version with replaceId
    if (parametersToReplace[parameterName].includes('/awg/remoteasset?url=')) {
      const remoteUrl = decodeURIComponent(parametersToReplace[parameterName].split('/awg/remoteasset?url=')[1]);
      extension = await getSupportedMediaExtension(remoteUrl);
    }
    return extension;
  }

  private async getParamsToReplace(replaceId: string, environment: string, replace: string) {
    Logger.log("getting params to replace...");
    let parametersToReplace: MaticParameters
    if (replaceId) {
      const api = new AutomationReplaceApi(environment);
      const result = await api.getReplaceInfo(replaceId);
      if (result && result.statusCode === 200) {
        parametersToReplace = result.body as MaticParameters;
      }
    } else {
      parametersToReplace = JSON.parse(decodeURIComponent(replace));
    }
    Logger.log(`params to replace: ${JSON.stringify(parametersToReplace)}`);
    return parametersToReplace;
  }

  private addWebGLContextListeners(view: HTMLCanvasElement) {
    view.addEventListener('webglcontextlost', () => {
      Logger.warn('webglcontextlost, Vendor/Renderer: ' + this.engine.getVendorGLParameter() + '/' + this.engine.getRendererGLParameter());
    }, false);

    view.addEventListener('webglcontextrestored', () => {
      Logger.warn('webglcontextrestored, Vendor/Renderer: ' + this.engine.getVendorGLParameter() + '/' + this.engine.getRendererGLParameter());
    }, false);
  }

  public initWideoInteractivity = (): boolean => {
    return this.wideo.startWideoInteractivity();
  }

  public isWideoLoaded = (): boolean => {
    return this.wideo && this.playState !== PlayState.Loading && this.playState !== PlayState.Error;
  }

  public getWatermark = (): boolean => {
    return this.watermark;
  }

  public getWatermarkType = (): string => {
    return this.watermarkType;
  }

  public isHtmlWatermark = (): boolean => {
    return this.htmlWatermark;
  }

  public getWideo = () => {
    return this.wideo;
  }

  public getWideoId = (): string => {
    return this.getWideo().getId();
  }

  public getWideoLength = (): number => {
    if (this.getWideo()) {
      return this.getWideo().getWideoLength();
    } else {
      return 0;
    }
  }

  public getCurrentTime = (): number => {
    return this.clock.getCurrentTime();
  }

  public reset = () => {
    this.stopTime = this.getWideoLength();
  }

  /**
   * Play wideo/scene
   */
  public play = (): void => {
    this.isFadeOutAudio = true;
    this.reset();
    this._play();
  }

  /**
   * Pause wideo/scene
   */
  public pause = (): void => {
    this.clock.stop();
    this.playState = PlayState.Paused;
    this.wideo.pause();
    this.fireStateChangeEvent(PlayState.Paused);
  }

  /**
   * Stop wideo/scene
   */
  public stop = async (): Promise<void> => {
    this.clock.stop();
    this.playState = PlayState.Stopped;
    this.wideo.pause();
    this.clock.seek(0);
    this.fireStateChangeEvent(PlayState.Stopped);
    await this.wideo.seek(0);
  }


  /**
   * Seek to time wideo and wait for all contained objects to have finished seeking before returning
   */
  public syncSeek = async (time: number): Promise<void> => {
    await this.wideo.seek(time)
    this.clock.seek(time);
    if (this.presentationMode) {
      this.presentationModeCurrentScene = this.getWideo().getSceneIndexAtTime(time);
    }
  }

  /**
   * Seek to time wideo without waiting for wideo and all contained objects to seek before returning.
   * Makes an async render of canvas when all objects have finished seeking though.
   */
  public fastSeek = (time: number): void => {

    this.wideo.seek(time).then(() => {
      // This is to render the canvas once the video has sought
      this.engine.render();
    }).catch((error) => {
      Logger.warn("Failed fast seeking, reason: " + Logger.errorToString(error));
    });
    this.clock.seek(time);

    if (this.presentationMode) {
      //when seek in presentation mode, we need to set the current scene at time,
      //and update the stopTime to the scene selected
      this.presentationModeCurrentScene = this.getWideo().getSceneIndexAtTime(time);
      this.stopTime = this.getWideo().getSceneAt(this.presentationModeCurrentScene).getEndTime();
    }
  }

  public playScene = async (scene: WideoObject) => {
    this.stopTime = scene.getEndTime();
    await this.syncSeek(scene.getStartTime());
    this.isFadeOutAudio = false;
    this._play();
  }

  public playObject = async (object: WideoObject) => {
    this.stopTime = object.getGlobalEndTime();
    await this.syncSeek(object.getGlobalStartTime());
    this.isFadeOutAudio = false;
    this._play();
  }

  protected _play = () => {
    this.wideo.play();
    this.clock.start();
    this.playState = PlayState.Playing;
    this.fireStateChangeEvent(PlayState.Playing);
  }

  public seekToScene = async (scene: WideoObject): Promise<void> => {
    this.stopTime = scene.getEndTime();
    if (scene) {
      await this.syncSeek(scene.getStartTime() + (scene.getLifetime() / 2));
    }
  }

  public getFps = () => {
    return this.clock.getFps();
  }

  public update = (globalElapsedMillis: number): void => {
    if (this.isWideoLoaded()) {
      // If we have passed the end of the Wideo (or Scene)

      if ((globalElapsedMillis >= this.stopTime &&
        globalElapsedMillis !== 0 &&
        this.stopTime !== 0)) {

        if (this.loop) {
          this.clock.stop();
          this.clock.seek(0);
          this.reset();
          this.play();
        }
        else {
          //presentation mode check
          //when timeElapsed >= stopTime and stopTime !== wideo.getEndTime
          //then we pause wideo. Because we don't reach the end yet
          if (this.presentationMode &&
            this.stopTime !== this.getWideoLength()) {
            this.pause();
          }
          else {
            this.clock.stop();
            this.wideo.pause();
            this.playState = PlayState.Ended;
            this.clock.seek(this.stopTime - 1); // The end time is NON-inclusive
            this.fireStateChangeEvent(PlayState.Ended);
          }
        }
      } else {
        //fadeout audio
        this.fadeOutAudio(globalElapsedMillis);
        this.wideo.update(globalElapsedMillis, this.playState === PlayState.Playing);
        this.engine.render();
      }
    }
  }

  private fadeOutAudio = (globalElapsedMillis: number) => {
    if (this.playState === PlayState.Playing && this.isFadeOutAudio) {
      const audioObjects: AudioObject[] = this.wideo.getAudioObjects();
      audioObjects.map((audioObject: AudioObject) => {
        //The time where the audio volume = 0
        const fadeOutAudioEndTime: number =
          Math.min(this.getWideoLength(), audioObject.getEndTime());
        //the time distance where the audio starts to down the volume and the volume = 0
        const timeToEnd: number = fadeOutAudioEndTime - globalElapsedMillis;
        if (timeToEnd < fadeOutAudioStartTime) {
          const currentTime = globalElapsedMillis - (fadeOutAudioEndTime - fadeOutAudioStartTime);
          audioObject.fadeOut(currentTime);
        }
      });
    }
  }

  /** Sets the global volume of all sounds in the wideo.
   *  PIXI.sound handles the multiplication of the global volume
   *  with the volume of each individual sound component in the wideo
   */
  public setVolume = (volume: number) => {
    sound.volumeAll = volume * 0.01;
  }

  public addStateChangeListener = (listener: IPlayerStateChangeListener): void => {
    this.stateChangeListeners.push(listener);
  }

  public removeStateChangeListener = (listener: IPlayerStateChangeListener): void => {
    if (this.stateChangeListeners) {
      this.stateChangeListeners = this.stateChangeListeners.filter((item) => {
        return item !== listener;
      });
    }
  }

  protected fireStateChangeEvent = (playState: PlayState): void => {
    for (const listener of this.stateChangeListeners) {
      listener.onStateChange(playState);
    }
  }

  public addClockListener = (listener: IPlayerClockListener): void => {
    this.clock.addListener(listener);
  }

  public removeClockListener = (listener: IPlayerClockListener): void => {
    if (this.clock) {
      this.clock.removeListener(listener);
    }
  }


  //presentation mode
  public startPresentationMode = async (): Promise<void> => {
    this.presentationMode = true;
    await this.stop();
    this.presentationModeCurrentScene = 0;
  }

  public stopPresentationMode = async (): Promise<void> => {
    this.presentationMode = false;
    await this.stop();
    this.presentationModeCurrentScene = 0;
  }

  public getCurrentScenePresentationMode = (): number => {
    return this.presentationModeCurrentScene;
  }

  public nextScenePresentationMode = async (): Promise<void> => {
    //if wideo is playing go to the end of the current scene
    if (this.playState === PlayState.Playing) {
      const scene: WideoObject = this.getWideo().getSceneAt(this.presentationModeCurrentScene);
      this.pause();
      await this.syncSeek(scene.getEndTime() - 1);
    } else {
      this.pause();
      //if it's the last scene, go to first scene and pause
      if (this.presentationModeCurrentScene === this.getWideo().getScenesQty() - 1
        && this.getWideo().getScenesQty() > 1) {
        this.presentationModeCurrentScene = 0;
        await this.syncSeek(this.getWideo().getSceneAt(0).getStartTime());
      }
      else {
        //if it's in beggining of the wideo or the scene, play the scene only
        let scene: WideoObject = this.getWideo().getSceneAt(this.presentationModeCurrentScene);
        if (this.getCurrentTime() === scene.getStartTime()) {
          await this.playScene(this.getWideo().getSceneAt(this.presentationModeCurrentScene));
        }
        else {
          // if doesn't go to next scene and play it
          //edge case. When wideo has only a scene
          if (this.presentationModeCurrentScene < this.getWideo().getScenesQty() - 1) {
            this.presentationModeCurrentScene++;
          }
          scene = this.getWideo().getSceneAt(this.presentationModeCurrentScene);
          await this.playScene(scene);
        }
      }
    }
  }

  public previousScenePresentationMode = async (): Promise<void> => {
    if (this.presentationModeCurrentScene > 0) {
      this.presentationModeCurrentScene--;
      const scene: WideoObject = this.getWideo().getSceneAt(this.presentationModeCurrentScene);
      this.pause();
      await this.syncSeek(scene.getStartTime());
    }
  }

  public getWideoContext() {
    return this.wideoContext;
  }

  public getReplaceManager() {
    return this.replaceManager;
  }

  public destroy() {
    if (this.clock) {
      this.clock.removeListener(this);
      this.clock.destroy();
      this.clock = null;
    }

    if (this.stateChangeListeners) {
      for (const listener of this.stateChangeListeners) {
        this.removeStateChangeListener(listener);
      }
      this.stateChangeListeners = null;
    }

    if (this.engine) {
      this.engine.destroy();
      this.engine = null;
    }

    if (this.wideo) {
      this.wideo.destroy();
      this.wideo = null;
    }

    if (this.wideoContext) {
      this.wideoContext.destroy();
    }
  }

}
