import {WideoDefVersion, Class, MaskComponentDef} from "../../core/model/WideoDef";
import ITransformer from "../ITransformer";

export default class TransformerToV42 implements ITransformer {
    readonly toVersion: WideoDefVersion = WideoDefVersion.V42;


    constructor() {
      // Intentionally blank
    }

    /*
      Add width, height and version to scenes
    */

    // tslint:disable-next-line:no-any
    public transformWideo( wideoDef: any ): void {

      //Update version and timestamp
      wideoDef.version = this.toVersion;
      wideoDef.timeStamp = Date.now();

      for (const sceneDef of wideoDef.scenes) {
        this.transformScene(sceneDef);
      }

    }

    // tslint:disable-next-line:no-any
    transformScene(sceneDef: any): void {      
      
      //Update version 
      sceneDef.version = this.toVersion;
      
      for (const componentDef of sceneDef.components ) {
        if (componentDef.class === Class.MaskComponent) {
          const maskComponentDef = componentDef as MaskComponentDef;
          sceneDef.width = maskComponentDef.width;
          sceneDef.height =  maskComponentDef.height;
        }
      }
    }

}
