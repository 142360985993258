import Logger from '../../common/log/Logger';
import WideoDef, { 
  Def, 
  SceneDef, 
  Class, 
  WideoDefVersion, 
  WideoObjectDef, 
  MaskObjectDef, 
  TextComponentDef, 
  MaskComponentDef, 
  AnimationComponentDef, 
  ComponentDef, 
  ShapeComponentDef 
} from '../../common/core/model/WideoDef';

export enum ScaleStrategy {
  Fit = 'Fit',
  Cover = 'Cover',
  Height = 'Height',
  Width = 'Width'
}

/** Scaler parses a Wideo and mutates all scales and dimensions in the Wideo. Especifically it scales Text using fontSize
    instead of transform scale which results in crisp texts (at the native resolution)
*/
export default class Scaler { 

  scale(def: Def, width: number, height: number, strategy: ScaleStrategy): void {
    
    if (!width || !height) {
      //Width or height not supplied just return without scaling
      Logger.warn('Calling scaler without widht and/or height. No scaling performed for def: ' + def.id + '. Def: ' + def.width + 'x' + def.height + ', width and height: ' + width + "x" + height);
      return;
    }
    let scale: number = 1;
    const scaleX = (width ? width / def.width : 1);
    const scaleY = (height ? height / def.height : 1);
    switch (strategy) {
      case ScaleStrategy.Cover: {
        scale = Math.max(scaleX, scaleY);
        break;
      }
      case ScaleStrategy.Fit: {
        scale = Math.min(scaleX, scaleY);
        break;
      }
      case ScaleStrategy.Height: {
        scale = scaleY;
        break;
      }
      case ScaleStrategy.Width: {
        scale = scaleX;
        break;
      }
      default: {
        return;
      }
        
    }
    
    if (scale !== 1) {
      Logger.debug('Scaling ' + def.class + ' ' + def.id + ' from ' + def.width + 'x' + def.height + ' by a scale of ' + scale + ' (requirement: ' + width + 'x' + height + ')'); 
      if (def.class === Class.Wideo) {
        const wideoDef: WideoDef = def as WideoDef;
        if (wideoDef.version >= WideoDefVersion.V43) { // CURRENT_WIDEO_DEF_VERSION ) {
          this.scaleWideo(wideoDef, scale);  
        } else {
          throw new Error('To scale a Wideo the HTML5 JSON version must be equal or greater than: ' + WideoDefVersion.V43);
        }
      } else if (def.class === Class.Scene) {
        const sceneDef: SceneDef = def as SceneDef;        
        this.scaleScene(sceneDef, scale);
      }
    }
  }

  scaleWideo(wideoDef: WideoDef, scale: number): void {
    wideoDef.width *= scale;
    wideoDef.height *= scale;

    for (const sceneDef of wideoDef.scenes) {
      this.scaleScene(sceneDef, scale);
    }
  }

  scaleScene(sceneDef: SceneDef, scale: number): void {

    sceneDef.attributes.x *= scale;
    sceneDef.attributes.y *= scale;
    sceneDef.width *= scale;
    sceneDef.height *= scale;

    for (const componentDef of sceneDef.components) {
      this.scaleComponent(componentDef, scale);
    }

    for (const objectDef of sceneDef.objects) {
      this.scaleWideoObject(objectDef, scale);
    }
  }

  scaleWideoObject(objectDef: WideoObjectDef, scale: number): void {

    if ( objectDef.class === 'textObject') {
      this.scaleTextObject(objectDef, scale);
    } else if ( objectDef.class === 'maskObject' ) {
        const maskObjectDef = objectDef as MaskObjectDef;
        this.scaleMaskObject(maskObjectDef, scale);
    } else if ( objectDef.class === 'shapeObject' ) {
        this.scaleShapeObject(objectDef, scale);
    }  else if ( objectDef.class === 'objectGroup' ||
                objectDef.class === 'maskedObject' ||
                objectDef.class === 'placeholder' ) {
      this.scaleContainer(objectDef, scale);
    } else {
      objectDef.attributes.x *= scale;
      objectDef.attributes.y *= scale;
      objectDef.attributes.scaleX *= scale;
      objectDef.attributes.scaleY *= scale;
    }

    //Continue recusively with components
    for (const componentDef of objectDef.components) {
      this.scaleComponent(componentDef, scale);
    }

    //Continue recusively with children
    for (const innerObjectDef of objectDef.objects) {
      this.scaleWideoObject(innerObjectDef, scale);
    }
  }

  /** scaleContainer handles scaling of containers
    - objectGroup
    - maskedObject
    - placeholder
  */
  scaleContainer(groupDef: WideoObjectDef, scale: number): void {

    // DO NOT SCALE "scaleX" and "scaleY" of group since we will do that for each individual contained object and component
    groupDef.attributes.x *= scale;
    groupDef.attributes.y *= scale;

  }

  scaleTextObject(textDef: WideoObjectDef, scale: number): void {

    // DO NOT scale "scaleX" and "scaleY" for TextObjects, the text will be scaled using fontSize and width and height of the components instead
    textDef.attributes.x *= scale;
    textDef.attributes.y *= scale;

  }

  scaleShapeObject(textDef: WideoObjectDef, scale: number): void {

    // DO NOT scale "scaleX" and "scaleY" for ShapeObjects, the shape will be scaled using width and height of the components instead
    textDef.attributes.x *= scale;
    textDef.attributes.y *= scale;

  }

  scaleMaskObject(maskObjectDef: MaskObjectDef, scale: number): void {

    // DO NOT scale "scaleX" and "scaleY" for MaskObjects, scale width and height directly instead
    maskObjectDef.attributes.x *= scale;
    maskObjectDef.attributes.y *= scale;
    maskObjectDef.width *= scale;
    maskObjectDef.height *= scale;

  }

  scaleComponent(componentDef: ComponentDef, scale: number): void {

    if (componentDef.class === Class.MaskComponent ) {
      const maskComponent: MaskComponentDef = componentDef as MaskComponentDef;
      maskComponent.width *= scale;
      maskComponent.height *= scale;
    } else if (componentDef.class === Class.ShapeComponent ) {
      const shapeComponent: ShapeComponentDef = componentDef as ShapeComponentDef;
      shapeComponent.width *= scale;
      shapeComponent.height *= scale;
    } else if ( componentDef.class === Class.AnimationComponent) {
      const animationComponent: AnimationComponentDef = componentDef as AnimationComponentDef;
      for (const keyFrame of animationComponent.animation.keyFrames ) {
        keyFrame.attributes.x *= scale;
        keyFrame.attributes.y *= scale;
      }
    } else if ( componentDef.class === Class.TextComponent) {
      const textComponentDef: TextComponentDef = componentDef as TextComponentDef;
      textComponentDef.width *= scale;
      textComponentDef.height *= scale;
      textComponentDef.style.fontSize *= scale;
    }

  }

}
