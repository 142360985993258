import TextComponent from './components/TextComponent';
import ImageComponent from './components/ImageComponent';
import WideoObject from './WideoObject';
import Scene from './Scene';
import MaskObject from './MaskObject';
import IComponent from './components/IComponent';
import AnimationComponent from './components/AnimationComponent';
import TransitionComponent from './components/TransitionComponent';
import BackgroundComponent from './components/BackgroundComponent';
import WideoDef, { SceneDef, WideoObjectDef, ComponentDef, Class, KeyFrameDef } from './model/WideoDef';
import KeyFrame from './animations/KeyFrame';
import Wideo from './Wideo';
import MaskComponent from "./components/MaskComponent";
import AssetsLoader from './AssetsLoader';
import WideoContext from './WideoContext';
import AudioComponent from "./components/AudioComponent";
import ShapeComponent from './components/ShapeComponent';
import AnimatedImageComponent from './components/AnimatedImageComponent';
import VideoComponent from "./components/VideoComponent";
import { pixiFontPreMiddleware } from '../fonts/FontLoader';
import { pixiVideoPostMiddleware } from './VideoLoader';
import AnimatedGroupComponent from './components/AnimatedGroupComponent';
import MaskedObject from './MaskedObject';
import Placeholder from './Placeholder';
import TextObject from './TextObject';
import ImageObject from './ImageObject';
import VideoObject from './VideoObject';
import AudioObject from './AudioObject';
import ShapeObject from './ShapeObject';

export default class WideoFactory {

    protected static COMPONENTS: Object = {
        [Class.MaskComponent]: MaskComponent,
        [Class.ImageComponent]: ImageComponent,
        [Class.BackgroundComponent]: BackgroundComponent,
        [Class.TextComponent]: TextComponent,
        [Class.AnimationComponent]: AnimationComponent,
        [Class.TransitionComponent]: TransitionComponent,
        [Class.ShapeComponent]: ShapeComponent,
        [Class.AnimatedImageComponent]: AnimatedImageComponent,
        [Class.VideoComponent]: VideoComponent,
        [Class.AnimatedGroupComponent]: AnimatedGroupComponent,
        [Class.AudioComponent]: AudioComponent,
    };

    public static OBJECTS: Object = {
        [Class.Scene]: Scene,
        [Class.WideoObject]: WideoObject,
        [Class.ImageObject]: ImageObject,
        [Class.VideoObject]: VideoObject,        
        [Class.AudioObject]: AudioObject,
        [Class.MaskObject]: MaskObject,
        [Class.TextObject]: TextObject,
        [Class.MaskedObject]: MaskedObject,
        [Class.Placeholder]: Placeholder,
        [Class.ShapeObject]: ShapeObject
    }

    public static CreateAssetsLoader(): AssetsLoader {
        return new AssetsLoader();
    }

    public static CreateWideoContext(loadFullVideos: boolean): WideoContext {
        const context: WideoContext = new WideoContext();
        const assetsLoader = this.CreateAssetsLoader();
        assetsLoader.addPreMiddleWare(pixiFontPreMiddleware);
        assetsLoader.setLoadFullVideos(loadFullVideos);
        if (loadFullVideos) {
            assetsLoader.addPostMiddleWare(pixiVideoPostMiddleware);
        }
        context.setAssetsLoader(assetsLoader);
        return context;
    }

    public static CreateWideo(context: WideoContext, wideoDef: WideoDef): Wideo {

        const wideo: Wideo = new Wideo(context, wideoDef);

        // Create all scenes of the wideo
        for (const sceneDef of wideoDef.scenes) {
            const scene: Scene = WideoFactory.CreateScene(context, null, sceneDef);
            wideo.addScene(scene);
        }

        if (wideoDef.audios) {
            //audios
            for (const audioObjectDef of wideoDef.audios) {
                const audioObject: AudioObject = WideoFactory.CreateWideoObject(context, null, audioObjectDef) as AudioObject;
                wideo.addAudioObject(audioObject);
            }
            
        }

        return wideo;

    }

    public static CreateScene(context: WideoContext, parent: WideoObject, sceneDef: SceneDef): Scene {
        const scene: Scene = new Scene(context, parent, sceneDef);

        for (const componentDef of sceneDef.components) {
            const component: IComponent = WideoFactory.CreateComponent(context, scene, componentDef);
            scene.addComponent(component);
        }

        for (const objectDef of sceneDef.objects) {
            const child: WideoObject = WideoFactory.CreateWideoObject(context, scene, objectDef);
            scene.addWideoObject(child);
        }

        return scene;
    }

    public static CreateWideoObject(context: WideoContext, parent: WideoObject, wideoObjectDef: WideoObjectDef): WideoObject {
        let wideoObject: WideoObject;
        if (WideoFactory.OBJECTS[wideoObjectDef.class]) {
            wideoObject = new WideoFactory.OBJECTS[wideoObjectDef.class](context, parent, wideoObjectDef);
        } else {
            wideoObject = new WideoObject(context, parent, wideoObjectDef);
        }

        for (const componentDef of wideoObjectDef.components) {
            const component: IComponent = WideoFactory.CreateComponent(context, wideoObject, componentDef);
            wideoObject.addComponent(component);
        }

        for (const objectDef of wideoObjectDef.objects) {
            const child: WideoObject = WideoFactory.CreateWideoObject(context, wideoObject, objectDef);
            wideoObject.addWideoObject(child);
        }

        return wideoObject;
    }

    public static CreateComponent(context: WideoContext, owner: WideoObject, componentDef: ComponentDef): IComponent {

        const component: IComponent = new WideoFactory.COMPONENTS[componentDef.class](context, owner, componentDef);
        return component;
    }

    public static CreateKeyFrame(keyFrameDef: KeyFrameDef): KeyFrame {
        return new KeyFrame(keyFrameDef);
    }
}
