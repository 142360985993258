import Logger from '../../common/log/Logger';

import Player from '../../player/core/Player';
import { PlayState } from '../../player/core/PlayerConstants';
import IPlayerStateChangeListener from '../../player/core/IPlayerStateChangeListener';

export enum ImageFormat {
  jpg = 'jpg',
  png = 'png',
  rgba = 'rgba'
}

export default class Encoder extends Player implements IPlayerStateChangeListener {

  constructor() {
    super(false);

    // Expose encoder as a global on the window object to be able to access it from an injected script
    // TODO: This can be done nicier if we eject from create-react-app:
    // https://stackoverflow.com/questions/34210274/how-to-execute-a-webpack-module-from-a-script
    (window as any).wideo = (window as any).wideo || {}; // tslint:disable-line:no-any
    (window as any).wideo.encoder = this; // tslint:disable-line:no-any

    this.addStateChangeListener(this);
    this.htmlWatermark = false;
  }

  onStateChange(state: PlayState): void {

    if (state === PlayState.Loaded) {
      Logger.log("Encoder loaded wideo successfully");
    }

  }

  public scale = (scale: number): void => {
    this.engine.scale(scale);
  }

  public generateFrameNo = async (imageFormat: ImageFormat, frame: number, fps: number): Promise<ArrayBuffer> => {
    let time: number = frame / fps * 1000;
    if (time >= this.getWideoLength()) {
      time = this.getWideoLength() - 1;
    }
    return this.generateFrameAt(imageFormat, time);
  }

  public generateFrameAt = async ( imageFormat: ImageFormat, time: number): Promise<ArrayBuffer> => {
        
    await this.syncSeek( time ); // Sync is needed to wait for videos to finish seeking
    this.engine.render(); // Explicitly render the scene
      
    if (ImageFormat.jpg === imageFormat) {
      return this.asyncGetCanvasAsArrayBuffer('image/jpeg', 1);
    } else if (ImageFormat.png === imageFormat) {
      return this.asyncGetCanvasAsArrayBuffer('image/png', 1);
    } else if (ImageFormat.rgba === imageFormat) {
      return this.getCurrentFrameAsPixels().buffer;
    } else {
      throw new Error("Unknown ImageFormat " + imageFormat);
    }
  }


  /**
   * Returns the contents of the stage as an HTMLImageElement.
   * 
   * NOTE: Antialias is NOT applied with WebGL (Shapes looks jagged)
   * 
   */
  public getCurrentFrameAsImage(): HTMLImageElement {
    return this.engine.renderer.plugins.extract.image(this.engine.getStage());
  }

  /**
   * Returns the contents of the stage as an Uint8ClampedArray | Uint8Array of pixels.
   * 
   * NOTE: Antialias is NOT applied with WebGL (Shapes looks jagged)
   * 
   */
  public getCurrentFrameAsPixels(): Uint8ClampedArray | Uint8Array {
    return this.engine.renderer.plugins.extract.pixels(this.engine.getStage());
  }

  /**
   * Returns the contents of the stage as a Blob.
   * 
   * NOTE: Antialias is NOT applied with WebGL (Shapes looks jagged)
   * 
   */
  public getCurrentFrameAsBlob(
    callback: (blob: Blob) => void,
    format: string = "image/jpeg",
    quality: number = 0.95
  ): void {
    this.engine.renderer.plugins.extract
      .canvas(this.engine.getStage())
      .toBlob(callback, format, quality);
  }

  /**
   * Returns the contents of the whole HTMLCanvasElement as a Blob.
   * 
   * NOTE: Antialias IS applied with WebGL (Shapes looks just fine)
   * 
   */
  public getCanvasAsBlob(callback: (blob: Blob) => void,
    format: string = "image/jpeg",
    quality: number = 0.95
  ): void {
    this.engine.renderer.view.toBlob(callback, format, quality);
  }

  /**
   * Returns the contents of the whole HTMLCanvasElement as an ArrayBuffer.
   * 
   * NOTE: Antialias IS applied with WebGL (Shapes looks just fine)
   * 
   */
  public async asyncGetCanvasAsArrayBuffer(
    format: string = "image/jpeg",
    quality: number = 0.95
  ): Promise<ArrayBuffer> {
    return new Promise<ArrayBuffer>(resolve => {
      this.engine.renderer.view.toBlob(
        (blob: Blob) => {
          // TODO: Do this efficient
          const reader: FileReader = new FileReader();
          reader.addEventListener("loadend", function () {
            resolve(reader.result as ArrayBuffer);
          });
          reader.readAsArrayBuffer(blob);
        },
        format,
        quality
      );
    });
  }

  /**
   * Returns the contents of the whole HTMLCanvasElement as an ArrayBuffer.
   * 
   * NOTE: Antialias is NOT applied with WebGL (Shapes looks just jagged)
   * 
   */
  public async asyncGetCurrentFrameAsArrayBuffer(
    format: string = "image/jpeg",
    quality: number = 0.95
  ): Promise<ArrayBuffer> {
    return new Promise<ArrayBuffer>(resolve => {
      this.engine.renderer.plugins.extract.canvas(this.engine.getStage()).toBlob(
        (blob: Blob) => {
          // TODO: Do this efficient
          const reader: FileReader = new FileReader();
          reader.addEventListener("loadend", function () {
            resolve(reader.result as ArrayBuffer);
          });
          reader.readAsArrayBuffer(blob);
        },
        format,
        quality
      );
    });
  }


  /**
   * Returns the contents of the whole HTMLCanvasElement as an Blob.
   * 
   * NOTE: Antialias is NOT applied with WebGL (Shapes looks just jagged)
   * 
   */
  public async asyncGetCurrentFrameAsBlob(
    format: string = "image/jpeg",
    quality: number = 0.95
  ): Promise<Blob> {
    return new Promise<Blob>(resolve => {
      this.engine.renderer.plugins.extract.canvas(this.engine.getStage()).toBlob(
        (blob: Blob) => {
          resolve(blob);
        },
        format,
        quality
      );
    });
  }


  public update = (time: number) => {
    this.wideo.update(time, false);
  }

  public render = () => {
    this.engine.render();
  }

  public destroy() {
    super.destroy();
  }

}
