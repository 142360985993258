import Api from './Api'
import UserAssetBackground from "./model/UserAssetBackground";
import BackgroundFav from './model/BackgroundFav';

interface BackgroundImage {
  backgroundID: number;
  backgroundName: string;
  fileName: string;
  previewFileName?: string;
  userID: number;
  privateBackground?: string;
  orderID: number;
  wideooID?: string;
  libraryID?: number;
  assetUrl: string;
  assetPreviewUrl: string;
  bucket: string;
  fileFormat: string;
  assetUrlSecure: string;
  backgroundIDasString: string;
  assetPreviewUrlSecure: string;
  fav: boolean;
}

export default class BackgroundsApi extends Api {

  public async saveBackground(wideoId: string, inputFile: File): Promise<UserAssetBackground> {

    const path: string = '/backgrounds';
    const body: FormData = new FormData();
    body.append('background_file', inputFile);

    const params: { [s: string]: string; } = {
      accessToken: this._accessToken,
      wideoID: wideoId
    };
    // tslint:disable-next-line:no-any (when a new version of Wideo API is created we can change any to that type)
    const response: any = await this.httpPostFormData<any>(path, body, params);

    return new UserAssetBackground(response.backgroundIDasString,
      response.backgroundIDasString,
      response.assetUrlSecure,
      response.userID,
      response.assetPreviewUrl,
      null,
      true
    );
  }

  public getBackgroundFavs = async (): Promise<BackgroundFav[]> => {
    const path: string = '/backgrounds/fav';
    const response: BackgroundFav[] = await this.httpGetJson<BackgroundFav[]>(path, { accessToken: this._accessToken });
    return response;
  }

  public async saveAsFav(backId: string) {
    const path: string = '/backgrounds/fav/' + backId + '/save';
    const params: { [s: string]: string; } = { accessToken: this._accessToken };
    return this.httpPost<Object>(path, params);
  }

  public async deleteAsFav(backId: string) {
    const path: string = '/backgrounds/fav/' + backId + '/delete';
    const params: { [s: string]: string; } = { accessToken: this._accessToken };
    return this.httpPost<Object>(path, params);
  }

  public getBackgroundShared = async (): Promise<BackgroundFav[]> => {
    const path: string = '/backgrounds/fav';
    const response: BackgroundFav[] = await this.httpGetJson<BackgroundFav[]>(path, { accessToken: this._accessToken });
    return response;
  }

  public async saveAsShared(backId: string) {
    const path: string = '/backgrounds/fav/' + backId + '/save';
    const params: { [s: string]: string; } = { accessToken: this._accessToken };
    return this.httpPost<Object>(path, params);
  }

  public async deleteAsShared(backId: string) {
    const path: string = '/backgrounds/fav/' + backId + '/delete';
    const params: { [s: string]: string; } = { accessToken: this._accessToken };
    return this.httpPost<Object>(path, params);
  }

  public async getBackgroundUrl(backId: string): Promise<string> {
    const path: string = '/backgrounds/' + backId;
    const params: { [s: string]: string; } = {
      accessToken: this._accessToken,
    };

    const background: BackgroundImage = await this.httpGetJson<BackgroundImage>(path, params);
    return background.assetUrlSecure;
  }

  public async deleteBackground(backgroundId: string): Promise<Object> {
    const path: string = '/backgrounds/' + backgroundId + '/DELETE';
    const params: { [s: string]: string; } = { accessToken: this._accessToken };
    return this.httpPost<Object>(path, params);
  }

  public async download(wideoId: string, url: string, mimeType: string): Promise<UserAssetBackground> {
    const path: string = '/backgrounds/download';

    const params: { [s: string]: string; } = {
      wideoID: wideoId,
      accessToken: this._accessToken,
      url: url,
      mimeType: mimeType
    };

    // tslint:disable-next-line:no-any (when a new version of Wideo API is created we can change any to that type)
    const response: any = await this.httpPost<any>(path, params);
    return new UserAssetBackground(response.backgroundIDasString,
      response.backgroundIDasString,
      response.assetUrlSecure,
      response.userID,
      response.assetPreviewUrl, null, false);
  }


}
