import * as React from 'react';
import Main from './Main';
import '../fonts/fonts.css';


/* App is the root component.*/
class App extends React.Component {
  render() {
    return (
          <Main/>
    );
  }
}

export default App;
