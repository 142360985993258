import * as PIXI from 'pixi.js-legacy';
import { Sound, PlayOptions } from '@pixi/sound';

import { AudioComponentDef } from "../model/WideoDef";
import WideoContext from "../WideoContext";
import Attributes from "../Attributes";
import AbstractComponent from "./AbstractComponent";
import { PendingAssetResource } from '../AssetsLoader';
import Tweener from '../animations/Tweener';
import { fadeOutAudioStartTime } from '../../../editor/core/EditorConstants';
import Logger from '../../log/Logger';
import WideoObject from '../WideoObject';

export default class AudioComponent extends AbstractComponent {

  private _volume: number;
  private _repeat: boolean;
  private _src: string;
  private _sound: Sound;
  private _fadeOut: boolean;

  constructor(context: WideoContext, owner: WideoObject, def: AudioComponentDef) {
    super(false);
    this._class = def.class;
    this._owner = owner;
    this._id = def.id;
    this._volume = def.volume;
    this._repeat = def.repeat;
    this._src = def.src;
    this._context = context;
    this._fadeOut = def.fadeOut;

    if (this.getSrc()) {
      this._context.getAssetsLoader().push(
        {
          id: this.getId(),
          src: this.getSrc()
        },
        () => {
          this.addContent();
        }
      );
    }

  }

  private addContent() {
    // Asset completed loading callback
    const asset: PendingAssetResource = this._context.getAssetsLoader().getAsset(this.getId());
    const content: PIXI.ILoaderResource = asset.content;
    if (content.error) {
      Logger.error("Failed adding audio resource in AudioComponent, error: %o", asset.content.error);
      return;
    }
    const soundContent: Sound = asset.content['sound'];
    // Check if sound was returned and that it is playable (if decoding fails for example with old android
    // phones isPlayable is false but no error is returned from the PIXI Sound Loader)
    if (soundContent && soundContent.isPlayable) {
      if (!this._owner.getEndTime() && soundContent.duration > 0) {
        this._owner.setEndTime(Math.round(soundContent.duration * 1000));
      }
      soundContent.volume = this._volume;
      soundContent.singleInstance = true; //Prohibit multiple instances of the sound for now
      this._sound = soundContent;
    }
    else {
      Logger.warn("Audio cannot be loaded in callback of asset loader. ID: " + this.getId()
        + ", src: " + this.getSrc());
    }
  }

  public getSoundDurationAsMs(): number {
    // round to the nearest multiple of 1000 
    // to avoid problems with numbers like 27500(27.5 seconds)
    // it's rounded to 27000 (27 seconds)
    if (this._sound) {
      return Math.ceil(Math.round(this._sound.duration * 1000) / 1000) * 1000;
    } else {
      return 0
    }
  }

  public serialize(): AudioComponentDef {
    return {
      id: this._id,
      volume: this._volume,
      repeat: this._repeat,
      src: this._src,
      class: this._class,
      fadeOut: this._fadeOut
    }
  }

  public update(elapsedTime: number, playing: boolean): Attributes {
    // update can be called before the sound has finished loading
    if (!this._sound) {
      return null;
    }
    // If we are supposed to be playing right now
    if (playing) {
      //if we reach the endtime of the audio pause
      if (this._owner.getEndTime() && (this._owner.getEndTime() <= elapsedTime)) {
        this.pauseAudio(true);
      }
      else {
        // The elapsed time in millisecond localized to the sound (repeat)
        const soundLocalElapsedTimeMs: number = this._repeat ? (elapsedTime - this._owner.getStartTime()) % (this._sound.duration * 1000) : elapsedTime - this._owner.getStartTime();

        // If we are actually playing the sound right now?
        if (this._sound.isPlaying) {
          //Only sync when audio and video desynchronize by more than this audioSyncThreshold
          const audioSyncThreshold: number = 100;
          //Assume only one instance of each sound playing
          const audioCurrentTimeMs: number = this._sound.instances[0].progress * this._sound.duration * 1000;
          const synchDiffMs = Math.abs(soundLocalElapsedTimeMs - audioCurrentTimeMs);
          // if we are off synch restart the sound with the actual time elapsed
          if (synchDiffMs > audioSyncThreshold) {
            this.pauseAudio();
            this.playAudio(soundLocalElapsedTimeMs);
          }
          // If we are not actually playing the sound right now but we ought to!
        } else {
          if (elapsedTime >= this._owner.getStartTime() && (this._owner.getEndTime() && (elapsedTime <= this._owner.getEndTime()))) {
            this.playAudio(soundLocalElapsedTimeMs);
          }
        }
      }
      // If we should not be playing right now, make sure we are not!
    } else {
      if (this._sound && this._sound.isPlaying) {
        this.pauseAudio();
      }
    }
    return null;

  }

  public pause() {
    if (this._sound) {
      this.pauseAudio(true);
    }
  }

  private pauseAudio(restartVolume: boolean = false): void {
    try {
      this._sound.pause();
    } catch (e) {
      // do nothing - safari throws invalid state of sound in some cases
    }
    if (restartVolume) {
      //restart volume
      this.setVolume(this._volume);
    }
  }

  private playAudio(soundLocalElapsedTimeMs: number): void {
    try {
      const options: PlayOptions = {
        start: soundLocalElapsedTimeMs / 1000
      }
      this._sound.play(options);
    } catch (e) {
      // do nothing - safari throws invalid state of sound in some cases
    }
  }

  public destroy(): void {
    if (this._sound) {
      this._sound.destroy();
    }
    super.destroy();
  }

  public fadeOut(currentTime: number): void {
    if (this._sound && this._fadeOut) {
      const from = this._sound.volume;
      const change = -0.01;
      const endTime = fadeOutAudioStartTime;
      const volume = Tweener.Linear(from, change, currentTime, endTime);
      if (volume > 0) {
        this._sound.volume = volume;
      }
      else { this._sound.volume = 0; }
    }
  }

  public getSrc(): string {
    return this._src;
  }

  public getVolume(): number {
    return this._volume;
  }

  public setVolume(volume: number) {
    this._volume = volume;
    // If the sound has not finished loading, just set the _volume and let addContent update volume of sound
    // later on.
    if (this._sound) {
      this._sound.volume = volume;
    }
  }

  public isRepeat(): boolean {
    return this._repeat;
  }

  public setRepeat(repeat: boolean) {
    this._repeat = repeat;
  }

  public isFadeOut() {
    return this._fadeOut;
  }

  public setFadeOut(fadeOut: boolean) {
    if (this._sound) {
      this._fadeOut = fadeOut;
    }

  }
}
