import WideoObject from './WideoObject';
import { Class } from './model/WideoDef';
import VideoComponent from './components/VideoComponent';

export default class VideoObject extends WideoObject {

  public getUrl(): string {
    const videoComponent = this.getComponentByClass(Class.VideoComponent) as VideoComponent;
    if (videoComponent) {
      return videoComponent.getSrc();
    }
    return null;
  }
  
  public static isVideoObject = (object: WideoObject): object is VideoObject => {
    return (object && object.getClass() === Class.VideoObject)
  }
}