import {WideoDefVersion, Class, ShapeComponentDef, MaskObjectDef} from "../../core/model/WideoDef";
import ITransformer from "../ITransformer";

export default class TransformerToV47 implements ITransformer {
    readonly toVersion: WideoDefVersion = WideoDefVersion.V47;

    // tslint:disable-next-line:no-any
    public transformWideo( wideoDef: any ) {

      //Update version and timestamp
      wideoDef.version = this.toVersion;
      wideoDef.timeStamp = Date.now();

      for (const sceneDef of wideoDef.scenes) {
        this.transformScene(sceneDef);
      }

    }   

    // tslint:disable-next-line:no-any
    transformScene(sceneDef: any): void {
      
      //Update version 
      sceneDef.version = this.toVersion;

      for (const wideoObjectDef of sceneDef.objects) {
        this.transformWideoObject(sceneDef, wideoObjectDef);
      }
    }

    // tslint:disable-next-line:no-any
    transformWideoObject(parentWideoObjectDef: any, wideoObjectDef: any): void {

      // Find MaskObjects without targets and delete them
      if (wideoObjectDef.class === Class.MaskObject) {
        const maskObjectDef: MaskObjectDef = wideoObjectDef as MaskObjectDef;
        if (!maskObjectDef.targetId) {
          // tslint:disable-next-line:no-any
          parentWideoObjectDef.objects = parentWideoObjectDef.objects.filter( ( child: any ) => {
            return child.id !== wideoObjectDef.id;
          });
        }
      }

      for (const innerObjectDef of wideoObjectDef.objects) {
          this.transformWideoObject(wideoObjectDef, innerObjectDef);
      }

      for (const componentDef of wideoObjectDef.components) {
        this.transformComponent(wideoObjectDef, componentDef);
      }


    }

    // tslint:disable-next-line:no-any
    transformComponent(wideoObjectDef: any, componentDef: any): void {   
    
        // Compensate for the change of position calculation in ShapeComponent. Before we compensated for
        // for the borderwidth inside the component. Now we dont.
        if (componentDef.class === Class.ShapeComponent ) {
            const shapeComponentDef: ShapeComponentDef = componentDef as ShapeComponentDef;
            // if (shapeComponentDef.borderAlpha && shapeComponentDef.borderThickness && shapeComponentDef.borderColor ) {
                wideoObjectDef.attributes.x -= shapeComponentDef.borderThickness / 2;
                wideoObjectDef.attributes.y -= shapeComponentDef.borderThickness / 2;
            // }
        }
    }


}