import WideoDef, {
  WideoDefVersion} from "../../core/model/WideoDef";
import Logger from "../../log/Logger";
import ITransformer from "../ITransformer";
import Converter from "../../../converter/core/Converter";
import WideoApi from "../../../api/WideoApi";
import Scaler, { ScaleStrategy } from "../Scaler";

export default class TransformerToV44 implements ITransformer {
  readonly toVersion: WideoDefVersion = WideoDefVersion.V44;

  private environment: string;
  private accessToken: string;

  private wideosIdsToTransform: string[] = [
    "21533741217801",
    "756721533305439900",
    "756721533301784838",
    "756721533144568300",
    "756721533156204898",
    "21533315812165",
    "21533667339952",
    "21533669159661",
    "21533743703451",
    "21533748774231",
    "21533748980714",
    "21533751972720",
    "21533751732268",
    "21533755150661",
    "21533840262624",
    "21533841238137",
    "21534360542240",
    "21534362109487",
    "21534361999133",
    "21534429286876",
    "21534433077846",
    "21534437832043",
    "21534441029563",
    "21534442000697",
    "21534530574252",
    "21534530453804",
    "21534866388516",
    "21534866952834",
    "21534951208535",
    "21534968608825",
    "21535039216140",
    "21535041947585",
    "21535054623136",
    "21535378336624",
    "21537208496758",
    "21535381974138",
    "21535382848148",
    "21535471231217",
    "21535567313743",
    "2153557311204",
    "21537208685418",
    "21537537819204",
    "756721417621629972",
    "756721432671514921",
    "756721432671505668",
    "21539106604250",
    "756721440686056704",
    "21539182380214",
    "21539182805294",
    "21539199302911",
    "21539283719259",
    "21539288548446",
    "21539802356135",
    "21540393726776",
    "21540394110437",
    "21540394643996",
    "21540401108452",
    "21540401139467",
    "21540402925083",
    "21540404984843",
    "21540406979412"
  ];

  constructor(environment: string, accessToken: string) {
    this.environment = environment;
    this.accessToken = accessToken;
  }

  // tslint:disable-next-line:no-any
  public async transformWideo(wideoDef: any): Promise<void> {
    if (wideoDef.version < this.toVersion) {
      // Only convert wideos that are explicitly added to the list of wideos to transform
      if (this.wideosIdsToTransform.indexOf(wideoDef.id) !== -1) {
        Logger.debug(
          "Running conditional transform %s on wideo: %s",
          this.toVersion,
          wideoDef.id
        );

        // Convert the entire wideo from Flash JSON to HTML5 JSON again, assuming it has the same WideoID for both Flash and HTML
        const converter: Converter = new Converter(
          this.environment,
          this.accessToken
        );

        const wideoApi: WideoApi = new WideoApi(
          this.environment,
          this.accessToken
        );
        const flashJson: Object = await wideoApi.getJsonFlash(wideoDef.id);
        const convertedWideoDef: WideoDef = await converter.convert(flashJson);
        const scaler: Scaler = new Scaler();
        scaler.scale(convertedWideoDef, wideoDef.width, wideoDef.height, ScaleStrategy.Fit);

        //Update version and timestamp
        wideoDef.version = this.toVersion;
        wideoDef.timeStamp = Date.now();

        for (const sceneDef of wideoDef.scenes) {
          this._transformScene(sceneDef, convertedWideoDef);
        }
      }
    }
  }

  // tslint:disable-next-line:no-any
  private getById(def: any, id: string): any {
    if (def.id === id) {
      return def;
    }

    let children;
    if (def.objects) {
      children = def.objects;
    } else {
      children = def.scenes;
    }
    for (const childDef of children) {
      // tslint:disable-next-line:no-any
      const foundDef: any = this.getById(childDef, id);
      if (foundDef) {
        return foundDef;
      }
    }

    return null;

  }

  // tslint:disable-next-line:no-any
  public transformScene(sceneDef: any): void {
    // This transformer does not suppport single scene conversions
  }

  // tslint:disable-next-line:no-any
  private _transformScene(sceneDef: any, convertedWideoDef: any): void {
    sceneDef.version = this.toVersion;

    for (const wideoObjectDef of sceneDef.objects) {
      this.transformWideoObject(sceneDef, wideoObjectDef, convertedWideoDef);
    }
  }

  // tslint:disable-next-line:no-any
  transformWideoObject(parentObjectDef: any, wideoObjectDef: any, convertedWideoDef: any): void {
    if (wideoObjectDef.class === "shapeObject") {
      //TODO: Dependencies to Editor defined objects, how to handle???
      this.reconvertShapeObject(
        parentObjectDef,
        wideoObjectDef,
        convertedWideoDef
      );
    }

    for (const innerObjectDef of wideoObjectDef.objects) {
      this.transformWideoObject(
        wideoObjectDef,
        innerObjectDef,
        convertedWideoDef
      );
    }
  }

  // tslint:disable-next-line:no-any
  reconvertShapeObject(parentObjectDef: any, shapeObjectDef: any, convertedWideoDef: any) {
    const index: number = parentObjectDef.objects.indexOf(shapeObjectDef);

    if (index !== -1) {
      const newShapeObjectDef = this.getById(
        convertedWideoDef,
        shapeObjectDef.id
      );

      if (newShapeObjectDef) {
        parentObjectDef.objects.splice(index, 1, newShapeObjectDef);

        if (
          parentObjectDef.class === "objectGroup" 
        //   parentObjectDef.class === "maskedObject" ||
        //   parentObjectDef.class === "placeholder"
        ) {
          //Compensate x and y with object groups x and y, assume no scaling of object group
          newShapeObjectDef.attributes.x -= parentObjectDef.attributes.x;
          newShapeObjectDef.attributes.y -= parentObjectDef.attributes.y;
        }
      }
    }
  }
}
