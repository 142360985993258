/**
 * Created by mdavids on 31/10/2017.
 */
import * as PIXI from 'pixi.js-legacy';
import WideoObject from "../WideoObject";
import TextStyle from "../TextStyle";
import { TextComponentDef, TextStyleDef } from '../model/WideoDef';
import WideoContext from '../WideoContext';
import Attributes from "../Attributes";
import WideoDefFactory from '../model/WideoDefFactory';
import AbstractComponent from './AbstractComponent';
import { MAX_FONT_SIZE, MIN_FONT_SIZE } from '../TextObject';
import Logger from '../../../common/log/Logger';

export default class TextComponent extends AbstractComponent {

  protected _width: number;
  protected _height: number;
  protected _pixiText: PIXI.Text;
  protected _textStyle: TextStyleDef; //Local copy of TextStyle
  protected _text: string; //Local copy of text

  protected _backGraphics: PIXI.Graphics;

  constructor(context: WideoContext, owner: WideoObject, def: TextComponentDef) {
    super(true, 'TextComponent-' + def.id);

    this._context = context;
    this._owner = owner;
    this._id = def.id;
    this._class = def.class;
    this._width = def.width;
    this._height = def.height;

    // BUGFIX for fontSize
    if (!def.style.fontSize || def.style.fontSize < MIN_FONT_SIZE) {
      def.style.fontSize = MIN_FONT_SIZE;
    } else if (def.style.fontSize > MAX_FONT_SIZE) {
      def.style.fontSize = MAX_FONT_SIZE;
    }

    this._textStyle = { ...WideoDefFactory.CreateDefaultTextStyleDef(), ...(def.style ? def.style : {}) };
    this._text = def.text;

    // Schedule loading of the needed font

    this._context.getAssetsLoader().push(
      {
        id: this._textStyle.fontFamily + '-' + this._textStyle.fontStyle + '-' + this._textStyle.fontWeight + '-' + this._text,
        src: null,
        content: null,
        contentGif: null
      },
      () => {
        this.addContent();
      },
      {
        metadata: {
          isFont: true,
          fontFamily: this._textStyle.fontFamily,
          fontWeight: this._textStyle.fontWeight,
          fontStyle: this._textStyle.fontStyle,
          text: this._text
        }
      }
    );

  }

  public addContent = () => {
    if (this._displayObject) { //check displayObject exists
      // Create a graphics object to maintain TextComponent width and height fixed
      if (this._backGraphics) {
        this._displayObject.removeChild(this._backGraphics);
        this._backGraphics.destroy();
      }
      this._backGraphics = new PIXI.Graphics();
      this._displayObject.addChild(this._backGraphics);

      // Create the actual PIXI Text
      if (this._pixiText) {
        this._displayObject.removeChild(this._pixiText);
        this._pixiText.destroy();
      }
      this._pixiText = new PIXI.Text(this._text, new TextStyle(this.getTextStyle(), this.getWidth()));

      // Make sure that no fallback fonts are used (fallback to blank characters)
      this._pixiText.style.fontFamily = [this.getTextStyle().fontFamily, 'Noto Color Emoji', 'AdobeBlank'];

      if (this._owner.isScaledOrRotated()) {
        this._pixiText.texture.baseTexture.scaleMode = PIXI.SCALE_MODES.LINEAR;
      } else {
        this._pixiText.texture.baseTexture.scaleMode = PIXI.SCALE_MODES.NEAREST;
      }
      this._displayObject.addChild(this._pixiText);

      this.drawText(this._text);

    }
  }

  public setScaleMode(scaleMode: number) {
    this._pixiText.texture.baseTexture.scaleMode = scaleMode;
  }

  serialize(): TextComponentDef {
    return {
      id: this._id,
      class: this._class,
      width: this._width,
      height: this._height,
      text: this._text,
      style: { ...this._textStyle }
    }
  }

  public setText(text: string): void {
    this._setText(text);
  }

  /**
   * Set the text without loading any potentially needed font files (performance)
   * 
   * @param text 
   */
  public fastSetText(text: string): void {
    this._text = text;
    this.drawText(this._text);
  }


  public getText(): string {
    return this._text;
  }

  public getTextStyle(): TextStyleDef {
    return this._textStyle;
  }

  public setTextStyle(name: string, value: number | string) {
    if (name === 'fontFamily' ||
      name === 'fontWeight' ||
      name === 'fontStyle') {
      this.setFont(name, value); // Pushes the font to the AssetsLoader
    } else {
      this._textStyle[name] = value;
      this.drawText(this._text);
    }
  }

  public setFontSize(size: number) {
    this._textStyle.fontSize = size;
    this.drawText(this._text);
  }

  private setFont(name: string, value: number | string) {

    const fontFamily = name === 'fontFamily' ? value as string : this._textStyle.fontFamily;
    const fontStyle = name === 'fontStyle' ? value as string : this._textStyle.fontStyle;
    const fontWeight = name === 'fontWeight' ? value as string : this._textStyle.fontWeight;

    // Schedule loading of the needed font
    this._context.getAssetsLoader().push(
      {
        id: fontFamily + '-' + fontStyle + '-' + fontWeight + '-' + this._text,
        src: null,
        content: null,
        contentGif: null
      },
      () => {
        // We change the actual fontFamily here in the callback to avoid the component
        // beeing drawn with the new font family without it beeing properly loaded.
        this._textStyle.fontFamily = fontFamily;
        this._textStyle.fontStyle = fontStyle as 'normal' | 'italic';
        this._textStyle.fontWeight = fontWeight as 'normal' | 'bold';
        this.addContent();
      },
      {
        metadata: {
          isFont: true,
          fontFamily: fontFamily,
          fontWeight: fontWeight,
          fontStyle: fontStyle,
          text: this._text
        }
      }
    );

  }

  private _setText(text: string) {

    // Schedule loading of possible additional font files (korean is split up into many files for example)
    this._context.getAssetsLoader().push(
      {
        id: this._textStyle.fontFamily + '-' + this._textStyle.fontStyle + '-' + this._textStyle.fontWeight + '-' + text,
        src: null,
        content: null,
        contentGif: null
      },
      () => {
        // We change the actual fontFamily here in the callback to avoid the component
        // beeing drawn with the new font family without it beeing properly loaded.
        this._text = text;
        this.addContent();
      },
      {
        metadata: {
          isFont: true,
          fontFamily: this._textStyle.fontFamily,
          fontWeight: this._textStyle.fontWeight,
          fontStyle: this._textStyle.fontStyle,
          text: text
        }
      }
    );

  }

  public getWidth(): number {
    return this._width;
  }

  public setWidth(width: number) {
    this._width = width;
    this.drawText(this._text);
  }

  public getHeight(): number {
    return this._height;
  }

  public setHeight(height: number): void {
    this._height = height;
    this.drawText(this._text);
  }

  public getFontSize(): number {
    return this._textStyle.fontSize;
  }

  public getHorizontalAlignment(): string {
    return this._textStyle.align;
  }

  public drawText(text: string) {
    // DrawText is called from beforeUpdate in TransitionComponent (AutoType/Word), if we have not finished
    // loading the TextComponent there is no pixitext to update BOOM!
    if (this._pixiText) {
      this._pixiText.text = text;
      this._pixiText.style = new PIXI.TextStyle(new TextStyle(this.getTextStyle(), this.getWidth()));

      // Make sure that no fallback fonts are used (fallback to blank characters)
      this._pixiText.style.fontFamily = [this.getTextStyle().fontFamily, 'Noto Color Emoji', 'Adobe NotDef'];

      if (this._owner.isScaledOrRotated()) {
        this._pixiText.texture.baseTexture.scaleMode = PIXI.SCALE_MODES.LINEAR;
      } else {
        this._pixiText.texture.baseTexture.scaleMode = PIXI.SCALE_MODES.NEAREST;
      }

      // Ugly bugfix, PIXI cannot handle texts with very small or huge font sizes
      if (!this._pixiText.style.fontSize || this._pixiText.style.fontSize < MIN_FONT_SIZE) {
        this._pixiText.style.fontSize = MIN_FONT_SIZE;
      } else if (this._pixiText.style.fontSize > MAX_FONT_SIZE) {
        this._pixiText.style.fontSize = MAX_FONT_SIZE;
      }

      //Measure the text to be able to align it vertically and horizontally
      let measuredWidth = 0;
      try {
        const textMetrics = PIXI.TextMetrics.measureText(this._pixiText.text, this._pixiText.style as PIXI.TextStyle);
        measuredWidth = textMetrics.width;
      } catch (error) {
        // This probably means that there is no text or the font was not loaded succesfully
        Logger.warn("Failed measuring text of TextObject with id: " +
          this.getId() + ', text: ' + this._pixiText.text +
          ' fontFamily: ' + this._pixiText.style.fontFamily);
      }

      if (this._textStyle.align === 'left') {
        this._pixiText.x = -this._width * 0.5;
      } else if (this._textStyle.align === 'right') {
        this._pixiText.x = this._width * 0.5 - measuredWidth;
      } else if (this._textStyle.align === 'center') {
        this._pixiText.x = -measuredWidth * 0.5;
      }

      // Default vertical alignment = 'top'
      this._pixiText.y = -this._height * 0.5;

      // This background graphics is used just to make sure that localbounds of the TextComponent
      // occupies the full width and height.
      this._backGraphics.clear();
      this._backGraphics.beginFill(0xFFFFFF, 0);
      this._backGraphics.drawRect(-this._width / 2, -this._height / 2, this._width, this._height);
      this._backGraphics.endFill();

    }
  }

  public measureTextHeight(text?: string): number {
    // measureTextHeight is called from TransitionComponent (autoType/Word), make sure there is an actual
    // pixiText (that we have finished loading) or we will crash.
    if (this._pixiText && this._pixiText.style) {
      try {
        return PIXI.TextMetrics.measureText((text ? text : this._pixiText.text), this._pixiText.style as PIXI.TextStyle).height;
      }
      catch (error) {
        Logger.warn("Failed measuring text height of: " +
          this.getId() + ', text: ' + this._pixiText.text +
          ' fontFamily: ' + this._pixiText.style.fontFamily);
        return this._height;
      }
    }
    return this._height;
  }

  // private measureText(text: string, style: PIXI.TextStyle): PIXI.TextMetrics {
  //   //const defaultMetricsString = PIXI.TextMetrics.METRICS_STRING;
  //   //PIXI.TextMetrics.METRICS_STRING = text.valueOf();
  //   PIXI.TextMetrics.BASELINE_MULTIPLIER = 3;
  //   PIXI.TextMetrics.clearMetrics(style.toFontString()); 
  //   const textMetrics = PIXI.TextMetrics.measureText((text ? text : this._pixiText.text), this._pixiText.style);
  //   //PIXI.TextMetrics.METRICS_STRING = defaultMetricsString;
  //   return textMetrics;

  // }

  public enable(): void {
    this._pixiText.visible = true;
    this._isEnabled = true;
  }

  public disable(): void {
    this._pixiText.visible = false;
    this._isEnabled = false;
  }

  /**
   * Return the a string representing the text style of this TextComponent, uses the same format
   * as the CSS Font property.
   * See: https://developer.mozilla.org/en-US/docs/Web/CSS/font
   */
  public getFontString(): string {
    if (this._pixiText) {
      return (this._pixiText.style as PIXI.TextStyle).toFontString();
    }
    return '';
  }

  public update(deltaTime: number): Attributes {
    return null;
  }

  public destroy(): void {
    if (this._pixiText) {
      this._pixiText.destroy();
      // this._pixiText = null;
    }
    if (this._backGraphics) {
      this._backGraphics.destroy();
      // this._backGraphics = null;
    }

    // this._text = null;
    // this._textStyle = null;

    super.destroy();
  }

}
