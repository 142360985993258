import * as PIXI from 'pixi.js-legacy';
import WideoDef, { Class, WideoColorDef } from './model/WideoDef';
import WideoContext from './WideoContext';
import WideoObject from "./WideoObject";
import Scene from './Scene';
import DisplayObject = PIXI.DisplayObject;
import { PendingAssetResource } from './AssetsLoader';
import AudioObject from './AudioObject';

const watermarkAssetId = "watermark";
const watermarkWidth = 420;
const watermarkHeight = 386;
export default class Wideo extends PIXI.Container {

  readonly _id: string;
  readonly _class: Class | string;

  protected _version: number;
  protected _width: number;
  protected _height: number;
  protected _timestamp: number;
  protected _context: WideoContext;
  protected _scenes: Scene[];
  protected _sceneContainer: PIXI.Container;
  protected _audios: AudioObject[];
  protected _presentationMode: boolean;
  protected _colors: WideoColorDef[]; 

  protected _localMask: PIXI.Graphics;

  protected _watermarkSprite: PIXI.Sprite;
  protected _createdFrom: string;
  protected _createdBy: string;
  protected _sequenceNumber: number;


  constructor(context: WideoContext, wideoDef: WideoDef) {
    super();
    this.name = 'Wideo-' + wideoDef.id;
    this._context = context;
    this._id = wideoDef.id;
    this._class = wideoDef.class;
    this._version = wideoDef.version;
    this._width = wideoDef.width;
    this._height = wideoDef.height;
    this._timestamp = wideoDef.timeStamp;
    this._presentationMode = wideoDef.presentationMode;
    this._createdFrom = wideoDef.createdFrom;
    this._createdBy = wideoDef.createdBy;
    this._sequenceNumber = wideoDef.sequenceNumber;
    this._colors = wideoDef.colors ? [...wideoDef.colors] : undefined;

    this._localMask = new PIXI.Graphics();
    this._localMask.name = "Mask-" + this.name;
    this._localMask.beginFill(0xffffff, 1);
    this._localMask.drawRect(0, 0, this._width, this._height);
    this._localMask.endFill();
    this.mask = this._localMask as any;
    this.addChild(this._localMask);

    this._sceneContainer = new PIXI.Container();
    this._sceneContainer.name = "SceneContainer";
    this.addChild(this._sceneContainer);

    this._scenes = [];
    this._audios = [];

    if (context.getWatermarkSrc()) {
      this._context.getAssetsLoader().push(
        {
          id: watermarkAssetId,
          src: context.getWatermarkSrc(),
          content: null,
          contentGif: null
        },
        () => {
          this.addWatermark();
        }
      );
    }

  }

  public addWatermark() {
    const asset: PendingAssetResource = this._context.getAssetsLoader().getAsset(watermarkAssetId);
    const content: PIXI.ILoaderResource = asset.content;

    // create a new Sprite from watermark
    this._watermarkSprite = new PIXI.Sprite(content.texture);

    this._watermarkSprite.name = watermarkAssetId;
    this._watermarkSprite.anchor.set(0);

    let scale = 0.8;

    if (watermarkWidth > this.getWidth() || watermarkHeight > this.getHeight()) {
      scale = 0.15;
    }

    this._watermarkSprite.x = this.getWidth() - watermarkWidth * scale;
    this._watermarkSprite.y = this.getHeight() - watermarkHeight * scale;

    this._watermarkSprite.scale.x = scale;
    this._watermarkSprite.scale.y = scale;
    this.addChild(this._watermarkSprite);
  }

  public disableMask() {
    this.removeChild(this._localMask);
    this.mask = null;
  }

  public enableMask() {
    this.addChild(this._localMask);
    this.mask = this._localMask as any;
  }

  public serialize(): WideoDef {
    return {
      id: this._id,
      class: this._class,
      timeStamp: this._timestamp,
      version: this._version,
      width: this._width,
      height: this._height,
      scenes: this._scenes.map((scene) => scene.serialize()),
      audios: this._audios.map((audioObject) => audioObject.serialize()),
      presentationMode: this._presentationMode,
      sequenceNumber: this._sequenceNumber,
      createdBy: this._createdBy,
      createdFrom: this._createdFrom,
      colors: this._colors ? [...this._colors] : undefined
    };
  }

  public getTimeStamp(): number {
    return this._timestamp;
  }

  public setTimeStamp(timestamp: number): void {
    this._timestamp = timestamp;
  }

  public getSequenceNumber(): number {
    return this._sequenceNumber;
  }

  public setSequenceNumber(seqNo: number): void {
    this._sequenceNumber = seqNo;
  }

  public increaseSequenceNumber(): void {
    this._sequenceNumber++;
  }

  public getWideoLength(): number {
    const scene: WideoObject = this.getLastScene();
    if (scene) {
      return scene.getEndTime();
    }
    return 0;
  }

  public getAudioObjects(): AudioObject[] {
    if (this._audios) {
      return this._audios;
    }
    else {
      return [];
    }
  }

  public addAudioObject(audioObject: AudioObject): void {
    this._audios.push(audioObject);
  }

  public removeAudioObject(audioObject: AudioObject): void {
    const index: number = this._audios.indexOf(audioObject);
    if (index >= 0) {
      this._audios.splice(index, 1);
    }
  }

  private updateAudioTimes(oldWideoLength: number) {
    for (const audio of this._audios) {

      const oldAudioEndTime = audio.getEndTime();

      //check audio endtime
      //adjust automatically if:
      //user didn't touched the endtime when wideo length increments
      //When wideo length is lower than audio endtime
      if (audio.getEndTime() === oldWideoLength || audio.getEndTime() > this.getWideoLength()) {
        audio.setEndTime(this.getWideoLength());
      }

      //audio.startime always < audio.endtime
      //audio.startime always > 0
      if (audio.getStartTime() > audio.getEndTime()) {
        audio.setStartTime(Math.max(audio.getStartTime() - (oldAudioEndTime - audio.getEndTime()), 0));
      }

    }
  }

  public startWideoInteractivity(): boolean {
    let hasInteractivity: boolean = false;
    for (const scene of this._scenes) {
      for (const object of scene.getWideoObjects()) {
        //object has interactivity
        if (object.getInteractivity()) {
          object.startWideoInteractivity();
          hasInteractivity = true;
        }
      }
    }
    return hasInteractivity;
  }

  public getScenes(): Scene[] {
    if (this._scenes) {
      return this._scenes;
    } else {
      return [];
    }
  }

  public getLastScene(): Scene {
    return this.getSceneAt(-1);
  }

  public getSceneAt(index: number): Scene {
    if (this._scenes.length > 0) {
      if (index < 0) {
        return this._scenes[this._scenes.length - 1];
      } else {
        if (index < this._scenes.length) {
          return this._scenes[index];
        }
      }
    }

    return null;
  }

  public getSceneAtTime(time: number): Scene {
    for (const scene of this._scenes) {
      if (scene.getStartTime() <= time && scene.getEndTime() > time) {
        return scene;
      }
    }
    return null;
  }

  public getSceneIndexAtTime(time: number): number {
    let index: number = 0;
    for (const scene of this._scenes) {
      if (scene.getStartTime() <= time && scene.getEndTime() > time) {
        return index;
      }
      index++;
    }
    return null;
  }

  public addScene(scene: Scene, atIndex: number = -1): void {
    if (atIndex <= this._scenes.length) {
      const oldWideoLength = this.getWideoLength();

      if (atIndex < 0 || atIndex === this._scenes.length) {
        this._scenes.push(scene);
        this._sceneContainer.addChildAt(scene, 0);
      } else {
        const prevSceneIndex: number = this._sceneContainer.getChildIndex(this._scenes[atIndex]);
        this._scenes.splice(atIndex, 0, scene);
        this._sceneContainer.addChildAt(scene, prevSceneIndex);
        this.updateSceneTimes(atIndex);
      }
      this.updateAudioTimes(oldWideoLength);
    }
  }

  public getIndexOfScene(soughtScene: Scene): number {
    for (let i = 0; i < this._scenes.length; i++) {
      if (this._scenes[i].getId() === soughtScene.getId()) {
        return i;
      }
    }
    return -1;
  }

  public deleteScene(indexScene: number): void {
    const scene: Scene = this._scenes[indexScene];
    const childIndex: number = this._sceneContainer.getChildIndex(scene);
    const oldWideoLength = this.getWideoLength();
    this._scenes.splice(indexScene, 1);
    this._sceneContainer.removeChildAt(childIndex);
    this.updateSceneTimes(indexScene);
    this.updateAudioTimes(oldWideoLength);
  }

  public swapScenes(indexA: number, indexB: number): void {
    const scenesCount: number = this._scenes.length;

    if (indexA < 0 || indexB < 0 ||
      indexA >= scenesCount || indexB >= scenesCount ||
      indexA === indexB) {
      return;
    }

    const indexAChildIndex: number = this._sceneContainer.getChildIndex(this._scenes[indexA]);
    const indexBChildIndex: number = this._sceneContainer.getChildIndex(this._scenes[indexB]);

    const childA: DisplayObject = this._sceneContainer.getChildAt(indexAChildIndex);
    const childB: DisplayObject = this._sceneContainer.getChildAt(indexBChildIndex);

    this._sceneContainer.setChildIndex(childA, indexBChildIndex);
    this._sceneContainer.setChildIndex(childB, indexAChildIndex);

    const sceneA: Scene = this._scenes[indexA];
    const sceneB: Scene = this._scenes[indexB];

    this._scenes[indexA] = sceneB;
    this._scenes[indexB] = sceneA;

    const smallestIndex: number = Math.min(indexA, indexB);
    this.updateSceneTimes(smallestIndex);
  }

  public moveScene(fromIndex: number, toIndex: number) {
    const sceneToMove: Scene = this.getSceneAt(fromIndex);
    this.deleteScene(fromIndex);
    this.addScene(sceneToMove, toIndex);

    const smallestIndex: number = Math.min(fromIndex, toIndex);
    this.updateSceneTimes(smallestIndex);
  }

  public changeSceneDuration(sceneIndex: number, newDuration: number) {
    const oldWideoLength = this.getWideoLength();
    this.getSceneAt(sceneIndex).changeLength(newDuration);
    this.updateSceneTimes(sceneIndex);
    this.updateAudioTimes(oldWideoLength);
  }

  private updateSceneTimes(fromIndex: number): void {
    if (fromIndex < 0 || fromIndex >= this._scenes.length) {
      return;
    }

    let newStartTime: number = fromIndex <= 0 ? 0 : this._scenes[fromIndex - 1].getEndTime();
    let newEndTime: number = 0;

    const scenesLength: number = this._scenes.length;
    let index: number = fromIndex;

    while (index < scenesLength) {
      newEndTime = newStartTime + this._scenes[index].getLifetime();

      this._scenes[index].setStartTime(newStartTime);
      this._scenes[index].setEndTime(newEndTime);

      index++;
      newStartTime = this._scenes[index - 1].getEndTime();
    }
  }

  public async seek(elapsedTime: number): Promise<void> {
    const scenes: Promise<void>[] = this._scenes.map(async (scene: Scene) => {
      return scene.seek(elapsedTime);
    });
    const audioObjects: Promise<void>[] = this._audios.map(async (audioObject: WideoObject) => {
      return audioObject.seek(elapsedTime);
    });
    await Promise.all(scenes.concat(audioObjects));
    return Promise.resolve();
  }
  public play(): void {
    this._scenes.map((scene: Scene) => {
      scene.play();
    });
    this._audios.map((audioObject: WideoObject) => {
      audioObject.play();
    });
  }
  public pause(): void {
    this._scenes.map((scene: Scene) => {
      scene.pause();
    });
    this._audios.map((audioObject: WideoObject) => {
      audioObject.pause();
    });
  }

  public update(globalElapsedMillis: number, playing: boolean): void {
    for (const scene of this._scenes) {
      scene.update(globalElapsedMillis, playing);
    }
    for (const audio of this._audios) {
      audio.update(globalElapsedMillis, playing);
    }
  }

  public getScenesQty(): number {
    return this._scenes.length;
  }

  public getObjectById(objectId: string): WideoObject {
    for (const scene of this._scenes) {
      const foundObject = scene.getObjectById(objectId);
      if (foundObject) {
        return foundObject;
      }
    }
    return undefined;
  }

  public getObjectsByName(name: string): WideoObject[] {
    const foundObjects: WideoObject[] = [];
    for (const scene of this._scenes) {
      foundObjects.push(...scene.getObjectsByName(name));
    }
    return foundObjects;
  }

  public getLastSceneIndex(): number {
    return this.getScenesQty() - 1;
  }

  public getId(): string {
    return this._id;
  }

  public getWidth(): number {
    return this._width;
  }

  public getHeight(): number {
    return this._height;
  }

  public setWidth(width: number) {
    this._width = width;
  }
  public setHeight(height: number) {
    this._height = height;
  }

  public setPresentationMode(setting: boolean) {
    this._presentationMode = setting;
  }

  public isPresentationMode(): boolean {
    return this._presentationMode;
  }

  public getCreatedFrom(): string {
    return this._createdFrom;
  }
  public getCreatedBy(): string {
    return this._createdBy;
  }
  public setCreatedFrom(createdFrom: string){
    this._createdFrom = createdFrom;
  }
  public setCreatedBy(createdBy: string){
    this._createdBy = createdBy
  }

  public getColors(): WideoColorDef[] {
    return this._colors;
  }

  public setColors(colors: WideoColorDef[]) {
    this._colors = [...colors];
  }

  /**
   * 
   * @param indexScene 
   * @returns the moment in time (ms) for the thumbnail of the wideo
   */
  public getThumbnail(): number {
    const scenes = this.getScenes();
    for (const scene of scenes) {
      const sceneThumbnail = scene.getThumbnail()
      if (sceneThumbnail !== undefined) {
        return scene.getStartTime() + sceneThumbnail;
      }
    }
    return undefined;
  }

  /**
   * 
   * @param time the moment in time (ms) for the thumbnail of the wideo. 
   */
  public setThumbnail(time: number) {

    // Clear thumbnails of all scenes, we can only have one per wideo for now
    for (const sceneToClear of this.getScenes()) {
      sceneToClear.clearThumbnail();
    }

    // Set the thumbnail of the scene at time
    const scene = this.getSceneAtTime(time);
    const sceneTime = time - scene.getStartTime();
    scene.setThumbnail(sceneTime);
  }

  public destroy(): void {

    // for (const scene of this._scenes) {
    //   scene.destroy();
    // }
    // this._scenes = [];

    // for (const audioObject of this._audios) {
    //   audioObject.destroy();
    // }
    // this._audios = [];

    // if (this._localMask) {
    //   this._localMask.destroy();
    // }

    super.destroy();
  }

}
