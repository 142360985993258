import { useState } from 'react';
import Mp4DownloadButton from './Button/Mp4DownloadButton';
import Mp4DownloadModal from './Modal/Mp4DownloadModal';
import AutomationApi from 'src/api/AutomationApi';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import EditorAccessTokenHandler from '../../../../editor/core/handlers/EditorAccessTokenHandler'
import { environments } from 'src/common/Env';
import Logger from 'src/common/log/Logger';

type ParameterItem = {
    name: string;
    value: string; // or any other appropriate type for 'value'
};

type Mp4DownloadComponentProps = {
    parameters: Array<ParameterItem>;
    templateId: string;
    environment: string;
    accessToken: string;
};

export default function Mp4DownloadComponent({ parameters, templateId, environment }: Mp4DownloadComponentProps) {
    const [data, setData] = useState({});
    const [error] = useState('');
    const [loading, setLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    let accessToken = ''

    const params = parameters.reduce((result, item) => {
        result[item.name] = item.value;
        return result;
    }, {});
    params["templateId"] = templateId;

    const showCircularProgress = () => {
        setLoading(true);
    };

    const hideCircularProgress = () => {
        setLoading(false);
    };

    const openModal = () => {
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    const toggleModal = () => {
        authorize()
        showCircularProgress();

        const automationApi = new AutomationApi(environment);

        automationApi
            .invoke(accessToken, params)
            .then((response) => {
                hideCircularProgress();
                setData(response);
                openModal();
            })
            .catch((err) => {
                hideCircularProgress()
            });
    };

    function authorize() {
        try {
            accessToken = EditorAccessTokenHandler.getAccessToken();
        } catch (e) {
            if (e.message.includes("User don't have valid session")) {
                //if we don't user token. Redirects to login
                redirectLogin();
            }
        }
    }

    function redirectLogin() {
        window.onbeforeunload = () => null;
        Logger.warn("Failed matic load because user don't have session. Redirecting to login"); // Leaves a breadcrumb for sentry
        window.location.href = `${environments[environment].webUrl.base + environments[environment].webUrl.login}?nextUrl=${encodeURIComponent(`${environments[environment].maticUrl}?wideoId=${templateId}`)}`
    }

    return (
        <>
            {loading && (
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            )}
            <Mp4DownloadButton onClick={toggleModal} />
            <Mp4DownloadModal
                open={modalOpen}
                onClose={closeModal}
                error={error}
                data={data}
            />
        </>
    );
}
