import UserAsset from "./UserAsset";
import { ACLResourceType } from "./ACLResourceType";

export default class UserAssetBackground extends UserAsset {

  public getAssetType = () => {
    return "userBackgrounds";
  }

  public getACLResourceType = () => {
    return ACLResourceType.BACKGROUND;
  }

}
