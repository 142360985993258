import WideoDef,
{
  Class,
  SceneDef,
  WideoObjectDef,
  MaskObjectDef,
  BackgroundComponentDef,
  MaskType,
  MaskComponentDef,
  AnimationComponentDef,
  KeyFrameDef,
  ImageComponentDef,
  CURRENT_WIDEO_DEF_VERSION,
  TransitionComponentDef,
  TransitionType,
  Tween,
  AttributesDef,
  TextComponentDef,
  TextStyleDef,
  AudioComponentDef,
  ShapeType,
  ShapeComponentDef,
  VideoComponentDef,
  AnimatedGroupComponentDef,
  TextObjectDef,
  WideoObjectInteractivityDef,
} from "./WideoDef";

import { v4 as uuid } from 'uuid';
import {
  defaultDurationOfNewScene,
  defaultDurationOfTransition,
  defaultTextWidth,
  defaultTextHeight,
  defaultTextFontSize,
  defaultTextLineHeightFactor,
  defaultTextAlignment,
  defaultTextFontWeight,
  defaultTextFontFamily,
  defaultTextFontStyle,
  defaultTextFillColor
} from "../../../editor/core/EditorConstants";

export default class WideoDefFactory {

  public static CreateEmptyWideoDef(wideoId: string, width: number, height: number): WideoDef {
    return {
      "version": CURRENT_WIDEO_DEF_VERSION,
      "id": wideoId,
      "timeStamp": 0,
      "sequenceNumber": 0,
      "class": Class.Wideo,
      "width": width,
      "height": height,
      "scenes": [],
      "audios": [],
      "presentationMode": false
    }
  }

  public static CreateEmptyWideoDefWithDefaultScene(wideoId: string, width: number, height: number): WideoDef {
    return {
      "version": CURRENT_WIDEO_DEF_VERSION,
      "id": wideoId,
      "timeStamp": 0,
      "sequenceNumber": 0,
      "class": Class.Wideo,
      "width": width,
      "height": height,
      "scenes": [this.CreateSceneDef(0, defaultDurationOfNewScene, width, height)],
      "audios": [],
      "presentationMode": false
    }
  }

  public static CreateDefaultTextStyleDef = (): TextStyleDef => {
    const textStyleDef: TextStyleDef = {
      align: defaultTextAlignment,
      fontSize: defaultTextFontSize,
      fontWeight: defaultTextFontWeight,
      fontFamily: defaultTextFontFamily,
      fontStyle: defaultTextFontStyle,
      lineHeightFactor: defaultTextLineHeightFactor,
      fill: defaultTextFillColor
    };
    return textStyleDef;
  }


  public static createTextComponentDef(textStyle: TextStyleDef, text?: string, width?: number, height?: number): TextComponentDef {
    return {
      id: uuid(),
      class: Class.TextComponent,
      text: text ? text : 'Type text here', // TODO: I18N
      width: width ? width : defaultTextWidth,
      height: height ? height : defaultTextHeight,
      style: { ...WideoDefFactory.CreateDefaultTextStyleDef(), ...(textStyle ? textStyle : {}) }
    }
  }

  public static CreateEmptyWideoObjectDef(clazz: Class | string, endTime: number, id?: string, startTime?: number): WideoObjectDef {
    return {
      id: id ? id : uuid(),
      class: clazz,
      startTime: startTime ? startTime : 0,
      endTime: endTime,
      attributes: {
        x: 0,
        y: 0,
        scaleX: 1,
        scaleY: 1,
        rotation: 0,
        alpha: 1
      },
      components: [],
      objects: [],
      hidden: false,
      locked: false
    };
  }

  public static CreateShapeComponentDef(shapeType: ShapeType, width?: number, height?: number,
    borderColor?: string, borderAlpha?: number, fillColor?: string, fillAlpha?: number,
    borderThickness?: number): ShapeComponentDef {

    return {
      id: uuid(),
      class: Class.ShapeComponent,
      width: width ? width : 100,
      height: height ? height : 100,
      shapeType: shapeType,
      borderColor: borderColor ? '0x' + borderColor : (shapeType === ShapeType.Line ? '0x000000' : null),
      borderAlpha: borderAlpha ? borderAlpha : 1,
      fillColor: fillColor ? '0x' + fillColor : null,
      fillAlpha: fillAlpha ? fillAlpha : 1,
      borderThickness: borderThickness || borderThickness === 0 ? borderThickness : 5,
      cornerRadius: 50
    }

  }

  public static CreateImageComponentDef(id: string, url: string): ImageComponentDef {
    return {
      id: id,
      class: Class.ImageComponent,
      src: url,
      loop: true
    }
  }

  public static CreateBackgroundImageComponentDef(id: string, url: string): BackgroundComponentDef {
    return {
      id: id,
      class: Class.BackgroundComponent,
      keepRatio: true,
      src: url
    };
  }

  public static CreateBackgroundColorComponentDef(id: string, colorHex: string): BackgroundComponentDef {
    return {
      id: id,
      class: Class.BackgroundComponent,
      color: '0x' + colorHex
    };
  }

  public static CreateAnimatedObjectGroupComponentDef(id: string): AnimatedGroupComponentDef {
    return {
      id: id,
      class: Class.AnimatedGroupComponent
    }
  }

  public static CreateVideoComponentDef(id: string, url: string): VideoComponentDef {
    return {
      id: id ? id : uuid(),
      class: Class.VideoComponent,
      src: url,
      fadeOut: false,
      repeat: false,
      volume: 1
    }
  }

  public static CreateAudioComponentDef(src: string, id?: string, volume?: number, fadeOut?: boolean): AudioComponentDef {
    return {
      id: id ? id : uuid(),
      class: Class.AudioComponent,
      src: src,
      fadeOut: fadeOut ? fadeOut : false,
      repeat: false,
      volume: volume ? volume : 1
    }
  }

  

  public static CreateEmptySceneDef(startTime: number, endTime: number, width: number, height: number, id?: string): SceneDef {
    return {
      id: id ? id : uuid(),
      class: Class.Scene,
      version: CURRENT_WIDEO_DEF_VERSION,
      width: width,
      height: height,
      attributes: {
        x: width * 0.5,
        y: height * 0.5,
        scaleX: 1,
        scaleY: 1,
        rotation: 0,
        alpha: 1,
      },
      startTime: startTime,
      endTime: endTime,
      components: [],
      objects: [],
      hidden: false,
      locked: false
    };
  }

  public static CreateSceneDef(startTime: number, endTime: number, width: number, height: number, id?: string): SceneDef {
    return {
      id: id ? id : uuid(),
      class: Class.Scene,
      version: CURRENT_WIDEO_DEF_VERSION,
      width: width,
      height: height,
      attributes: {
        x: width * 0.5,
        y: height * 0.5,
        scaleX: 1,
        scaleY: 1,
        rotation: 0,
        alpha: 1,
      },
      startTime: startTime,
      endTime: endTime,
      components: [
        this.CreateBackgroundColorComponentDef(uuid(), "FFFFFF")
      ],
      objects: [],
      hidden: false,
      locked: false
    };
  }

  public static CreateAnimatedImageComponentDef(id: string, url: string): ImageComponentDef {
    return {
      id: id,
      class: Class.AnimatedImageComponent,
      src: url
    }
  }

  public static CreateMaskComponentDef(width: number, height: number, maskType: MaskType): MaskComponentDef {
    return {
      "class": Class.MaskComponent,
      "type": maskType,
      "width": width,
      "height": height
    };
  }

  public static CreateAnimationComponentDef(): AnimationComponentDef {
    return {
      class: Class.AnimationComponent,
      animation: {
        keyFrames: []
      }
    }
  }

  public static CreateKeyFrameDef(time: number, attributes: AttributesDef): KeyFrameDef {
    return {
      id: uuid(),
      time: time,
      attributes: attributes
    }
  }

  public static CreateTransitionDef(type: TransitionType, tween: Tween, length?: number): TransitionComponentDef {

    return {
      class: Class.TransitionComponent,
      type: type,
      tween: tween,
      length: length !== null && length >= 0 ? length : defaultDurationOfTransition
    }
  }

  /**
   */
  public static CreateMaskWideoObjectDef(x: number, y: number, width: number, height: number, maskType: MaskType, startTime: number, endTime: number, scaleX?: number, scaleY?: number, id?: string, targetId?: string): MaskObjectDef {
    return {
      id: id ? id : uuid(),
      class: Class.MaskObject,
      startTime: startTime,
      endTime: endTime,
      hidden: false,
      locked: false,
      targetId: targetId,
      width: width,
      height: height,
      type: maskType,
      attributes: {
        x: x,
        y: y,
        scaleX: scaleX ? scaleX : 1,
        scaleY: scaleY ? scaleY : 1,
        rotation: 0,
        alpha: 1
      },
      components: [],
      objects: []
    };
  }

  /**
   *
   * @param x
   * @param y
   * @param endTime
   * @param textStyle (text style have default values defined in textComponent, that will be merge with the values of this param)
   * @param text
   */
  public static createTextWideoObjectDef(x: number, y: number, endTime: number,
    textStyle?: TextStyleDef, width?: number, height?: number, text?: string,
    scaleX?: number, scaleY?: number, rotation?: number, alpha?: number,
    startTime?: number, hidden?: boolean, id?: string, maskId?: string): TextObjectDef {

    return {
      id: id ? id : uuid(),
      class: Class.TextObject.toString(),
      startTime: startTime ? startTime : 0,
      endTime: endTime,
      hidden: hidden ? hidden : false,
      locked: false,
      maskId: maskId ? maskId : undefined,
      attributes: {
        x: x,
        y: y,
        scaleX: scaleX ? scaleX : 1,
        scaleY: scaleY ? scaleY : 1,
        rotation: rotation ? rotation : 0,
        alpha: alpha ? alpha : 1
      },
      components: [
        WideoDefFactory.CreateMaskComponentDef(width ? width : defaultTextWidth, height ? height : defaultTextHeight, MaskType.Rectangle),
        WideoDefFactory.createTextComponentDef(textStyle, text, width, height)
      ],
      objects: []
    };
  }

  public static CreateImageWideoObjectDef(x: number, y: number, endTime: number,
    assetId: string, assetUrl: string, scaleX?: number, scaleY?: number,
    rotation?: number, alpha?: number, startTime?: number, hidden?: boolean, id?: string, maskId?: string): WideoObjectDef {
    return {
      id: id ? id : uuid(),
      class: Class.ImageObject,
      startTime: startTime ? startTime : 0,
      endTime: endTime,
      attributes: {
        x: x,
        y: y,
        scaleX: scaleX ? scaleX : 1,
        scaleY: scaleY ? scaleY : 1,
        rotation: rotation ? rotation : 0,
        alpha: alpha ? alpha : 1,
      },
      components: [
        WideoDefFactory.CreateImageComponentDef(assetId, assetUrl)
      ],
      objects: [],
      hidden: hidden ? hidden : false,
      locked: false,
      maskId: maskId ? maskId : undefined
    };
  }

  public static CreateVideoWideoObjectDef(x: number, y: number, assetId: string, assetUrl: string): WideoObjectDef {
    return {
      id: uuid(),
      class: Class.VideoObject,
      startTime: 0,
      endTime: 0,
      attributes: {
        x: x,
        y: y,
        scaleX: 1,
        scaleY: 1,
        rotation: 0,
        alpha: 1,
      },
      components: [
        WideoDefFactory.CreateVideoComponentDef(assetId, assetUrl)
      ],
      objects: [],
      hidden: false,
      locked: false,
      maskId: undefined
    };
  }

  public static CreateAudioWideoObjectDef(src: string, componentId?: string, volume?: number, fadeOut?: boolean): WideoObjectDef {
    return {
      id: uuid(),
      class: Class.AudioObject,
      startTime: 0,
      endTime: 0,
      attributes: {
        x: 0,
        y: 0,
        scaleX: 1,
        scaleY: 1,
        rotation: 0,
        alpha: 1,
      },
      components: [
        WideoDefFactory.CreateAudioComponentDef(src, componentId, volume, fadeOut)
      ],
      objects: [],
      hidden: false,
      locked: false,
      maskId: undefined
    };
  }
  
  //interactivity
  public static CreateInteractivityDef(url: string, newTab: boolean): WideoObjectInteractivityDef {
    return {
      url: url,
      newTab: newTab,
    }
  }


}
