import { Class } from './model/WideoDef';
import WideoObject from './WideoObject';
import ShapeComponent from './components/ShapeComponent';

export default class ShapeObject extends WideoObject {

    protected getShapeComponent(): ShapeComponent {
        return this.getComponentByClass(Class.ShapeComponent) as ShapeComponent;
    }

    public getShapeObjectFillColor(): number {
        const shapeComponent: ShapeComponent = this.getShapeComponent();
        return shapeComponent.getFillColor();
    }

    public getShapeObjectStrokeColor(): number {
        const shapeComponent: ShapeComponent = this.getShapeComponent();
        return shapeComponent.getStrokeColor();
    }

    public setShapeObjectFillColor(rgbColor: number) {
        const shapeComponent: ShapeComponent = this.getShapeComponent();
        shapeComponent.setFillColor(rgbColor);
    }

    public setShapeObjectStrokeColor(rgbColor: number) {
        const shapeComponent: ShapeComponent = this.getShapeComponent();
        shapeComponent.setStrokeColor(rgbColor);
    }    

    public static isShapeObject = (object: WideoObject): object is ShapeObject => {
        return (object && object.getClass() === Class.ShapeObject);
    }

}
