import React from 'react'

import TextField from '@mui/material/TextField';

/* const styles = {
  inputField: {
      maxWidth: '100%',
      borderRadius: '40px'
  },
  input: {
    "&::placeholder": {
      fontStyle: 'italic'
    }
  }
}; */

interface Props {
  id: string;
  label: string | unknown;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
}

interface State {
}


type Styled = any

type PropsWithStyles = Props & Styled;

//type PropsWithStyles = Props & WithStyles<'inputField' | 'input'>;

class InputField extends React.Component<PropsWithStyles, State> {

  render() {
    const { id, label } = this.props;
    return (  
      <TextField
          id={`|${id}`}
          label={label}
          name="search-input"
          value={this.props.value}
          variant="outlined"
          size="small"
          fullWidth
          margin="normal"
          onChange={this.props.onChange}
/*           InputProps={{
            css: {
              ...styles.inputField,
              input: styles.input
            }
          }} */
      />
    );
  }

  }

  export default InputField;
