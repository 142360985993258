
/**
 * used to convert a hexa number in '#000fff' format, 
 * to an integer.
 * @param num 
 */
export function hexToInt(num: string): number {
    return parseInt(num.replace(/^#/, '0x'), 16);
}

/**
 * used to convert an integer number,
 * to a hexa number.
 * @param num
 */
export function intToHex(num: number): string {
    return "#" + ('000000' + ((num) >>> 0).toString(16)).slice(-6);
}

export function hexToRgba (hex: string) {
    let r = 0, g = 0, b = 0, a = 1;
    if (hex.length === 4) {
      r = parseInt(hex[1] + hex[1], 16) / 255;
      g = parseInt(hex[2] + hex[2], 16) / 255;
      b = parseInt(hex[3] + hex[3], 16) / 255;
    } else if (hex.length === 7) {
      r = parseInt(hex[1] + hex[2], 16) / 255;
      g = parseInt(hex[3] + hex[4], 16) / 255;
      b = parseInt(hex[5] + hex[6], 16) / 255;
    }
    return [r, g, b, a];
}