import { TextStyleDef } from './model/WideoDef';

import { defaultTextPaddingFactor } from '../../editor/core/EditorConstants';

export default class TextStyle {
  align?: 'left' | 'center' | 'right';
  fill?: string;
  fontFamily?: string;
  fontSize?: number;
  fontStyle?: 'normal' | 'italic';
  fontWeight?: 'normal' | 'bold';
  lineHeight?: number;
  breakWords?: boolean;
  wordWrap?: boolean;
  wordWrapWidth?: number;
  padding?: number;

  constructor(textStyleDef: TextStyleDef, wordWrapWidth: number) {
    this.align = textStyleDef.align;
    this.fill = textStyleDef.fill;
    this.fontFamily = textStyleDef.fontFamily;
    this.fontSize = textStyleDef.fontSize;
    this.fontStyle = textStyleDef.fontStyle;
    this.fontWeight = textStyleDef.fontWeight;
    this.lineHeight = textStyleDef.fontSize * textStyleDef.lineHeightFactor;
    this.breakWords = true;
    this.wordWrap = true;
    this.wordWrapWidth = wordWrapWidth;
    this.padding = textStyleDef.fontSize * defaultTextPaddingFactor;
  }

  serialize(): TextStyleDef {
    const textStyleDef: TextStyleDef = {
      align: this.align,
      fill: this.fill,
      fontFamily: this.fontFamily,
      fontSize: this.fontSize,
      fontStyle: this.fontStyle,
      fontWeight: this.fontWeight,
      lineHeightFactor: this.lineHeight / this.fontSize
    }
    return textStyleDef;
  }
}
