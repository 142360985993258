import Lottie from 'react-lottie';

import { Theme } from '@mui/material/styles';
import { hexToRgba } from 'src/common/ColorUtils';

import { blue, green } from '@mui/material/colors';

import { PlayState } from 'src/player/core/PlayerConstants';
import IPlayerQueryParams from 'src/player/ui/IPlayerQueryParams';

import BrandThemeProvider from 'src/common/ui/BrandThemeProvider';

import { withTranslation, WithTranslation } from 'react-i18next';

import animationData from '../../assets/animations/loadingSpinnerAnimation.json'; 

interface LoaderProps extends WithTranslation {
  playState?: PlayState;
  queryParams: IPlayerQueryParams;
}


export const getBackground900 = (theme: Theme) => ({
  backgroundColor: theme.palette.grey['900'],
});

const Loader = ({ playState, queryParams, t }: LoaderProps) => {
  const { brandConfig, icon } = BrandThemeProvider.getBrandInfo(queryParams);

  const primaryColorHex = brandConfig?.primaryColor ?? blue['A200']; 
  const secondaryColorHex = brandConfig?.secondaryColor ?? green['800']; 

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const primaryColor = hexToRgba(primaryColorHex);
  const secondaryColor = hexToRgba(secondaryColorHex);    

  animationData.layers.forEach(layer => {
    if (layer.shapes) {
      layer.shapes.forEach(shape => {
        if (shape.ty === 'gr' && shape.it) {
          shape.it.forEach(item => {
            if (item.ty === 'st') {
              const currentColor = item.c.k;
              if (currentColor[0] === 0.3215686274509804 && currentColor[1] === 0.38823529411764707) {
                item.c.k = primaryColor;
              } else if (currentColor[0] === 0.3176470588235294 && currentColor[1] === 0.796078431372549) {
                item.c.k = secondaryColor;
              }
            }
          });
        }
      });
    }
  });

  const styles = {
    contentLoader: {
      position: 'absolute' as 'absolute',
      width: '100%',
      height: '100%',
      overflow: 'hidden' as 'hidden',
      backgroundColor: 'white',    
      zIndex: 1299,      
    },
    loader: {
      width: '160px',
      height: '160px',      
    },
    centerScreen: {
      position: 'absolute' as 'absolute',
      left: '50%',
      top: '50%',
      zIndex: 100,
      transform: 'translate(-50%, -50%)'
    },
    contentLoaderInitial: {
      position: 'absolute' as 'absolute',
      left: '0',
      zIndex: 1301,
      '& $loader': {
        width: '240px',
        height: '240px',
      },
    },    
    icon: {
      maxWidth: '40px',
      position: 'absolute' as 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
    },  
    text: {
      letterSpacing: 0.6,
      fontSize: 11,
      textAlign: 'center' as 'center', 
      marginTop: -10,
    },
  };

  if (playState !== PlayState.Loading) {
    return null;
  }

  return (
    <div style={{...styles.contentLoader, ...styles.contentLoaderInitial}}>
      <div style={{ ...styles.loader, ...styles.centerScreen }}>
        <img src={icon} alt="Logo" style={styles.icon} />
        <Lottie options={defaultOptions} />
        <div style={{ ...styles.text, color: primaryColorHex, opacity: 0.8 }}>
          {t("player.state.loading")}
        </div>
      </div>
    </div>
  );
};

export default withTranslation("player")(Loader);
