/**
 * Created by mdavids on 31/10/2017.
 */
import KeyFrame from './KeyFrame';
import { AnimationDef } from '../model/WideoDef';
import Attributes from '../Attributes';

interface ActiveKeyFrames {
    current: KeyFrame;
    next: KeyFrame;
}


export default class Animation {

    protected _keyFrames: KeyFrame[];

    constructor(animationDef: AnimationDef) {
        this._keyFrames = [];
        for (const keyFrame of animationDef.keyFrames) {
            this._keyFrames.push(new KeyFrame(keyFrame));
        }
    }

    public serialize() {
        return {
            keyFrames: this._keyFrames.map( (keyFrame: KeyFrame) => keyFrame.serialize() ),
        }
    }

    public isScaledOrRotated(): boolean {
        for ( const keyframe of this._keyFrames ) {
            if ( keyframe.getAttributes().scaleX !== 1 || 
                 keyframe.getAttributes().scaleY !== 1 || 
                 keyframe.getAttributes().rotation !== 0) {
              return true;
            }
        }  
        return false;  
    }

    /*
     * Called from the target object on each tick
     */
    public interpolateAt(elapsedLocalTime:number): Attributes {
        // Update this animation to have the properties corresponding to elapsedLocalTime
        let deltaAttributes: Attributes = new Attributes({
            x: 0,
            y: 0,
            rotation: 0,
            scaleX: 1,
            scaleY: 1,
            alpha: 1
        });

        if ( elapsedLocalTime <= this.getStartTime() ) {

            // We have not yet reached the first key frame. Use the
            // values of the first key frame directly without interpolating
            const firstKeyFrame = this.getFirstKeyFrame();

            deltaAttributes = { ...firstKeyFrame.getAttributes() };

        }
        else if ( elapsedLocalTime > this.getStartTime() &&
            elapsedLocalTime < this.getEndTime() ) {

            // We have actual animation, interpolate the values between
            // the two active keyframes

            //Get the active keyframes (current and next)
            const activeKeyFrames = this.getActiveKeyFrames(elapsedLocalTime);
            const currentKeyFrame = activeKeyFrames.current;
            const nextKeyFrame = activeKeyFrames.next;

            // Normalize the time to a value within [0..1]
            const normalizedTime = (elapsedLocalTime - currentKeyFrame.getTime()) / (nextKeyFrame.getTime() - currentKeyFrame.getTime());

            // Calculate value of easing function at time t
            // For now it is a linear function f(t) = t
            // Note by Max: It's not linear anymore, it's using quadratic out, like in the old days
            const normalizedValue = normalizedTime;

            // Apply (de-normalize) the normalized value for all animating
            // properties of the target object at time t, g(t) = k*f(t) + m

            deltaAttributes.x = nextKeyFrame.applyEasing(currentKeyFrame.getAttributes().x,
                nextKeyFrame.getAttributes().x - currentKeyFrame.getAttributes().x,
                normalizedValue,
                1);
            deltaAttributes.y = nextKeyFrame.applyEasing(currentKeyFrame.getAttributes().y,
                nextKeyFrame.getAttributes().y - currentKeyFrame.getAttributes().y,
                normalizedValue,
                1);
            deltaAttributes.rotation = nextKeyFrame.applyEasing(currentKeyFrame.getAttributes().rotation,
                nextKeyFrame.getAttributes().rotation - currentKeyFrame.getAttributes().rotation,
                normalizedValue,
                1);
            deltaAttributes.scaleX = nextKeyFrame.applyEasing(currentKeyFrame.getAttributes().scaleX,
                nextKeyFrame.getAttributes().scaleX - currentKeyFrame.getAttributes().scaleX,
                normalizedValue,
                1);
            deltaAttributes.scaleY = nextKeyFrame.applyEasing(currentKeyFrame.getAttributes().scaleY,
                nextKeyFrame.getAttributes().scaleY - currentKeyFrame.getAttributes().scaleY,
                normalizedValue,
                1);
            deltaAttributes.alpha = nextKeyFrame.applyEasing(currentKeyFrame.getAttributes().alpha,
                nextKeyFrame.getAttributes().alpha - currentKeyFrame.getAttributes().alpha,
                normalizedValue,
                1);

        }
        else if ( elapsedLocalTime >= this.getEndTime() ) {

            //We have passed this animation set values from the last keyframe
            const lastKeyFrame = this.getLastKeyFrame();

            deltaAttributes = { ...lastKeyFrame.getAttributes() };

        }

        return deltaAttributes;
    }

    public getKeyFrame( time: number ): KeyFrame {
      for ( const keyframe of this._keyFrames ) {
        if ( keyframe.getTime() === time ) {
          return keyframe;
        }
      }
      return null;
    }

    /**
     *
     */
    public getKeyFrames = (): KeyFrame[] => {
        return this._keyFrames;
    }


    public getNewEndTime(change: number): number {
        if (this.getKeyFrame(change) === null){
            return change
        }
        const newEndTime: number = change + 500
        return this.getNewEndTime(newEndTime)
    }

    /**
     *
     */
    public addKeyFrame = ( keyFrame: KeyFrame, limit: number ): void => {
        //If there is already a key frame at this position in time add 0.5 seconds
        let newEndTime: number = this.getNewEndTime(keyFrame.getTime())
        if (newEndTime > limit){
            newEndTime = limit
        }
        keyFrame.setTime( newEndTime );

        // Add the key  frame to the list of key frames
        this._keyFrames.push(keyFrame);

        // Sort the list of keyframes on time
        this.sortKeyFrames();
    }

    /**
     *
     */
    public updateKeyFrame = ( keyFrame: KeyFrame ): void => {

        // Sort the list of keyframes on time
        this.sortKeyFrames();
    }

    private sortKeyFrames = (): void => {
        this._keyFrames = this._keyFrames.sort( (a: KeyFrame, b: KeyFrame): number => {
            return ( a.getTime() - b.getTime() );
        });
    }

    /**
     *
     */
    public removeKeyFrame = ( keyFrame: KeyFrame ): void => {
        // Remove the key frame to the list of key frames matching on times
        // TODO: maybe we should have an id for keyframes or just avoid creating two
        //       keyframes with the same time
        const index: number = this._keyFrames.findIndex( ( element: KeyFrame): boolean => {
            return element.getTime() === keyFrame.getTime();
        }  );

        this._keyFrames.splice( index, 1 );
    }

    /**
     * Return the time of the first keyframe of the animation
     */
    public getStartTime(): number {
        return this.getFirstKeyFrame().getTime();
    }

    /**
     *  Return the time of the last keyframe of the animation
     */
    public getEndTime(): number {
        return this.getLastKeyFrame().getTime();
    }

    public changeLength( change: number): void {
        const newEndTime = this.getEndTime() + change;
        for ( const keyFrame of this._keyFrames ) {
            if ( keyFrame.getTime() > newEndTime ) {
                keyFrame.setTime( newEndTime );
            }
        }
    }

    public moveToLeft( change: number): void {
        for ( const keyFrame of this._keyFrames ) {
            let newTime = keyFrame.getTime() - change;
            if ( newTime < 0 ) {
                newTime = 0
            }
            keyFrame.setTime( newTime );
        }
    }

    /**
     *  Return the first key frame
     */
    public getFirstKeyFrame(): KeyFrame {
        return this._keyFrames[0];
    }

    /**
     *  Return the last key frame
     */
    public getLastKeyFrame(): KeyFrame {
        return this._keyFrames[this._keyFrames.length - 1];
    }



    /**
     * Get the two "active" keyframes for a specific point in time,
     * current.time <= elapsedLocalTime <= next.time
     * @param {number} elapsedLocalTime
     */
    public getActiveKeyFrames(elapsedLocalTime: number): ActiveKeyFrames {

        for (let i = 0; i < this._keyFrames.length; i++) {
            if (this._keyFrames[i].getTime() <= elapsedLocalTime &&
                this._keyFrames[i+1].getTime() >= elapsedLocalTime) {

                return { current: this._keyFrames[i], next: this._keyFrames[i+1] };

            }
        }
        throw new Error("Missing or invalid time in keyframe.");
    }

    public rescaleKeyFrames(scaleX: number, scaleY: number): void {
      for ( const keyFrame of this._keyFrames ) {
        const attributes = keyFrame.getAttributes();
        attributes.scaleX /= scaleX;
        attributes.scaleY /= scaleY;
        keyFrame.setAttributes(attributes);
      }
    }

    public destroy():void
    {
        for ( const keyFrame of this._keyFrames ) {
            keyFrame.destroy();
        }
        // this._keyFrames = [];
    }
}
