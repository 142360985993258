import UserAssetVideo from "./UserAssetVideo";
import UserAssetImage from "./UserAssetImage";

export default class AssetFactory {
  public static CreateAsset(type: string, id: string, name: string, url: string, userId: string, preview: string, duration: number, fullWidthPreview: boolean, wideoId?: string, order?: number) {

    switch (type.toLowerCase()) {
      case 'mp4':
        return new UserAssetVideo(id, name, url, userId, preview, duration, fullWidthPreview, wideoId, order);
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
      case 'g89':
        return new UserAssetImage(id, name, url, userId, preview, duration, fullWidthPreview, wideoId, order);
      default:
        throw new Error("Unsupported asset type: " + type + " assetId: " + id);
    }

  }
}