import * as React from 'react';

import { blue } from '@mui/material/colors';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import InputLabel from '@mui/material/InputLabel';
import Editor from '../../../../core/Editor';

import { TransitionType } from "../../../../../common/core/model/WideoDef";
import ConversionUtils from './ConversionUtils';
import NumericInput from "react-numeric-input";
import { WithTranslation, withTranslation } from 'react-i18next';

const styles = {
  label: {
    margin: '10px 0',
    display: 'block',
    color: 'inherit'
    }
};

export const styleNumericInput = (primaryColor: string) => ({
  input: {
    background: 'transparent',
    fontFamily: 'inherit',
    paddingRight: '1.5ex',
    margin: '5px 0',
    color: primaryColor
  },
  'input:not(.form-control)': {
    paddingLeft: 0,
    border: 'none'
  },
  'input:focus': {
    outline: 'none'
  },
  btnUp: {
    background: 'transparent',
    border: 'none',
    boxShadow: 'none',
    borderRadius: '50%'
  },
  btnDown: {
    background: 'transparent',
    border: 'none',
    boxShadow: 'none',
    borderRadius: '50%'
  },
  btn: {
    width: '0.8em',
    height: '0.8em',
    color: blue['A400'],
    background: 'transparent'
  },
  'btn:hover': {
    background: 'rgba(33, 150, 243, 0.08)',
    cursor: 'pointer'
  },
  'btn:active': {
    background: 'transparent',
    boxShadow: 'none',
  },
  arrowUp: {
    fontSize: 15,
    content: '',
    display: 'inline-block',
    margin: '-0.15em 0px 0px -5px',
    width: '0.4em',
    height: '0.4em',
    borderTopColor: primaryColor,
    borderRightColor: primaryColor,
    borderWidth: '0.1em 0.1em 0 0',
    MozTransform: 'rotate(-45deg)',
    WebkitTransform: 'rotate(-45deg)',
    transform: 'rotate(-45deg)'
  },
  arrowDown: {
    fontSize: 15,
    content: '',
    display: 'inline-block',
    margin: '-0.4em 0px 0px -5px',
    width: '0.4em',
    height: '0.4em',
    borderTopColor: primaryColor,
    borderRightColor: primaryColor,
    borderWidth: '0.1em 0.1em 0 0',
    MozTransform: 'rotate(135deg)',
    WebkitTransform: 'rotate(135deg)',
    transform: 'rotate(135deg)'
  }
});

interface Props {
  editor: Editor;
  transitions: string[];
  primaryColor: string;
}

interface State {
  inEffect: string;
  inEffectStartTime: number;
  inEffectDuration: number;
  outEffect: string;
  outEffectEndTime: number;
  outEffectDuration: number;
}

interface NumericSelectionRange {
  [name: string]: number
}

const defaultInEffectValue: string = 'In';
const defaultOutEffectValue: string = 'Out';

type Styled = any

type PropsWithStyles = Props & WithTranslation & Styled;

//type PropsWithStyles = Props & WithTranslation & WithStyles<'label'>;

class InOutEffect extends React.Component<PropsWithStyles, State> {

  private timeoutEffect = null;

  constructor(props: PropsWithStyles) {
    super(props);
    this.state = this.stateFromProps(props);
  }

  private stateFromProps = (props: PropsWithStyles): State => {
    const object = props.editor.getSelection().getSelectedObject();
    if (object) {
      const intro = props.editor.getObjectIntro(object);
      const outro = props.editor.getObjectOutro(object);
      return {
        inEffect: intro ? intro.getTween() : defaultInEffectValue,
        inEffectStartTime: object ? ConversionUtils.millisToSeconds(object.getStartTime()) : 0,
        inEffectDuration: intro ? ConversionUtils.roundAsNumber(ConversionUtils.millisToSeconds(intro.getLength())) : 0,
        outEffect: outro ? outro.getTween() : defaultOutEffectValue,
        outEffectEndTime: object ? ConversionUtils.roundAsNumber(ConversionUtils.millisToSeconds(object.getEndTime())) : 0,
        outEffectDuration: outro ? ConversionUtils.roundAsNumber(ConversionUtils.millisToSeconds(outro.getLength())) : 0
      };
    }
    else {
      return {
        inEffect: '',
        inEffectStartTime: 0,
        inEffectDuration: 0,
        outEffect: '',
        outEffectEndTime: 0,
        outEffectDuration: 0
      };
    }
  }

  handleChangeEffectEndTimeFromInputNumeric = (valueAsNumber: number, valueAsString: string, element: HTMLInputElement) => {
    const object = this.props.editor.getSelection().getSelectedObject();
    const outEffectEndTimeMin: number = ConversionUtils.millisToSeconds(this.props.editor.getMinWideoObjectEndTime(object));
    const outEffectEndTimeMax: number = ConversionUtils.millisToSeconds(this.props.editor.getMaxWideoObjectEndTime());

    clearTimeout(this.timeoutEffect)

    if (valueAsNumber !== null) {
      let valueOutEffectEndTime = valueAsNumber;

      // Execute this function, after waiting a few milliseconds 
      // to show the previous value, when it's not valid and then returns maximum or minimum allowed value
      this.timeoutEffect = setTimeout(() => {
        if (valueOutEffectEndTime > outEffectEndTimeMax) {
          this.setState({ outEffectEndTime: outEffectEndTimeMax });
          valueOutEffectEndTime = outEffectEndTimeMax
        } else if (valueOutEffectEndTime < outEffectEndTimeMin) {
          this.setState({ outEffectEndTime: outEffectEndTimeMin });
          valueOutEffectEndTime = outEffectEndTimeMin
        }
        this.props.editor.changeSelectedTransitionObjectEndTime(ConversionUtils.secondsToMillis(valueOutEffectEndTime), this.props.editor.getSelection().getSelectedObject().getStartTime());
      }, 125);
    }

  }

  handleChangeEffectStartTimeFromInputNumeric = (valueAsNumber: number, valueAsString: string, element: HTMLInputElement) => {
    const object = this.props.editor.getSelection().getSelectedObject();
    const inEffectStartTimeMin: number = ConversionUtils.millisToSeconds(this.props.editor.getMinWideoObjectStartTime());
    const inEffectStartTimeMax: number = ConversionUtils.millisToSeconds(this.props.editor.getMaxWideoObjectStartTime(object));

    clearTimeout(this.timeoutEffect)

    if (valueAsNumber !== null) {

      let valueInEffectStartTime = valueAsNumber;

      // Execute this function, after waiting a few milliseconds 
      // to show the previous value, when it's not valid and then returns maximum or minimum allowed value
      this.timeoutEffect = setTimeout(() => {
        if (valueInEffectStartTime >= inEffectStartTimeMax) {
          this.setState({ inEffectStartTime: inEffectStartTimeMax });
          valueInEffectStartTime = inEffectStartTimeMax
        } else if (valueInEffectStartTime < inEffectStartTimeMin) {
          this.setState({ outEffectEndTime: inEffectStartTimeMin });
          valueInEffectStartTime = inEffectStartTimeMin
        }
        this.props.editor.changeSelectedTransitionObjectStartTime(ConversionUtils.secondsToMillis(valueInEffectStartTime), this.props.editor.getSelection().getSelectedObject().getEndTime());
      }, 125);
    }

  }

  handleChangeEffectDurationFromInputNumeric = (valueAsNumber: number, transitionType: TransitionType) => {

    const object = this.props.editor.getSelection().getSelectedObject();
    const effectDurationMin: number = ConversionUtils.millisToSeconds(this.props.editor.getMinTransitionDuration());
    const effectDurationMax: number = ConversionUtils.millisToSeconds(this.props.editor.getMaxTransitionDuration(object, transitionType));

    clearTimeout(this.timeoutEffect)

    if (valueAsNumber !== null) {
      let valueEffectDuration = valueAsNumber;

      // Execute this function, after waiting a few milliseconds 
      // to show the previous value, when it's not valid and then returns maximum or minimum allowed value
      this.timeoutEffect = setTimeout(() => {

        valueEffectDuration = (valueEffectDuration > effectDurationMax) ? effectDurationMax : ((valueEffectDuration < effectDurationMin) ? effectDurationMin : valueAsNumber)

        this.setState({ [(transitionType === TransitionType.Intro) ? 'inEffectDuration' : 'outEffectDuration']: valueEffectDuration } as Pick<State, 'inEffectDuration' | 'outEffectDuration'>)

        this.props.editor.changeSelectedTransitionObjectLength(
          transitionType,
          ConversionUtils.secondsToMillis(valueEffectDuration)
        );

        if(transitionType === TransitionType.Intro) {
          this.props.editor.logAmplitudeEvent("MODIFY_IN_TRANSITION_LENGTH", { "from": "RIGHTBAR" });
          this.props.editor.logGAEvent("MODIFY_IN_TRANSITION_LENGTH", null, false);           
        } else {
          this.props.editor.logAmplitudeEvent("MODIFY_OUT_TRANSITION_LENGTH", { "from": "RIGHTBAR" });
          this.props.editor.logGAEvent("MODIFY_OUT_TRANSITION_LENGTH", null, false);           
        }        

      }, 125);

    }

  }

  handleChangeEffectInDurationFromInputNumeric = (valueAsNumber: number, valueAsString: string, element: HTMLInputElement) => {
    this.handleChangeEffectDurationFromInputNumeric(valueAsNumber, TransitionType.Intro);
  }

  handleChangeEffectOutDurationFromInputNumeric = (valueAsNumber: number, valueAsString: string, element: HTMLInputElement) => {
    this.handleChangeEffectDurationFromInputNumeric(valueAsNumber, TransitionType.Outro);
  }

  getNumericSelection = (e: React.SyntheticEvent<HTMLInputElement>) => {
    let start = 0
    let end = 0

    if (typeof e.currentTarget.selectionStart === "number" && typeof e.currentTarget.selectionEnd === "number") {
      start = e.currentTarget.selectionStart;
      end = e.currentTarget.selectionEnd;
    }
    return {
      start: start,
      end: end
    };

  }

  setNumericSelectionRange = (e: React.SyntheticEvent<HTMLInputElement>, result: NumericSelectionRange) => {
    const cursorPosition = result.start;
    const moveCursorPosition = cursorPosition + 1;
    const posZero = e.currentTarget.value.indexOf("0");

    if (posZero === cursorPosition && cursorPosition === 0) {
      e.currentTarget.setSelectionRange(moveCursorPosition, moveCursorPosition);
    }

  }

  handleSelectNumber = (e: React.SyntheticEvent<HTMLInputElement, Event>): void => {
    const result = this.getNumericSelection(e)
    this.setNumericSelectionRange(e, result)
  }

  renderInEffect = (): JSX.Element => {
    const { editor, t, primaryColor } = this.props;

    if (editor.getSelection().hasObjectSelected()) {

      return (
        <Grid container alignItems="center" spacing={0}>
          <Grid item xs={12}>
            <NumericInput
              id="inEffectDuration"
              cyid="inEffectDuration"
              value={this.state.inEffectDuration}
              onChange={this.handleChangeEffectInDurationFromInputNumeric}
              onSelect={this.handleSelectNumber}
              precision={1}
              step={0.1}
              size={7}
              disabled={this.state.inEffect === defaultInEffectValue}
              style={styleNumericInput(primaryColor)}
              mobile={false}
              maxLength={4}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="caption"
              gutterBottom={true}
            >
              <Box color="rgba(0, 0, 0, 0.38)">{t("rightbar.objects.effects.in-length")}</Box>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <NumericInput
              id="inEffectStartTime"
              cyid="inEffectStartTime"
              value={this.state.inEffectStartTime}
              onChange={this.handleChangeEffectStartTimeFromInputNumeric}
              onSelect={this.handleSelectNumber}
              precision={1}
              step={0.1}
              size={7}
              style={styleNumericInput(primaryColor)}
              mobile={false}
              maxLength={4}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="caption"
              gutterBottom={true}
            >
              <Box color="rgba(0, 0, 0, 0.38)">{t("rightbar.objects.effects.start-in")}</Box>
            </Typography>
          </Grid>
        </Grid>)
    } else { return (<span />) }
  }

  renderOutEffect = (): JSX.Element => {
    const { editor, t, primaryColor } = this.props;

    if (editor.getSelection().hasObjectSelected()) {

      return (
        <Grid container alignItems="center" spacing={0}>
          <Grid item xs={12}>
            <NumericInput
              id="outEffectDuration"
              cyid="outEffectDuration"
              value={this.state.outEffectDuration}
              onChange={this.handleChangeEffectOutDurationFromInputNumeric}
              onSelect={this.handleSelectNumber}
              precision={1}
              step={0.1}
              size={7}
              disabled={this.state.outEffect === defaultOutEffectValue}
              style={styleNumericInput(primaryColor)}
              mobile={false}
              maxLength={4}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="caption"
              gutterBottom={true}
            >
              <Box color="rgba(0, 0, 0, 0.38)">{t("rightbar.objects.effects.out-length")}</Box>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <NumericInput
              id="outEffectEndTime"
              cyid="outEffectEndTime"
              value={this.state.outEffectEndTime}
              onChange={this.handleChangeEffectEndTimeFromInputNumeric}
              onSelect={this.handleSelectNumber}
              precision={1}
              step={0.1}
              size={7}
              style={styleNumericInput(primaryColor)}
              mobile={false}
              maxLength={4}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="caption"
              gutterBottom={true}
            >
              <Box color="rgba(0, 0, 0, 0.38)">{t("rightbar.objects.effects.end-in")}</Box>
            </Typography>
          </Grid>
        </Grid>)
    } else { return (<span />) }
  }
  componentDidUpdate(prevProps: PropsWithStyles) {
    if (prevProps !== this.props) {
      this.setState(() => {
        return this.stateFromProps(this.props);
      });
    }
  }

  componentWillUnmount(): void {
    clearTimeout(this.timeoutEffect)
  }

  render() {
    const { t } = this.props;

    return (
      <Grid container alignItems="center" spacing={3}>
        <Grid item xs={6}>
          <InputLabel
            style={{ ...styles.label }}
          >
            {t("rightbar.objects.effects.in")}
          </InputLabel>
          <this.renderInEffect />
        </Grid>
        <Grid item xs={6}>
          <InputLabel
            style={{ ...styles.label }}
          >
            {t("rightbar.objects.effects.out")}
          </InputLabel>
          <this.renderOutEffect />
        </Grid>
      </Grid>
    );

  }
}

export default withTranslation("rightbarObj")(InOutEffect);

