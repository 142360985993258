import { createContext, useContext } from "react";

import { BrandConfig } from "../Env";

type BrandContextType = BrandConfig | undefined;
export const BrandContext = createContext<BrandContextType>(undefined);

export interface BrandContextProviderProps {
  brandConfig: BrandConfig;
  children?: React.ReactNode;
}

export const BrandContextProvider = ({children, brandConfig}: BrandContextProviderProps) => {

  return (
    <BrandContext.Provider value={brandConfig}> 
      {children}
    </BrandContext.Provider>
  );
};

export const useBrand = () => {
  const currentBrandContext = useContext(BrandContext);
  return currentBrandContext;
};