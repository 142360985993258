import {WideoDefVersion, Class} from "../../core/model/WideoDef";
import ITransformer from "../ITransformer";

export default class TransformerToV40 implements ITransformer {
    readonly toVersion: WideoDefVersion = WideoDefVersion.V411;

    constructor() {
      // Intentionally blank
    }

/*
          {
            "id": 'text-text-1',
            "class": Class.TextComponent,
            "text": "An animated text, An animated text, An animated text, An animated text, other text",
            "width": 1920,
            "height": 36,
            "brightness": 0.5,
            "style": {
              "align": "left",
              "fontSize": 30,
              "fontWeight": "bold",
              "fontFamily": "OpenSans_v2",  <--- Font name change
              "fontStyle": "normal",
              "lineHeightFactor": 1.2,
              "fill": "#ff0099"
            }

  ----->

  {
    "id": 'text-text-1',
    "class": Class.TextComponent,
    "text": "An animated text, An animated text, An animated text, An animated text, other text",
    "width": 1920,
    "height": 36,
    "brightness": 0.5,
    "style": {
      "align": "left",
      "fontSize": 30,
      "fontWeight": "bold",
      "fontFamily": "OpenSans_Light", <--- Font name change
      "fontStyle": "normal",
      "lineHeightFactor": 1.2,
      "fill": "#ff0099"
    }

  */

    // tslint:disable-next-line:no-any
    public transformWideo( wideoDef: any ): void {

      //Update version and timestamp
      wideoDef.version = this.toVersion;
      wideoDef.timeStamp = Date.now();

      for (const sceneDef of wideoDef.scenes) {
        this.transformScene(sceneDef);
      }
    }

    // tslint:disable-next-line:no-any
    transformScene(sceneDef: any): void {

      //Update version 
      sceneDef.version = this.toVersion;

      for (const wideoObjectDef of sceneDef.objects) {
        this.transformWideoObject(wideoObjectDef);
      }
    }

    // tslint:disable-next-line:no-any
    transformWideoObject(wideoObjectDef: any): void {

      if ( wideoObjectDef.class === 'textObject') { //TODO: Dependencies to Editor defined objects, how to handle???
        this.transformTextObject(wideoObjectDef);
      } else {
        for (const innerObjectDef of wideoObjectDef.objects) {
          this.transformWideoObject(innerObjectDef);
        }
      }
    }

    // tslint:disable-next-line:no-any
    transformTextObject(textObjectDef: any): void {
      for (const component of textObjectDef.components) {
        if (component.class === Class.TextComponent ) {
          this.transformTextComponent(component);
        }
      }
    }

    // tslint:disable-next-line:no-any
    transformTextComponent(textComponentDef: any): void {

      if (textComponentDef.style.fontFamily === 'OpenSans_v2') {
        textComponentDef.style.fontFamily = 'OpenSans_Light';
      }

    }

}
