import { WideoDefVersion } from "../../core/model/WideoDef";
import ITransformer from "../ITransformer";
  
export default class TransformerToV55 implements ITransformer {
  readonly toVersion: WideoDefVersion = WideoDefVersion.V55;

  // tslint:disable-next-line:no-any
  public transformWideo(wideoDef: any): void {

    //Update version and timestamp
    wideoDef.version = this.toVersion;
    wideoDef.timeStamp = Date.now();

    for (const sceneDef of wideoDef.scenes) {
      this.transformScene(sceneDef);
    }

  }

/** Round to hundreads of a second */
private roundTime(x: number): number {
  return Math.round(x / 10) * 10;
}

  // tslint:disable-next-line:no-any
  transformScene(sceneDef: any): void {

    //Update version 
    sceneDef.version = this.toVersion;
    sceneDef.startTime = this.roundTime(sceneDef.startTime);
    sceneDef.endTime = this.roundTime(sceneDef.endTime);

    for (const objectDef of sceneDef.objects) {
      this.transformObject(objectDef);
    }
  }

  // tslint:disable-next-line:no-any
  transformObject(objectDef: any): void {

    objectDef.startTime = this.roundTime(objectDef.startTime);
    objectDef.endTime = this.roundTime(objectDef.endTime);

    for (const childDef of objectDef.objects) {
      this.transformObject(childDef);
    }
  }


}
