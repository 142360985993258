/**
 * Created by mdavids on 31/10/2017.
 */

import Animation from "../animations/Animation";
import IComponent from "./IComponent";
import WideoObject from "../WideoObject";
import { AnimationComponentDef, Class } from '../model/WideoDef';
import KeyFrame from '../animations/KeyFrame';
import Attributes from '../Attributes';
import WideoContext from '../WideoContext';
import AbstractComponent from "./AbstractComponent";

export default class AnimationComponent extends AbstractComponent {

  protected _animation: Animation;

  constructor(context: WideoContext, owner: WideoObject, def: AnimationComponentDef) {
    super(false);
    this.priority = 2000;
    this._owner = owner;
    this._class = def.class;
    this._context = context;
    this._animation = new Animation(def.animation);
  }

  public serialize(): AnimationComponentDef {
    return {
      class: this._class,
      animation: this._animation.serialize()
    }
  }

  public isScaledOrRotated(): boolean {
    return this._animation.isScaledOrRotated();
  }

  public addKeyFrame(keyFrame: KeyFrame): void {
      const limit: number = this._owner.getEndTime()
      this._animation.addKeyFrame(keyFrame, limit);
  }

  public removeKeyFrame(keyFrame: KeyFrame): void {
    this._animation.removeKeyFrame(keyFrame);

    // If it was the last keyframe, remove the whole animation
    if (this.getKeyFrames().length === 0) {
      this._owner.removeComponent(this);
    }
  }

  public updateKeyFrame(keyFrame: KeyFrame): void {
    this._animation.updateKeyFrame(keyFrame);
  }

  public getStartTime(): number {
    return this._animation.getStartTime();
  }

  public getEndTime(): number {
    return this._animation.getEndTime();
  }

  public changeLength(change: number): void {
    this._animation.changeLength(change);
  }

  public getKeyFrames = (): KeyFrame[] => {
    return this._animation.getKeyFrames();
  }

  public getKeyFrame( time: number ): KeyFrame {
    return this._animation.getKeyFrame( time );
  }

  public rescaleKeyFrames(scaleX: number, scaleY: number): void {
    this._animation.rescaleKeyFrames(scaleX, scaleY);
  }

  public interpolateAt(elapsedLocalTime: number): Attributes {
    return this._animation.interpolateAt(elapsedLocalTime);
  }

  /*
   * Called by parent (which localizes the time)
   */
  public update(elapsedLocalTime: number): Attributes {
    if (this._isEnabled) {
      return this.interpolateAt(elapsedLocalTime);
    }
    return null;
  }

  public destroy(): void {
    if (this._animation) { 
      this._animation.destroy();
    }
    super.destroy();
  }

  public static isAnimationComponent(component: IComponent): component is AnimationComponent {
    return component.getClass() === Class.AnimationComponent;
  }

}
