import Logger from '../log/Logger';
import * as PIXI from 'pixi.js-legacy';
import { ExtendedPixiMetadata } from './AssetsLoader';

/** 
 * This Pixi middleware is activated when a video is pushed to the resource loader with the
 * metadata isVideo set to true. It requires the following options:
 * 
 *      metadata: {
 *        isVideo: true          
 *      },
 *      loadType: PIXI.ILoaderResource.LOAD_TYPE.XHR,
 *      xhrType: PIXI.ILoaderResource.XHR_RESPONSE_TYPE.BLOB 
 * 
 * @param resource the resource that the PIXI ResourceLoader has loaded
 * @param next the next middleware in the chain
 */
export const pixiVideoPostMiddleware = (resource: PIXI.ILoaderResource, next: (err?: Error) => void): void => {

  // We set the isVideo metadata in the VideoComponent to know which resources shall be handled
  // by this loader.
  if ((resource.metadata as ExtendedPixiMetadata).isVideo) {
    // Create a ObjectURL from the Blob
    let src = null;
    try {
      src = URL.createObjectURL(resource.data);
      resource.data = null;
    } catch (error) {
      Logger.error("Failed creating object url from " + resource.url)
      next(error);
    }
    const videoElement = document.createElement('video');

    // Return the HTMLVideoElement as property video on the resource
    resource['video'] = videoElement;

    // Create the VideoLoader that handles the actual loading of the Blob into the source of
    // the video element and makes sure that the video has been properly loaded before advancning
    // in the chain
    const loader = new VideoLoader();
    loader.load(videoElement, src, next); // Will eventually call next

  } else {
    next();
  }
}

/**
 * The VideoLoader class handles the actual loading of the Blob into the source of
 * the video element and makes sure that the video has been properly loaded before advancning
 * in the chain.
 */
class VideoLoader {
  private videoElement: HTMLVideoElement; // The video element that we are loading
  private next: (err?: Error) => void;    // A reference to the next middleware in the chain

  /**
   * Load the src into the videoElement and setup listeners to call next() if succesfull.
   * 
   * @param videoElement // The video element that we are loading
   * @param src          // The DataUrl to load as src
   * @param next         // A reference to the next middleware in the chain
   */
  public load(videoElement: HTMLVideoElement, src: string, next: (err?: Error) => void) {
    this.videoElement = videoElement;
    this.next = next;
    videoElement.addEventListener('canplay', this.sourceReady);
    videoElement.addEventListener('canplaythrough', this.sourceReady); 
    videoElement.addEventListener('resize', this.sourceReady);

    videoElement.addEventListener('error', () => {
      Logger.warn('Error loading video element ' + src + ': ' + videoElement.error.code + ': ' + videoElement.error.message);
      next(new Error('Error loading video element ' + src + ': ' + videoElement.error.code + ': ' + videoElement.error.message));
    });

    window['video'] = videoElement;

    videoElement.loop = false;
    videoElement.autoplay = false;
    videoElement.controls = false;    
    
    // copy of workaround for https://github.com/pixijs/pixi.js/issues/5996
    videoElement.setAttribute('preload', 'auto');

    videoElement.setAttribute('webkit-playsinline', '');
    videoElement.setAttribute('playsinline', '');

    // Load the source
    videoElement.src = src;
    videoElement.load();
  }

  /**
   *
   *
   * @memberof VideoLoader
   */
  public sourceReady = (event: Event): void => {
    
    if ( (this.videoElement.readyState === 3 || this.videoElement.readyState === 4) &&
         this.videoElement.videoWidth !== 0 && 
         this.videoElement.videoHeight !== 0 ) {

      // Remove listeners
      this.videoElement.removeEventListener('canplay', this.sourceReady);
      this.videoElement.removeEventListener('canplaythrough', this.sourceReady); 
      this.videoElement.removeEventListener('resize', this.sourceReady);

      this.next();
    }
  }

}