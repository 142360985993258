/* tslint:disable */
export type EasingFunction = (from: number, change: number, currentTime: number, endTime: number) => number;

export default class Tweener
{

    public static Linear(from:number, change:number, currentTime:number, endTime:number):number {
        if (currentTime >= endTime) {
            return from + change;
        }
        return change * currentTime / endTime + from;
    }

    public static EaseInQuad(from:number, change:number, currentTime:number, endTime:number):number {
        if (currentTime >= endTime) {
            return from + change;
        }
        return change * (currentTime /= endTime) * currentTime + from;
    }

    public static EaseOutQuad(from:number, change:number, currentTime:number, endTime:number):number {
        if (currentTime >= endTime) {
            return from + change;
        }
        return -change * (currentTime /= endTime) * (currentTime - 2.0) + from;
    }

    public static EaseInOutQuad(from:number, change:number, currentTime:number, endTime:number):number {
        if (currentTime >= endTime) {
            return from + change;
        }
        currentTime /= endTime * 0.5;
        if (currentTime < 1.0) {
            return change * 0.5 * currentTime * currentTime + from;
        }

        return -change * 0.5 * (--currentTime * (currentTime - 2.0) - 1.0) + from;
    }

    public static EaseInElastic(from:number, change:number, currentTime:number, endTime:number):number {
        if (currentTime >= endTime) {
            return from + change;
        }

        const p = 0.3;
        currentTime = currentTime / endTime - 1;

        return from + change * -Math.pow(2,10 * currentTime) * Math.sin((currentTime - p / 4) * (2 * Math.PI) / p);
    }

    public static EaseOutElastic(from:number, change:number, currentTime:number, endTime:number):number {
        if (currentTime >= endTime) {
            return from + change;
        }

        const p = 0.3;
        currentTime /= endTime;

        return from + change * (Math.pow(2,-10 * currentTime) * Math.sin((currentTime - p / 4) * (2 * Math.PI) / p) + 1);
    }
}
