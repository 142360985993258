import * as React from 'react'

import { CardMedia } from '@mui/material/';

const styles = { 
  cardmedia: {
    position: 'absolute' as 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    width: '100%',
    height: '100%',
    border: 0
  },     
};  

interface Props {
  src: string;
  poster: string;
}

type PropsWithStyles = Props;

//type PropsWithStyles = Props & WithStyles<'cardmedia'>;

class SearchDialogVideoCardMedia extends React.Component<PropsWithStyles>{

  private videoRef: React.RefObject<HTMLVideoElement>;

  constructor(props: PropsWithStyles) {
    super(props as PropsWithStyles);
    this.videoRef = React.createRef<HTMLVideoElement>();
  }

  handlePlay = () => {
    const playPromise = this.videoRef.current.play();
    // Old versions of Edge does not return anything
    if (playPromise) { // tslint:disable-line:no-promise-as-boolean
      playPromise.catch(() => {
        // Silently ignore any error doing playback, but do a catch to avoid uncaught errors
        // Most probably because of a pause before the play even started, i.e.
        // "AbortError: The play() request was interrupted by a call to pause(). https://goo.gl/LdLk22"
      });
    }
  }

  handlePause = () => {
    this.videoRef.current.pause();
  } 

  render() {
    
    return (    
      <CardMedia 
        onMouseOver={ this.handlePlay }
        onMouseOut={ this.handlePause }       
        component="video"
        ref={this.videoRef}
        height="130"
        src={this.props.src}
        poster={this.props.poster}
        style={{ ...styles.cardmedia }}
      />
    )
  }
}

export default SearchDialogVideoCardMedia;