import * as PIXI from 'pixi.js-legacy';

import IComponent from "./components/IComponent";
import DisplayObject = PIXI.DisplayObject;
import IAnimatable from "./IAnimatable";
import ISerializable from "../serialization/ISerializable";

import { WideoObjectDef, Class, ObjectFit } from './model/WideoDef';
import WideoContext from './WideoContext';
import Scene from './Scene';
import Attributes from './Attributes';
import AnimationComponent from "./components/AnimationComponent";
import KeyFrame from './animations/KeyFrame';
import TransitionComponent from './components/TransitionComponent';
import WideoObjectInteractivity from './WideoObjectInteractivity';

export default class WideoObject extends PIXI.Container implements IAnimatable, ISerializable {

  protected _context: WideoContext;

  protected _class: Class | string;
  protected _id: string;
  protected _name: string;
  protected _objectFit: ObjectFit;

  protected _components: IComponent[];
  protected _parent: WideoObject;
  protected _objects: WideoObject[];
  protected _focus: boolean;

  protected _mainContainer: PIXI.Container;
  protected _componentsContainer: PIXI.Container;

  protected _startTime: number = 0;
  protected _endTime: number = 0;
  protected _timeBleeding: number = 0;
  protected _loop: boolean = false;

  protected _isEnabled: boolean = true;
  protected _isUnlocked: boolean = true;
  protected _maskId: string;
  protected _scaleXInverted: boolean;
  protected _scaleYInverted: boolean;

  protected _interactivity: WideoObjectInteractivity;

  public attributes: Attributes; // TODO: Make this private?
  public currentAttributes: Attributes; // TODO: Make this private

  constructor(context: WideoContext, parent: WideoObject, wideoObjectDef: WideoObjectDef) {
    super();

    this.name = wideoObjectDef.class + '-' + wideoObjectDef.id;
    this._name = wideoObjectDef.name;
    this._objectFit = wideoObjectDef.objectFit;
    this._context = context;
    this._class = wideoObjectDef.class;
    this._id = wideoObjectDef.id;
    this._components = [];
    this._parent = parent;
    this._objects = [];

    this._mainContainer = new PIXI.Container();
    this._mainContainer.name = 'MainContainer';
    this.addChildAt(this._mainContainer, 0);

    this._componentsContainer = new PIXI.Container();
    this._componentsContainer.name = 'ComponentsContainer';
    this._mainContainer.addChild(this._componentsContainer);

    this.attributes = new Attributes(wideoObjectDef.attributes);
    this.currentAttributes = this.attributes; // Set the current attributes (tranform) to the initial state
    this.applyCurrentAttributes(this.currentAttributes); // Update the PIXI display object with the initial attributes (transform)

    this._startTime = wideoObjectDef.startTime;
    this._endTime = wideoObjectDef.endTime;

    this.visible = !wideoObjectDef.hidden;
    this._isEnabled = !wideoObjectDef.hidden;
    this._isUnlocked = !wideoObjectDef.locked;

    this._maskId = wideoObjectDef.maskId;

    this._focus = false;
    this._loop = wideoObjectDef.loop;
    if (wideoObjectDef.interactivity) {
      this._interactivity = new WideoObjectInteractivity(wideoObjectDef.interactivity);
    }

  }

  public getMaskId(): string {
    return this._maskId;
  }

  public setMaskId(maskId: string): void {
    this._maskId = maskId;
  }

  public serialize(): WideoObjectDef {
    let wideoObject = {
      id: this._id,
      class: this._class,
      attributes: this.attributes.serialize(),
      startTime: this._startTime,
      endTime: this._endTime,
      hidden: !this.isEnabled(),
      locked: !this.isUnlocked(),
      components: this._components.map((component: IComponent) => component.serialize()),
      objects: this._objects.map((object: WideoObject) => object.serialize()),
      loop: this._loop,
    }

    if (this._interactivity && this._interactivity.getUrl().trim() !== "") {
      wideoObject = Object.assign(wideoObject, { interactivity: this._interactivity.serialize() })
    }

    if (this._maskId) {
      wideoObject = Object.assign(wideoObject, { maskId: this._maskId })
    }

    if (this._name) {
      wideoObject = Object.assign(wideoObject, { name: this._name })
    }
    if (this._objectFit) {
      wideoObject = Object.assign(wideoObject, { objectFit: this._objectFit })
    }
    return wideoObject;
  }

  public getId(): string {
    return this._id;
  }

  public setId(id: string): void {
    this._id = id;
  }

  public getClass(): Class | string {
    return this._class;
  }

  public getAttributes(): Attributes {
    return this.attributes;
  }

  public setAttributes(attributes: Attributes): void {
    this.attributes.x = attributes.x;
    this.attributes.y = attributes.y;
    this.attributes.scaleX = attributes.scaleX;
    this.attributes.scaleY = attributes.scaleY;
    this.attributes.rotation = attributes.rotation;
    this.attributes.alpha = attributes.alpha;
  }

  public setKeyFrameAttributes(keyFrame: KeyFrame, attributes: Attributes): void {

    // The attributes of a keyframe are relative to the object orignal/base position
    const relativeAttributes: Attributes = new Attributes({
      x: attributes.x - this.attributes.x,
      y: attributes.y - this.attributes.y,
      rotation: attributes.rotation - this.attributes.rotation,
      scaleX: attributes.scaleX / this.attributes.scaleX,
      scaleY: attributes.scaleY / this.attributes.scaleY,
      alpha: attributes.alpha / this.attributes.alpha
    });

    keyFrame.setAttributes(relativeAttributes);
  }

  public getKeyFrameAttributes(keyFrame: KeyFrame): Attributes {

    const relativeAttributes: Attributes = keyFrame.getAttributes();

    // The attributes of a keyframe are relative to the object orignal/base position
    return new Attributes({
      rotation: this.attributes.rotation + relativeAttributes.rotation,
      x: this.attributes.x + relativeAttributes.x,
      y: this.attributes.y + relativeAttributes.y,
      scaleX: this.attributes.scaleX * relativeAttributes.scaleX,
      scaleY: this.attributes.scaleY * relativeAttributes.scaleY,
      alpha: this.attributes.alpha * relativeAttributes.alpha
    });

  }

  public getStartTime(): number {
    return this._startTime;
  }

  public getEndTime(): number {
    return this._endTime;
  }

  public setStartTime(value: number): void {
    this._startTime = value;
  }

  public setEndTime(time: number): void {
    this._endTime = time;
  }

  public changeLength(change: number): void {

    const newEndTime = this.getEndTime() + change;

    // Adjust the length of animations if neccessary
    for (const component of this._components) {
      if (AnimationComponent.isAnimationComponent(component)) {
        const componentChange = newEndTime - component.getEndTime();
        if (componentChange < 0) {
          component.changeLength(componentChange);
        }
      }
    }

    // Adjust the length of the children WideoObjects if neccessary
    const newLocalEndTime: number = newEndTime - this._startTime;
    for (const child of this._objects) {
      //if it's a loopeable object, objects within doesn't need to be changed
      if (!this._loop) {
        const childChange = newLocalEndTime - child.getEndTime();
        // Case 1. If the new end time is less than the child end time always update child
        if (childChange < 0) {
          child.changeLength(childChange);
        }
        // Case 2. If the child end time is equal or greater than the current end time
        // update the child (no matter if we are increasing or decreasing length)
        else if (this.getEndTime() - this.getStartTime() <= child.getEndTime()) {
          child.changeLength(change);
        }
      }
    }

    // Change the length of this specific wideo object
    this.setEndTime(newEndTime);
  }

  public setTimeBleeding(value: number): void {
    // time bleeding cannot be negative
    this._timeBleeding = Math.max(0, value);
  }

  public getLifetime(): number {
    return Math.max(0, this._endTime - this._startTime);
  }

  public isEnabled(): boolean {
    return this._isEnabled;
  }

  public isUnlocked(): boolean {
    return this._isUnlocked;
  }

  public enable(): void {
    this.visible = true;
    this._isEnabled = true;
  }

  public disable(): void {
    this.visible = false;
    this._isEnabled = false;
  }

  public lock(): void {
    this._isUnlocked = false;
    this.updateInteractive(false);
  }

  public unLock(): void {
    this._isUnlocked = true;
    this.updateInteractive(true);
  }

  public updateInteractive(interactive: boolean) {
    if (interactive && this._isUnlocked) {
      this.interactive = true;
      this.cursor = 'move';
    } else {
      this.interactive = false;
    }
  }

  public forceInteractive(interactive: boolean) {
    this.interactive = interactive;
  }

  public startWideoInteractivity() {
    if (this._interactivity && this._interactivity.getUrl() !== "") {
      // Opt-in to interactivity
      this.interactive = true;

      // Shows hand cursor
      this.buttonMode = true;

      // Pointers normalize touch and mouse
      this.on('pointerdown', () => {
        let url = this._interactivity.getUrl();
        //ensure url has protocol
        if (!(url.indexOf("https://") >= 0 || url.indexOf("http://") >= 0)) {
          url = "https://" + url;
        }
        if (this._interactivity.getNewTab()) {
          window.open(url, "_blank");
        } else {
          if (window.parent) {
            window.parent.window.location.href = url;
          } else {
            window.location.href = url;
          }
        };
      });
    }
  }

  public isVisible(): boolean {
    return this.visible;
  }

  public isScaledOrRotated(): boolean {
    if (this.attributes.scaleX !== 1 ||
      this.attributes.scaleY !== 1 ||
      this.attributes.rotation !== 0) {
      return true;
    }

    for (const component of this._components) {
      if (component.getClass() === Class.AnimationComponent) {
        if ((component as AnimationComponent).isScaledOrRotated()) {
          return true;
        }
      } else if (component.getClass() === Class.TransitionComponent) {
        if ((component as TransitionComponent).isScaledOrRotated()) {
          return true;
        }
      }
    }
    return false;
  }

  public getObjectsByClass(clazz: Class): WideoObject[] {
    const results: WideoObject[] = [];
    for (const object of this._objects) {
      if (object.getClass() === clazz) {
        results.push(object);
      }
    }

    return results;
  }

  public getComponentsByClass(clazz: Class): IComponent[] {
    const results: IComponent[] = [];
    for (const component of this._components) {
      if (component.getClass() === clazz) {
        results.push(component);
      }
    }

    return results;
  }

  public getComponentByClass(clazz: Class): IComponent {
    for (const component of this._components) {
      if (component.getClass() === clazz) {
        return component;
      }
    }

    return null;
  }

  public addComponent(component: IComponent): void {
    // components are ordered by priority
    let atIndex: number = -1;
    let lastDisplayObject: DisplayObject;

    const length: number = this._components.length;
    for (let i: number = 0; i < length; i++) {

      const currentDisplayObject = this._components[i].getDisplayObject();
      if (currentDisplayObject) {
        lastDisplayObject = currentDisplayObject;
      }

      if (component.priority < this._components[i].priority) {
        atIndex = i;
        break;
      }
    }

    if (atIndex < 0) {
      this._components.push(component);

    } else {
      this._components.splice(atIndex, 0, component);
    }

    const displayObject: DisplayObject = component.getDisplayObject();
    if (displayObject) {

      if (!lastDisplayObject) {
        this._componentsContainer.addChild(displayObject);
      } else {
        const displayIndex: number = this._componentsContainer.getChildIndex(lastDisplayObject);
        this._componentsContainer.addChildAt(displayObject, displayIndex);
      }
    }

    component.onAdded();
  }

  public removeComponent(component: IComponent): boolean {
    const index: number = this._components.indexOf(component);
    if (index >= 0) {
      this._components.splice(index, 1);

      const displayObject: DisplayObject = component.getDisplayObject();
      if (displayObject) {
        this._componentsContainer.removeChild(displayObject);
      }

      component.onRemoved();

      return true;
    }

    return false;
  }

  public setParentWideoObject(object: WideoObject): void {
    this._parent = object;
  }

  public getParentWideoObject(): WideoObject {
    return this._parent;
  }

  /**
   * return the scene that this WideoObject belongs to
   */
  public getScene(): Scene {
    return this._parent.getScene();
  }

  public getWideoObjects(): WideoObject[] {
    return this._objects;
  }

  public getWideoObjectsQty(): number {
    return this._objects.length;
  }

  public getWideoObjectAt(index: number): WideoObject {
    return this._objects[index];
  }

  public getObjectById(objectId: string): WideoObject {
    // Is it me?
    if (this.getId() === objectId) {
      return this;
    }

    // It is not me, maybe one of my children?
    for (const object of this._objects) {
      const foundObject = object.getObjectById(objectId);
      if (foundObject) {
        return foundObject;
      }
    }

    // No, not even one of my children...
    return undefined;
  }

  public getObjectsByName(name: string): WideoObject[] {
    const foundObjects: WideoObject[] = [];
    if (this.getReplaceableName() === name) {
      foundObjects.push(this);
    }
    for (const child of this._objects) {
      foundObjects.push(...child.getObjectsByName(name));
    }
    return foundObjects;
  }

  public getGlobalStartTime(): number {
    if (this.getParentWideoObject()) {
      return this.getStartTime() + this.getParentWideoObject().getGlobalStartTime();
    } else {
      return this.getStartTime();
    }
  }
  public getGlobalEndTime(): number {
    if (this.getParentWideoObject()) {
      return this.getEndTime() + this.getParentWideoObject().getGlobalStartTime();
    } else {
      return this.getEndTime();
    }
  }


  public addWideoObject(object: WideoObject): void {
    this._objects.push(object);
    this._mainContainer.addChild(object);
  }

  public getIndexOfWideoObject(object: WideoObject): number {
    return this._objects.indexOf(object);
  }

  public removeWideoObject(object: WideoObject): boolean {
    const index: number = this._objects.indexOf(object);

    if (index >= 0) {
      this._objects.splice(index, 1);
      this._mainContainer.removeChild(object);
      return true;
    }

    return false;
  }

  public removeAllWideoObjects(): WideoObject[] {
    this._mainContainer.removeChildren(1);
    return this._objects.splice(0);
  }

  public setObjectIndex(object: WideoObject, newIndex: number): void {
    const oldIndex: number = this.getObjectIndex(object);
    if (oldIndex >= 0 && newIndex >= 0 && newIndex < this._objects.length && oldIndex !== newIndex) {
      this._objects.splice(oldIndex, 1);
      this._objects.splice(newIndex, 0, object);

      //+1 because mainContainer is a child of wideoObject
      this._mainContainer.setChildIndex(object, newIndex + 1);
    }
  }

  public getObjectIndex(object: WideoObject) {
    return this._objects.indexOf(object);
  }

  public toLocalPoint(fromPoint: PIXI.Point, fromObject: WideoObject, skipUpdate?: boolean): PIXI.Point {
    const displayObject = (this.parent) ? this.parent : this;
    return displayObject.toLocal(fromPoint, fromObject.parent, null, skipUpdate) as PIXI.Point; // TODO: Why do we need to use fromObject.parent and not fromObject???
  }

  public toLocalBounds(fromBounds: PIXI.Rectangle, fromObject: WideoObject, skipUpdate?: boolean): PIXI.Rectangle {
    const displayObject = (this.parent) ? this.parent : this;

    const g1: PIXI.Point = displayObject.toLocal(new PIXI.Point(fromBounds.left, fromBounds.top), fromObject, null, skipUpdate) as PIXI.Point;
    const g2: PIXI.Point = displayObject.toLocal(new PIXI.Point(fromBounds.right, fromBounds.top), fromObject, null, true) as PIXI.Point;
    const g3: PIXI.Point = displayObject.toLocal(new PIXI.Point(fromBounds.right, fromBounds.bottom), fromObject, null, true) as PIXI.Point;
    const g4: PIXI.Point = displayObject.toLocal(new PIXI.Point(fromBounds.left, fromBounds.bottom), fromObject, null, true) as PIXI.Point;

    const minX = Math.min(Math.min(g1.x, g2.x), Math.min(g3.x, g4.x));
    const maxX = Math.max(Math.max(g1.x, g2.x), Math.max(g3.x, g4.x));
    const minY = Math.min(Math.min(g1.y, g2.y), Math.min(g3.y, g4.y));
    const maxY = Math.max(Math.max(g1.y, g2.y), Math.max(g3.y, g4.y));

    return new PIXI.Rectangle(minX, minY, maxX - minX, maxY - minY);

  }

  private updateComponents(globalElapsedMillis: number, playing: boolean): void {

    // Run the before update step for all components this can be used to reset
    // any side effects caused by the component when we are inside the object
    // lifetime but outside of the component lifetime. For example seeking from
    // the outro of a object to the middle of the object lifetime where there is
    // no intro or otro
    for (const component of this._components) {
      component.beforeUpdate();
    }
    this.currentAttributes = this.attributes;
    for (const component of this._components) {
      const attributes: Attributes = component.update(globalElapsedMillis, playing);

      // If there is a contribution to attributes from the component aggregate it with the rest of the other components
      if (attributes) {
        this.currentAttributes = this.aggregateAttributes(this.currentAttributes, attributes)
      }
    }

    //Set the new current attributes on this WideoObject
    this.applyCurrentAttributes(this.currentAttributes);
  }

  /**
   * Aggregate attributes and return as a new object
   *
   * @param {any} a
   * @param {any} b
   */
  private aggregateAttributes(a: Attributes, b: Attributes): Attributes {
    return new Attributes({
      x: a.x + b.x,
      y: a.y + b.y,
      rotation: a.rotation + b.rotation,
      scaleX: a.scaleX * b.scaleX,
      scaleY: a.scaleY * b.scaleY,
      alpha: a.alpha * b.alpha,
    });
  }

  /**
   * Apply the new attributes to this object
   *
   * @param {any} newProps an object with the new (interpolated) attributes of the target object
   */
  private applyCurrentAttributes(currentAttributes: Attributes): void {
    this.position.x = currentAttributes.x;
    this.position.y = currentAttributes.y;
    this.rotation = currentAttributes.rotation;

    this.scale.x = currentAttributes.scaleX;
    this.scale.y = currentAttributes.scaleY;
    this.alpha = currentAttributes.alpha;
  }

  private updateChildren(globalElapsedMillis: number, playing: boolean): void {
    for (const child of this._objects) {
      let elapsedLocalTime = this.getLocalTime(globalElapsedMillis);
      if ((child._endTime <= elapsedLocalTime) && this._loop) {
        elapsedLocalTime = elapsedLocalTime % child._endTime;
      }
      child.update(elapsedLocalTime, playing);
    }
  }

  public getLocalTime(globalElapsedMillis: number) {
    return Math.min(globalElapsedMillis - this._startTime, this.getLifetime() - 1);
  }

  public async seek(globalElapsedMillis: number): Promise<void> {

    const localElapsedMillis = Math.min(globalElapsedMillis - this._startTime, this.getLifetime() - 1);
    const objects: Promise<void>[] = this._objects.map(async (object: WideoObject) => {
      return object.seek(localElapsedMillis);
    });

    const components: Promise<void>[] = this._components.map(async (component: IComponent) => {
      return component.seek(globalElapsedMillis);
    });
    await Promise.all(objects.concat(components));
    return Promise.resolve();
  }

  public play(): void {
    this._objects.map((object: WideoObject) => {
      object.play();
    });
    this._components.map((component: IComponent) => {
      component.play();
    });
  }

  public pause(): void {
    this._objects.map((object: WideoObject) => {
      object.pause();
    });
    this._components.map((component: IComponent) => {
      component.pause();
    });
  }

  public update(globalElapsedMillis: number, playing: boolean): void {

    const actualEndTime: number = this._endTime + this._timeBleeding;

    // Verify that this WideoObject shall be visible or not. Note that
    // StartTime is inclusive and EndTime is NON-inclusive!!!
    if (this._isEnabled &&
      globalElapsedMillis >= this._startTime &&
      globalElapsedMillis < actualEndTime) {

      // Show this object and all its children
      if (!this.visible) {
        this.visible = true;
      }

      // Play children (audios, videos, self updating animations)
      if (playing) {
        this.play();
      }

      this.updateComponents(globalElapsedMillis, playing);

      this.updateChildren(globalElapsedMillis, playing);

    } else {
      // Hide this object and all its children
      if (this.visible) {
        this.visible = false;
      }
      // Pause all children (audios, videos, self updating animations)
      this.pause();
    }
  }

  /** Destroy this WideoObject and all its child objects and components */
  public destroy(): void {
    for (const component of this._components) {
      component.destroy();
    }
    // this._components = [];

    // for (const object of this._objects ) {
    //   object.destroy();
    // }
    // this._objects = [];

    this._componentsContainer.destroy();
    this._mainContainer.destroy();
    super.destroy();
  }

  public isWideoObject(): boolean {
    return true;
  }

  public setClass(newClass: Class | string) {
    this._class = newClass;
  }

  public setFocus(focus: boolean) {
    this._focus = focus;
  }

  public getFocus(): boolean {
    return this._focus;
  }

  public setLoop(loop: boolean) {
    this._loop = loop;
  }

  public getLoop(): boolean {
    return this._loop;
  }

  public static isWideoObject(displayObject: PIXI.DisplayObject): displayObject is WideoObject {
    const object: WideoObject = (displayObject as WideoObject);
    return (object.isWideoObject !== undefined) && (object.isWideoObject() === true);
  }

  public resetComponentsId(): void {
    this._components.forEach((component: IComponent) => {
      component.reset();
    });
  }

  //Flips
  public flipY() {
    const attrs: Attributes = this.getAttributes();
    attrs.scaleY = attrs.scaleY * -1;
    this.setAttributes(attrs);
  }

  public flipX() {
    const attrs: Attributes = this.getAttributes();
    attrs.scaleX = attrs.scaleX * -1;
    this.setAttributes(attrs);
  }

  public setReplaceableName(name: string) {
    this._name = name;
  }

  public getReplaceableName(): string {
    return this._name;
  }
  
  public getReplaceObjectFit() {
    return this._objectFit;
  }

  public setReplaceObjectFit(objectFit: ObjectFit) {
    this._objectFit = objectFit;
  }

  setInteractivity(interactivity: WideoObjectInteractivity) {
    this._interactivity = interactivity;
  }

  getInteractivity() {
    return this._interactivity;
  }

}
