import { WideoObjectDef } from "../model/WideoDef";
import { v4 as uuid } from 'uuid';

export default class IdManager {

  /** Replace all ids and relationships to those ids in the whole subtree of objectDef (ObjectDef can be a Scene or an Object) */
  public replaceIds(objectDef: WideoObjectDef, replaceRootId: boolean = true) {
    const idMap: object = {};
    this.resetIds(objectDef, idMap, replaceRootId);
    this.updateRelationships(objectDef, idMap);
  }

  /** Create new Ids for all objects and components in a wideo subtree */
  private resetIds(objectDef: WideoObjectDef, idMap: object, replaceRootId: boolean) {
   
    // Only replace the id of the root object if specified so (in the case with scenes and transformer V59 we do not want to)
    if (replaceRootId) {
      // Store the current id in a map from old to new id. To be used for updating relationships between
      // WideoObjects (MaskObject targetId and WideoObject maskId)
      const newId = uuid();
      idMap[objectDef.id] = newId;

      // New ID for object
      objectDef.id = newId;
    }

    // New IDs for all children
    for (const childDef of objectDef.objects) {
      this.resetIds(childDef, idMap, true)
    }

    // New IDs for all components that have IDs
    for (const componentDef of objectDef.components) {
      // TODO: Not all components have the ID property, let's do like this while we think about it ;-)
      if (componentDef['id']) {
        componentDef['id'] = uuid();
      }
    }
  }

  private updateRelationships(objectDef: WideoObjectDef, idMap: object) {

    // Update the ids of all relationships from the idMap.
    if (objectDef['maskId']) {
      objectDef['maskId'] = idMap[objectDef['maskId']];
    }
    if (objectDef['targetId']) {
      objectDef['targetId'] = idMap[objectDef['targetId']];
    }

    // Update relationships in all children
    for (const childDef of objectDef.objects) {
      this.updateRelationships(childDef, idMap)
    }
  }

}