/**
 * Created by dclerici.
 */
import WideoObject from "../WideoObject";
import AbstractComponent from './AbstractComponent';
import WideoContext from "../WideoContext";
import { AnimatedGroupComponentDef } from "../model/WideoDef";
import Attributes from "../Attributes";
  
export default class AnimatedGroupComponent extends AbstractComponent {

  constructor(context: WideoContext, owner: WideoObject, def: AnimatedGroupComponentDef) {
    super(true, 'AnimatedGroupComponent-' + def.id);

    this._context = context;
    this._owner = owner;
    this._class = def.class;
    this._id = def.id;

  }

  public update(deltaTime: number, playing?: boolean): Attributes {
    return null;
  }

  public serialize(): AnimatedGroupComponentDef {
    const component = {
      id: this._id,
      class: this._class,
    };

    return component;

  }

}
