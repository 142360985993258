import * as React from 'react';

import Player from "../../player/core/Player";
import IPlayerClockListener from '../../player/core/IPlayerClockListener';

interface State {
    currentTime: number;
    fps: number;
}

interface Props {
    player: Player;
    [propName: string]: any; // tslint:disable-line:no-any
}

export interface WithClock {
    currentTime: number;
    fps?: number;
}

// tslint:disable-next-line:no-any
export function withClock(WrappedComponent: any) {

  return class extends React.Component<Props, State>  implements IPlayerClockListener {
      private lastUpdate: number = 0;

      constructor(props: Props) {
          super(props);

          this.state = {
                  currentTime: props.player.getCurrentTime(),
                  fps: 60
          };
      }

      componentDidMount(): void {
          this.props.player.addClockListener(this);
      }

      componentWillUnmount(): void {
          this.props.player.removeClockListener(this);
      }

     /**
      * Implementing listener interface of Clock
      *
      * @param {number} globalElapsedTime in milliseconds
      */
     update(globalElapsedTimeMillis: number): void {
         // Optimization, only update time display at most every tenth of a second
         // TODO: Maybe this should be implemented in shallComponentUpdate?
         if ( (Math.abs(globalElapsedTimeMillis - this.lastUpdate) >= 10) || (globalElapsedTimeMillis % 100) === 0) {
             this.setState(() => {
                 return {
                     currentTime: globalElapsedTimeMillis
                 };
             });
             this.lastUpdate = globalElapsedTimeMillis;
         }

     }

      render() {
          // ... and renders the wrapped component with the fresh data!
          // Notice that we pass through any additional props
          return <WrappedComponent currentTime={this.state.currentTime} {...this.props} />;
      }
  };
}
