
export enum WideoDefVersion {
  V36 = 3.6,
  V37 = 3.7,
  V38 = 3.8, // changed architecture of transitions for scene
  V40 = 4.0, // Removed inner object in TextObjects (in the process removing the alignment of text using the x and y
  // attributes of the inner object)
  V41 = 4.1, // Removed TextComponentProperties wordWrap, wordWrapWidth, breakWords and replaced lineHeight with
  V411 = 4.11, // OpenSans_v2 changed name to OpenSans_Light
  V42 = 4.2, // Added properties width, height and version on scenes
  V43 = 4.3, // Added property presentationMode. Player setting
  V44 = 4.4, // Conditional transform (only run on selected converted wideo templates),
  V45 = 4.5, // Added properties targetId, width, height and type to MaskObject, removed MaskComponent from MaskObject
  V46 = 4.6, // Added property repeat on AudioComponentDef
  V47 = 4.7, // Fixed a bug that displaced shapes by half of the border width and removed non-applied masks
  V48 = 4.8, // Added property ID to keyframes
  V49 = 4.9, // Removed MaskComponent from all Scenes and made the mask an incorporated part of each scene instead
  V50 = 5.0, // Removed Brightness from WideoObject
  V51 = 5.1, // Sanitize fontSize since it is null in some cases and also bring it into range [MIN..MAX]
  V52 = 5.2, // Added scaleXInverted, scaleYInverted to wideoObjects
  V53 = 5.3, // Cleanup fonts OpenSans_v2, NanumMyeongjo that for some reason is circulating among wideos
  V54 = 5.4, // Added audio startTime and endTime
  V55 = 5.5, // Round all object start and end time to hundreds of a second
  V56 = 5.6, // Fixed old animated assets. (animatedImageComponent Replacement)
  V57 = 5.7, // Added property animated to ImageComponent (indicates that a image is an animated GIF), removed loop property from non-animated images
  V58 = 5.8, // Created new ids for all mask objects and target objects inside MaskedObjects and Placeholders to fix a bug with duplicate ids retroactivamente
  V59 = 5.9, // A retroactive fix that fixes all cases of duplicate object ids in all wideo objects
  V60 = 6.0, // Removed scaleXInverted and scaleYInverted to WideoObject, flip is now modeled with negative scale
  V70 = 7.0, // Created an AudioObject between Wideo and AudioComponent and also allow AudiosObjects under scenes
  V71 = 7.1, // Added wideo root property thumbnails which lists the thumbnails for the wideo
  V72 = 7.2, // Added sequence number
}

//<<<---- update this when you upgrade
export const CURRENT_WIDEO_DEF_VERSION: WideoDefVersion = WideoDefVersion.V72;

export enum Class {
  // Wideo:
  Wideo = 'wideo',
  // WideoObjects:
  WideoObject = 'wideoObject',
  MaskObject = 'maskObject',
  ObjectGroup = 'objectGroup',
  MaskedObject = 'maskedObject',
  Placeholder = 'placeholder',
  ImageObject = 'imageObject',
  TextObject = 'textObject',
  ShapeObject = 'shapeObject',
  AnimatedImageObject = 'animatedImageObject',
  AnimatedGroupObject = 'animatedGroupObject',
  VideoObject = 'videoObject',
  AudioObject = 'audioObject',
  Scene = 'scene',
  // Components:
  MaskComponent = 'mask',
  BackgroundComponent = 'background',
  ImageComponent = 'image',
  AnimatedImageComponent = 'animatedImage',
  AnimatedGroupComponent = 'animatedGroup',
  TransitionComponent = 'transition',
  AnimationComponent = 'animation',
  TextComponent = 'text',
  AudioComponent = 'audio',
  ShapeComponent = 'shape',
  VideoComponent = 'video',
}

export enum MaskType {
  Rectangle = 'rectangle',
  Circle = 'circle',
  Ellipse = 'ellipse',
  RoundedRect = 'roundedRect',
  Diamond = 'diamond'
}

export enum ShapeType {
  Arrow = 'arrow',
  Diamond = 'diamond',
  Star = 'star',
  MultipleStar = 'multipleStar',
  Hexagon = 'hexagon',
  Square = 'square',
  Rectangle = 'rectangle',
  RoundedRect = 'roundedRect',
  RoundedSquare = 'roundedSquare',
  Triangle = 'triangle',
  TriangleRectangle = 'triangleRectangle',
  Line = 'line',
  DottedLine = 'dottedLine',
  Ellipse = 'ellipse'
}

export namespace ShapeType {

  export function getShapeTypeFromAssetId(assetId: string): ShapeType {
    let shapeType: ShapeType;
    switch (assetId) {
      case '9223371978361715914': shapeType = ShapeType.RoundedRect; break;
      case '9223371978361715915': shapeType = ShapeType.Star; break;
      case '9223371978361715916': shapeType = ShapeType.Diamond; break;
      case '9223371978361715917': shapeType = ShapeType.MultipleStar; break;
      case '9223371978361715918': shapeType = ShapeType.Hexagon; break;
      case '9223371978361715919': shapeType = ShapeType.Rectangle; break;
      case '9223371978361715920': shapeType = ShapeType.Line; break;
      case '9223371978361715921': shapeType = ShapeType.TriangleRectangle; break;
      case '9223371978361715922': shapeType = ShapeType.Square; break;
      case '9223371978361715923': shapeType = ShapeType.RoundedSquare; break;
      case '9223371978361715924': shapeType = ShapeType.Arrow; break;
      case '9223371978361715925': shapeType = ShapeType.DottedLine; break;
      case '9223371978361715926': shapeType = ShapeType.Triangle; break;
      case '9223371978361715927': shapeType = ShapeType.Ellipse; break;
      default: shapeType = null; break;
    }
    return shapeType;
  }


}

export enum TransitionType {
  Intro = 'intro',
  Outro = 'outro'
}

export enum Tween {
  PopIn = 'popIn',
  PopOut = 'popOut',
  EnlargeIn = 'enlargeIn',
  EnlargeOut = 'enlargeOut',
  FadeIn = 'fadeIn',
  FadeOut = 'fadeOut',
  SlideLeft = 'slideLeft',
  SlideRight = 'slideRight',
  SlideUp = 'slideUp',
  SlideDown = 'slideDown',
  ZoomIn = 'zoomIn',
  ZoomOut = 'zoomOut',
  ScaleIn = 'scaleIn', //for scenes
  ScaleOut = 'scaleOut', //for scenes
  HandLeft = 'handLeft',
  HandRight = 'handRight',
  HandUp = 'handUp',
  HandDown = 'handDown',
  AutoType = 'autoType',
  AutoTypeWord = 'autoTypeWord',
}

/**
 * See https://developer.mozilla.org/en-US/docs/Web/CSS/object-fit for definition of the terms used
 */
export enum ObjectFit {
  Contain = 'contain',    // Default, whole replacing image contained inside replaced object, letterboxed if needed
  Cover = 'cover',        // Whole replaced object covered by replacing image, cropped if needed
  Fill = 'fill',          // Fill whole replaced object with the replacing object, no respect for aspect ratio
  None = 'none',          // No fit, natural scale of replacing object (=1)
  ScaleDown = 'scaleDown' // As None or Contain, whichever results in the smaller concrete size
}

export namespace Tween {

  export function parse(tween: string): Tween {
    let result: string;
    for (const tweenObj in Tween) {
      if (tweenObj.toLowerCase() === tween.toLowerCase()) {
        result = tweenObj
      }
    }
    return Tween[result];
  }
}

export enum Easing {
  Linear = 'Linear',
  QuadraticIn = 'QuadraticIn',
  QuadraticOut = 'QuadraticOut',
  QuadraticInOut = 'QuadraticInOut',
  ElasticIn = 'ElasticIn',
  ElasticOut = 'ElasticOut'
}

export interface Def {
  id: string;
  class: Class | string;
  width: number, // Native Pixel width
  height: number, // NAtive Pixel height
  version: number  // [major].[minor], step major for non backward compatible changes
}

// export default interface WideoDef extends Def {
//   timeStamp: number;        // number of milliseconds since midnight,
//   // January 1, 1970 Universal Coordinated Time (UTC)
//   scenes: SceneDef[];
//   audioComponents: AudioComponentDef[];
//   presentationMode: boolean;
// }

export default interface WideoDef extends Def {
  createdFrom?: string;
  createdBy?: string;
  timeStamp: number;        // number of milliseconds since midnight,
                            // January 1, 1970 Universal Coordinated Time (UTC)
  sequenceNumber: number;   // Every save or alteration updates the sequenceNumber
  scenes: SceneDef[];
  audios: WideoObjectDef[];
  presentationMode: boolean;
  colors?: WideoColorDef[]; // Optional ordered list of named colors for the wideo
}

/* A Wideo Color object holds a named color that can be replaced by
   the editor, automation or any other client, example:
   {
     "name": "Primary",
     "color": "#FFFFFF"
    }
*/
export interface WideoColorDef {
  name: string,
  color: string // "#4f4f4f" RGB
  // TODO: description: string
}

export interface SceneDef extends Def, WideoObjectDef {
  thumbnail?: number; // Optional thumbnail time in the scene (scene local time)
}

export interface WideoObjectDef {
  id: string;
  name?: string;
  objectFit?: ObjectFit;             
  class: Class | string;
  attributes: AttributesDef;
  startTime: number;              // Milliseconds relative to parent object
  endTime: number;                // Milliseconds relative to parent object
  components: ComponentDef[];
  objects: WideoObjectDef[];
  hidden: boolean;
  locked: boolean;
  maskId?: string;
  loop?: boolean;
  interactivity?: WideoObjectInteractivityDef;
}

export interface MaskObjectDef extends WideoObjectDef {
  targetId: string;
  width: number;
  height: number;
  type: MaskType;
}

export interface TextObjectDef extends WideoObjectDef {
}

export interface WideoObjectInteractivityDef {
  url: string;
  newTab: boolean;
}

export interface AttributesDef {
  x: number;
  y: number;
  scaleX: number;
  scaleY: number;
  rotation: number;  // Radians
  alpha: number;     // [0..1]
}

export interface ComponentDef {
  class: Class;
}

export interface AnimationComponentDef extends ComponentDef {
  class: Class; //'animation';
  animation: AnimationDef;
}

export interface AnimationDef {
  keyFrames: KeyFrameDef[];
}
export interface KeyFrameDef {
  id: string;
  attributes: AttributesDef;
  time: number;  // Milliseconds
  easing?: Easing; // Easing is optional, see KeyFrame.ts for default easing
}

export interface AudioComponentDef {
  id: string;
  class: Class; // 'audio'
  volume: number;
  repeat: boolean;
  src: string;
  fadeOut: boolean;
}

export interface MaskComponentDef extends ComponentDef {
  class: Class; //'mask';
  type: MaskType;
  width: number;
  height: number;
}

export interface BackgroundComponentDef extends ImageComponentDef {
  id: string;
  class: Class;       // 'background' | 'image'; //for now allow image since we inherit
  color?: string;      // "0xRRGGBB", either color or src need to be set
  keepRatio?: boolean; // only needed if src is set
  src?: string;        // either src or color needs to be set
}

export interface AnimatedGroupComponentDef extends ComponentDef {
  id: string;
  class: Class;
}

export interface ImageComponentDef extends ComponentDef {
  id: string;
  class: Class;   // 'image' | 'background'; //for now allow image since we inherit
  src?: string;    // optional because BackgroundComponent is inheriting ImageComponent
  // TODO: Can we compose BackgroundComponent of ImageComponent instead?
  loop?: boolean; // Optional since it is only applicable for Animated GIFs
  animated?: boolean; // Optional, not present should be interpreted as true
}

export interface AnimatedImageComponentDef extends ComponentDef {
  id: string;
  class: Class;
  src: string;
}

export interface VideoComponentDef extends ComponentDef {
  id: string;
  class: Class; // 'video'
  src: string;
  fadeOut: boolean;
  volume: number;
  repeat: boolean;
}

export interface TextComponentDef extends ComponentDef {
  id: string;
  class: Class; //'text';
  text: string;
  width: number;
  height: number;
  style: TextStyleDef;
}

export interface TextStyleDef {
  align?: 'left' | 'center' | 'right';
  fill?: string;
  fontFamily?: string;
  fontSize?: number;
  fontStyle?: 'normal' | 'italic';
  fontWeight?: 'normal' | 'bold';
  lineHeightFactor?: number;
}

export interface TransitionComponentDef extends ComponentDef {
  class: Class; // 'transition';
  type: TransitionType; // 'intro' | 'outro';
  tween: Tween;
  length: number;  // milliseconds
}

export interface ShapeComponentDef extends ComponentDef {
  id: string;
  class: Class; //'shape';
  shapeType: ShapeType;
  borderColor: string; //RGBA (alpha channel is applied to border)
  borderAlpha: number;
  width: number;
  height: number;
  fillColor: string;
  fillAlpha: number;
  borderThickness: number;
  cornerRadius?: number;
}
