import * as React from 'react';


import Logger from '../../common/log/Logger';
import IEncoderQueryParams from './IEncoderQueryParams';
import Encoder, { ImageFormat } from '../core/Encoder';
import EncoderFfmpeg from '../core/EncoderFfmpeg';
import { version } from '../core/EncoderConstants';
import S3Uploader from '../core/S3Uploader';

import * as base64 from 'base64-arraybuffer-es6';
/* 
const styles = {
  wideoCanvasContainer: {
    maxHeight: 1080,
    maxWidth: 1920
  },
}; */

interface State {
  version: string;
  state: ManualEncoderState;
  error: string;
  // displayRenderView: boolean;
  displayFrames: boolean;
  uploadFrames: boolean;
  displayVideo: boolean;
  frameImageFormat: ImageFormat;
  quality: number; //0..1
  startFrame: number;
  stopFrame: number;
  videoUrl: string;
  frameBuffers: ArrayBuffer[],
  framesUrls: string[];
  generatedFrames: number;
  uploadedFrames: number;
  fps: number;
  scale: number;
  wideoId: string;
  processId: number;
  s3Key: string;
  s3AccessKey: string;
  s3Bucket: string;
}

interface Props {
  queryParams: IEncoderQueryParams;
}

type Styled = any

type PropsWithStyles = Props & Styled;

//type PropsWithStyles = Props & WithStyles<'wideoCanvasContainer'>;

export enum ManualEncoderState {
  Ready = 'Ready',
  Loading = 'Loading',
  Loaded = 'Loaded',
  GeneratingFrames = 'GeneratingFrames',
  FramesGenerated = 'FramesGenerated',
  FramesUploaded = 'FramesUploaded',
  EncodingVideo = 'EncodingVideo',
  VideoEncoded = 'VideoEncoded',
  Error = 'Error'
}

/* EncoderApp is the root component in the Encoder. */
class ManualEncoder extends React.Component<PropsWithStyles, State> {

  private canvas: HTMLCanvasElement;
  private encoder: Encoder;

  constructor(props: PropsWithStyles) {
    super(props);
    this.state = {
      version: version,
      state: ManualEncoderState.Ready,
      error: null,
      // displayRenderView: false,
      displayFrames: false,
      displayVideo: false,
      uploadFrames: true,
      frameImageFormat: ImageFormat[props.queryParams.imageType] || ImageFormat.png, //'jpg', "png", 'rgba'
      quality: 1, //0..1
      startFrame: Number(props.queryParams.start),
      stopFrame: Number(props.queryParams.end),
      videoUrl: null,
      frameBuffers: [],
      framesUrls: [],
      generatedFrames: 0,
      encodedFrames: 0,
      uploadedFrames: 0,
      fps: Number(props.queryParams.fps),
      scale: 1,
      wideoId: props.queryParams.wideoId,
      processId: props.queryParams.processId,
      s3Key: props.queryParams.s3Key,
      s3AccessKey: props.queryParams.s3AccessKey,
      s3Bucket: props.queryParams.s3Bucket
    };

    this.encoder = new Encoder();

    (window as any).wideo = (window as any).wideo || {}; // tslint:disable-line:no-any
    (window as any).wideo.manualEncoderApp = this; // tslint:disable-line:no-any


  }

  // async componentDidMount(): Promise<void> {
  //   if (this.props.queryParams.wideoId) { 
  //     await this.loadWideo();
  //     await this.generate();
  //   }
  // }

  public getAllFramesBase64Encoded(): string[] {
    const base64EncodedFrameBuffers: string[] = [];
    for (const frame of this.state.frameBuffers) {
      const base64EncodedBuffer = base64.encode(frame);
      base64EncodedFrameBuffers.push(base64EncodedBuffer);
    }
    return base64EncodedFrameBuffers;
  }

  public getRendererInfo(): Object {
    return {
      state: this.state,
      options: this.encoder.engine.renderer.options,
      type: this.encoder.engine.renderer.type,
      screen: this.encoder.engine.renderer.screen,
      view: this.encoder.engine.renderer.view.outerHTML
    }
  }

  public canvasCallback = (canvas: HTMLCanvasElement) => {
    this.canvas = canvas;
  }

  private loadWideo = async () => {
    this.setState(() => {
      return {
        state: ManualEncoderState.Loading
      };
    });
    await this.encoder.init(
      this.canvas,
      this.props.queryParams.environment,
      this.props.queryParams.accessToken,
      this.state.wideoId,
      null,
      null,
      null,
      null,
      null,
      null,
      this.props.queryParams.forceCanvas,
      this.props.queryParams.antialias,
      this.props.queryParams.replace, 
      this.props.queryParams.replaceId,
      this.props.queryParams.jsonUrl);


    this.canvas.style.width = this.encoder.getWideo().getWidth() / window.devicePixelRatio + 'px';
    this.canvas.style.height = this.encoder.getWideo().getHeight() / window.devicePixelRatio + 'px';

    this.setState(() => {
      return {
        state: ManualEncoderState.Loaded
      };
    });

  }

  async generate(startFrameParam?: number, stopFrameParam?: number) {
    
    Logger.log("Generating frames " + startFrameParam + " to " + stopFrameParam);
    const fps = this.state.fps;
    let stopFrame = stopFrameParam ? stopFrameParam : this.state.stopFrame;
    if (!stopFrame) {
      stopFrame = this.encoder.getWideoLength() / 1000 * fps;
      this.setState(() => {
        return {
          stopFrame: stopFrame
        };
      });
    }
    const frameObjectUrls: string[] = [];
    this.setState(() => {
      return {
        state: ManualEncoderState.GeneratingFrames
      };
    });
    const startFrame = startFrameParam ? startFrameParam : this.state.startFrame;

    const s3Uploader = new S3Uploader(this.state.s3Key, this.state.s3AccessKey, this.state.s3Bucket);
    let contentType = 'image/jpeg';
    if (this.state.frameImageFormat === ImageFormat.jpg) {
      contentType = 'image/jpeg';
    } else if (this.state.frameImageFormat === ImageFormat.png) {
      contentType = 'image/png';
    } else if (this.state.frameImageFormat === ImageFormat.rgba) {
      contentType = 'image/rgba';
    }

    const uploads: Promise<void>[] = [];
    for (let i = startFrame; i < stopFrame; i++) {
      const time = i * (1 / fps) * 1000;
      Logger.log("Generate frame #" + i + " at time: " + time);
      const frameBuffer: ArrayBuffer = await this.encoder.generateFrameAt(this.state.frameImageFormat, time);

      this.setState((prevState) => ({
        generatedFrames: prevState.generatedFrames + 1
      }));

      if (this.state.displayFrames) {
        this.state.frameBuffers.push(frameBuffer);
        const frameObjectDataUrl: string = URL.createObjectURL(new Blob([frameBuffer]));
        frameObjectUrls.push(frameObjectDataUrl);
      }

      if (this.state.uploadFrames) {
        const key = this.state.wideoId + '/' + this.state.processId + '/' + this.state.startFrame + '/' + i + '.' + this.state.frameImageFormat;
        
        uploads.push(s3Uploader.uploadToS3(frameBuffer, key, contentType).then(() => {
          this.setState((prevState) => ({
            uploadedFrames: prevState.uploadedFrames + 1
          }));
        }));
        

      }

    }

    this.setState(() => {
      return {
        state: ManualEncoderState.FramesGenerated,
        framesUrls: frameObjectUrls
      };
    });

    if (this.state.uploadFrames) {
      Logger.log("Waiting for all uploads to finish...")
      await Promise.all(uploads);
      Logger.log("All uploads finished.")
      this.setState(() => {
        return {
          state: ManualEncoderState.FramesUploaded,
        };
      });
    }

  }

  encode = async () => {
    const fps = this.state.fps;
    try {
      this.setState(() => {
        return {
          state: ManualEncoderState.EncodingVideo
        };
      });
      Logger.time("encodeJpg2Mp4WorkerFs");
      const video: ArrayBuffer = await new EncoderFfmpeg().encode2Mp4WorkerFs(fps, this.state.frameImageFormat, this.state.frameBuffers, this.encoder.getWideo().getWidth(), this.encoder.getWideo().getHeight());
      Logger.timeEnd("encodeJpg2Mp4WorkerFs");
      this.setState(() => {
        return {
          state: ManualEncoderState.VideoEncoded,
          videoUrl: URL.createObjectURL(new Blob([video]))
        };
      });
    } catch (error) {
      this.setState(() => {
        return {
          state: ManualEncoderState.Error
        }
      });
    }
    Logger.log("Done encoding.")
  }

  handleChangeWideoId = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value: string = event.target.value;

    this.setState({ wideoId: value });
  }

  handleChangeInputNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value: number = parseInt(target.value, 10);
    const name: string = target.name as 'fps' | 'scale' | 'startFrame' | 'stopFrame';
    this.setState({ [name]: value } as Pick<State, 'fps' | 'scale' | 'startFrame' | 'stopFrame'>
    );
  }

  handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value: boolean = event.target.checked;
    const name = event.target.name;

    this.setState({ [name]: value } as Pick<State, /*'displayRenderView' |*/ 'displayFrames' | 'displayVideo' | 'uploadFrames'>);
  }

  handleChangeImageFormat = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value: string = event.target.value;

    this.setState({ frameImageFormat: ImageFormat[value] });
  }

  handleLoad = async () => {
    await this.loadWideo();
  }

  handleGenerate = async () => {
    await this.loadWideo();
    await this.generate();
  }

  handleEncode = async () => {
    await this.loadWideo();
    await this.generate();
    await this.encode();
  }

  render() {
    
    return (
      <div>

        <div>
          <div>
            <form>
              Version: <span id="version">{this.state.version}</span><br />
              State: <span id="state">{this.state.state}</span><br />
              Generated frames: {this.state.generatedFrames}<br />
              Uploaded frames: {this.state.uploadedFrames}<br />
              WideoId: <input cyid="encoderWideoId" name="wideoId" value={this.state.wideoId} onChange={this.handleChangeWideoId} /><br />{}<br />
              FPS: <input name="fps" value={this.state.fps} onChange={this.handleChangeInputNumber} /><br />
              Scale: <input name="scale" value={this.state.scale} onChange={this.handleChangeInputNumber} /><br />
              StartFrame: <input name="startFrame" value={this.state.startFrame} onChange={this.handleChangeInputNumber} /><br />
              StopFrame: <input name="stopFrame" value={this.state.stopFrame} onChange={this.handleChangeInputNumber} /><br />
              ImageFormat:
              <select name="frameImageFormat" value={this.state.frameImageFormat} onChange={this.handleChangeImageFormat}>
                <option value={ImageFormat.jpg}>{ImageFormat.jpg}</option>
                <option value={ImageFormat.png}>{ImageFormat.png}</option>
                <option value={ImageFormat.rgba}>{ImageFormat.rgba}</option>
              </select><br />
              {/* <label>
                Display Render View:
                <input
                  name="displayRenderView"
                  type="checkbox"
                  checked={this.state.displayRenderView}
                  onChange={this.handleChangeCheckbox} />
              </label><br /> */}
              <label>
                Display Frames:
                <input
                  name="displayFrames"
                  type="checkbox"
                  checked={this.state.displayFrames}
                  onChange={this.handleChangeCheckbox} />
              </label><br />
              <label>
                Upload Frames to S3:
                <input
                  name="uploadFrames"
                  type="checkbox"
                  checked={this.state.uploadFrames}
                  onChange={this.handleChangeCheckbox} />
              </label><br />
              <label>
                Display Video:
                <input
                  name="displayVideo"
                  type="checkbox"
                  checked={this.state.displayVideo}
                  onChange={this.handleChangeCheckbox} />
              </label><br />
              <input type="button" name="load" value="Load Wideo" onClick={this.handleLoad} disabled={this.state.state !== ManualEncoderState.Ready} />
              <input type="button" name="generate" value="Generate Frames" onClick={this.handleGenerate} disabled={this.state.state !== ManualEncoderState.Ready && this.state.state !== ManualEncoderState.Loaded} />
              <input type="button" name="encode" value="Encode" onClick={this.handleEncode} disabled={this.state.state !== ManualEncoderState.Ready && this.state.state !== ManualEncoderState.Loaded && this.state.state !== ManualEncoderState.FramesGenerated} />
            </form>

          </div>
          <div id="EncoderDiv">
            <div 
              //style={styles.wideoCanvasContainer}
            >
              <canvas ref={this.canvasCallback} />
            </div>
          </div>

          {this.state.displayVideo && this.state.videoUrl &&
            <div>
              <p>
                <a id="link" href={this.state.videoUrl} download={this.state.wideoId + '.mp4'}><video id="video" src={this.state.videoUrl} width={this.encoder.getWideo().getWidth() / window.devicePixelRatio} height={this.encoder.getWideo().getHeight() / window.devicePixelRatio} controls autoPlay loop>Your browser does not support video</video>{this.state.wideoId + '.mp4'}</a><br />
              </p>
            </div>
          }

          {this.state.framesUrls.map((frameUrl: string, i: number) => {
            return (
              <span key={i}>
                <a href={frameUrl} download={i + '.' + this.state.frameImageFormat}><img src={frameUrl} style={{ maxWidth: 240, maxHeight: 240 }} />{i + '.' + this.state.frameImageFormat}</a>
              </span>
            );
          })}

        </div>
      </div>
    );
  }
}

export default ManualEncoder;
