/**
 * Wideo play state
 */
export enum PlayState {
        Loading = 'Loading',
        Loaded = 'Loaded',
        Playing = 'Playing',
        Paused = 'Paused',
        Stopped = 'Stopped',
        Ended = 'Ended',
        Error = 'Error'
}
