
import * as Sentry from '@sentry/browser';
import packageJson from '../../../package.json';

export const sentryInit = () => {

    Sentry.init(
        {
            dsn: "https://e89452ed55ad420ea56cc33a17cee675@sentry.io/1769411",
            environment: window.location.href.indexOf("editor.wideo.co") > 0 ? 'production' :
                window.location.href.indexOf("editordev.wideo.co") > 0 ? 'staging' :
                    'development',
            release: packageJson.version,
            attachStacktrace: true,
        }
    );
}