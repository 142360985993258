import * as PIXI from 'pixi.js-legacy';
import WideoObject from "../WideoObject";
import { PendingAssetResource } from "../AssetsLoader";
import { VideoComponentDef } from '../model/WideoDef';
import AbstractComponent from './AbstractComponent';
import WideoContext from '../WideoContext';
import Attributes from "../Attributes";
import Logger from '../../log/Logger';
import IAudio from '../IAudio';

export default class VideoComponent extends AbstractComponent implements IAudio {

  private _src: string;
  private _playing: boolean;
  private _sprite: PIXI.Sprite;
  private _videoElement: HTMLVideoElement;
  private _videoResource: PIXI.VideoResource;

  private _fadeOut: boolean; // Not implemented
  private _volume: number;
  private _repeat: boolean;

  private _seekedCallback: () => void;
  private _errorCallback: () => void;
  private _resolve: () => void;
  private _seekTimeoutId: number;

  constructor(context: WideoContext, owner: WideoObject, def: VideoComponentDef) {
    super(true, 'VideoComponent-' + def.id);
    this._context = context;
    this._owner = owner;
    this._class = def.class;
    this._id = def.id;
    this._src = def.src;
    this._fadeOut = def.fadeOut ? def.fadeOut : false;
    this._repeat = def.repeat ? def.repeat : false;
    this._volume = (typeof def.volume !== 'undefined') ? Math.min(1, Math.max(0, def.volume)) : 1; // Default to full volume 100%

    this._context.getAssetsLoader().push(
      {
        id: this._id,
        src: this._src
      },
      () => {
        this.addContent(this._id);
      },
      this._context.getAssetsLoader().getLoadFullVideos() ?
        // This loads the video as XHR instead of as a VideoElement which is the default in PIXI
        // This allows us to force the load of 100% of the video before allowing playback 
        {
          metadata: {
            isVideo: true
          },
          loadType: PIXI.LoaderResource.LOAD_TYPE.XHR,
          xhrType: PIXI.LoaderResource.XHR_RESPONSE_TYPE.BLOB
        }
        :
        {}
    );
  }

  public serialize(): VideoComponentDef {
    return {
      id: this._id,
      class: this._class,
      src: this._src,
      volume: this._volume,
      fadeOut: this._fadeOut,
      repeat: this._repeat
    }
  }

  public getSrc = (): string => {
    return this._src;
  }

  protected addContent(key: string): void {
    const asset: PendingAssetResource = this._context.getAssetsLoader().getAsset(key);
    const content: PIXI.ILoaderResource = asset.content;
    if (content.error) {
      Logger.error("Failed adding video resource in VideoComponent, error: %o", content.error);
      return;
    }
    this._videoElement = (this._context.getAssetsLoader().getLoadFullVideos() ? content['video'] : content.data);

    // hack to enable reproduction of videos with sound on mobile
    window.addEventListener('touchstart', () => {
      const playPromise = this._videoElement.play();
      if (playPromise) { // tslint:disable-line:no-promise-as-boolean
        playPromise.then(
          () => {
            this._videoElement.pause();
          },
          (reason) => Logger.warn('Failed activating video, reason: ', reason));
      }
    }, { once: true, passive: true });

    const texture = PIXI.Texture.from(this._videoElement, { resourceOptions: { autoPlay: false } });

    this._videoResource = texture.baseTexture.resource as PIXI.VideoResource;
    this._videoResource.autoUpdate = false;

    // Set initial volume
    this._videoElement.volume = this._volume;
    this._videoElement.loop = this._repeat;

    // Create the sprite to hold the texture
    this._sprite = new PIXI.Sprite(texture);
    this._sprite.name = 'VideoSprite-' + this._id;
    this._sprite.anchor.set(0);
    this._sprite.x -= this._videoElement.videoWidth * 0.5;
    this._sprite.y -= this._videoElement.videoHeight * 0.5;
    this._displayObject.addChild(this._sprite);

  }

  getRepeat(): boolean {
    return this._repeat;
  }
  setRepeat(repeat: boolean) {
    this._repeat = repeat;
  }
  getVolume(): number {
    return this._volume;
  }
  setVolume(volume: number) {
    this._volume = volume;
    this._videoElement.volume = this._volume;
  }
  setFadeOut(fadeOut: boolean) {
    this._fadeOut = fadeOut;
  }
  getFadeOut(): boolean {
    return this._fadeOut;
  }

  public enable(): void {
    this._sprite.visible = true;
    this._isEnabled = true;
  }

  public disable(): void {
    this._sprite.visible = false;
    this._isEnabled = false;
  }

  public async seek(elapsedTime: number): Promise<void> {
    if (this._videoResource) {

      let videoLocalElapsedTime = elapsedTime - this._owner.getStartTime();

      // Repeat handling 
      if (this._repeat && videoLocalElapsedTime >= this._videoElement.duration * 1000) {
        videoLocalElapsedTime = videoLocalElapsedTime % (this._videoElement.duration * 1000);
      }

      const videoLocalElapsedTimeS: number = videoLocalElapsedTime / 1000;

      // Only seek if we have moved since last seek and inside of the owning objects
      // lifetime
      if (videoLocalElapsedTime >= 0 &&
        videoLocalElapsedTimeS !== this._videoElement.currentTime &&
        videoLocalElapsedTime < this._owner.getLifetime()) {

        return this.setVideoCurrentTime(videoLocalElapsedTimeS);
      }
      else if (videoLocalElapsedTime < 0) {
        //Always wideo seeks to a time before the start of the video, we need to restart the video because it needs to start from 0
        //We set to 0 once time.
        if (this._videoElement.currentTime !== 0) {
          return this.setVideoCurrentTime(0);
        }
      }
      else if (videoLocalElapsedTime === 0) {
        //Probably the first seek used from encoder when request the first frame of the wideo if the video clip starts at the beginning of the wideo
        return this.setVideoCurrentTime(0);
      }
    }
    return Promise.resolve();
  }

  private async setVideoCurrentTime(videoLocalElapsedTimeS: number): Promise<void> {
       // If this video element is already seeking (may happen if it has an asynch search ongoing or if several different enteties seek)
      if (this._seekTimeoutId) {
        this._videoElement.removeEventListener('seeked', this._seekedCallback);
        this._videoElement.removeEventListener('error', this._errorCallback);
        window.clearTimeout(this._seekTimeoutId);
        this._seekTimeoutId = null;
        if (this._resolve) {
          this._resolve();
          this._resolve = null;
        }
      }

    const promise = new Promise<void>((resolve) => {

      // This is the normal event listener for seeked 
      this._seekedCallback = () => {  
        this._videoElement.removeEventListener('seeked', this._seekedCallback);
        this._videoElement.removeEventListener('error', this._errorCallback);
        window.clearTimeout(this._seekTimeoutId);
        this._seekTimeoutId = null;
        this._resolve = null;
        resolve();
      }
      // This is if something fails with the seek
      this._errorCallback = () => {
        Logger.warn("Error seeking video element in videoComponent " + this._id + " to video local time: " + videoLocalElapsedTimeS + ": %o", this._videoElement.error)
        this._videoElement.removeEventListener('seeked', this._seekedCallback);
        this._videoElement.removeEventListener('error', this._errorCallback);
        window.clearTimeout(this._seekTimeoutId);
        this._seekTimeoutId = null;
        this._resolve = null;
        resolve();
      }
      // Make sure we never get a hanging seek since it hands all time based animation, audio and video
      this._seekTimeoutId = window.setTimeout(() => {
        Logger.warn("Timeout seeking video element in videoComponent " + this._id + " to video local time: " + videoLocalElapsedTimeS);
        this._videoElement.removeEventListener('seeked', this._seekedCallback);
        this._videoElement.removeEventListener('error', this._errorCallback);
        this._seekTimeoutId = null;
        this._resolve = null;
        resolve();
      }, 60000);

      this._resolve = resolve;
      this._videoElement.addEventListener('seeked', this._seekedCallback);
      this._videoElement.addEventListener('error', this._errorCallback);
    });
    this._videoElement.currentTime = videoLocalElapsedTimeS;
    return promise;     

  }



  public play(): void {
    this._playing = true;
  }

  public pause(): void {
    this._playing = false;
    if (this._videoElement) {
      this._videoElement.pause();
    }
  }

  public update(elapsedTime: number, playing: boolean): Attributes {

    // Make sure the video has finished loading (update may be called before component is fully loaded)
    if (this._videoResource) {
      const videoLocalElapsedTimeMs: number = elapsedTime - this._owner.getStartTime();

      // If we are within this objects lifetime
      if (videoLocalElapsedTimeMs >= 0 &&
        videoLocalElapsedTimeMs <= this._owner.getLifetime()) {
        // make sure that the video is playing if we are supposed to be playing and the video has not ended
        if (this._videoElement.paused && this._playing && !this._videoElement.ended) {

          const playPromise = this._videoElement.play();
          // Old versions of Edge does not return anything
          if (playPromise) { // tslint:disable-line:no-promise-as-boolean
            playPromise.catch(() => {
              // Silently ignore any error since we cannot do much about it anyway
            }); // Note that this is an async operation so we cannot guarantee that we are
            // in sync when we start the video (best effort)
          }
        }
        // make sure that the video is not playing if it is paused
        else if (!this._videoElement.paused && !this._playing) {
          this._videoElement.pause();
        }

      }
      // We are outside of the object lifetime, make sure that the video is paused
      else if (!this._videoElement.paused) {
        this._videoElement.pause();
      }
      this._videoResource.update();
    }
    return null;
  }

  public destroy(): void {

    if (this._videoResource) {
      const src: string = this._videoElement.src;
      this._videoElement.src = '';
      URL.revokeObjectURL(src);
      this._videoResource.destroy();
      this._videoResource = null;
    }

    if (this._sprite) {
      this._sprite.destroy();
      this._sprite = null;
    }

    super.destroy();
  }

}
