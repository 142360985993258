import { WideoDefVersion } from "../../core/model/WideoDef";
import ITransformer from "../ITransformer";

export default class TransformerToV52 implements ITransformer {
  readonly toVersion: WideoDefVersion = WideoDefVersion.V52;

  // tslint:disable-next-line:no-any
  public transformWideo(wideoDef: any) {

    //Update version and timestamp
    wideoDef.version = this.toVersion;
    wideoDef.timeStamp = Date.now();

    for (const sceneDef of wideoDef.scenes) {
      this.transformScene(sceneDef);
    }

  }

  // tslint:disable-next-line:no-any
  transformScene(sceneDef: any): void {

    //Update version and timestamp
    sceneDef.version = this.toVersion;

    for (const wideoObjectDef of sceneDef.objects) {
      this.transformWideoObject(wideoObjectDef);
    }
  }

  // tslint:disable-next-line:no-any
  transformWideoObject(wideoObjectDef: any): void {
    wideoObjectDef.scaleXInverted = false;
    wideoObjectDef.scaleYInverted = false;
    for (const innerObjectDef of wideoObjectDef.objects) {
      this.transformWideoObject(innerObjectDef);
    }
  }
}