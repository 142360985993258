import { WideoDefVersion, Class } from "../../core/model/WideoDef";
import ITransformer from "../ITransformer";
import { v4 as uuid } from 'uuid';

export default class TransformerToV70 implements ITransformer {
  readonly toVersion: WideoDefVersion = WideoDefVersion.V70;

  // tslint:disable-next-line:no-any
  public transformWideo(wideoDef: any) {

    //Update version and timestamp
    wideoDef.version = this.toVersion;
    wideoDef.timeStamp = Date.now();

    for (const sceneDef of wideoDef.scenes) {
      this.transformScene(sceneDef);
    }

    // Add new audio arrays
    wideoDef.audios = [];

    // Transform all AudioComponents into AudioObjects containing AudioComponents
    if (wideoDef.audioComponents) {
      for (const audioComponentDef of wideoDef.audioComponents) {
        this.transformAudio(audioComponentDef, wideoDef);
      }
    }

    // Delete audioComponents
    delete wideoDef.audioComponents;

  }

  // tslint:disable-next-line:no-any
  transformScene(sceneDef: any): void {
    //Update version and timestamp
    sceneDef.version = this.toVersion;
  }

  // tslint:disable-next-line:no-any
  transformAudio(audioComponentDef: any, wideoDef: any): void {

     const audioObject = {
      id: uuid(),
      class: Class.AudioObject,
      startTime: audioComponentDef.startTime,
      endTime: audioComponentDef.endTime,
      attributes: {
        x: 0,
        y: 0,
        scaleX: 1,
        scaleY: 1,
        rotation: 0,
        alpha: 1,
      },
      components: [
        audioComponentDef
      ],
      objects: [],
      hidden: false,
      locked: false,
      maskId: undefined
    };

    // Delete the start and stopTime 
    delete audioComponentDef.startTime;
    delete audioComponentDef.endTime;

    wideoDef.audios.push(audioObject);

  }

}