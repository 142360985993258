import * as PIXI from 'pixi.js-legacy';

import WideoObject from './WideoObject';
import Attributes from './Attributes';
import { Class, MaskObjectDef, MaskType } from './model/WideoDef';
import WideoContext from './WideoContext';
import Logger from '../log/Logger';
import * as ShapeUtils from './ShapeUtils';

/** 
A MaskObject is a stand alone “mask” that can be masking any other object under the same parent.
*/
export default class MaskObject extends WideoObject {

    readonly _type: MaskType;
    protected _width: number;
    protected _height: number;
    protected _targetId: string;
    
    protected _localMask: PIXI.Graphics; // Transient
    protected _target: WideoObject; //Transient
    
    constructor(context: WideoContext, owner: WideoObject, def: MaskObjectDef) {
        super(context, owner, def);

        this._targetId = def.targetId; 
        this._width = def.width;
        this._height = def.height;
        this._type = def.type;

        // We cannot set the _target reference yet since we cannot guarantee that it has been created.
        this._context.pushMask(this);
        
        this._localMask = new PIXI.Graphics();
        this.addChild(this._localMask);

        this.drawMask();

    }

    public serialize(): MaskObjectDef {
        const maskObjectDef: MaskObjectDef = super.serialize() as MaskObjectDef;
        maskObjectDef.targetId = this._targetId;
        maskObjectDef.width = this._width;
        maskObjectDef.height = this._height;
        maskObjectDef.type = this._type;
        return maskObjectDef;
    }

    public setAttributes(attributes: Attributes): void {       
        super.setAttributes(attributes);
        this.refreshBitmapCache();
    }

    private refreshBitmapCache() {
        // The mask object is cached as a bitmap
        // TODO: Implement this performance optimization again, I had to remove it since we need to know when the first render
        //       has been done to know when it is safe to cache the object.
        //       Shall we hace an onFirstRender() or render only this object apart?
        // console.log("MaskObject::refreshBitmapCache()")
        // this.cacheAsBitmap = false;
        // if (this._context.getRenderer()) {
        //     console.log("MaskObject::refreshBitmapCache(), there is a renderer")
        //     const baseRenderTexture = new PIXI.BaseRenderTexture(this._width, this.height);
        //     const renderTexture = new PIXI.RenderTexture(baseRenderTexture);
        //     this._context.getRenderer().render(this, renderTexture);
        //     console.log("MaskObject::refreshBitmapCache(), caching as bitmap")
        //     this.cacheAsBitmap = true;
        // } else {
        //     console.log("MaskObject::refreshBitmapCache(), there is NO renderer")  
        // }
    }

    private drawMask(): void {
        this._localMask.clear();
        this._localMask.name = 'MaskObjectGraphics-' + this.getId();
        this._localMask.beginFill(0xffffff, 1);

        // BUGFIX: For some reason the isFastRect() funcionality of PIXI is not working. The rectangles are not painted.
        if (this._type === MaskType.Rectangle) {
            this._localMask.lineStyle(1, 0, 0);
        }
        ShapeUtils.drawShape(this._localMask, ShapeUtils.maskTypeToShapeType(this._type), this._width, this._height);
        
        this._localMask.endFill();

        // Center graphics to the object container
        this._localMask.x = -this._localMask.width * 0.5;
        this._localMask.y = -this._localMask.height * 0.5;

        
        this.refreshBitmapCache();
    }

    public getType(): MaskType {
        return this._type;
    }

    public getTargetId(): string {
        return this._targetId;
    }
    public setTargetId(targetId: string) {
        this._targetId = targetId;
    }

    /** This function is called when we know that all siblings of this MaskObject exists, right now this is handled by
     * WideoContext in a rather peculiar way. Maybe we can let some entity call an event handler, like onLoaded() instead and apply
     * masks there?
     */
    public apply() {
        if (this._targetId) {
            // Search siblings for the referenced id
            const target: WideoObject = this._parent.getObjectById(this._targetId) as WideoObject;
            if (target) {
                this._target = target;
                this._target.mask = this._localMask as any;
            } else {
                Logger.warn('Trying to apply mask ' + this._id + ' to a non existing target (targetId: ' + this._targetId + ').');
                this._target = null;
            }
        }
    }

    /** This function is called  */
    public unapply() {
        if (this._targetId) {
            // Search siblings for the referenced id
            const target: WideoObject = this._parent.getObjectById(this._targetId) as WideoObject;
            if (this._target && target) {
                this._target.mask = null;
                this._target = null;
            } else {
                Logger.warn('Trying to unapply mask' + this._id + ' from a null reference target.');
                this._target = null;
            }
        }
    }

    public static isMaskObject = (object: WideoObject): object is MaskObject => {
        return (object && object.getClass() === Class.MaskObject);
    }

}
