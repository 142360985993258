import Api from './Api'
import UserAssetAudio from './model/UserAssetAudio';
import AudioFav from './model/AudioFav';

export interface AudioResponse {
  audioID: number;
  name: string;
  durationMillis: number;
  artist?: string;
  album?: string;
  gender?: string;
  info?: string;
  fileName?: string;
  userID: number;
  privateAudio: string;
  previewFileName?: string;
  orderID?: string;
  storage: string;
  wideooID?: string;
  assetUrl: string;
  bucket: string;
  assetUrlSecure: string;
  audioIDasString: string;
  fav: boolean;
}

export default class AudiosApi extends Api {

  public async saveAudio(wideoId: string, inputFile: File): Promise<UserAssetAudio> {
    const path: string = '/audios';
    const body: FormData = new FormData();
    body.append('audio_file', inputFile);
    const params: { [s: string]: string; } = {
      accessToken: this._accessToken,
      wideoID: wideoId
    };

    // tslint:disable-next-line:no-any
    const response: any = await this.httpPostFormData<any>(path, body, params);

    return new UserAssetAudio(response.audioIDasString,
      response.name,
      response.assetUrlSecure,
      response.userID,
      null,
      null
    );
  }

  public getAudioFavs = async (): Promise<AudioFav[]> => {
    const path: string = '/audios/fav';
    const response: AudioFav[] = await this.httpGetJson<AudioFav[]>(path, { accessToken: this._accessToken });
    return response;
  }

  public async saveAsFav(audioId: string) {
    const path: string = '/audios/fav/' + audioId + '/save';
    const params: { [s: string]: string; } = { accessToken: this._accessToken };
    return this.httpPost<Object>(path, params);
  }

  public async deleteAsFav(audioId: string) {
    const path: string = '/audios/fav/' + audioId + '/delete';
    const params: { [s: string]: string; } = { accessToken: this._accessToken };
    return this.httpPost<Object>(path, params);
  }

  public getAudioShared = async (): Promise<AudioFav[]> => {
    const path: string = '/audios/fav';
    const response: AudioFav[] = await this.httpGetJson<AudioFav[]>(path, { accessToken: this._accessToken });
    return response;
  }

  public async saveAsShared(audioId: string) {
    const path: string = '/audios/fav/' + audioId + '/save';
    const params: { [s: string]: string; } = { accessToken: this._accessToken };
    return this.httpPost<Object>(path, params);
  }

  public async deleteAsShared(audioId: string) {
    const path: string = '/audios/fav/' + audioId + '/delete';
    const params: { [s: string]: string; } = { accessToken: this._accessToken };
    return this.httpPost<Object>(path, params);
  }

  public async deleteAudio(audioId: string): Promise<Object> {
    const path: string = '/audios/' + audioId + '/DELETE';
    const params: { [s: string]: string; } = { accessToken: this._accessToken };
    return this.httpPost<Object>(path, params);
  }

  public async getAudioUrl(audioId: string): Promise<string> {
    const path: string = '/audios/' + audioId;
    const params: { [s: string]: string; } = {
      accessToken: this._accessToken,
    };

    const audio: AudioResponse = await this.httpGetJson<AudioResponse>(path, params);
    return audio.assetUrlSecure;
  }


}
