import { getCookie, getUrlParameter } from "../EditorConstants";



export default class EditorAccessTokenHandler {

  public static getAccessToken(): string {
    //read session cookie
    const cookie = getCookie("sessionID") ? getCookie("sessionID").replace(/["\\]/g, ''): '';
    const accessToken = cookie ? cookie : getUrlParameter("accessToken");

    if (accessToken && accessToken.trim().length !== 0) {
      return accessToken;
    } else {
      throw new Error("User don't have valid session");
    }
  }
}
