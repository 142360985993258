//Converts from degrees to radians.
import WideoDef, { Tween, Def, SceneDef, WideoObjectDef } from "../../common/core/model/WideoDef";
import WideoFile from "../../common/core/model/WideoFile";
import TemplateObject from "../../api/model/TemplateObject";
import WideoApi from "../../api/WideoApi";
import Deserializer from "../../common/serialization/Deserializer";
import Logger from "../../common/log/Logger";
import { environments } from "../../common/Env";

export const resourcesBaseUrl = "https://resources.wideo.co/wideo-html5-react";

//CHARGE_TYPE_ID by plan
export const CHARGE_TYPE_ID_FREE = 18;

export const CHARGE_TYPE_ID_PRO_ANNUAL = 39;
export const CHARGE_TYPE_ID_PRO_MONTHLY = 40;

export const CHARGE_TYPE_ID_PRO_PLUS_ANNUAL = 44;
export const CHARGE_TYPE_ID_PRO_PLUS_MONTHLY = 45;

export const userWithoutSessionRedirectToLogin = function (wideoId: string, environment: string) {
  // tslint:disable-next-line: no-any
  window.onbeforeunload = (): any => null;
  Logger.warn("Failed editor load, because user don't have session. Redirecting to login"); // Leaves a breadcrumb for sentry
  const urlToEditWideo = environments[environment].webUrl.edit + "/" + wideoId;
  window.location.href = environments[environment].webUrl.base + environments[environment].webUrl.login + "?nextUrl=" + encodeURIComponent(urlToEditWideo);
}

export const toRadians = function (degrees: number): number {
  return degrees * (Math.PI / 180);
};

// Converts from radians to degrees.
export const toDegrees = function (radians: number): number {
  return radians * 180 / Math.PI;
};

/** Round to hundreads of a second */
export const roundTime = function (x: number): number {
  return Math.round(x / 10) * 10;
}

export const sceneTransitions: Tween[] = [Tween.ScaleIn, Tween.ScaleOut, Tween.SlideLeft,
Tween.SlideRight, Tween.SlideDown, Tween.SlideUp, Tween.FadeOut, Tween.HandLeft, Tween.HandRight,
Tween.HandUp, Tween.HandDown];

export const objectTransitions: Tween[] = [Tween.PopIn, Tween.PopOut, Tween.FadeIn,
Tween.FadeOut, Tween.ZoomIn, Tween.ZoomOut, Tween.EnlargeIn, Tween.EnlargeOut, Tween.SlideLeft,
Tween.SlideRight, Tween.SlideDown, Tween.SlideUp, Tween.HandLeft, Tween.HandRight,
Tween.HandUp, Tween.HandDown, Tween.AutoType, Tween.AutoTypeWord];

// Toolbar Sizes
export const toolbarMinHeight: number = 70;

// Gral

export const offset: number = 7;
export const offsetAxisY: number = 220;
export const offsetMobileAxisY: number = 175;
export const offsetMobileIOSAxisY: number = 235;
export const offsetSmAxisY: number = 120;
export const offsetMdAxisY: number = offsetSmAxisY + toolbarMinHeight;
export const objectMenuHeight: number = 48;

// Drawers Sizes
export const drawerCloseWidth: number = 0;
export const drawerXxsWidth: number = 60;
export const drawerXsWidth: number = 200;
export const drawerSmWidth: number = 250;
export const drawerMdWidth: number = 320;

export const sceneListOffsetHeight: number = offsetAxisY + objectMenuHeight;
export const colorsOffsetHeight: number = 330;

export const defaultDurationOfNewScene: number = 5000; //in miliseconds
export const maxDurationOfScene: number = 60000; // in miliseconds
export const minDurationOfScene: number = 1000; // in miliseconds

export const autoSaveInterval: number = 5000;// 5 seconds in miliseconds
export const INCREMENTS_MS: number = 1000; //in miliseconds

export const defaultAccessToken = 'S4045880291008160848'; //userID=2385327 //wideohtml5tester@mail.com

export const emptyTransitionValue: string = '0';
export const defaultInEffectValue: string = 'In';
export const defaultOutEffectValue: string = 'Out';

export const defaultDurationOfTransition = 500; //ms
export const defaultDurationOfHandSceneTransition = 1000; // ms

export const defaultKeyFrameSpacing = 500; //ms

// Text
export const defaultTextWidth = 600; //px
export const defaultTextFontSize = 60; //px
export const defaultTextAlignment = 'left';
export const defaultTextFontWeight = 'normal';
export const defaultTextFontFamily = 'Open Sans';
export const defaultTextFontStyle = 'normal';
export const defaultTextFillColor = '#000000';
export const defaultTextPaddingFactor = 0.2;
export const simpleLineHeightFactor = 1;
export const normalLineHeightFactor = 1.2;
export const doubleLineHeightFactor = 2;
export const defaultTextLineHeightFactor = normalLineHeightFactor;
export const defaultTextHeight = defaultTextFontSize * defaultTextLineHeightFactor; //px

export const defaultMaskWidth = 200;
export const defaultMaskHeight = 200;

export interface FontSizes {
  displaySize: string
}

// Timeline 

export const timelineOffsetWidth = 300;
export const objectListOffsetWidth = 370;
export const objectListPadding = objectListOffsetWidth - timelineOffsetWidth;
export const timelineResizeWidth: number = 20 //px
export const minWidthForNoneTransitionInPx: number = 75 //px
export const minWidthForNoneAudioTransitionInPx: number = 11 //px

// Monocomando

export const defaultMarginRespectObject = 20; //px

// PreviewWideo

export const spacingFactor = 60
export const headerHeight = 36

/**
* Available Font sizes for texts
*/
export const AVAILABLE_FONT_SIZES: FontSizes[] =
  [
    { displaySize: '14' },
    { displaySize: '21' },
    { displaySize: '28' },
    { displaySize: '42' },
    { displaySize: '56' },
    { displaySize: '84' },
    { displaySize: '112' },
    { displaySize: '168' },
    { displaySize: '224' },
    { displaySize: '336' },
    { displaySize: '448' },
    { displaySize: '672' },
    { displaySize: '896' }
  ];

/**
 * Transitions
 */

// Transition sizes 
export const minDurationOfTransitionInMs: number = 0.1 * 1000; //ms
export const minWidhtOfTransitionInPx: number = 22 //px
export const minWidthToShowTransitionInPx: number = 64 //px


/**
 * Paths SVG Icons
 */

export const ICONS = {
  WIDEO: 'M19.1,23.6c-1,0-1.5-0.5-1.5-1.4c0,0-0.8,1.4-2.3,1.4c-1,0-1.7-0.6-1.7-1.9c0-1.7,1.2-3.4,1.2-5.3c0,0,0.4-0.1,0.7-0.1c0.6,0,1.1,0.3,1.1,1.2c0,1.3-1.1,2.9-1.1,3.9c0,0.3,0.2,0.5,0.4,0.5c1.3,0,2.8-3.3,2.8-5.4c0,0,0.3-0.1,0.6-0.1c0.6,0,1.2,0.4,1.2,1.3c0,1.3-1.1,2.9-1.1,3.9c0,0.2,0.1,0.4,0.4,0.4c1,0,2.2-1.1,2.5-2.5c-0.6-0.4-1-1-1-1.7c0-1,0.6-1.7,1.3-1.7c1,0,1.5,0.8,1.5,1.9c0,0.2,0,0.3,0,0.3c1.4-0.1,2-0.7,2-0.7s0.2,0.2,0.2,0.6c0,0.9-1,1.5-2.6,1.6C23.1,21.7,21,23.6,19.1,23.6z',
  FACEBOOK: 'M17,2V2H17V6H15C14.31,6 14,6.81 14,7.5V10H14L17,10V14H14V22H10V14H7V10H10V6A4,4 0 0,1 14,2H17Z',
  TWITTER: 'M22.46,6C21.69,6.35 20.86,6.58 20,6.69C20.88,6.16 21.56,5.32 21.88,4.31C21.05,4.81 20.13,5.16 19.16,5.36C18.37,4.5 17.26,4 16,4C13.65,4 11.73,5.92 11.73,8.29C11.73,8.63 11.77,8.96 11.84,9.27C8.28,9.09 5.11,7.38 3,4.79C2.63,5.42 2.42,6.16 2.42,6.94C2.42,8.43 3.17,9.75 4.33,10.5C3.62,10.5 2.96,10.3 2.38,10C2.38,10 2.38,10 2.38,10.03C2.38,12.11 3.86,13.85 5.82,14.24C5.46,14.34 5.08,14.39 4.69,14.39C4.42,14.39 4.15,14.36 3.89,14.31C4.43,16 6,17.26 7.89,17.29C6.43,18.45 4.58,19.13 2.56,19.13C2.22,19.13 1.88,19.11 1.54,19.07C3.44,20.29 5.7,21 8.12,21C16,21 20.33,14.46 20.33,8.79C20.33,8.6 20.33,8.42 20.32,8.23C21.16,7.63 21.88,6.87 22.46,6Z',
  INSTAGRAM: 'M7.8,2H16.2C19.4,2 22,4.6 22,7.8V16.2A5.8,5.8 0 0,1 16.2,22H7.8C4.6,22 2,19.4 2,16.2V7.8A5.8,5.8 0 0,1 7.8,2M7.6,4A3.6,3.6 0 0,0 4,7.6V16.4C4,18.39 5.61,20 7.6,20H16.4A3.6,3.6 0 0,0 20,16.4V7.6C20,5.61 18.39,4 16.4,4H7.6M17.25,5.5A1.25,1.25 0 0,1 18.5,6.75A1.25,1.25 0 0,1 17.25,8A1.25,1.25 0 0,1 16,6.75A1.25,1.25 0 0,1 17.25,5.5M12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9Z',
  YOUTUBE: 'M10,15L15.19,12L10,9V15M21.56,7.17C21.69,7.64 21.78,8.27 21.84,9.07C21.91,9.87 21.94,10.56 21.94,11.16L22,12C22,14.19 21.84,15.8 21.56,16.83C21.31,17.73 20.73,18.31 19.83,18.56C19.36,18.69 18.5,18.78 17.18,18.84C15.88,18.91 14.69,18.94 13.59,18.94L12,19C7.81,19 5.2,18.84 4.17,18.56C3.27,18.31 2.69,17.73 2.44,16.83C2.31,16.36 2.22,15.73 2.16,14.93C2.09,14.13 2.06,13.44 2.06,12.84L2,12C2,9.81 2.16,8.2 2.44,7.17C2.69,6.27 3.27,5.69 4.17,5.44C4.64,5.31 5.5,5.22 6.82,5.16C8.12,5.09 9.31,5.06 10.41,5.06L12,5C16.19,5 18.8,5.16 19.83,5.44C20.73,5.69 21.31,6.27 21.56,7.17Z',
  VIMEO: 'M0 0v24h24v-24h-24zm19.249 10.732c-1.628 3.478-5.558 8.213-8.042 8.213-2.448 0-2.802-5.221-4.139-8.696-.658-1.709-1.083-1.317-2.316-.454l-.752-.97c1.798-1.581 3.599-3.418 4.705-3.52 1.245-.12 2.012.731 2.299 2.554.379 2.396.908 6.114 1.832 6.114.72 0 2.494-2.95 2.585-4.004.162-1.544-1.135-1.591-2.261-1.109 1.781-5.836 9.194-4.761 6.089 1.872z',
  WISTIA:
  {
    TOP: 'M104.5,43.8c1.8-10.8-4.3-13.8-4.3-13.8s0.3,8.8-15.9,10.7c-14.4,1.7 -62.5,0.4-62.5,0.4s0,0,15.5,17.8c4.2,4.8,6.4,5.4,11.1,5.7c4.7,0.3 15.1,0.2,22.2-0.3c7.7-0.6,18.7-3.1,26.1-8.9C100.4,52.4,103.7,48.3 104.5,43.8z',
    BOTTOM: 'M105.5,55c0,0-1.9,3.9-11.6,10c-4.1,2.6-12.7,5.4-23.7,6.4c-6,0.6-16.9 0.1-21.6,0.1c-4.7,0-6.9,1-11.1,5.8 C21.7,94.9,21.7,94.9,21.7,94.9s 5.4,0,9.5,0c4.1,0,29.7,1.5,41-1.6C109.1,83.1,105.5,55,105.5,55z'
  },
  QUICKTIME:
  {
    TOP: 'M14.8,7L11,10.7c-0.5,0.5-0.6,1.4,0,2c0.3,0.3,0.6,0.4,1,0.4s0.7-0.1,1-0.4l3.8-3.7C16.3,8.1,15.6,7.4,14.8,7z',
    BOTTOM: 'M20.8,11.7c0-4.9-4-8.8-8.8-8.8s-8.8,4-8.8,8.8s4,8.8,8.8,8.8c1.8,0,3.5-0.6,5-1.5l1.5,1.5h2.3v-2.3l-1.5-1.5C20.3,15.3,20.8,13.6,20.8,11.7z M17.5,11.7c0,3.1-2.5,5.5-5.5,5.5s-5.5-2.5-5.5-5.5S8.9,6.2,12,6.2c1,0,2,0.3,2.8,0.8c0,0,1.4-1.3,1.5-1.4c0.7,0.5,1.4,1.1,1.9,1.9c-0.1,0.1-1.4,1.4-1.4,1.4C17.2,9.7,17.5,10.7,17.5,11.7z'
  },
  CELLPHONE: 'M17.25,18H6.75V4H17.25M14,21H10V20H14M16,1H8A3,3 0 0,0 5,4V20A3,3 0 0,0 8,23H16A3,3 0 0,0 19,20V4A3,3 0 0,0 16,1Z',
  BOARD: 'M4,2A1,1 0 0,0 3,3V4A1,1 0 0,0 4,5H5V14H11V16.59L6.79,20.79L8.21,22.21L11,19.41V22H13V19.41L15.79,22.21L17.21,20.79L13,16.59V14H19V5H20A1,1 0 0,0 21,4V3A1,1 0 0,0 20,2H4Z',
  REDO: 'M21,11L14,4V8C7,9 4,14 3,19C5.5,15.5 9,13.9 14,13.9V18L21,11Z',
  TRASH: 'M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM8 9h8v10H8V9zm7.5-5l-1-1h-5l-1 1H5v2h14V4z',
  UNLINK: 'M17 7h-4v1.9h4c1.71 0 3.1 1.39 3.1 3.1 0 1.43-.98 2.63-2.31 2.98l1.46 1.46C20.88 15.61 22 13.95 22 12c0-2.76-2.24-5-5-5zm-1 4h-2.19l2 2H16zM2 4.27l3.11 3.11C3.29 8.12 2 9.91 2 12c0 2.76 2.24 5 5 5h4v-1.9H7c-1.71 0-3.1-1.39-3.1-3.1 0-1.59 1.21-2.9 2.76-3.07L8.73 11H8v2h2.73L13 15.27V17h1.73l4.01 4L20 19.74 3.27 3 2 4.27z',
  CLONE: 'M4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm16-4H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-1 9h-4v4h-2v-4H9V9h4V5h2v4h4v2z',
  LINEHEIGHT:
  {
    SIMPLE: 'M6,7h2.5L5,3.5L1.5,7H4v10H1.5L5,20.5L8.5,17H6V7z M10,8v2h12V8H10z M10,16h12v-2H10V16z M10,13h12v-2H10V13z',
    NORMAL: 'M6,7h2.5L5,3.5L1.5,7H4v10H1.5L5,20.5L8.5,17H6V7z M10,7v2h12V7H10z M10,17h12v-2H10V17z M10,13h12v-2H10V13z',
    DOUBLE: 'M6,7h2.5L5,3.5L1.5,7H4v10H1.5L5,20.5L8.5,17H6V7z M10,5v2h12V5H10z M10,19h12v-2H10V19z M10,13h12v-2H10V13z',
  },
  COLORLESS: 'M12,0.5c6.3,0,11.5,5.2,11.5,11.5S18.3,23.5,12,23.5S0.5,18.3,0.5,12S5.7,0.5,12,0.5 M12,0C5.4,0,0,5.4,0,12s5.4,12,12,12s12-5.4,12-12S18.6,0,12,0L12,0z',
  LOADER:
  {
    INNERGUTTER: 'M20,35c-8.271,0-15-6.729-15-15S11.729,5,20,5s15,6.729,15,15S28.271,35,20,35z M20,5.203C11.841,5.203,5.203,11.841,5.203,20c0,8.159,6.638,14.797,14.797,14.797S34.797,28.159,34.797,20C34.797,11.841,28.159,5.203,20,5.203z',
    OUTERGUTTER: 'M20,33.125c-7.237,0-13.125-5.888-13.125-13.125S12.763,6.875,20,6.875S33.125,12.763,33.125,20S27.237,33.125,20,33.125z M20,7.078C12.875,7.078,7.078,12.875,7.078,20c0,7.125,5.797,12.922,12.922,12.922S32.922,27.125,32.922,20C32.922,12.875,27.125,7.078,20,7.078z',
    INNERCIRCLE: 'M5.203,20c0-8.159,6.638-14.797,14.797-14.797V5C11.729,5,5,11.729,5,20s6.729,15,15,15v-0.203C11.841,34.797,5.203,28.159,5.203,20z',
    OUTERCIRCLE: 'M7.078,20c0-7.125,5.797-12.922,12.922-12.922V6.875C12.763,6.875,6.875,12.763,6.875,20S12.763,33.125,20,33.125v-0.203C12.875,32.922,7.078,27.125,7.078,20z'
  },
  PLAY: 'M20.2,5c0-0.8-0.7-1.5-1.5-1.5H5.3C4.5,3.5,3.8,4.2,3.8,5v13.4c0,0.8,0.7,1.5,1.5,1.5h13.4c0.8,0,1.5-0.7,1.5-1.5V5zM14.9,12.4l-4.6,3c-0.6,0.4-1.1,0.1-1.1-0.6V8.6c0-0.7,0.5-1,1.1-0.6l4.6,3C15.4,11.4,15.4,12,14.9,12.4z',
  FOLDER: 'M19.6,7.1l-4.6-4.6c-0.1-0.1-0.3-0.2-0.4-0.2c-0.1,0-9.7,0-9.8,0C4.5,2.4,4.2,2.7,4.2,3v18c0,0.3,0.3,0.6,0.6,0.6h14.4c0.3,0,0.6-0.3,0.6-0.6V7.6C19.8,7.4,19.8,7.3,19.6,7.1z M15.2,4.5L17.7,7h-2.4V4.5z M18.6,20.4H5.4V3.6H14v4c0,0.3,0.3,0.6,0.6,0.6h4L18.6,20.4L18.6,20.4z',
  PLAYCURRENTSCENE: 'M13,4.8l9.9,6.5L13,17.8V4.8z M9,4.8H3.9v13H9V4.8z',
  AUTOMATION: 'M20,9V7c0-1.1-0.9-2-2-2h-3c0-1.66-1.34-3-3-3S9,3.34,9,5H6C4.9,5,4,5.9,4,7v2c-1.66,0-3,1.34-3,3c0,1.66,1.34,3,3,3v4 c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2v-4c1.66,0,3-1.34,3-3C23,10.34,21.66,9,20,9z M7.5,11.5C7.5,10.67,8.17,10,9,10 s1.5,0.67,1.5,1.5S9.83,13,9,13S7.5,12.33,7.5,11.5z M16,17H8v-2h8V17z M15,13c-0.83,0-1.5-0.67-1.5-1.5S14.17,10,15,10 s1.5,0.67,1.5,1.5S15.83,13,15,13z'
};

/**
 * Editor Modes
 */
export enum EditorMode {
  Normal = 'Normal',
  Edit = 'Edit', //when editing a template, this should show a minimal editor ui
  EditImage = 'EditImage',
  Pan = 'Pan',
  Crop = 'Crop',
  PreviewWideo = 'PreviewWideo'
}

export interface AnimatedAssetId {
  id: string,
  width: number,
  height: number
}

export interface AssetIDOfAnimatedReplacedWithAnimatedGroupObject {
  id: string,
  order: number
}

/**
* assets id of animated objects in database. THEY ARE THE OLD animated assets with spine
*/
export const animatedAssetIds: AnimatedAssetId[] = [
  { id: '669246623204940768', width: 846, height: 878 },
  { id: '928063200160034489', width: 1003, height: 1082 },
  { id: '1619606648163450397', width: 1075, height: 1080 },
  { id: '2186315982123707147', width: 731, height: 1099 },
  { id: '3064575756174143741', width: 296, height: 1079 },
  { id: '3130395843316559212', width: 865, height: 1091 },
  { id: '3373104806101548931', width: 958, height: 1575 },
  { id: '3407608717434012866', width: 279, height: 1079 },
  { id: '3437547835393420107', width: 286, height: 1079 },
  { id: '3587291990308086392', width: 753, height: 1073 },
  { id: '3761151762478750583', width: 534, height: 114 },
  { id: '3851828047050295353', width: 317, height: 1029 },
  { id: '4301671226836137746', width: 1099, height: 1079 },
  { id: '4719860750322600574', width: 1909, height: 1079 },
  { id: '4931982612969768221', width: 317, height: 1079 },
  { id: '5319083271254794373', width: 1919, height: 1046 },
  { id: '5440608631694819875', width: 1920, height: 1080 },
  { id: '6102725893835341898', width: 781, height: 1080 },
  { id: '6797683884158283720', width: 317, height: 1024 },
  { id: '6914528281944189222', width: 865, height: 1074 },
  { id: '6980733169745203012', width: 653, height: 1123 },
  { id: '7188761901331455049', width: 1020, height: 1091 },
  { id: '7587469324002970467', width: 288, height: 1024 },
  { id: '7716970535912365925', width: 307, height: 1024 },
  { id: '7744072858882078515', width: 472, height: 878 },
  { id: '7748890955672495975', width: 561, height: 1023 },
  { id: '7814366428502711172', width: 1921, height: 712 },
  { id: '7884249637937815955', width: 886, height: 981 },
  { id: '7892682221640845393', width: 608, height: 1099 },
  { id: '8409029984707165612', width: 1453.96, height: 1080 },
  { id: '8469200642451057195', width: 551, height: 1074 },
  { id: '8597300322646933255', width: 1484, height: 1080 },
  { id: '8753675444355360952', width: 0, height: 0 }, //earth not implemented
  { id: '8880149101697017274', width: 461, height: 878 },
  { id: '8898357593063653053', width: 1493, height: 1083 }
];
export const wideoIDContainingGroupDefCharacters = "756721560514861939";
export const wideoIDContainingOldAnimatedAssets = "756721563293203959";

export const assetsIDsOfAnimatedReplacedWithGifs = ["7716970535912365925",
  "7587469324002970467",
  "7744072858882078515",
  "7892682221640845393",
  "7748890955672495975",
  "6797683884158283720",
  "2186315982123707147",
  "7884249637937815955",
  "3761151762478750583",
  "4931982612969768221",
  "6980733169745203012",
  "669246623204940768"];

export const assetsIDsOfAnimatedReplacedWithAnimatedGroupObjects: AssetIDOfAnimatedReplacedWithAnimatedGroupObject[] = [
  { id: "8409029984707165612", order: 0 },
  { id: "7814366428502711172", order: 1 },
  { id: "5319083271254794373", order: 2 },
  { id: "1619606648163450397", order: 3 },
  { id: "6102725893835341898", order: 4 },
  { id: "5440608631694819875", order: 5 },
  { id: "3130395843316559212", order: 6 },
  { id: "3373104806101548931", order: 7 },
  { id: "3064575756174143741", order: 8 },
  { id: "8880149101697017274", order: 9 },
  { id: "3437547835393420107", order: 10 },
  { id: "3407608717434012866", order: 11 },
  { id: "8469200642451057195", order: 12 },
  { id: "6914528281944189222", order: 13 },
  { id: "3851828047050295353", order: 14 },
  { id: "4719860750322600574", order: 15 },
  { id: "8753675444355360952", order: 16 },
  { id: "7188761901331455049", order: 17 },
  { id: "4301671226836137746", order: 18 },
  { id: "8597300322646933255", order: 19 },
  { id: "928063200160034489", order: 20 },
  { id: "8898357593063653053", order: 21 },
  { id: "3587291990308086392", order: 22 }];


// STOCK STYLES
export const STOCK_IMAGE_STYLES = [
  {
    id: '1',
    name: 'business',
    image:
      'https://resources.wideo.co/wideo-html5-react/img/editor/icons/covers/images/01_BUSINESS.jpg',
  },
  {
    id: '2',
    name: 'people',
    image:
      'https://resources.wideo.co/wideo-html5-react/img/editor/icons/covers/images/02_PEOPLE.jpg',
  },
  {
    id: '3',
    name: 'nature',
    image:
      'https://resources.wideo.co/wideo-html5-react/img/editor/icons/covers/images/03_NATURE.jpg',
  },
  {
    id: '4',
    name: 'health',
    image:
      'https://resources.wideo.co/wideo-html5-react/img/editor/icons/covers/images/04_HEALTH.jpg',
  },
  {
    id: '5',
    name: 'technology',
    image:
      'https://resources.wideo.co/wideo-html5-react/img/editor/icons/covers/images/05_TECHNOLOGY.jpg',
  },
  {
    id: '6',
    name: 'sports',
    image:
      'https://resources.wideo.co/wideo-html5-react/img/editor/icons/covers/images/06_SPORTS.jpg',
  },
  {
    id: '7',
    name: 'holidays-celebrations',
    image:
      'https://resources.wideo.co/wideo-html5-react/img/editor/icons/covers/images/07_HOLIDAYS.jpg',
  },
  {
    id: '8',
    name: 'pets',
    image:
      'https://resources.wideo.co/wideo-html5-react/img/editor/icons/covers/images/08_PETS.jpg',
  },

];

export const STOCK_VIDEO_STYLES = [
  {
    id: '1',
    name: 'business',
    image:
      'https://resources.wideo.co/wideo-html5-react/img/editor/icons/covers/videos/01_BUSINESS.jpg',
  },
  {
    id: '2',
    name: 'people',
    image:
      'https://resources.wideo.co/wideo-html5-react/img/editor/icons/covers/videos/02_PEOPLE.jpg',
  },
  {
    id: '3',
    name: 'nature',
    image:
      'https://resources.wideo.co/wideo-html5-react/img/editor/icons/covers/videos/03_NATURE.jpg',
  },
  {
    id: '4',
    name: 'health',
    image:
      'https://resources.wideo.co/wideo-html5-react/img/editor/icons/covers/videos/04_HEALTH.jpg',
  },
  {
    id: '5',
    name: 'technology',
    image:
      'https://resources.wideo.co/wideo-html5-react/img/editor/icons/covers/videos/05_TECHNOLOGY.jpg',
  },
  {
    id: '6',
    name: 'sports',
    image:
      'https://resources.wideo.co/wideo-html5-react/img/editor/icons/covers/videos/06_SPORTS.jpg',
  },
  {
    id: '7',
    name: 'holidays-celebrations',
    image:
      'https://resources.wideo.co/wideo-html5-react/img/editor/icons/covers/videos/07_HOLIDAYS.jpg',
  },
  {
    id: '8',
    name: 'pets',
    image:
      'https://resources.wideo.co/wideo-html5-react/img/editor/icons/covers/videos/08_PETS.jpg',
  },
];

// END STOCK STYLES

//
//
//


// ZENDESK KEY

export const ZENDESK_KEY = "90924e4e-98de-40ea-bb7f-6079e14b132b";

const fileExtensionRegxpr = /(?:\.([^.]+))?$/;

export const getExtension = function (fileName: string): string {
  return fileExtensionRegxpr.exec(fileName)[1];
}

export const getExtensionFromUrl = (url: string): string => {
  return url.split(/\#|\?/)[0].split('.').pop().trim();
}

export const isValidUrl = (str: string): boolean => {
  const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
  return pattern.test(str);
}

export const dataURLToWideoFile = function (dataurl: string, fileName: string): WideoFile {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  const byteString: string = atob(dataurl.split(',')[1]);

  // separate out the mime component
  const mimeString: string = dataurl.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to an ArrayBuffer
  const ab: ArrayBuffer = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  const ia: Uint8Array = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  // write the ArrayBuffer to a blob, and you're done
  return new WideoFile(fileName, [ab], { type: mimeString });
}

export const fadeOutAudioStartTime: number = 4000; // in ms

//unsplash
export const unsplashAccessKey = "a0e2e59060291288147ae5ac23eb0e31f4b51cb392b34f31d98e3399368b404f";
export const unsplashSecretKey = "86f26050e68cc3b6df9d9d9e6627442236af30edec4ccd375c99a02db064c752";

export enum AssetSearchSource {
  UNSPLASH = 'unsplash',
  GOOGLE = 'google',
  PIXABAY_VIDEO = 'pixabay_video',
  PEXELS_VIDEO = 'pexels_video'
}

export const imagesByPageInSearchDialogWhenSearch = 3;
export const imagesByPageInSearchDialogWhenFetchMore = 9;

export const getAnimatedGroupDefs = async (wideoId: string, environment: string, accessToken: string): Promise<TemplateObject[]> => {

  //TODO: Get the Template objects from somewhere

  const templateObjectsFromApi: TemplateObject[] = [new TemplateObject(
    "0",
    wideoId,
    40,
    "en",
    "IMG",
    "https://s3.amazonaws.com/previews-scene-templates/")];

  //load all defs async
  const wideoDefs: WideoDef[] = await Promise.all(templateObjectsFromApi.map(async (templateObject: TemplateObject) => {
    // For each Scene Template Wideo (templateScenes) get the entire Wideo JSON
    const wideoApi: WideoApi = new WideoApi(environment, accessToken);

    const json: Def = await wideoApi.getJson(templateObject.wideoId) as Def;
    //we need to load the wideo containing scene templates
    const wideoDef: WideoDef = await new Deserializer().deserializeFromJson(
      environment,
      accessToken,
      json, false,
      undefined,
      undefined
    ) as WideoDef;
    return wideoDef;
  }));

  const templateObjectResult: TemplateObject[] = [];

  templateObjectsFromApi.map((templateObject: TemplateObject) => {

    const defFiltered: WideoDef[] = filterObjs((wideoDef: WideoDef) => wideoDef.id === templateObject.wideoId)(wideoDefs);

    if (defFiltered[0]) {
      defFiltered[0].scenes.map((sceneDef: SceneDef, index: number) => {
        // For each scene in the Wideo

        sceneDef.objects.map((wideoObjectDef: WideoObjectDef) => {

          templateObjectResult.push(new TemplateObject(templateObject.id + "-" + index,
            templateObject.wideoId,
            templateObject.chargeTypeId,
            templateObject.lang,
            templateObject.category,
            null,
            wideoObjectDef
          ));

        });
      })
    } else {
      Logger.warn("An error ocurred loading a template scene. WideoId: %s in scene " +
        "doesn't match with any wideo returned from scenesApi", templateObject.wideoId)
    }
  });

  return templateObjectResult;
}

export const getSupportedMediaExtension = async (url: string): Promise<string> => {
  const index = url.lastIndexOf('.');
  const extension = url.slice(index + 1);
  const isValidExtension = imageFileTypes.indexOf(extension.toLowerCase()) !== -1 ||  
    videoFileTypes.indexOf(extension.toLowerCase()) !== -1
    audioFileTypes.indexOf(extension.toLowerCase()) !== -1;

  if (isValidExtension) {
    return extension;
  }

  const mimeType = await getMimeType(url);

  const isValidMimeType = imageMimeTypes.indexOf(mimeType.toLowerCase()) !== -1 ||  
  videoFileTypes.indexOf(mimeType.toLowerCase()) !== -1
  audioFileTypes.indexOf(mimeType.toLowerCase()) !== -1;

  if (isValidMimeType) {
    return mimeTypeToExtension(mimeType);
  }
  console.warn('Unsupported media in URL: ' + url);
  return '';
   
}

const mimeTypeToExtension = (mimeType: string) => {
  switch (mimeType) {
    case 'image/jpeg':
      return "jpg";
    case 'image/png':
      return "png";
    case 'image/gif':
      return "gif";
    case 'image/webp':
      return "webp";
    case 'video/mp4':
      return "mp4";
    case 'audio/mpeg':
    case 'audio/mp3':
      return "mp3";
    default:
      console.warn('Unknown MIME type:', mimeType);
      return '';
  }
}

export const getMimeType = async (url: string) => {
  const response = await fetch(url, { method: 'HEAD' });
  if (!response.ok) {
    return '';
  }
  const contentType = response.headers.get('Content-Type');
  return contentType;
}

// Images 
export const imageMimeTypes = ["image/png", "image/jpeg", "image/gif", "image/webp"];
export const imageFileTypes = ["jpeg", "jpg", "png", "gif", "webp"];
export const imageMaxFileSize = 10485760; // 10 mb

// Images GIF special case
export const gifMimeTypes = ["image/gif"];
export const gifFileTypes = ["gif"];
export const gifMaxFileSize = 10485760; // 10 mb
export const gifMaxComplexity = 50000000; // Formula: width*height*frames

// Background
export const backMimeTypes = ["image/png", "image/jpeg"];
export const backFileTypes = ["jpeg", "jpg", "png"];
export const backMaxFileSize = 10485760; // 10 mb

// Audio
export const audioMimeTypes = ["audio/mpeg", "audio/mp3"];
export const audioFileTypes = ["mp3"];
export const audioMaxFileSize = 10485760; // 10 mb
export const audioMaxFilesWideo = 2;
export const audioMaxFilesScene = 5;


// Video
export const videoFileTypes = ["mp4", "mov", "webm", "m4v", "3gp"];
export const videoMaxFileSize = 20971520; // 20 mb (hard limit)
export const videoMaxNoOfObjectsPerWideo = 15; // hard limit
export const videoMaxNoOfObjectsPerWideoForSuperUser = 20;
export const videoMaxNoOfObjectsPerScene = 3; // hard limit
export const videoMaxResolution = 14745600; // Corresponds to 3840x3840 > 4K (hard limit)
export const videoMaxDuration = 60; // 60 seconds (hard limit, also automatically truncated on upload)
export const videoMaxWidthHeight = 1920; // (Automaticaly scaled to fit into square of 1920x1920 maintaining aspect ratio on upload

// GIF
export const gifMaxDuration = 15000; // Max duration of wideo that can be downloaded as GIF: 15 seconds

// REPLACE
export enum ReplaceTarget {
  MyImages = "myImages",
  Objects = "objects",
  UploadImage = "uploadImage",
  MyVideos = "myVideos",
  UploadVideo = "uploadVideo",
  TTS = "tts",
  StockImages = "stockImages",
  StockVideos = "stockVideos",
}

export const fontCategories = [
  { name: 'All categories', value: 'all' },
  { name: 'Serif', value: 'serif' },
  { name: 'Sans Serif', value: 'sans-serif' },
  { name: 'Display', value: 'display' },
  { name: 'Handwriting', value: 'handwriting' },
  { name: 'Monospace', value: 'monospace' },
];

export const fontLanguages = [
  { name: 'All languages', value: 'all' },
  { name: 'Arabic', value: 'arabic' },
  { name: 'Bengali', value: 'bengali' },
  { name: 'Chinese (Hong Kong)', value: 'chinese-hongkong' },
  { name: 'Chinese (Simplified)', value: 'chinese-simplified' },
  { name: 'Chinese (Traditional)', value: 'chinese-traditional' },
  { name: 'Cyrillic', value: 'cyrillic' },
  { name: 'Cyrillic Extended', value: 'cyrillic-ext' },
  { name: 'Devanagari', value: 'devanagari' },
  { name: 'Greek', value: 'greek' },
  { name: 'Greek Extended', value: 'greek-ext' },
  { name: 'Gujarati', value: 'gujarati' },
  { name: 'Gurmukhi', value: 'gurmukhi' },
  { name: 'Hebrew', value: 'hebrew' },
  { name: 'Japanese', value: 'japanese' },
  { name: 'Kannada', value: 'kannada' },
  { name: 'Khmer', value: 'khmer' },
  { name: 'Korean', value: 'korean' },
  { name: 'Latin', value: 'latin' },
  { name: 'Latin Extended', value: 'latin-ext' },
  { name: 'Malayalam', value: 'malayalam' },
  { name: 'Myanmar', value: 'myanmar' },
  { name: 'Oriya', value: 'oriya' },
  { name: 'Sinhala', value: 'sinhala' },
  { name: 'Tamil', value: 'tamil' },
  { name: 'Telugu', value: 'telugu' },
  { name: 'Thai', value: 'thai' },
  { name: 'Tibetan', value: 'tibetan' },
];

export enum wideoAssetStyles {
  ANIMATED = 'animated'
}

// Given a cookie key `name`, returns the value of
// the cookie or `null`, if the key is not found.
export const getCookie = (name: string): string => {
  const nameLenPlus = (name.length + 1);
  return document.cookie
    .split(';')
    .map(c => c.trim())
    .filter(cookie => {
      return cookie.substring(0, nameLenPlus) === `${name}=`;
    })
    .map(cookie => {
      return decodeURIComponent(cookie.substring(nameLenPlus));
    })[0] || null;
}

export const getUrlParameter = (param: string): string => {
  const name = param.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  const regex: RegExp = new RegExp('[\\?&]' + name + '=([^&#]*)');
  // eslint-disable-next-line no-restricted-globals
  const results: RegExpExecArray = regex.exec(location.search);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

export interface NumericSelectionRange {
  [name: string]: number
}

export const getNumericSelection = (e: React.SyntheticEvent<HTMLInputElement>) => {
  let start = 0
  let end = 0

  if (typeof e.currentTarget.selectionStart === "number" && typeof e.currentTarget.selectionEnd === "number") {
    start = e.currentTarget.selectionStart;
    end = e.currentTarget.selectionEnd;
  }
  return {
    start: start,
    end: end
  };

}

export const setNumericSelectionRange = (e: React.SyntheticEvent<HTMLInputElement>, result: NumericSelectionRange) => {
  const cursorPosition = result.start;
  const moveCursorPosition = cursorPosition + 1
  const posColon = e.currentTarget.value.indexOf(":");
  if (posColon === cursorPosition) {
    e.currentTarget.setSelectionRange(moveCursorPosition, moveCursorPosition);
  }
  else {
    e.currentTarget.setSelectionRange(cursorPosition, moveCursorPosition);
  }

}

/**
@func util
a custom high-performance filter

@perf
60% faster than the built-in JavaScript filter func

@typedef {(e: *) => boolean} filterFnAny
@param {filterFnAny} fn
@param {*[]} a
@return {*[]}
*/
const fil = (fn, a) => {
  const f = []; //final
  const length = a.length;
  let i = 0;
  while (i !== length) {
    if (fn(a[i])) {
      f.push(a[i]);
    }
    i++;
  }
  return f;
};

/**
@func curry
filter an arr of objs

@typedef {(e: object) => boolean} filterFnObjs
@param {filterFnObjs} fn
@return {(a: object[]) => object[]}
*/
export const filterObjs = fn => a => fil(fn, a);