import * as React from 'react';
import amplitude from "amplitude-js";

import { Theme } from '@mui/material/styles';
import Fab from '@mui/material/Fab';
import IconButton from '@mui/material/IconButton';
import PlayIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';

import { PlayState } from '../core/PlayerConstants';
import { resourcesBaseUrl } from '../../editor/core/EditorConstants';
import PlayerControls from './PlayerControls';
import IPlayerQueryParams from './IPlayerQueryParams';
import PlayerDebug from './PlayerDebug';
import Player from '../core/Player';
import IPlayerStateChangeListener from '../core/IPlayerStateChangeListener';
import { Typography } from '@mui/material';
import WideoDef from '../../common/core/model/WideoDef';

import Logger from '../../common/log/Logger';
import PageVisibility from 'react-page-visibility';

import injectStyleKeyframeAnimation from '../../editor/ui/layout/utils/injectStyleKeyframeAnimation';

import * as Sentry from '@sentry/browser';
import { environments } from '../../common/Env';
import { isMobileOnly } from 'react-device-detect';

import { withTranslation, WithTranslation } from 'react-i18next';
import { getWhiteColor } from 'src/editor/ui/layout/dialogs/FontDialog';
import { getMargin } from 'src/editor/ui/layout/rightbar/audio/AudioAttributes';
import { Helmet } from 'react-helmet';

import BrandThemeProvider from 'src/common/ui/BrandThemeProvider';

import Loader from 'src/editor/ui/Loader';

const IconPlayPauseStyle = `
        @keyframes effect-fadeout {
          0% {
              opacity: 1
          }
          to {
              opacity: 0;
              transform: scale(2) translate(-12px, -12px)
          }
        }
    `;
const IconInitialPlayStyle = `
        @keyframes effect-fadein {
          0% {
              opacity: 0
          }
          to {
              opacity: 1;
              transform: scale(2) translate(-12px, -12px)
          }
        }
    `;

injectStyleKeyframeAnimation(IconPlayPauseStyle);
injectStyleKeyframeAnimation(IconInitialPlayStyle);

export const getBackground900 = (theme: Theme) => ({
  backgroundColor: theme.palette.grey['900'],
});

export const getPadding = (theme: Theme) => ({
  padding: `0 ${theme.spacing(10)}px`,
});

export const getFontSize = (theme: Theme) => ({
  fontSize: theme.typography.pxToRem(13),
});

export const getFontSizeSm = (theme: Theme) => ({
  fontSize: theme.typography.pxToRem(8),
});


const styles = {
  playerApp: {
    position: 'absolute' as 'absolute',
    width: '100%',
    height: '100%',
    overflow: 'hidden' as 'hidden',
    backgroundColor: '#212121'
  },
  playerArea: {
    width: '100%',
    height: '100%',
  },
  playerAreaInitial: {
    '&:hover': {
      '& $iconInitialPlay': {
        backgroundColor: '#4285F4',
      }
    }
  },
  wideoCanvasContainer: {
    display: 'flex',
    alignItems: 'center' as 'center',
    justifyContent: 'center' as 'center',
    height: '100%',
  },
  centerScreen: {
    position: 'absolute' as 'absolute',
    left: '50%',
    top: '50%',
    zIndex: 100,
    transform: 'translate(-50%, -50%)'
  },
  contentIconPlayPauseInteractivity: {
    height: "150px",
    width: "150px",
    position: 'absolute' as 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    '&:hover': {
      cursor: 'pointer',
    },
    borderRadius: "50%"
  },
  contentIconPlayPause: {
    height: "100%",
    width: "100%",
    position: 'absolute' as 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  contentIconZindex: {
    zIndex: 100,
  },
  contentIconWatermarkZindex: {
    zIndex: 1001,
    width: '100px',
    height: '100px',
  },
  iconPlayPause: {
    ...getWhiteColor,
    transform: 'scale(4) translate(-6px, -6px)',
    '-moz-animation': 'effect-fadeout .5s linear 1 normal forwards',
    '-webkit-animation': 'effect-fadeout .5s linear 1 normal forwards',
    animation: 'effect-fadeout .5s linear 1 normal forwards',
    backgroundColor: 'rgba(0,0,0,.3)',
/*     [theme.breakpoints.down('sm')]: {
      padding: '5px',
    },  */   
  },
  iconInitialPlay: {
    ...getWhiteColor,
    transform: 'scale(4) translate(-6px, -6px)',
    '-moz-animation': 'effect-fadein .5s linear 1 normal forwards',
    '-webkit-animation': 'effect-fadein .5s linear 1 normal forwards',
    animation: 'effect-fadein .5s linear 1 normal forwards',
    backgroundColor: 'rgba(0,0,0,.3)',
    '&:hover': {
      backgroundColor: '#4285F4'
    },
/*     [theme.breakpoints.down('sm')]: {
      padding: '5px',
    },  */     
  },
  effectIconPlayPause: {
    display: 'none'
  },
  hidden: {
    display: 'none'
  },
  contentLoader: {
    position: 'absolute' as 'absolute',
    width: '100%',
    height: '100%',
    overflow: 'hidden' as 'hidden',
    ...getBackground900,
    zIndex: 1300,
  },
  loader: {
    width: '160px',
    height: '160px',
/*     [(theme: Theme) => theme.breakpoints.down('sm')]: {
      width: '80px',
      height: '80px'
    },    */   
    
  },
  text: {
    letterSpacing: 0.6,
    fontSize: 11,
    textAlign: 'center' as 'center', 
    marginTop: -10,
  },
  centerwideo: {
    display: 'flex',
    alignItems: 'center' as 'center',
    justifyContent: 'center' as 'center',
    height: '100%'
  },
  watermarkBody: {
    zIndex: 1001
  },
  rightBottomPosition: {
    position: 'absolute' as 'absolute',
    right: 20,
    bottom: '50px'
  },
  rightBottomPositionMobile: {
    position: 'absolute' as 'absolute',
    right: 20,
    bottom: '50px'
  },
  buttonFab: {
    backgroundColor: '#4285F4',
    ...getMargin,
    ...getPadding,
    '&:hover': {
      backgroundColor: '#1976d2'
    }
  },
  buttonFabMobile: {
    backgroundColor: '#4285F4',
    ...getMargin,
    '&:hover': {
      backgroundColor: '#1976d2'
    }
  },
  buttonSizeSmallFab: {
    ...getFontSize,
  },
  buttonSizeSmallFabMobile: {
    ...getFontSizeSm,
    height: '25px!important'
  },
  logo: {
    opacity: 0.6,
    maxWidth: '140px',
    width: '100%',
    display: 'block',
    margin: '0 auto'
  },
  icon: {
    maxWidth: '40px',
    position: 'absolute' as 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },  
  logoMobile: {
    opacity: 0.6,
    maxWidth: '50px',
    width: '100%',
    display: 'block',
    margin: '0 auto'
  }
};

interface State {
  player: Player;
  playState: PlayState;
  seekedTime: number;
  isPresentationMode: boolean;
  toogleIconPlayPause: boolean;
  isEffectIconPlayPause: boolean;
  isInitialIconPlay: boolean;
  showPlayerControls: boolean;
  volume: number;
  isMuted: boolean;
  oldPlayState: PlayState;
  showSliderPlayer: boolean;
  hasInteractivity: boolean;
}

interface Props {
  player?: Player;
  queryParams: IPlayerQueryParams;
  wideoDef?: WideoDef;
  noFullscreenButton?: boolean;
  noToggleModeButton?: boolean;
  isPreviewOnPresentationMode?: boolean;
  intrusiveTestCallback?: () => void;
}


type Styled = any

type PropsWithStylesWithTranslation = Props & WithTranslation & Styled;

export const defaultVolume: number = 50;

/* PlayerApp is the root component in the Player. For now PlayerApp is the owner of all state */

class PlayerApp extends React.Component<PropsWithStylesWithTranslation, State> implements IPlayerStateChangeListener {

  private playerPreviewRootDiv: React.MutableRefObject<HTMLDivElement>;

  // tslint:disable-next-line:no-any
  private timeoutEffectIconPlayPause: any = null;

  constructor(props: PropsWithStylesWithTranslation) {
    super(props);

    Sentry.configureScope((scope: Sentry.Scope) => {
      scope.setTag("wideoId", this.props.queryParams.wideoId);
    });

    this.playerPreviewRootDiv = React.createRef<HTMLDivElement>();

    // State of the PlayerApp is the entire Wideo JSON and the current play state and time
    this.state = {
      playState: PlayState.Loading,    // PLAYING, STOPPED, PAUSED
      seekedTime: 0,                    // seekedTime, updated by the user using the slider
      isPresentationMode: false,
      player: this.props.player ?? new Player(true),
      toogleIconPlayPause: this.props.queryParams.autoplay,
      isEffectIconPlayPause: true,
      isInitialIconPlay: true,
      showPlayerControls: !this.props.queryParams.hideControls ?? false,
      volume: defaultVolume,
      isMuted: false,
      oldPlayState: PlayState.Loading,
      showSliderPlayer: false,
      hasInteractivity: false,
    };
  }
  componentDidMount(): void {
    this.state.player.addStateChangeListener(this);
  }

  async componentWillUnmount(): Promise<void> {
    this.state.player.removeStateChangeListener(this);
    if (this.state.player.getWideo()) {
      await this.state.player.stop();
      this.state.player.destroy();
    }
    //clearTimeout(this.timeoutPlayerControls)
    clearTimeout(this.timeoutEffectIconPlayPause)
  }

  public canvasCallback = async (canvasElement: HTMLCanvasElement): Promise<void> => {
    if (canvasElement) {

      await this.state.player.init(
        canvasElement,
        this.props.queryParams.environment,
        this.props.queryParams.accessToken,
        this.props.queryParams.wideoId,
        this.props.wideoDef,
        undefined,
        this.props.queryParams.convert,
        this.props.queryParams.repeat,
        undefined,
        undefined,
        this.props.queryParams.forceCanvas,
        this.props.queryParams.antialias,
        this.props.queryParams.replace,
        this.props.queryParams.replaceId,
        this.props.queryParams.jsonUrl);

      canvasElement.style.width = '100%';
      canvasElement.style.height = '100%';
      canvasElement.style.objectFit = 'contain';

    }
  }

  public onStateChange = async (state: PlayState) => {
    this.setState(() => {
      return {
        playState: state,
      };
    });

    if (state === PlayState.Loaded) {

      Logger.info("PlayerApp got Wideo Loaded");

      await this.state.player.syncSeek(Number(this.props.queryParams.startTime));

      // Autoplay
      if (this.props.queryParams.autoplay && !this.state.player.getWideo().isPresentationMode()) {
        this.state.player.play();
      }

      //Intrusive testing, used to sync test cases to when PlayerApp is "Loaded"
      if (this.props.intrusiveTestCallback) {
        this.props.intrusiveTestCallback();
      }
      const hasInteractivity = this.state.player.initWideoInteractivity();
      this.setState({
        isPresentationMode: this.state.player.getWideo().isPresentationMode(),
        hasInteractivity: hasInteractivity //parse interactive objects
      });

      if (hasInteractivity) {
        amplitude.getInstance().init(environments[this.props.queryParams.environment].amplitudeKey, null, {
          includeUtm: true,
          includeReferrer: true
        });

        if (hasInteractivity) {
          amplitude.getInstance().logEvent("INTERACTIVITY_PLAY", { wideoId: this.props.queryParams.wideoId });
        }
      }

    }

    if (state === PlayState.Paused || state === PlayState.Playing || state === PlayState.Ended) {
      this.setState({
        toogleIconPlayPause: !this.state.toogleIconPlayPause,
      });
    }
  }

  public async componentDidUpdate(prevProps: Props, nextState: State) {
    // If we are seeking to a new time
    if (nextState.seekedTime !== this.state.seekedTime) {
      await this.state.player.syncSeek(nextState.seekedTime);
    }

    if (prevProps.isPreviewOnPresentationMode !== this.props.isPreviewOnPresentationMode) {
      this.onTogglePresentationMode()
    }
  }

  public onPlay = () => {
    this.state.player.play();
  }

  public onPause = () => {
    this.state.player.pause();
  }

  public onReplay = async () => {
    await this.state.player.stop();
    this.state.player.play();
  }

  public onStop = async () => {
    await this.state.player.stop();
  }

  public onSeek = (seekedTime: number) => {
    this.setState(() => {
      return {
        seekedTime: seekedTime
      };
    });
  }

  public onVolumeChange = (volume: number) => {
    this.setState((previousState: State) => {
      return {
        volume: volume,
        isMuted: volume === 0 ? true : false
      }
    }, () => {
      this.state.player.setVolume(this.state.volume);
    })
  }

  public onToggleMute = () => {
    this.setState(prevState => ({
      isMuted: !prevState.isMuted,
      volume: prevState.volume !== 0 ? prevState.volume : defaultVolume
    }), () => {
      if (this.state.isMuted) {
        this.mutePlayerVolume();
      } else {
        this.resetPlayerVolume();
      }
    });
  };

  public onTimeSliderMouseDown = () => {
    this.setState({ oldPlayState: this.state.playState });
    if (this.state.playState === PlayState.Playing) {
      this.onPause();
    }
    this.handleShowSliderPlayer()

  }

  public onTimeSliderMouseUp = () => {
    if (this.state.oldPlayState === PlayState.Playing) {
      this.onPlay();
    }
    this.handleHideSliderPlayer()
  }

  public onTimeSliderMouseEnter = () => {
    this.handleShowSliderPlayer()
  }

  public onTimeSliderMouseLeave = () => {
    this.handleHideSliderPlayer()
  }

  public handleShowSliderPlayer = () => {
    this.setState({ showSliderPlayer: true })
  }

  public handleHideSliderPlayer = () => {
    this.setState({ showSliderPlayer: false })
  }

  public onTogglePresentationMode = () => {
    this.setState((previousState: State) => {
      return {
        isPresentationMode: !previousState.isPresentationMode,
        isMuted: !previousState.isPresentationMode,
        volume: previousState.volume !== 0 ? previousState.volume : defaultVolume
      }
    }, async () => {
      if (this.state.isPresentationMode) {
        this.mutePlayerVolume();
        await this.state.player.startPresentationMode();
      }
      else {
        await this.state.player.stopPresentationMode();
        this.resetPlayerVolume();
      }
    });

  }

  public onPreviousScenePresentationMode = async () => {
    if (this.state.isPresentationMode) {
      await this.state.player.previousScenePresentationMode();
    }
  }

  public onNextScenePresentationMode = async () => {
    if (this.state.isPresentationMode) {
      await this.state.player.nextScenePresentationMode();
    }
  }

  public handleShowPlayerControls = () => {

    if(!this.props.queryParams.hideControls) {
      this.setState({ showPlayerControls: true })
    }    
  }

  public handleHidePlayerControls = () => {
    
    if(!this.props.queryParams.hideControls) {
      this.setState({ showPlayerControls: false })
    }
  }

  /*public delayHidePlayerControls = () => {
      clearTimeout(this.timeoutPlayerControls)
      this.timeoutPlayerControls = setTimeout(() => {
          this.setState({ showPlayerControls: false })
      }, 5000);
  }*/

  handleVisibilityChange = (isVisible: Boolean, visibilityState: string) => {
    if (visibilityState !== "visible") {
      if (this.state.playState === PlayState.Playing) {
        this.onPause();
        this.setState(prevState => ({
          toogleIconPlayPause: !prevState.toogleIconPlayPause,
        }));
      }
    }
  }

  handleToogleIconPlayPause = async () => {
    if (!this.state.isPresentationMode) {
      this.setState(prevState => ({
        toogleIconPlayPause: !prevState.toogleIconPlayPause,
        isEffectIconPlayPause: false,
        isInitialIconPlay: false,
      }));
      if (this.state.toogleIconPlayPause && this.state.playState !== PlayState.Ended) {
        this.onPause();
      } else if (this.state.playState === PlayState.Ended) {
        await this.onReplay();
      }
      else {
        this.onPlay();
      }
      this.delayEffectIconPlayPause()
    }
    else {
      this.setState({
        toogleIconPlayPause: false,
        isEffectIconPlayPause: false,
        isInitialIconPlay: false,
      });
      await this.state.player.nextScenePresentationMode();
    }

  }

  public delayEffectIconPlayPause = () => {
    clearTimeout(this.timeoutEffectIconPlayPause)
    this.timeoutEffectIconPlayPause = setTimeout(() => {
      this.setState({ isEffectIconPlayPause: true });
    }, 500);
  }

  private resetPlayerVolume() {
    this.state.player.setVolume(this.state.volume);
  }

  private mutePlayerVolume() {
    this.state.player.setVolume(0)
  }

  private renderWatermark() {

    const { t } = this.props;
    const logoLargeEdu = resourcesBaseUrl + "/img/player/logos/logo-large-gray-edu.png";
    const logoLarge = resourcesBaseUrl + "/img/player/logos/logo-large-gray.png";
    const watermarkImage = <img style={isMobileOnly ? styles.logoMobile : styles.logo} src={this.state.player.getWatermarkType() === "edu" ? logoLargeEdu : logoLarge} alt="Wideo" id="logo" />;

    const watermarkStyle = isMobileOnly
    ? { ...styles.rightBottomPositionMobile, ...styles.watermarkBody }
    : { ...styles.rightBottomPosition, ...styles.watermarkBody }; 
    

    if (this.state.player.getWatermark() && this.state.player.isHtmlWatermark()) {
      if (this.state.player.getWatermarkType() !== "edu") {
        return (
          <a target='_blank' href={environments[this.props.queryParams.environment].webUrl.base + '?utm_source=wideoplayer&utm_medium=virality&utm_campaign=cta_player&utm_content=blue_createyourvideo'} rel="noreferrer" >
            <div style={watermarkStyle}>
              {watermarkImage}
              <Fab
                style={{
                  ...isMobileOnly ? styles.buttonFabMobile : styles.buttonFab,
                  ...isMobileOnly ? styles.buttonSizeSmallFabMobile : styles.buttonSizeSmallFab,
                }}
                variant="extended"
                color="primary"
                aria-label={t("player.watermark.text")}
                size={isMobileOnly ? "small" : "medium"}
              >
                {t("player.watermark.text")}
              </Fab>
            </div>
          </a>)
      }
      else {
        return (<div style={{...styles.watermarkBody, ...styles.rightBottomPosition}}>{watermarkImage}</div>);
      }
    } else {
      return <span />
    }
  }

  render() {
    
    const { t } = this.props;

    const { brandName, icon } = BrandThemeProvider.getBrandInfo(this.props.queryParams);

    const contentClass = this.state.hasInteractivity ? styles.contentIconPlayPauseInteractivity : styles.contentIconPlayPause;

    const playerAreaStyle = this.state.playState === PlayState.Loaded || (this.state.playState === PlayState.Stopped && this.state.isInitialIconPlay === true)
    ? { ...styles.playerAreaInitial, ...styles.playerArea }
    : styles.playerArea;   

    const contentIconStyle = this.state.player.getWatermark()
    ? { ...styles.contentIconWatermarkZindex, ...contentClass }
    : { ...styles.contentIconZindex, ...contentClass }

    const effectIconStyle = this.state.isEffectIconPlayPause
    ? { ...styles.effectIconPlayPause, ...styles.centerScreen, ...styles.iconPlayPause }
    : { ...styles.centerScreen, ...styles.iconPlayPause }
            
    return (
      <>
        <Helmet>
          <title>{brandName} - Player</title>
          <link rel="icon" href={icon} />
        </Helmet>
        <PageVisibility onChange={this.handleVisibilityChange}>
          <BrandThemeProvider queryParams={this.props.queryParams}>
            <div
              ref={this.playerPreviewRootDiv}
              id="player-preview-root"
              style={styles.playerApp}
              onMouseLeave={this.handleHidePlayerControls}
              onMouseEnter={this.handleShowPlayerControls}>
              <Loader playState={this.state.playState} queryParams={this.props.queryParams} />
              {this.state.playState === PlayState.Error &&
                <Typography
                  style={styles.centerScreen}
                  align="center"
                  component="h2"
                  variant="h5"
                  color="error"
                >
                  {t("player.state.error")}
                </Typography>
              }

              <div id="canvas-container" style={this.state.playState === PlayState.Error ? styles.hidden : styles.centerwideo}>

                { /* Overlay end of wideo centered with link to Wideo */}
                {this.renderWatermark()}

                <div id="canvas-wrapper"
                  style={styles.wideoCanvasContainer}
                >
                  <canvas ref={this.canvasCallback} />
                </div>

              </div>           
              {!(this.state.playState === PlayState.Loading) && !(this.state.playState === PlayState.Error) &&
                <div
                  style={playerAreaStyle}
                >
                  <div
                    style={contentIconStyle}
                    onClick={this.handleToogleIconPlayPause}
                  >
                    {(this.state.playState === PlayState.Loaded || this.state.playState === PlayState.Stopped) && this.state.isInitialIconPlay === true &&
                      <IconButton
                        style={{...styles.iconInitialPlay, ...styles.centerScreen}}
                      >
                        <PlayIcon id={'playIconCenter'} />
                      </IconButton>
                    }
                    <IconButton
                      style={effectIconStyle}
                    >
                      {this.state.toogleIconPlayPause ? <PlayIcon id={'playPauseButton'} /> : <PauseIcon id={'playPauseButton'} /> }
                    </IconButton>
                  </div>
                  {!this.props.queryParams.hideControls && 
                    <PlayerControls
                      player={this.state.player}
                      wideoLength={this.state.player.getWideoLength()}
                      playState={this.state.playState}
                      onPlay={this.onPlay}
                      onPause={this.onPause}
                      onReplay={this.onReplay}
                      onStop={this.onStop}
                      onSeek={this.onSeek}
                      onPreviousScenePresentationMode={this.onPreviousScenePresentationMode}
                      onNextScenePresentationMode={this.onNextScenePresentationMode}
                      fullScreenRootHtmlElement={this.playerPreviewRootDiv}
                      presentationMode={this.state.isPresentationMode}
                      onTogglePresentationMode={this.onTogglePresentationMode}
                      playerControlsEl={this.state.showPlayerControls}
                      volume={!this.state.isMuted ? this.state.volume : 0}
                      onVolumeChange={this.onVolumeChange}
                      isMuted={this.state.isMuted}
                      onToggleMute={this.onToggleMute}
                      onTimeSliderMouseDown={this.onTimeSliderMouseDown}
                      onTimeSliderMouseUp={this.onTimeSliderMouseUp}
                      onTimeSliderMouseEnter={this.onTimeSliderMouseEnter}
                      onTimeSliderMouseLeave={this.onTimeSliderMouseLeave}
                      handleShowSliderPlayer={this.state.showSliderPlayer}
                      noFullScreenButton={this.props.noFullscreenButton}
                      noToggleModeButton={this.props.noToggleModeButton}
                    />
                  }
                </div>
              }
              {this.props.queryParams.debug && <PlayerDebug {...this.props} {...this.state} />}
            </div>
            {  // This span is used in integration testing for deciding when the Player has finished loading successfully
              this.state.playState === PlayState.Loaded &&
              <span id={'PlayerLoadComplete'} />
            }

          </BrandThemeProvider>
        </PageVisibility>
      </>
    );
  }
}

export default withTranslation("player")(PlayerApp);

