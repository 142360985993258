import * as React from 'react';


import { Theme } from '@mui/material/styles';

import Typography from '@mui/material/Typography';
import { Variant } from '@mui/material/styles/createTypography';

import { WithClock, withClock } from '../../common/ui/WithClock';
import theme from 'src/common/ui/theme';

export const getColor300 = (theme: Theme) => ({
    color: theme.palette.grey[300],
  }); 

const styles = {
  numbers: {
    padding: '0 8px',
    opacity: .9,
    ...getColor300(theme),
    textShadow: '0 0 2px rgba(0,0,0,.5)',
  }
};


interface Props {
    wideoLength: number;
    variant?: Variant; 
}

type Styled = any

type PropsWithStyles = Props & Styled;

//type PropsWithStyles = Props & WithStyles<'numbers'>;

/**
 * PlayerControls handles the play, pause and stop buttons as well as the time display and slider
 */

class TimeDisplay extends React.Component<PropsWithStyles & WithClock> {

    /**
     * return a string of type: "hh:mm:ss" (the "hh" part only if at least an hour has passed)
     *
     * @param {number} timeMillis the time in milliseconds
     *
     */
    getTimeString(millis: number): string {
        const totalSeconds = Math.floor( ( millis + 1 ) / 1000 );
        const seconds = totalSeconds % 60;
        const totalMinutes: number = Math.floor( totalSeconds / 60);
        const minutes: number = totalMinutes % 60;
        const hours: number = Math.floor( totalMinutes / 60);

        const secondsString: string = (seconds < 10 ? '0' + seconds : seconds.toString());
        const minutesString: string = (minutes < 10 ? '0' + minutes : minutes.toString());
        const hoursString: string = (hours < 10 ? '0' + hours : hours.toString());

        return (hours > 0 ? hoursString + ':' : '') + minutesString + ':' + secondsString;
    }

    render() {
        const { variant } = this.props;
        return (
                <div>
                    <Typography cyid={'time'} style={styles.numbers} variant={variant ?? 'body1'} noWrap={true}>
                        {this.getTimeString( this.props.currentTime )}/{this.getTimeString( this.props.wideoLength )}
                    </Typography>
                </div>
        );
    }
}

export default withClock(TimeDisplay);
