import React from 'react'

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

import PublishIcon from '@mui/icons-material/Publish';

interface Props {
  uploadFiles: (e: React.SyntheticEvent<HTMLInputElement>) => void,
  id: string;
  text?: 'image' | 'video' | 'audio'
}

interface State {
}

class UploadButton extends React.Component<Props, State> {

  render() {

    const { 
      id, 
      text = "image" 
    } = this.props; 

    return (
      <Box componment="span" ml={1} mt={text === 'video' ? 0 : 2.5}>  
        <input
          id={id}
          style={{ display: 'none' }}
          type="file"
          accept={`${text}/*`}
          onChange={this.props.uploadFiles}
        />
        <label htmlFor={id}>
            <Tooltip title={`Upload ${text}`}>
              <Button 
                variant="contained"
                color="primary"
                component="span"
                size="small"
                disableElevation
                startIcon={<PublishIcon />}
                >
                Upload {text}
              </Button>
            </Tooltip>
        </label>
      </Box>
    );
  }

  }

  export default UploadButton;  
